import React from 'react';
import AbstractSearchBar from '../../reusable/searchBar/AbstractSearchBar';
import { useAbstractSearchBar } from '../../reusable/searchBar/useAbstractSearchBar';
import { dateFormatter } from '../../../utils/general';
import { listOrders } from '../../../utils/gateway/OrderAPIGateway';
import { IStore } from '../../../interfaces/store.interface';
import { IOrder } from '../../../interfaces/order.interfaces';

export const useOrderSearchBar = useAbstractSearchBar;

const DEFAULT_PLACEHOLDER = 'Search orders';

const fetchOrders = (searchTerm: string, filters: any = {}) => {
  return listOrders({ search: searchTerm, elasticsearch: true }).then((orders) => {
    return orders.filter((order) => !filters?.items?.find((i: any) => i.uuid === order.uuid));
  });
};

export const OrderSearchBar: React.FC<{
  store: IStore;
  setItems: (item: any) => void;
  placeholder?: string;
  props: any;
}> = ({ store, placeholder = DEFAULT_PLACEHOLDER, setItems, ...props }) => {
  const disabled = !store?.uuid;
  return (
    <AbstractSearchBar
      {...props}
      disabled={disabled}
      search={fetchOrders}
      onSearchComplete={setItems}
      itemListDisplay={OrderListDisplay}
      placeholder={placeholder}
    />
  );
};

const OrderListDisplay: React.FC<{
  items: Array<IOrder>;
  onSelectItem: (order: IOrder) => void;
}> = ({ items, onSelectItem }) => {
  return (
    <table className="table">
      <tbody>
        {items.map((order) => (
          <OrderListItem order={order} onSelectItem={onSelectItem} />
        ))}
      </tbody>
    </table>
  );
};

export const OrderListItem: React.FC<{
  order: IOrder;
  onSelectItem: (order: IOrder) => void;
}> = ({ order, onSelectItem }) => {
  // const { order, onSelectItem } = props;
  return (
    <tr
      className="table-body"
      onClick={(e) => onSelectItem(order)}
      style={{
        width: '100%',
        fontVariantNumeric: 'tabular-nums',
      }}
    >
      <td className="has-text-left">
        <p>
          <strong>{order.name}</strong>
        </p>
        <p className="has-text-grey">Created At: {dateFormatter(order.created_at)}</p>
        <p className="has-text-grey">
          {order.ship_date ? 'Shipped At: ' + dateFormatter(order.ship_date) : ''}
        </p>
      </td>
      <td>
        <span>{order.customer?.name} </span>
        {!!order.shipping ? (
          <>
            <p>
              {' '}
              {order?.customer?.first_name} {order?.customer?.last_name}{' '}
            </p>
            <p>
              {order.shipping.address1} {order.shipping.address2}{' '}
            </p>
            <p>
              {' '}
              {order.shipping.province_code} {order.shipping.zipcode}, {order.shipping.country_code}{' '}
            </p>
          </>
        ) : (
          ''
        )}
      </td>
      <td>{/* TODO: show SKU codes  */}</td>
    </tr>
  );
};

export const styles = {
  noResults: {
    backgroundColor: '#F8F8F9',
    fontStyle: 'italic',
  },
};

export default OrderSearchBar;
