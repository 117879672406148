import React from "react";

export const PageFooter = (props) => {
  const { children } = props;
  return (
    <footer className="footer fixed">
      <div className="content">{children}</div>
    </footer>
  );
};
export default PageFooter;
