import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Notification } from 'react-bulma-components';

import { isAuthenticated, isPostworks, isSudoing } from '../utils/authentication.js';
import { UserContext } from './contexts/UserStore.tsx';
import LoginAsDropdown from './LoginAsDropdown.jsx';
import MigrateNotification from './MigrateNotification.jsx';

import {
  PAGE_TITLE as PROJECT_REQUEST_PAGE_TITLE,
  PAGE_URL as PROJECT_REQUEST_PAGE_URL,
} from './pages/ProjectRequest/ProjectRequestHomePage';

class NavBar extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      mobileShowMenu: false,
    };

    this.logout = this.logout.bind(this);
  }

  toggleMobileMenu = (e) => {
    e.preventDefault();
    this.setState({
      mobileShowMenu: !this.state.mobileShowMenu,
    });
  };

  logout = (e) => {
    e.preventDefault();
    this.props.logout();
    // Browser reload clears all context store caches
    window.location.replace('/login');
  };

  render() {
    const isAdmin = this.props.data?.account?.is_superuser;
    const returnsEnabled = isAdmin || this.props.data?.account?.company.returns_enabled === true;
    const inboundEnabled = isAdmin || this.props.data?.account?.company?.inbound_enabled === true;

    const isProjectRequestEnabled = this.props.data?.account?.company.project_requests_enabled;

    const isDelinquent = this.props.data?.account?.company.get_delinquent_invoices;

    const showProjectsInNav = isProjectRequestEnabled || isSudoing();

    return (
      <React.Fragment>
        <nav className='navbar' role='navigation' aria-label='main navigation'>
          <div className='navbar-brand'>
            <Link className='navbar-item' to='/'>
              <img
                src={'/images/' + (isPostworks() ? 'postworks' : 'airhouse') + '-logo.png'}
                width='84.99'
                height='16'
                alt='Logo'
              />
            </Link>

            <Link
              role='button'
              className='navbar-burger burger'
              aria-label='menu'
              aria-expanded='false'
              to=''
              onClick={this.toggleMobileMenu}
            >
              <span aria-hidden='true'></span>
              <span aria-hidden='true'></span>
              <span aria-hidden='true'></span>
            </Link>
          </div>

          {isAuthenticated() ? (
            <div
              id='navbar'
              className={'navbar-menu' + (this.state.mobileShowMenu ? ' is-active' : '')}
            >
              <div className='navbar-start'>
                <Link
                  className={
                    'navbar-item' + (this.props.activeTab === 'inventory' ? ' is-active' : '')
                  }
                  to='/inventory'
                >
                  <span className='icon pr-1'>
                    <i className='icon-ic-inventory' />
                  </span>
                  Inventory
                </Link>
                <Link
                  className={
                    'navbar-item' + (this.props.activeTab === 'orders' ? ' is-active' : '')
                  }
                  to='/orders'
                >
                  <span className='icon pr-1'>
                    <i className='icon-ic-orders' />
                  </span>
                  Orders
                </Link>
                <Link
                  className={
                    'navbar-item' + (this.props.activeTab === 'inbound' ? ' is-active' : '')
                  }
                  to='/inbound'
                >
                  <span className='icon pr-1'>
                    <i className='icon-ic-restock' />
                  </span>
                  Restock
                </Link>
                <Link
                  className={
                    'navbar-item' + (this.props.activeTab === 'billing' ? ' is-active' : '')
                  }
                  to='/billing'
                >
                  <span className='icon pr-1'>
                    <i className='icon-ic-billing' />
                  </span>
                  Billing
                </Link>
                {returnsEnabled && (
                  <Link
                    className={
                      'navbar-item' + (this.props.activeTab === 'returns' ? ' is-active' : '')
                    }
                    to='/returns'
                  >
                    <span className='icon pr-1'>
                      <i className='icon-ic-returns' />
                    </span>
                    Returns
                  </Link>
                )}
                {showProjectsInNav && (
                  <Link
                    className={
                      'navbar-item' +
                      (this.props.activeTab === PROJECT_REQUEST_PAGE_TITLE ? ' is-active' : '')
                    }
                    to={PROJECT_REQUEST_PAGE_URL}
                  >
                    <span className='icon pr-1'>
                      <i className='icon-ic-checkmark' />
                    </span>
                    {PROJECT_REQUEST_PAGE_TITLE}
                  </Link>
                )}
              </div>

              <div className='navbar-end pr-2'>
                {this.context.data.account.is_superuser && (
                  <div className='navbar-item'>
                    <span className='user-badge is-admin'>
                      <i className='icon-ic-admin' aria-hidden='true' />
                    </span>
                  </div>
                )}

                <a
                  className='navbar-item'
                  target='_blank'
                  rel='noopener'
                  href='https://form.typeform.com/to/dgac6o1s'
                >
                  <span className='icon pr-1'>
                    <i className='icon-ic-link' />
                  </span>
                  Refer a Company
                </a>

                <a
                  className='navbar-item'
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://help.airhouse.io/'
                >
                  <span className='icon pr-1'>
                    <i className='icon-ic-help' />
                  </span>
                  Help &amp; Docs
                </a>

                {this.state.mobileShowMenu ? (
                  <React.Fragment>
                    <Link
                      className={
                        'navbar-item' + (this.props.activeTab === 'settings' ? ' is-active' : '')
                      }
                      to='/settings/user'
                    >
                      Settings
                    </Link>
                    <Link className='navbar-item' onClick={this.logout} to=''>
                      Sign Out
                    </Link>
                  </React.Fragment>
                ) : (
                  <div className='navbar-item has-dropdown is-hoverable'>
                    <Link className='navbar-link' to='/settings/user'>
                      {this.context.data.account.email}
                      <span className='icon is-small pl-1'>
                        <i className='icon-ic-caret' />
                      </span>
                    </Link>
                    <div className='navbar-dropdown is-right'>
                      <Link
                        className={
                          'navbar-item' + (this.props.activeTab === 'settings' ? ' is-active' : '')
                        }
                        to='/settings/user'
                      >
                        Settings
                      </Link>
                      <Link className='navbar-item' onClick={this.logout} to=''>
                        Sign Out
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </nav>

        {!!localStorage.getItem('sudoToken') && !this.context.loading && !!this.props.data ? (
          // Sudo mode
          <Notification className='is-admin'>
            <div className='is-flex is-justify-content-center'>
              <div>
                <i className='icon-ic-sudo mr-2' aria-hidden='true'></i>
                <strong>{this.props.data.account.company.name}</strong>
              </div>
              <div className='ml-2' style={styles.vCenterSudoBar}>
                <LoginAsDropdown getSudo={this.props.getSudo} setSudo={this.props.setSudo} />
              </div>
              <button
                className='button is-small is-admin ml-2'
                onClick={this.context.exitSudo}
                style={styles.vCenterSudoBar}
              >
                Logout
              </button>
            </div>
          </Notification>
        ) : null}

        {this.props.customNotification && this.props.customNotification}

        {!this.context.loading && this.props.activeTab !== 'onboarding' && this.context.isDemo() ? (
          // Demo mode
          <Notification className='has-text-centered'>
            <i className='fas fa-plug mr-2' aria-hidden='true'></i>
            You are in demo mode. Connect your store to complete onboarding.
            <Link
              className='button is-small is-warning ml-2'
              to='/?connectShopify=initiate'
              style={styles.vCenterSudoBar}
            >
              Connect Store
            </Link>
          </Notification>
        ) : // Migration mode
        !this.context.loading &&
          !!this.props.data &&
          this.props.activeTab !== 'onboarding' &&
          isPostworks() ? (
          <MigrateNotification account={this.props.data.account} />
        ) : isDelinquent?.length > 0 ? (
          <Notification className='has-text-centered is-danger'>
            To avoid fulfillment interruptions, please pay your overdue invoice(s) by{' '}
            {isDelinquent[0]?.date_range_suspension}
          </Notification>
        ) : null}
      </React.Fragment>
    );
  }
}

const styles = {
  vCenterSudoBar: {
    marginTop: '-0.25rem',
  },
};

export default NavBar;
