import React, { useContext, useEffect, useState } from "react";
import { Modal, Columns } from "react-bulma-components";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../../contexts/UserStore";
import { PaymentModalContext } from "./PaymentStore";

export const maxDaysBeforePopupHavePassed = 1;

export const PaymentModal = () => {
  const history = useHistory();
  const location = useLocation();
  const userContext = useContext(UserContext);
  const paymentModalContext = useContext(PaymentModalContext);
  const [isSuperuser, setIsSuperuser] = useState(false);

  useEffect(() => {
    paymentModalContext.setDelinquent(userContext.data?.account?.company?.get_delinquent_invoices || false);
    paymentModalContext.setSuspended(userContext.data?.account?.company?.get_suspended_invoices || false);
    const lastAccessedDate = localStorage.getItem("paymentModalPopupLastAccessed");
    const lastAccessedDays =
      Math.round((new Date() - new Date(lastAccessedDate)) / (1000 * 3600 * 24)) >
      maxDaysBeforePopupHavePassed;
    const path = location.pathname.split("/");
    const ignorePaths = ![
      "billing",
      "login",
      "privacy",
      "register",
      "forgot-password",
      "reset-password",
      "settings",
    ].includes(path[1]);

    setIsSuperuser(userContext.data?.isAdmin);

    const showPopUp =
      !userContext.loading &&
      ignorePaths &&
      ((userContext.data?.account?.company?.get_delinquent_invoices?.length > 0 &&
        (lastAccessedDate == null || lastAccessedDays)) ||
        userContext.data?.account?.company?.get_suspended_invoices?.length > 0);
    paymentModalContext.setShowLate(showPopUp);
  }, [userContext.store, location]);

  const onClose = () => {
    localStorage.setItem("paymentModalPopupLastAccessed", new Date());
    paymentModalContext.setShowLate(false);
  };

  const onGoToBilling = () => {
    if (!PaymentModalContext.suspended) {
      localStorage.setItem("paymentModalPopupLastAccessed", new Date());
    }
    paymentModalContext.setShowLate(false);
    history.push(`/billing/${userContext.data.account.company.get_delinquent_invoices[0].uuid}`);
  };

  if (isSuperuser) {
    return <></>;
  }

  if (paymentModalContext.suspended.length > 0) {
    return (
      <PaymentModalSuspended
        onClose={onClose}
        onGoToBilling={onGoToBilling}
        isSuperuser={isSuperuser}
      />
    );
  }
  if (paymentModalContext.delinquent.length > 0) {
    return <PaymentModalDelinquent onClose={onClose} onGoToBilling={onGoToBilling} />;
  }
  return <></>;
};

export const PaymentModalDelinquent = (props) => {
  const { onClose, onGoToBilling } = props;
  const userContext = useContext(UserContext);
  const paymentModalContext = useContext(PaymentModalContext);

  return (
    <Modal show={paymentModalContext.showLate} onClose={onClose}>
      <Modal.Card>
        <Modal.Card.Header onClose={onClose}>
          <Modal.Card.Title>You have an overdue invoices</Modal.Card.Title>
        </Modal.Card.Header>
        <Modal.Card.Body>
          <p>
            You currently have an overdue invoice from{" "}
            {userContext.data.account.company.get_delinquent_invoices[0].due_date}
          </p>
          <br />
          <p>
            Please note that on {userContext.data.account.company.get_delinquent_invoices[0].date_range_suspension},{" "}
            <span className="has-text-weight-bold">
              Airhouse will pause fulfillment and your orders will not ship{" "}
            </span>
            until payment is submitted. To avoid interruptions to your fulfillment please submit
            payment before {userContext.data.account.company.get_delinquent_invoices[0].date_range_suspension}.
          </p>
          <br />
          <p>
            If you have any questions, contact us at{" "}
            <a href="mailto:team@airhouse.io">team@airhouse.io</a>
          </p>
        </Modal.Card.Body>
        <Modal.Card.Footer>
          <Columns className="" style={{ flex: "auto", float: "right", textAlign: "right" }}>
            <Columns.Column>
              <button className="button is-primary is-right mr-4" onClick={onGoToBilling}>
                Pay Invoice
              </button>
            </Columns.Column>
          </Columns>
        </Modal.Card.Footer>
      </Modal.Card>
    </Modal>
  );
};

export const PaymentModalSuspended = (props) => {
  const { onClose, onGoToBilling, isSuperuser } = props;
  const paymentModalContext = useContext(PaymentModalContext);

  return (
    <Modal show={paymentModalContext.showLate} onClose={onClose}>
      <Modal.Card>
        <>
          <Modal.Card.Header showClose={isSuperuser} onClose={onClose}>
            <Modal.Card.Title>Your service is paused</Modal.Card.Title>
          </Modal.Card.Header>
          <Modal.Card.Body>
            <p>
              Due to unpaid invoices, we have temporarily{" "}
              <span className="has-text-weight-bold">
                paused your fulfillment and orders will not ship{" "}
              </span>
              to avoid incurring additional shipping and fulfillment fees.
            </p>
            <br />
            <p>
              To resume fulfillment, please submit payment for any outstanding invoices as soon as
              possible. Once payment is submitted and received, Airhouse will{" "}
              <span className="has-text-weight-bold">automatically resume fulfillment </span>
              and process any outstanding orders.
            </p>
            <br />
            <p>
              If you have any questions, contact us at{" "}
              <a href="mailto:team@airhouse.io">team@airhouse.io</a>.
            </p>{" "}
          </Modal.Card.Body>
          <Modal.Card.Footer>
            <Columns className="" style={{ flex: "auto", float: "right", textAlign: "right" }}>
              <Columns.Column>
                <button className="button is-primary is-right mr-4" onClick={onGoToBilling}>
                  Pay Invoice to resume
                </button>
              </Columns.Column>
            </Columns>
          </Modal.Card.Footer>
        </>
      </Modal.Card>
    </Modal>
  );
};
