import React from "react";

import InboundSearchBar from "../../Inbound/InboundSearchBar";
import { dateFormatter } from "../../../../utils/general";
import { RemoveItemButton } from "../../../reusable/page/buttons/RemoveItemButton";
import { Link } from "react-router-dom";

export const SelectInbounds = (props) => {
  const { inbounds, addInbound, removeInbound, store } = props;

  const onSelectInboundCustomHandler = props?.onSelectInbound || ((inbound) => {});

  const onSelectInbound = (inbound) => {
    onSelectInboundCustomHandler(inbound);
    addInbound(inbound);
  };

  const filters = {
    ...props.filters,
    store,
    items: inbounds,
  }

  return (
    <>
      <InboundSearchBar
        onSelectItem={onSelectInbound}
        filters={filters}
        {...props.inboundSearchBarState}
        store={store}
      />
      <InboundList inbounds={inbounds} onDelete={removeInbound} />
    </>
  );
};

export const InboundList = (props) => {
  const { inbounds, onDelete } = props;
  return <table>{inbounds.map((inbound) => (
    <tr
      className="table-body"
      style={{
        width: "100%",
        fontVariantNumeric: "tabular-nums"
      }}
    >
      <td className="has-text-left">
        <p>
          <strong><Link to={`/inbound/edit/${inbound.uuid}`}>{inbound.name}</Link></strong>
        </p>
        <p className="has-text-grey">
          Created At: {dateFormatter(inbound.created_at)}
        </p>
        <p className="has-text-grey">
          {inbound.ship_date
            ? "Shipped At: " + dateFormatter(inbound.ship_date)
            : ""}
        </p>
      </td>
      <td>
        <span>{inbound.customer?.name} </span>
        {!!inbound.shipping ? (
          <>
            <p>
              {" "}
              {inbound?.customer?.first_name} {inbound?.customer?.last_name}{" "}
            </p>
            <p>
              {inbound.shipping.address1} {inbound.shipping.address2}{" "}
            </p>
            <p>
              {" "}
              {inbound.shipping.province_code} {inbound.shipping.zipcode},{" "}
              {inbound.shipping.country_code}{" "}
            </p>
          </>
        ) : (
          ""
        )}
      </td>
      {onDelete && <td><RemoveItemButton onClick={() => {
        onDelete(inbound)
      }} /></td>}
    </tr>
  ))}</table>;
}