import React, { useEffect } from "react";
import { listProjectRequestTypes } from "../../utils/gateway/ProjectRequestTypeAPIGateway";

export const defaultState = {
  data: null,
  errors: {},
  isLoading: true,
  queryString: "",
  totalSize: 0,
  isStoreDropdownActive: false,
  projectRequestTypes: [],
};

export const ProjectRequestContext = React.createContext(defaultState);

export const ProjectRequestStore = (props) => {
  const [state, setState] = React.useState(defaultState);

  const setProjectRequestTypes = (projectRequestTypes) => {
    setState((prevState) => ({
      ...prevState,
      projectRequestTypes,
      isLoading: false,
    }));
  };

  const getProjectRequestType = (projectRequestTypeId = null) => {
    state.projectRequestTypes.filter(
      (item) => item.uuid == projectRequestTypeId
    );
  };

  const setStore = (store) => {
    setState((prevState) => ({
      ...prevState,
      store,
    }));
  };

  return (
    <ProjectRequestContext.Provider
      value={{
        ...state,
        setStore,
        getProjectRequestType,
        setProjectRequestTypes,
      }}
    >
      {props.children}
    </ProjectRequestContext.Provider>
  );
};

export default ProjectRequestStore;
