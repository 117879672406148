import { Ok, Err, Result } from "ts-results";
import {
  IOrderFiles,
  IOrderPayload,
} from "../../components/pages/Orders/NewOrder/config/newOrder.interface";
import { get, getZip, post } from "../requests";
import { IFilters, IGatewayError } from "./gateway.interfaces";
import { IOrder } from "../../interfaces/order.interfaces";

const ORDER_API_ENDPOINT = "api/orders/";
const ORDER_API_CSV_EXPORT_ENDPOINT = "api/orders/export_grid/";
const ORDERS_BY_NAME_ENDPOINT = "api/orders-by-name/";

export const getOrderAttachmentUrl = (uuid: string, documentUuid: string) =>
  `${ORDER_API_ENDPOINT}${uuid}/attachments/${documentUuid}`;

export const listOrders = (filters: IFilters = {}): Promise<IOrder[]> => {
  filters.type = "SALES";

  return get(ORDER_API_ENDPOINT, filters).then((response) =>
    response["results"].map(convertOrderAPIResponse)
  );
};

export const getOrders = (options: any) => {
  const url = `${ORDER_API_ENDPOINT}?${new URLSearchParams(options)}`;
  return get(url).then((response) => ({
    success: true,
    results: response.results.map(convertOrderAPIResponse),
    count: response.count,
  }));
};

export const getOrderById = async <T = IOrder,>(id: string): Promise<Result<T, IGatewayError>> => {
  try {
    const result = await get(ORDER_API_ENDPOINT + id + "/").then((response) =>
      convertOrderAPIResponse(response)
    );
    return Ok(result);
  } catch (error: any) {
    return Err(error);
  }
};

export const convertOrderAPIResponse = (orderAPIResponse: any) => {
  return {
    uuid: orderAPIResponse.uuid,
    ...orderAPIResponse,
  };
};

export const exportOrderGridCsv = (options: IFilters) => {
  const url = `${ORDER_API_CSV_EXPORT_ENDPOINT}?${new URLSearchParams(options)}`;
  return getZip(url);
};

export const getOrdersByName = (store: IFilters, names: string[]) => {
  const payload = {
    store,
    names,
  };
  // post so we don't need to concatenate names into a comma separated list
  return post(ORDERS_BY_NAME_ENDPOINT, payload);
};

export const createOrder = async (order: IOrderPayload): Promise<Result<IOrder, IGatewayError>> => {
  try {
    const response = await post(ORDER_API_ENDPOINT, order);
    return Ok(response);
  } catch (error) {
    return Err(error as IGatewayError);
  }
};

export const addOrderAttachment = (orderId: string, file: FormData): Promise<void> => {
  return post(`${ORDER_API_ENDPOINT}${orderId}/attachment/`, null, file);
};

export const addOrderAttachments = (orderId: string, files: IOrderFiles[]): Array<Promise<any>> => {
  const uploadTasks: any = [];
  files.forEach((file) => {
    const formData = new FormData();
    formData.append("order", orderId);
    formData.append("file", file.file);
    uploadTasks.push(addOrderAttachment(orderId, formData));
  });
  return uploadTasks;
};
