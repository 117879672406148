import React, { useCallback } from "react";
import { Loader } from "react-bulma-components";

import externalStyles from "../../../utils/styles";
import { toast } from "react-toastify";
import messages from "../../../utils/messages";

const DEFAULT_PLACEHOLDER = "Search items.";

export const AbstractSearchBar = (props) => {

  const searchBarRef = React.useRef(null);

  const { searchTerm, onSearchComplete, items, filters, isLoading, isMenuOpen, disabled } = props;
  const { setSearchTerm, hideMenu, showMenu, resetSearchMenu } = props;
  const { onSelectItem, search } = props;
  const { itemListDisplay: ItemListDisplay } = props;
  const placeholder = props.placeholder || DEFAULT_PLACEHOLDER;



  const debouncedSearch = _.debounce((searchTerm, filters) => {
    search(searchTerm, filters)
      .then(onSearchComplete)
      .catch(() => {
        toast.error("There was an error when trying to search results");
      });
  }, 1000);

  const searchCallback = useCallback(debouncedSearch, []);


  const onClickItem = (item) => {
    onSelectItem(item);
    resetSearchMenu();
  };

  const onClickOutside = (event) => {
    if (searchBarRef.current && !searchBarRef.current.contains(event.target) && isMenuOpen) {
      hideMenu();
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  });

  const onChangeSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    searchCallback(searchTerm, filters);
  };

  const onFocusItem = (e) => {
    showMenu();
    searchCallback(searchTerm, filters);
  };

  return (
    <>
      <div
        ref={searchBarRef}
        className={'dropdown is-fullwidth mb-3 ' + (isMenuOpen ? 'is-active' : '')}
      >
        <div className='dropdown-trigger is-fullwidth'>
          <input
            aria-controls='dropdown-menu'
            aria-haspopup='true'
            className='input'
            disabled={disabled}
            onChange={onChangeSearch}
            onFocus={onFocusItem}
            placeholder={placeholder}
            type='text'
            value={searchTerm}
          />
        </div>

        <div className='dropdown-menu is-fullwidth' id='dropdown-menu' role='menu'>
          {isLoading ? (
            <div className='dropdown-content p-4'>
              <Loader style={externalStyles.littleSpinner} />
            </div>
          ) : (
            <div className='dropdown-content'>
              {!!items?.length ? (
                <ItemListDisplay
                  className='dropdown-item'
                  isDropdown={true}
                  keyPrefix='item-search-'
                  onSelectItem={(item) => onClickItem(item)}
                  options={{
                    showSKU: true,
                    showStockStatus: true,
                  }}
                  items={items}
                />
              ) : (
                <div className='has-text-grey p-2' style={styles.noResults}>
                  No results found.
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};


export const styles = {
  noResults: {
    backgroundColor: "#F8F8F9", fontStyle: "italic"
  }
};


export default AbstractSearchBar;
