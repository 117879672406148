export const getToken = () =>
  localStorage.getItem('sudoToken') || localStorage.getItem('token');

export const getSudoerToken = () =>
  isSudoing() ? localStorage.getItem('token') : null;

export const isAuthenticated = () =>
  getToken() !== null;

export const isSudoing = () =>
  !!localStorage.getItem('sudoToken');

export const isPostworks = () =>
  window.location.hostname.includes('postworks.io');

export const isLocalhost = () =>
  window.location.hostname.includes('localhost');