import { IVariant } from "../../interfaces/variant.interfaces";
import { get, postResponse } from "../requests";
import { getSudoerToken, getToken, isSudoing } from "../authentication";

export const VARIANT_API_URL = "api/variants/";

export const getVariants = async (searchTerm: any, filters: any = {}) => {
  const { store, orders, restocks } = filters;

  const variantsInOrder =
    orders
      ?.map((i: any) => i.items)
      .flatMap((i: any) => i)
      .map((i: any) => i.variant.sku) || [];

  const variantsInRestock =
    restocks
      ?.map((i: any) => i.items)
      .flatMap((i: any) => i)
      .map((i: any) => i.variant.sku) || [];

  const eligibleVariants = [...variantsInOrder, ...variantsInRestock].filter(
    (value, index, self) => self.indexOf(value) === index
  );

  const requestFilters: any = {
    search: searchTerm,
    ordering: "-available",
    inventory__items__available__gte: 0,
    should_fulfill: true,
    product__store: store?.uuid,
  };

  if (eligibleVariants?.length) {
    requestFilters.variant__in = eligibleVariants.join(",");
  }

  try {
    const data = await get(VARIANT_API_URL, requestFilters);
    return data.results;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getInboundOrderVariants = async (searchTerm: any, store: any) => {
  const queryString = `${
    "ordering=-available" +
    "&virtual=false" +
    "&should_fulfill=true" +
    `&product__store=${store?.uuid}`
  }${searchTerm ? `&search=${searchTerm}` : ""}`;

  try {
    const url = `${VARIANT_API_URL}?${queryString}`;
    const data = await get(url);
    return data.results;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getVariantById = async (id: string): Promise<IVariant> => {
  return get(`${VARIANT_API_URL}${id}/`);
};

export const exportInventoryItemCSV = async () => {
  const queryString = `should_fulfill=true&token=${getToken()}` + (isSudoing() ? '&sudoer=' + getSudoerToken() : '');
  const url = `tasks/export-inventory-items/?${queryString}`;
  try {
    return await postResponse(url);
  } catch (err) {
    console.error(err);
  }
};
