import _ from "lodash";
import React from "react";
import { OrderContext } from "../../contexts/OrderStore";
import { dateFormatter } from "../../../utils/general";
import { useAbstractSearchBar } from "../../reusable/searchBar/useAbstractSearchBar";
import AbstractSearchBar from "../../reusable/searchBar/AbstractSearchBar";
import { listRestocks } from "../../../utils/gateway/RestockAPIGateway";

export const useRestockSearchBar = useAbstractSearchBar;

const DEFAULT_PLACEHOLDER = "Search restocks";

const fetchRestocks = async (searchTerm, filters) => {
  const restocks = await listRestocks({search: searchTerm, warehouseId: filters?.warehouseId})
  const { items } = filters;
  return restocks.filter(r => !items.find((i) => i.uuid === r.uuid));
};


export const RestockSearchBar = (props) => {
  const { store } = props;
  const { setItems } = props;
  const placeholder = props.placeholder || DEFAULT_PLACEHOLDER;

  const disabled = !store?.uuid;
  return (
    <AbstractSearchBar
      {...props}
      disabled={disabled}
      search={fetchRestocks}
      onSearchComplete={setItems}
      itemListDisplay={RestockListDisplay}
      placeholder={placeholder}
    />
  );
};

export const RestockListDisplay = (props) => {
  const { items, onSelectItem } = props;
  return (
    <table className="table">
      <tbody>
        {items.map((restock) => (
          <RestockListItem restock={restock} onSelectItem={onSelectItem} />
        ))}
      </tbody>
    </table>
  );
};

export const RestockListItem = (props) => {
  const { restock: order, onSelectItem } = props;
  return (
    <tr
      className="table-body"
      onClick={(e) => onSelectItem(order)}
      style={{
        width: "100%",
        fontVariantNumeric: "tabular-nums",
      }}
    >
      <td className="has-text-left">
        <p>
          <strong>{order.name}</strong>
        </p>
        <p className="has-text-grey">
          Created At: {dateFormatter(order.created_at)}
        </p>
        <p className="has-text-grey">
          {order.ship_date
            ? "Shipped At: " + dateFormatter(order.ship_date)
            : ""}
        </p>
      </td>
      <td>
        <span>{order.customer?.name} </span>
        {!!order.shipping ? (
          <>
            <p>
              {" "}
              {order?.customer?.first_name} {order?.customer?.last_name}{" "}
            </p>
            <p>
              {order.shipping.address1} {order.shipping.address2}{" "}
            </p>
            <p>
              {" "}
              {order.shipping.province_code} {order.shipping.zipcode},{" "}
              {order.shipping.country_code}{" "}
            </p>
          </>
        ) : (
          ""
        )}
      </td>
      <td>{/* TODO: show SKU codes  */}</td>
    </tr>
  );
};

export const styles = {
  noResults: {
    backgroundColor: "#F8F8F9",
    fontStyle: "italic",
  },
};

export default RestockSearchBar;
