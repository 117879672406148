import React from 'react';

const DEFAULT_TRACKING_CARRIER_STATE = '';

export const useTrackingCarrier = () => {
  const [trackingCarrier, setTrackingCarrier] = React.useState(
    DEFAULT_TRACKING_CARRIER_STATE
  );
  return [trackingCarrier, setTrackingCarrier];
};

export const TrackingCarrier = ({ trackingCarrier, setTrackingCarrier }) => {
  const onTrackingCarrierChange = (e) => {
    setTrackingCarrier(e.target.value);
  };

  return (
    <span className="select">
      <select value={trackingCarrier} onChange={onTrackingCarrierChange}>
        <option value="">Select Carrier</option>
        <option>USPS</option>
        <option>UPS</option>
        <option>FedEx</option>
        <option>DHL</option>
        <option value="">Not sure</option>
      </select>
    </span>
  );
};
