import { useState } from "react";
import { useRestockSearchBar } from "../../Restock/RestockSearchBar";

export const SELECT_RESTOCKS_DEFAULT_STATE = {
  restocks: [],
};

export const useSelectRestocks = (newState = {}) => {
  const [state, setState] = useState({
    ...SELECT_RESTOCKS_DEFAULT_STATE,
    restocks: newState.restocks || []
  });

  const addRestock = (restock) => {
    setState((prevState) => ({
      ...prevState,
      restocks: [...prevState.restocks, restock],
    }));
  };

  const restockSearchBarState = useRestockSearchBar();

  const removeRestock = (restock) => {
    setState((prevState) => ({
      ...prevState,
      restocks: prevState.restocks.filter((o) => o !== restock),
    }));
  };

  const loadRestocks = (restocks) => {
    setState((prevState) => ({
      ...prevState,
      restocks,
    }));
  }

  const resetRestocks = () => {
    setState((prevState) => ({
      ...prevState,
      restocks: [],
    }));
  };

  return {
    ...state,
    addRestock,
    removeRestock,
    resetRestocks,
    loadRestocks,
    restockSearchBarState,
  };
};
