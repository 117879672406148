import React from "react";
import { Columns } from "react-bulma-components";

interface IRecipientModalInput {
  value: string;
  error: string;
  placeholder: string;
  children?: React.ReactNode;
  onChange: (value: string) => void;
}
export const RecipientModalInput: React.FC<IRecipientModalInput> = ({
  value,
  placeholder,
  error,
  children,
  onChange,
}) => {
  return (
    <Columns.Column>
      <div className="field m-0 p-0">
        <div className="control has-icons-right">
          <input
            className="input"
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
          {!!error && (
            <span className="icon is-small is-right">
              <i className="icon-ic-warning"></i>
            </span>
          )}
        </div>
      </div>
      {!!error && <div className="help is-danger is-size-7 py-2 mb-0">{error}</div>}
      {children}
    </Columns.Column>
  );
};
