import React from "react";

export const DEFAULT_PROJECT_INSTRUCTION_STATE = {
  instructions: ""
};

export const useProjectInstructions = (newState = {}) => {
  const [state, setState] = React.useState({...DEFAULT_PROJECT_INSTRUCTION_STATE, ...newState});


  const setInstructions = (instructions) => {
    setState(prevState => ({
      ...prevState,
      instructions}))
  };



  return {...state, setInstructions};
};

export const ProjectInstructions = (props) => {
  const { instructions, setInstructions } = props;

  const onInstructionChange = (e) => {
    const instructions = e.target.value;
    setInstructions(instructions)
  };


  return <textarea
    className="textarea is-small mb-1"
    value={instructions}
    onChange={onInstructionChange}
  />;
}