import { Columns } from "react-bulma-components";
import { Img } from "./Img";
import apiEndpoints from "../../../utils/apiEndpoints";
import { formatBytes } from "../../../utils/formatting/byteFormatter";
import React from "react";
import { FILE_TYPE, LINK_TYPE } from "./FileAttachementUpload";

export const FileAttachmentList = (props) => {
  const { items, updateItem, deleteItem, readOnly } = props;

  const onUpdateItem = (item) => {
    updateItem(item);
  };

  const onDeleteItem = (item) => {
    deleteItem(item);
  };

  const canDeleteDocuments = !!deleteItem;
  const canUpdateDocuments = !!updateItem;

  return (
    items.length > 0 && (
      <div className="mb-6">
        <p className={"subtitle is-6 mb-3"}>Uploaded Documents</p>

        {items.map((item) => (
          <FileAttachmentEntry
            key={item.id}
            data={item}
            canDeleteDocuments={canDeleteDocuments}
            canUpdateDocuments={canUpdateDocuments}
            updateUploadedDocument={onUpdateItem}
            deleteUploadedDocument={() => onDeleteItem(item)}
            readOnly={readOnly}
          />
        ))}
      </div>
    )
  );
};

export const FileAttachmentEntry = (props) => {
  const {
    data,
    canUpdateDocuments,
    canDeleteDocuments,
    updateUploadedDocument,
    deleteUploadedDocument,
    readOnly,
  } = props;
  const { file, type, fileContent, url } = data;
  const { setIsInvalidURLError, isInvalidURLError } = data;

  const isFile = type === FILE_TYPE;
  const isLink = type === LINK_TYPE;

  return (
    <div>
      <Columns>
        <Columns.Column className="is-11">
          {isFile && (
            <FileAttachment file={file} fileContent={fileContent} url={url} />
          )}
          {isLink && (
            <LinkAttachment
              url={url}
              isInvalidURLError={isInvalidURLError}
              setIsInvalidURLError={setIsInvalidURLError}
            />
          )}
        </Columns.Column>
        <Columns.Column className="is-1">
          {!readOnly && canDeleteDocuments && (
            <i
              className="icon-ic-trash has-text-grey-light is-icon"
              onClick={deleteUploadedDocument}
            />
          )}
        </Columns.Column>
      </Columns>
    </div>
  );
};

export const FileAttachment = (props) => {
  const { file, fileContent, url } = props;
  return (
    <Columns>
      <Columns.Column className="is-narrow" style={{ alignSelf: "center" }}>
        <Img src={fileContent} alt={file.name} fileName={file.name} />
      </Columns.Column>
      <Columns.Column>
        <div className="has-text-grey">
          {url != null && (
            <a
              href="#"
              onClick={() => apiEndpoints.downloadFile(url, file.name)}
            >
              {file.name}
            </a>
          )}
          {url == null && file.name}
          <span>{formatBytes(file.size)}</span>
        </div>
      </Columns.Column>
    </Columns>
  );
};

export const LinkAttachment = (props) => {
  return props.url;
};
