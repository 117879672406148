import React from "react";
import { Level, Loader } from "react-bulma-components";
import externalStyles from "../../../../utils/styles";

export const PageLoadingBar = () => (
  <Level>
    <Level.Item>
      <Loader style={externalStyles.bigSpinner} />
    </Level.Item>
  </Level>
);
