import { useState } from "react";

export const DEFAULT_PAGE_STATE = {
  isLoading: true,
};

const useInboundOrderPage = () => {
  const [pageState, setPageState] = useState(DEFAULT_PAGE_STATE);

  const setIsLoading = (isLoading) => {
    setPageState((prevState) => ({
      ...prevState,
      isLoading,
    }));
  };

  return {
    ...pageState,
    setIsLoading,
  };
};

export default useInboundOrderPage;
