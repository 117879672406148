import React, { Component } from 'react';

import apiEndpoints from '../../utils/apiEndpoints.js';


const defaultState = {
  data: [],
  isLoading: true,
  queryString: '',
  totalCount: 0,
}

const buildQueryString = (options) => {
  const parseFilters = (filterModel) => Object.entries(filterModel).map((column) =>
    [column[0]] + '=' + column[1].filter
  ).join('&');

  const filters = parseFilters(options.filterModel);
  const search = options?.searchTerm && 'search=' + options.searchTerm;
  const offset = options?.offset && 'offset=' + options.offset;

  return [filters, search, offset].filter(item => !!item).join('&');
}


export const InvoiceContext = React.createContext(defaultState);


export default class InvoiceStore extends Component {
  state = defaultState;

  getInvoices = async (options) => {
    const queryString = buildQueryString(options);

    // Data is cached and no querystring changes
    if (!this.state.isLoading && options?.forceReload === false && (queryString === this.state.queryString)) {
      return this.state.data;
    }

    this.setState({ isLoading: true });

    try {
      const url = apiEndpoints.API_BASE + `api/invoices/` + (queryString ? `?${queryString}` : '');
      const response = await apiEndpoints.getData(url);
      const data = await response.json();

      this.setState({
        data: data.results,
        isLoading: false,
        queryString: queryString,
        totalCount: data.count,
      });
      return data.results;
    } catch (err) {
      console.error(err);
    }
  }

  clearCache = () =>
    this.setState({
      data: [],
      isLoading: true,
    });

  render() {
    return (
      <InvoiceContext.Provider value={{
        ...this.state,
        getInvoices: this.getInvoices,
        clearCache: this.clearCache,
      }}>
        {this.props.children}
      </InvoiceContext.Provider>
    );
  }
}
