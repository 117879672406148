import React, { useEffect, useState } from "react";
import NavBar from "../../NavBar";
import { Link, useHistory } from "react-router-dom";
import { PageHeader } from "../../reusable/page/PageHeader";
import PageFooter from "../../reusable/page/PageFooter";
import externalStyles from "../../../utils/styles";
import variables from '../../../sass/export.module.scss';


import {
  PAGE_TITLE as PROJECT_REQUEST_PAGE_TITLE,
  PAGE_URL as PROJECT_REQUEST_PAGE_URL,
} from "./ProjectRequestHomePage";
import PageBody from "../../reusable/page/PageBody";
import { isSudoing } from "../../../utils/authentication";
import { UserContext } from "../../contexts/UserStore";
import { createProjectRequest } from "../../../utils/gateway/ProjectRequestAPIGateway";
import { SaveButton } from "../../reusable/page/buttons/SaveButton";
import { SelectProjectRequestTypeSection } from "./sections/SelectProjectRequestTypeSection";
import { useSelectProjectRequestType } from "./fields/SelectProjectRequestType";
import ProjectRequestForm from "./components/ProjectRequestForm";
import { useCreateProjectRequestPage } from "./useCreateProjectRequestPage";
import { PAGE_URL as EDIT_PAGE_URL } from "./EditProjectRequestPage";
import { useSelectStore } from "./fields/SelectStore";
import { SelectStoreSection } from "./sections/SelectStoreSection";
import { toast } from "react-toastify";
import WholesaleCostEstimator from "./components/WholesaleCostEstimator";

export const PAGE_URL = "/projects/new";
export const PAGE_TITLE = "Create Project Request";

export const CreateProjectRequestPage = (props) => {
  const userContext = React.useContext(UserContext);
  const history = useHistory();
  const [isFreight, setIsFreight] = useState(false);

  const {
    formState: newFormState,
    projectRequestType,
    store: newStore,
  } = history.location.state || {};
  const isProjectRequestEnabled = props.data.account.company.project_requests_enabled;

  const hasProjectRequests = isProjectRequestEnabled || isSudoing();
  const companyStores = props.data.account.company.stores || [];

  const defaultStore = newStore ? newStore : userContext.store;
  const projectRequestTypeState = useSelectProjectRequestType(projectRequestType);
  const selectStoreState = useSelectStore({ currentItem: defaultStore });

  useEffect(() => {
    if (userContext.store && !newStore) {
      selectStoreState.setCurrentItem(userContext.store);
    }
  }, [userContext.store]);

  const { isSaving, isLoading, setIsLoading, formState, setIsSaving } = useCreateProjectRequestPage(
    { formState: newFormState }
  );

  const hasCostEstimator = projectRequestTypeState?.currentItem.hasOrders;


  useEffect(() => {
    setIsLoading(projectRequestTypeState.isLoading);
  }, [projectRequestTypeState.isLoading]);

  const onCreateProjectRequest = () => {
    if (!formState.selectWarehouseState.currentItem.uuid) {
      toast.error("Warehouse is a required field");
      return;
    }

    setIsSaving(true);
    const projectRequestState = {
      ...formState,
      projectRequestTypeState,
      isFreight,
    };

    createProjectRequest(projectRequestState, store)
      .then(onCreateSuccess)
      .catch(onCreateError)
      .finally(afterCreate);
  };

  const onCreateError = () => {};

  const onCreateSuccess = (projectRequest) => {
    props.history.push(EDIT_PAGE_URL.replace(":projectRequestId", projectRequest.uuid));
  };

  const afterCreate = () => {
    setIsSaving(false);
  };
  const onStoreSelected = () => {
    formState.resetWarehouse();
  };
  const isProjectRequestTypeSelected = projectRequestTypeState.currentItem?.uuid;

  const isSaveDisabled = isSaving || isLoading || !isProjectRequestTypeSelected;
  const store = selectStoreState.currentItem;

  if (!props.data.isAdmin && !props.data.isDemo) {
    return <></>;
  }

  return (
    <>
      <NavBar {...props} activeTab={PROJECT_REQUEST_PAGE_TITLE} />
      <PageHeader
        title={PAGE_TITLE}
        returnLinkURL={PROJECT_REQUEST_PAGE_URL}
        returnLinkText={PROJECT_REQUEST_PAGE_TITLE}
      />
      <PageBody>
        <div className="columns">
          <div
            className={
              hasCostEstimator
                ? "container is-max-desktop max-width column is-three-quarters"
                : "container is-max-desktop max-width column"
            }
            style={hasCostEstimator ? { borderRight: `1px solid ${variables.greyLighter}` } : {}}
          >
            <SelectStoreSection
              {...selectStoreState}
              onStoreSelected={onStoreSelected}
              items={companyStores}
            />
            <SelectProjectRequestTypeSection {...projectRequestTypeState} />
            <ProjectRequestForm
              store={store}
              {...formState}
              projectRequestType={projectRequestTypeState?.currentItem}
              isFreight={isFreight}
              setIsFreight={setIsFreight}
            />
          </div>

          {hasCostEstimator && (
            <WholesaleCostEstimator
              isFreight={isFreight}
              warehouse={formState.selectWarehouseState.currentItem.uuid}
              orders={formState.selectOrdersState.orders}
            />
          )}
        </div>
      </PageBody>
      <PageFooter>
        <Link className="button is-text" to="/projects">
          Go back
        </Link>
        <SaveButton
          onSave={onCreateProjectRequest}
          isLoading={isSaving}
          isDisabled={isSaveDisabled}
          text="Create Project Request"
        />
      </PageFooter>
    </>
  )
};

export default CreateProjectRequestPage;
