import React from 'react';
import CustomDropdown from "./CustomDropdown";
import { useDropdown } from "./dropdown/useDropdown";

export const useSelectWarehouse = (state) => {
  const dropdownState = useDropdown(state);

  return {
    ...dropdownState.state,
    ...dropdownState,
  };
};

export const SelectWarehouse = (props) => {
  const { items, currentItem } = props;

  return (
      <CustomDropdown
        items={items}
        setCurrentItem={props.setCurrentItem}
        currentItem={currentItem}
        isDropdownActive={props.isActive}
        toggleDropdownMenu={props.toggleIsActive}
        displayName="display"
        readOnly={props.readOnly}
      />
  );
};
