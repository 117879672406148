import React, { Component } from 'react';


class LoginAsDropdown extends Component {
  state = {
    isActive: false,
    selected: '',
    searchText: '',
    users: [],
  };

  componentDidMount() {
    this.props.getSudo().then((users) =>
      this.setState({ users })
    );
    document.addEventListener('mousedown', this.clickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutside);
  }

  reference = node => this.dropdown = node;

  clickOutside = (e) => {
    if (this.dropdown && !this.dropdown.contains(e.target) && this.state.isActive) {
      this.setState({
        isActive: false,
        searchText: '',
      });
    }
  }

  onChange = (event) => {
    const selected = event.target.text;
    const loginUser = this.state.users.find(user => user.company === selected);

    if (this.state.selected !== selected) {
      this.props.setSudo(loginUser.token);
      this.setState({
        selected: selected,
        isActive: false,
      });

      event.preventDefault();
    }
  }

  render() {
    const filteredCompanies = this.state.users?.filter(user => user.company.toLowerCase().includes(this.state.searchText)) || [];

    return (
      <div ref={this.reference} className={"dropdown" + (this.state.isActive ? " is-active" : "")}>
        <div className="dropdown-trigger" onClick={() => this.setState({ isActive: !this.state.isActive })}>
          <button className="button is-small" aria-haspopup="true" aria-controls="dropdown-menu">
            <span>{!!this.state.selected ? this.state.selected : 'Select a user to login as'}</span>
            <span className="icon is-small">
              <i className="fas fa-angle-down" aria-hidden="true" />
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu" style={styles.menuSize}>
          <div className="dropdown-content">
            <div className="dropdown-item p-0">
              <div className="control has-icons-left">
                <input
                  className="input"
                  type="text"
                  placeholder='Search'
                  value={this.state.searchText}
                  onChange={(e) => this.setState({ searchText: e.target.value })}
                  style={styles.searchBox}
                />
                <span className="icon is-left">
                  <i className="icon-ic-search" />
                </span>
              </div>
            </div>
            <hr className="dropdown-divider" />
            {filteredCompanies.map((user) =>
              <a
                key={"login-as-" + user.token}
                href="/"
                className={"dropdown-item" + (this.state.selected === user.token ? " is-active" : "")}
                onClick={this.onChange}
              >
                {user.company}
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}


const styles = {
  menuSize: {
    width: '20rem',
  },
  searchBox: {
    border: 'none',
    boxShadow: 'none'
  },
}


export default LoginAsDropdown;
