import { THREE_BUSINESS_DAYS_AGO } from '../general.js';
import _ from 'lodash';

const commonClasses = ["tag", "is-rounded"];

export const statusMap = {
  'FULFILLED': {
    displayName: 'Fulfilled',
    description: 'This order is awaiting pickup from the carrier.',
    filterable: false,
    classes: [...commonClasses, "pill-green"],
  },
  'PARTIAL': {
    displayName: 'Partial',
    description: 'Part of this order is fulfilled.',
    filterable: false,
    classes: [...commonClasses, 'pill-blue'],
  },
  'SHIPPED': {
    displayName: 'Shipped',
    description: 'This order has been picked up by the carrier.',
    filterable: true,
    classes: [...commonClasses, 'pill-blue'],
  },
  'DELAYED': {
    displayName: 'Delayed',
    description: 'This order is in the warehouse but has not yet shipped.',
    filterable: true,
    classes: [...commonClasses, 'pill-blue'],
  },
  'OUT_OF_STOCK': {
    displayName: 'Out of Stock',
    description: 'One or more products in this order are out of stock.',
    filterable: true,
    classes: [...commonClasses, 'pill-red'],
  },
  'partial': {
    displayName: 'Partial',
    description: 'This order may contain multiple shipments with different statuses.',
    filterable: true,
    classes: [...commonClasses, 'pill-blue'],
  },
  'processing': {
    displayName: 'Processing',
    description: 'This order hasn\'t yet shipped.',
    filterable: true,
    classes: [...commonClasses, 'pill-grey'],
  },
  /* Aftership statuses */
  'DELIVERED': {
    displayName: 'Delivered',
    description: 'This order has been delivered.',
    filterable: true,
    classes: [...commonClasses, 'pill-green'],
  },
  'INTRANSIT': {
    displayName: 'In Transit',
    description: 'This order is en route to its destination.',
    filterable: true,
    classes: [...commonClasses, 'pill-blue'],
  },
  'OUTFORDELIVERY': {
    displayName: 'Out for Delivery',
    description: 'This order will be delivered shortly.',
    filterable: true,
    classes: [...commonClasses, 'pill-blue'],
  },
  'INFORECEIVED': {
    displayName: 'Pending Carrier Scan',
    description: 'The carrier has not scanned the package. The package may have been picked up and be in transit.',
    filterable: true,
    classes: [...commonClasses, 'pill-blue'],
  },
  'AVAILABLEFORPICKUP': {
    displayName: 'Available for Pickup',
    description: 'The recipient can collect the package from the carrier.',
    filterable: true,
    classes: [...commonClasses, 'pill-yellow'],
  },
  'Pending': {
    displayName: 'Pending Scan',
    description: 'The shipping label has been created.',
    filterable: true,
    classes: [...commonClasses, 'pill-blue'],
  },
  'ATTEMPTFAIL': {
    displayName: 'Failed Attempt',
    // TODO: Append "See more" link
    description: 'The carrier attempted to deliver this order but failed.',
    filterable: true,
    classes: [...commonClasses, 'pill-blue'],
  },
  'EXCEPTION': {
    displayName: 'Carrier Exception',
    // TODO: Append "See more" link
    description: 'This order encountered a problem in transit.',
    filterable: true,
    classes: [...commonClasses, 'pill-red'],
  },
  'TOOOLDTOTRACK': {
    displayName: 'Too Old to Track',
    description: 'Status is unknown because tracking number has expired.',
    filterable: true,
    classes: [...commonClasses, 'pill-red'],
  },
  'EXPIRED': {
    displayName: 'Expired',
    description: 'Status is unknown because tracking number has expired.',
    filterable: false,
    classes: [...commonClasses, 'pill-red'],
  },
  /* End of Aftership statuses */
  'DRAFT': {
    displayName: 'Draft',
    description: 'This order is being held.',
    filterable: true,
    classes: [...commonClasses, 'pill-grey'],
  },
  'NEW': {
    displayName: 'New',
    description: 'This order has not yet been fulfilled.',
    filterable: true,
    classes: [...commonClasses, 'pill-grey'],
  },
  'PROCESSING': {
    displayName: 'Processing',
    description: 'Order is in progress in the warehouse.',
    filterable: false,
    classes: [...commonClasses, 'pill-grey'],
  },
  'CANCELLED': {
    displayName: 'Cancelled',
    description: 'Order has been cancelled.',
    filterable: true,
    classes: [...commonClasses, 'pill-red'],
  },
  'PARTIALLY_RECEIVED': {
    displayName: 'Partially Received',
    description: 'Some items have been received.',
    filterable: true,
    classes: [...commonClasses, 'pill-blue'],
  },
  'RECEIVED': {
    displayName: 'Received',
    description: 'This has been received.',
    filterable: true,
    classes: [...commonClasses, 'pill-green'],
  },
  'STOCKED': {
    displayName: 'Stocked',
    description: 'This has been stocked.',
    filterable: false,
    classes: [...commonClasses, 'pill-green'],
  },
  'pending': {
    displayName: 'Pending Payment',
    description: '',
    filterable: false,
    classes: [...commonClasses, 'pill-blue'],
  },
  'AUTHORIZED': {
    displayName: 'Authorized',
    description: '',
    filterable: false,
    classes: [...commonClasses, 'pill-blue'],
  },
  'PARTIALLY_PAID': {
    displayName: 'Partially Paid',
    description: '',
    filterable: false,
    classes: [...commonClasses, 'pill-blue'],
  },
  'PAID': {
    displayName: 'Paid',
    description: '',
    filterable: false,
    classes: [...commonClasses, 'pill-green'],
  },
  'PARTIALLY_REFUNDED': {
    displayName: 'Partially Refunded',
    description: '',
    filterable: false,
    classes: [...commonClasses, 'pill-blue'],
  },
  'REFUNDED': {
    displayName: 'Refunded',
    description: '',
    filterable: false,
    classes: [...commonClasses, 'pill-red'],
  },
  'VOID': {
    displayName: 'Voided Payment',
    description: '',
    filterable: false,
    classes: [...commonClasses, 'pill-red'],
  },
  'ERROR': {
    displayName: 'Error',
    description: 'This order contains an error. We’re looking into it.',
    filterable: true,
    classes: [...commonClasses, 'pill-red'],
  },
  'OPEN': {
    displayName: 'No Tracking',
    description: '',
    filterable: false,
    classes: [...commonClasses, 'pill-red'],
  },
  'CREATED': {
    displayName: 'In Review',
    description: 'This order is being reviewed by our operations team.',
    filterable: true,
    classes: [...commonClasses, 'pill-blue'],
  },
}

export const toTitleCase = (text) =>
  text.toLowerCase()
      .split('_')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

export const getDisplayName = (status) => {
  // "pending" is a Shopify financial status
  // "Pending" is an Aftership tracking status
  // Everything else is case insensitive
  const cleanedStatus = status?.trim().toUpperCase() === 'PENDING' ? status : status?.trim().toUpperCase();
  return statusMap[cleanedStatus] ? statusMap[cleanedStatus].displayName : toTitleCase(status);
}

export const getDescription = (status) => {
  let cleanedStatus = status.trim().toUpperCase() === 'PENDING' ?
    status : status.trim().toUpperCase();

  return statusMap[cleanedStatus] ? statusMap[cleanedStatus].description : '';
}

export const getClasses = status => {
  let cleanedStatus = status.trim().toUpperCase() === 'PENDING' ?
    status : status.trim().toUpperCase();

  return statusMap[cleanedStatus] ? statusMap[cleanedStatus].classes : ['tag', 'is-rounded'];
}

export const getFulfilledVariantIds = order =>
  _(order.fulfillments)
    .map(fulfillment => fulfillment.items)
    .flatten()
    .value()
    .map(item => item.variant);

export const getDelayedVariantIds = order =>
  _(order.fulfillments)
    .filter(fulfillment => isFulfillmentDelayed(fulfillment))
    .map(fulfillment => fulfillment.items)
    .flatten()
    .value()
    .map(item => item.variant);

export const getRemovedVariantIds = order => {
  const fulfilledVariantIds = getFulfilledVariantIds(order);

  return order.items
    .filter(item => {
      const desired = item.fulfillable_count === null
        ? item.count
        : item.fulfillable_count;
      const fulfilled = !!_.find(
        fulfilledVariantIds,
        fulfilledVariantId => fulfilledVariantId === item.variant.uuid
      );

      return desired === 0 && !fulfilled;
    })
    .map(item => item.variant.uuid);
}

export const isFulfillmentDelayed = fulfillment =>
  // Fulfillment we are supposed to ship...
  (fulfillment.process === 'SHIP') &&
  // ...that is older than 3 business days...
  (fulfillment.created_at <= THREE_BUSINESS_DAYS_AGO) &&
  // ...and is not shipped.
  (fulfillment.shipments.length === 0);

export const isOrderDelayed = order =>
  order.type === 'SALES' &&
  order.fulfillments
    .filter(fulfillment => isFulfillmentDelayed(fulfillment))
    .length > 0;

export const isItemOutOfStock = item => {
  const available = !!item.variant.inventory
    ? _.sumBy(item.variant.inventory.items, inventoryItem => inventoryItem.available)
    : 0;
  const reserved = !!item.variant.inventory
    ? _.sumBy(item.variant.inventory.items, inventoryItem => inventoryItem.reserved)
    : 0;
  const purchased = !!item.variant.inventory ? item.variant.inventory.purchased : 0;
  const fulfillable = item.fulfillable_count === null
    ? item.count
    : item.fulfillable_count
  const shop_available = available - purchased - reserved;

  return item.variant.should_fulfill &&
    shop_available < fulfillable;
}

export const isOrderOutOfStock = order => {
  const fulfilledVariantIds = getFulfilledVariantIds(order);
  const removedVariantIds = getRemovedVariantIds(order);

  return order.type === 'SALES' &&
    order.items
      // Exclude items already fulfilled
      .filter(item => fulfilledVariantIds.indexOf(item.variant.uuid) === -1)
      // Exclude removed items
      .filter(item => removedVariantIds.indexOf(item.variant.uuid) === -1)
      .filter(item => isItemOutOfStock(item))
      .length > 0;
}

export default {
  getDisplayName,
  getDescription,
  getClasses,
  statusMap,
  getFulfilledVariantIds,
  getDelayedVariantIds,
  getRemovedVariantIds,
  isFulfillmentDelayed,
  isOrderDelayed,
  isItemOutOfStock,
  isOrderOutOfStock,
}
