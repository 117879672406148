import { Columns } from "react-bulma-components";
import { ProjectInstructions } from "../fields/ProjectInstructions";
import React from "react";

const DEFAULT_ADDITIONAL_INFORMATION_STATE = {
  isPhotoRequired: false,
  isQuoteRequired: false,
};
export const useAdditionalInformation = (props = {}) => {
  const [state, setState] = React.useState({
    ...DEFAULT_ADDITIONAL_INFORMATION_STATE,
    ...props,
  });

  const setIsPhotoRequired = (isPhotoRequired) => {
    setState((prevState) => ({
      ...prevState,
      isPhotoRequired,
    }));
  };

  const setIsQuoteRequired = (isQuoteRequired) => {
    setState((prevState) => ({
      ...prevState,
      isQuoteRequired,
    }));
  };

  const loadState = (state) => {
    setState(state);
  };

  return { ...state, loadState, setIsQuoteRequired, setIsPhotoRequired };
};

export const AdditionalInformation = (props) => {
  const {
    hasPhotoRequired,
    hasQuoteRequired,
    isPhotoRequired,
    isQuoteRequired,
    setIsPhotoRequired,
    setIsQuoteRequired,
    isReadOnly,
  } = props;

  const onIsPhotoRequiredChange = (e) => {
    setIsPhotoRequired(e.target.checked);
  };

  const onIsQuoteRequiredChange = (e) => {
    setIsQuoteRequired(e.target.checked);
  };

  return (
    <>
      <div>
        {hasQuoteRequired && (
          <label className="checkbox">
            <input
              type="checkbox"
              checked={isQuoteRequired}
              onChange={onIsQuoteRequiredChange}
              disabled={isReadOnly}
            />{" "}
            Quote prior to approval required
          </label>
        )}
        {hasPhotoRequired && (
          <label className="checkbox">
            <input
              type="checkbox"
              checked={isPhotoRequired}
              onChange={onIsPhotoRequiredChange}
              disabled={isReadOnly}
            />{" "}
            Photo prior to approval required
          </label>
        )}
      </div>
    </>
  );
};

export const AdditionalInformationSection = (props) => {
  return (
    <>
      <div className="section-container">
        <Columns>
          <Columns.Column size={3}>
            <p data-testid="target-due-date" className={"title is-4 mb-4"}>
              Additional Information
            </p>
            <p>If desired, request a quote or photo from Airhouse for approval before proceeding with the entire project.</p>
          </Columns.Column>
          <Columns.Column>
            <AdditionalInformation {...props} />
          </Columns.Column>
        </Columns>
      </div>
    </>
  );
};

export const AdditionalInformationInlineSection = (props) => (
  <div className="section-container">
    <h5 className="title is-5 mb-4">Additional Information</h5>
    <p className={"mb-4"}>If desired, request a quote or photo from Airhouse for approval before proceeding with the entire project.</p>
    <AdditionalInformation {...props} />
  </div>
);
