import React from "react";
import { SelectOrders } from "../fields/SelectOrders";
import { Columns } from "react-bulma-components";

export const SelectOrderSection = (props) => {
  const { isFreight, setIsFreight } = props;
  return (
    <Columns className="section-container">
      <Columns.Column size={3}>
        <p
          data-testid="upload-document-subheading"
          className={"title is-4 mb-4"}
        >
          Orders
        </p>
        <p>Select the Order(s) associated with this project.</p>
      </Columns.Column>
      <Columns.Column>
        <SelectOrders {...props} />
        <IsFreight isFreight={isFreight} setIsFreight={setIsFreight} />
      </Columns.Column>
    </Columns>
  );
};

export const SelectOrderInlineSection = (props) => (
  <div className="section-container">
    <h5 className="title is-5 mb-4">Orders</h5>
    <p className={"mb-4"}>Select the Order(s) associated with this project.</p>
    <SelectOrders {...props} />
  </div>
);


export const IsFreight = (props) => {
  const { isFreight, setIsFreight, isReadOnly } = props;
  return (<div className="field mt-6">
    <div className="control mt-2">
      <input
        className="is-checkradio"
        id="shipping-standard-radio"
        type="radio"
        checked={!isFreight}
        onChange={() => !isReadOnly && setIsFreight(false)}
        disabled={isReadOnly}
      />
      <label className="radio" htmlFor="shipping-standard-radio">
        Small Parcel (USPS, UPS, FedEx, DHL etc.)
      </label>
    </div>
    <div className="control mt-2">
      <input
        className="is-checkradio"
        id="shipping-freight-radio"
        type="radio"
        checked={isFreight}
        onChange={() => !isReadOnly && setIsFreight(true)}
        disabled={isReadOnly}
      />
      <label className="radio" htmlFor="shipping-freight-radio">
        Freight
      </label>
    </div>
  </div>);
}

export default SelectOrderSection;
