import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { formatBytes } from '../../../utils/formatting/byteFormatter';
import { useDropdown } from "../dropdown/useDropdown";
import { FILE_TYPE, LINK_TYPE } from "./FileAttachementUpload";

export const MAX_FILE_UPLOAD_SIZE = 5 * 1024 * 1024; // 5mb

const DEFAULT_FILE_ATTACHMENT_STATE = {
  items: [],
  selectedItem: {},
  newItem: null,
  isUploadingNewFile: false,
  isError: false,
  isFileLinkVisible: false,
  errorMessage: '',
};

export const useFileAttachmentUpload = (newState = {}) => {

  const [data, setData] = React.useState({ ...DEFAULT_FILE_ATTACHMENT_STATE, ...newState});

  const dropdownState = useDropdown();

  const setSelectedItem = (selectedItem) => {

    const isFileLinkVisible = (selectedItem.uuid === LINK_TYPE);
    const isUploadingNewFile = (selectedItem.uuid !== FILE_TYPE)

    setData((prevState) => ({
      ...prevState,
      selectedItem,
      isFileLinkVisible,
      isUploadingNewFile,
    }));
  };

  const loadItems = (newItems) => {
    const items = newItems.map((item) => {
      const file = { name: item.file_name, size: item.file_size };
      const type = (item.file_size)? FILE_TYPE: LINK_TYPE;
      const id = (item.uuid)? item.uuid: uuidv4();
      const newItem = {
        ...item,
        id,
        file,
        type
      };

      return newItem;
    });

    setData((prevState) => ({
      ...prevState,
      items,
    }));
  };

  const addItem = (type, itemData) => {

    const {url, file, fileContent} = itemData;
    const item = {
      id: uuidv4(),
      type,
      url,
      file,
      fileContent,
    };

    try {
      setData((prevState) => ({
        ...prevState,
        isError: false,
        errorMessage: '',
        items: [...prevState.items, item],
      }));
    } catch (e) {
      setData((prevState) => ({
        ...prevState,
        isError: true,
        errorMessage: e.message,
      }));
    }
  };

  const updateItem = (newItem) => {
    setData((prevState) => {
      const items = [...prevState.items];
      return {
        ...prevState,
        items: items.map((item) => {
          if (item.id === newItem.id) {
            return newItem;
          }
          return item;
        }),
      };
    });
  };

  const deleteItem = (item) => {
    setData((prevState) => {
      const items = [...prevState.items];
      const itemIndex = items.indexOf(item);
      items.splice(itemIndex, 1);

      return {
        ...prevState,
        items,
      };
    });
  };

  const setErrorMessage = (errorMessage) => {
    setData((prevState) => ({
      ...prevState,
      isError: true,
      errorMessage,
    }));
  };

  const resetErrorMessage = () => {
    setData((prevState) => ({
      ...prevState,
      isError: false,
      errorMessage: '',
    }));
  };

  return {
    ...data,
    dropdownState,
    setSelectedItem,
    loadItems,
    addItem,
    updateItem,
    deleteItem,
    setErrorMessage,
    resetErrorMessage,
  };
};

const validateFileAttachment = (fileAttachment) => {
  if (fileAttachment.file.size > MAX_FILE_UPLOAD_SIZE) {
    throw new Error(
      `The file size exceeded the limit of ${formatBytes(MAX_FILE_UPLOAD_SIZE)}`
    );
  }

  const fileExtension = fileAttachment.file.name.split('.').pop().toLowerCase();
  const validExtensions = ['pdf'];
  if (validExtensions.indexOf(fileExtension) == -1) {
    throw new Error(
      `File extension "${fileExtension}" not supported, try with: ${validExtensions.join(
        ', '
      )}`
    );
  }
};
