import _ from 'lodash';
import React, { useState } from 'react';
import { ValidationError } from '../../../reusable/ValidationError';

import SelectVariantsGrid from './SelectVariantsGrid';
import VariantTableWithEditableCount, { areItemLotsValid } from './VariantTableWithEditableCount';
import { DEFAULT_TITLE, LOT_NUMBER_CHARS_LIMIT } from '../../../../utils/consts';

export const getVariantDisplayName = (variant) => {
  const { product_name, name } = variant;
  const isMissingName = name === DEFAULT_TITLE;
  return isMissingName ? product_name : `${product_name} / ${name}`;
};

export const useSelectVariants = (inputState = []) => {
  // this state should hold a map. only relevant if perf starts to be an issue.
  const [selectedVariants, setSelectedVariants] = useState(inputState.selectedVariants || []);

  const addRow = (
    variantId,
    combinedName,
    price,
    count,
    received_count,
    total_count,
    sku,
    track_lot_number,
    lots
  ) => {
    const newRow = {
      variantId,
      combinedName,
      price,
      count,
      received_count,
      total_count,
      sku,
      track_lot_number,
      lots: lots || (track_lot_number ? [{ lot_number: '', quantity: count }] : undefined),
    };

    setSelectedVariants((rows) => {
      if (rows.filter((item) => item.variantId === variantId).length === 0) {
        return [...rows, newRow];
      }
      return rows;
    });
  };

  const updateOrCreateRow = (
    variantId,
    combinedName,
    sku,
    price,
    count,
    received_count,
    total_count,
    track_lot_number,
    lots
  ) => {
    const updateRow = {
      variantId,
      combinedName,
      price,
      count,
      received_count,
      total_count,
      sku,
      track_lot_number,
      lots: lots || (track_lot_number ? [{ lot_number: '', quantity: count }] : undefined),
    };

    setSelectedVariants((rows) => {
      const numMatchingRows = rows.filter((item) => item.variantId === variantId).length;
      if (numMatchingRows === 0) {
        // create row
        return [...rows, updateRow];
      }
      // update row
      return rows.map((row) => (row.variantId === variantId ? updateRow : row));
    });
  };

  const addVariant = (variant) => {
    const { uuid, price, sku, track_lot_number } = variant;
    const min_count = 1;
    const received_count = 0;
    const total_count = 0;
    const combinedName = getVariantDisplayName(variant);
    addRow(uuid, combinedName, price, min_count, received_count, total_count, sku, track_lot_number);
  };

  const addOrderItem = (orderItem) => {
    const { variantId, combined_name, price, received_count, total_count, sku, lots } = orderItem;
    const variant = selectedVariants.find((v) => v.variantId === variantId);
    const track_lot_number = variant?.track_lot_number || lots?.length > 0;
    addRow(
      variantId,
      combined_name,
      price,
      total_count,
      received_count,
      total_count,
      sku,
      track_lot_number,
      lots
    );
  };

  const removeVariant = (variantId) => {
    setSelectedVariants((prevState) => [...prevState].filter((row) => row.variantId !== variantId));
  };

  const variantChecked = (variant, checked) => {
    if (checked) {
      addVariant(variant);
    } else {
      removeVariant(variant.uuid);
    }
  };

  const updateField = (variantId, field, value) =>
    setSelectedVariants((prevState) =>
      [...prevState].map((row) => (row.variantId === variantId ? { ...row, [field]: value } : row))
    );

  const addLot = (variantId, lots) =>
    updateField(variantId, 'lots', [...lots, { lot_number: '', quantity: 1 }]);

  const updateLot = (variantId, lots, lotInd, lotField, lotValue) =>
    updateField(
      variantId,
      'lots',
      lots.map((lot, ind) => (ind === lotInd ? { ...lot, [lotField]: lotValue } : lot))
    );

  const removeLot = (variantId, lots, lotInd) =>
    updateField(
      variantId,
      'lots',
      lots.filter((_, ind) => ind !== lotInd)
    );

  const areLotsInvalid = () =>
    getItems().filter((item) => item.track_lot_number && !areItemLotsValid(item)).length !== 0;

  const updateCount = (variantId, count) => {
    updateField(variantId, 'count', count);
  };

  const getTotalPrice = () => {
    return _.sum(
      selectedVariants.map((variantValues) => {
        const { price, count } = variantValues;
        const priceNumber = Number(price);
        const value = isNaN(priceNumber) ? 0 : priceNumber;
        return value * Number(count);
      })
    ).toFixed(2);
  };

  const getItems = () => {
    return selectedVariants;
  };

  const getItemCount = () => {
    return _.sum(getItems().map((item) => Number(item.count)));
  };

  const isProductsMissing = () => getItemCount() === 0;
  const hasZeroQuantityProducts = () => getItems().filter((item) => item.count == 0).length > 0;
  const hasDuplicateSkuSelected = () => _.some(_.countBy(getItems(), 'sku'), (count) => count > 1);

  const loadOrderItems = (items) => {
    items.forEach((item) => addOrderItem(item));
  };

  return {
    selectedVariants,
    variantChecked,
    addVariant,
    removeVariant,
    updateCount,
    updateField,
    getTotalPrice,
    getItemCount,
    getItems,
    isProductsMissing,
    areLotsInvalid,
    hasZeroQuantityProducts,
    hasDuplicateSkuSelected,
    loadOrderItems,
    addRow,
    updateOrCreateRow,
    addLot,
    updateLot,
    removeLot,
  };
};

const SelectVariants = (props) => {
  const {
    selectedVariants,
    variantChecked,
    removeVariant,
    updateCount,
    addLot,
    updateLot,
    removeLot,
    store,
    isEditing,
    inboundOrderState,
    errors,
    csvUploaded,
    getTotalPrice,
    getItemCount,
  } = props;

  return (
    <>
      {errors.productsMissing && (
        <ValidationError message="Please add at least one product or packaging component." />
      )}
      {errors.zeroQuantityProducts && (
        <ValidationError message="One or more selected products has a quanity of zero. Remove this product or adjust the quantity to a positive number." />
      )}
      {errors.duplicateSkuSelected && (
        <ValidationError message="Two products with the same SKU were selected. Deselect one of them." />
      )}
      {errors.lotsInvalid && (
        <ValidationError
          message={`Each lot number must have ${LOT_NUMBER_CHARS_LIMIT} or fewer characters. The sum of the lot numbers quantities must match the total item count.`}
        />
      )}
      {isEditing && (
        <SelectVariantsGrid
          selectedVariants={selectedVariants}
          variantChecked={variantChecked}
          store={store}
        />
      )}
      <VariantTableWithEditableCount
        errors={errors}
        selectedVariants={selectedVariants}
        removeItem={removeVariant}
        updateCount={updateCount}
        addLot={addLot}
        updateLot={updateLot}
        removeLot={removeLot}
        itemKey="variantId"
        isEditing={isEditing}
        inboundOrderState={inboundOrderState}
      />
      <div className="columns">
        <div className="column is-three-quarters" />
        <div className="column">
          <small className="has-text-grey has-text-small tag mr-0">
            TOTAL QUANTITY {getItemCount()}
          </small>
        </div>
      </div>
      <div className="columns">
        <div className="column is-three-quarters" />
        <div className="column">
          <small className="has-text-grey has-text-small tag mr-0">
            TOTAL PRICE {getTotalPrice()}
          </small>
        </div>
      </div>
    </>
  );
};

export default SelectVariants;
