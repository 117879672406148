import { useContext } from "react";
import { listProjectRequestTypes } from "../../../../utils/gateway/ProjectRequestTypeAPIGateway";
import { ProjectRequestContext } from "../../../contexts/ProjectRequestStore";

export const useProjectRequestType = () => {
  const projectRequestContext = useContext(ProjectRequestContext);

  if (projectRequestContext === undefined) {
    throw new Error(
      "useProjectRequestType must be used within a ProjectRequestContext provider"
    );
  }

  const { projectRequestTypes, setProjectRequestTypes, isLoading } =
    projectRequestContext;

  if (projectRequestTypes.length === 0) {
    listProjectRequestTypes().then((projectRequestTypes) => {
      setProjectRequestTypes(projectRequestTypes.filter((t) => t.enabled));
    });
  }

  return { projectRequestTypes, isLoading };
};
