import React, { useEffect, useRef } from "react";
import bulmaCalendar from "bulma-extensions/bulma-calendar/dist/js/bulma-calendar.min.js";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";

export const DatePicker = (props) => {
  const calendarRef = useRef(null);
  const { date, minDate, maxDate, id, setDate, setRangeDate, setId, disableWeekends, isRange,
    startDate, endDate } = props;

  const disabledWeekDays = [];
  if (disableWeekends) {
    disabledWeekDays.push(0);
    disabledWeekDays.push(6);
  }
  const title = "Choose a Date";

  useEffect(() => {
    const id = `dropdown-${uuidv4()}`;
    setId(id);
    return () => {
      setId(null);
      setDate(null);
    };
  }, []);

  useEffect(() => {
    if (id) {
      const initializeDatePickersDefault = () => {
        const calendar = bulmaCalendar.attach(
          `#${calendarRef.current.id}[type="date"]`,
          {
            type: "date",
            isRange,
            showHeader: false,
            minDate,
            maxDate,
            startDate,
            endDate,
            disabledWeekDays,
          }
        );

        const updateCalendarState = () => {
          const date = calendar[0].date.start;
          if (date) {
            setDate(moment(date).format("YYYY-MM-DD"));
          } else {
            setDate(null);
          }
        };

        const updateCalendarRangeState = (date) => {
          const { startDate, endDate } = calendar[0];
          if (!startDate && !endDate) return;
          setRangeDate(
            moment(startDate).format("YYYY-MM-DD"),
            moment(endDate).format("YYYY-MM-DD"),
          );
        }

        if(!isRange) {
          calendar[0]?.on("select", updateCalendarState);
          calendar[0]?.on("hide", updateCalendarState);
          calendar[0]?.value(moment(date).toDate());
        } else {
          calendar[0]?.on("select", updateCalendarRangeState);
          calendar[0]?.on("hide", updateCalendarRangeState);
        }
      };
      initializeDatePickersDefault();
    }
  }, [id]);

  return (
    <>
      <div className="mr-2">
        <input type="date" ref={calendarRef} id={id} />
      </div>
    </>
  );
};

export default DatePicker;
