import React from "react";
import DeliveryNotes, { useDeliveryNotes } from "../fields/DeliveryNotes";
import { useFileAttachmentUpload } from "../../../reusable/fileAttachmentUpload/useFileAttachmentUpload";
import DeliveryAttachmentsSection from "./DeliveryAttachmentsSection";
import ShippingDetailsSection, {
  useShippingDetailsSection,
} from "./ShippingDetailsSection";

export const useDeliveryDetails = (inputState = {}, inboundOrderState) => {
  const shippingDetails = useShippingDetailsSection(
    inputState?.shippingDetails || {}, inboundOrderState
  );
  const deliveryNotes = useDeliveryNotes(inputState?.deliveryNotes || {});
  const fileAttachments = useFileAttachmentUpload(
    inputState?.fileAttachments || {}
  );
  return { shippingDetails, deliveryNotes, fileAttachments };
};

const DeliverySection = (props) => {
  const {
    deliveryDetailsId,
    carrierDetailsId,
    deliveryNotesId,
    deliveryAttachmentsId,
    shippingDetails,
    deliveryNotes,
    fileAttachments,
    hasContainer,
    store,
    isEditing,
    errors,
    name,
  } = props;

  return (
    <div className="section-container">
      <div className="scroll-section" id={deliveryDetailsId}>
        <h3 className="is-dominique-title">Delivery Details</h3>
      </div>
      <ShippingDetailsSection
        {...shippingDetails}
        name={name}
        carrierDetailsId={carrierDetailsId}
        hasContainer={hasContainer}
        store={store}
        isEditing={isEditing}
        errors={errors}
      />
      <DeliveryNotes
        {...deliveryNotes}
        scrollId={deliveryNotesId}
        isEditing={isEditing}
      />
      <DeliveryAttachmentsSection
        fileAttachments={fileAttachments}
        scrollId={deliveryAttachmentsId}
        isEditing={isEditing}
      />
    </div>
  );
};

export default DeliverySection;
