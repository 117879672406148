import { useState } from "react";

export const DEFAULT_DATE_PICKER_STATE = {
  id: null,
  date: null,
  minDate: null,
  maxDate: null,
  startDate: null,
  endDate: null
}

export const useDatePicker = (datePickerInitialState = {}) => {

  const [state, setState] = useState({
    ...DEFAULT_DATE_PICKER_STATE,
    ...datePickerInitialState,
  });

  const setDate = (date) => {
    setState(prevState => ({
      ...prevState,
      date,
    }))
  }

  const setDateRange = (startDate, endDate) => {
    setState(prevState => ({
      ...prevState,
      startDate,
      endDate
    }))
  }

  const setId = (id) => {
    setState(prevState => ({
      ...prevState,
      id,
    }));
  };

  const setMinDate = (minDate) => {
    setState((prevState) => ({
      ...prevState,
      minDate,
    }));
  }

  const setMaxDate = (maxDate) => {
    setState((prevState) => ({
      ...prevState,
      maxDate,
    }));
  }

  return { ...state, setDate, setMinDate, setMaxDate, setId };
}