import { IRecipientModal } from "./recipientModal.interfaces";

export const getRecipientModalInitialState = (props: IRecipientModal) => ({
  data: {
    customer: props.customer,
    shipping: props.shipping || {
      first_name: "",
      last_name: "",
      company: "",
      address1: "",
      address2: "",
      city: "",
      province_code: "",
      country_code: "",
      zipcode: "",
      phone: "",
    },
  },
  errors: { ...RECIPIENT_MODAL_NO_ERRORS },
  ssResponse: props.ssResponse || '',
  ssReturnMultiple: props.ssReturnMultiple || [],
  ssReturnSingle: props.ssReturnSingle || {},
  ssSuggestions: props.ssSuggestions || {},
  editing: false,
  selectedMultiple: {
    deliveryLine1: "",
    deliveryLine2: "",
    components: {
      cityName: "",
      state: "",
      zipCode: "",
    },
  },
});

export const RECIPIENT_MODAL_NO_ERRORS = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  company: "",
  address1: "",
  address2: "",
  city: "",
  country_code: "",
  province_code: "",
  zipcode: "",
};
