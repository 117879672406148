import _ from "lodash";
import React from "react";
import { OrderContext } from "../../contexts/OrderStore";
import { dateFormatter } from "../../../utils/general";
import { useAbstractSearchBar } from "../../reusable/searchBar/useAbstractSearchBar";
import AbstractSearchBar from "../../reusable/searchBar/AbstractSearchBar";
import { getInboundOrders } from "../../../utils/gateway/InboundOrderAPIGateway";
import { orderStatusRenderer } from "../../../utils/grid/cellRendering";

export const useInboundSearchBar = useAbstractSearchBar;

const DEFAULT_PLACEHOLDER = "Search inventory shipments";

const fetchInbounds = async (searchTerm, filters) => {
  const data = await getInboundOrders({ searchTerm: encodeURIComponent(searchTerm) });
  const inbounds = data.results
  const { items } = filters;
  return inbounds.filter(r => !items.find((i) => i.uuid === r.uuid));
};


export const InboundSearchBar = (props) => {
  const { store } = props;
  const { setItems } = props;
  const placeholder = props.placeholder || DEFAULT_PLACEHOLDER;

  const disabled = !store?.uuid;
  return (
    <AbstractSearchBar
      {...props}
      disabled={disabled}
      search={fetchInbounds}
      onSearchComplete={setItems}
      itemListDisplay={InboundListDisplay}
      placeholder={placeholder}
    />
  );
};

export const InboundListDisplay = (props) => {
  const { items, onSelectItem } = props;
  return (
    <table className="table modern">
      <tbody>
        {items.map((inbound) => (
          <InboundListItem inbound={inbound} onSelectItem={onSelectItem} />
        ))}
      </tbody>
    </table>
  );
};

export const InboundListItem = (props) => {
  const { inbound: order, onSelectItem } = props;
  return (
    <tr
      className="table-body"
      onClick={(e) => onSelectItem(order)}
      style={{
        width: "100%",
        fontVariantNumeric: "tabular-nums",
      }}
    >
      <td className="has-text-left">
        <p>
          <strong>{order.name}</strong>
        </p>
        <p className="has-text-grey">
          Created At: {dateFormatter(order.created_at)}
        </p>
        <p className="has-text-grey">
          {order.ship_date
            ? "Shipped At: " + dateFormatter(order.ship_date)
            : ""}
        </p>
      </td>
      <td>
        <span>{order.customer?.name} </span>
        {!!order.receiving_address_detail ? (
          <>
            <p>
              {" "}
              {order?.customer?.first_name} {order?.customer?.last_name}{" "}
            </p>
            <p>
              {order.receiving_address_detail.address1} {order.receiving_address_detail.address2}{" "}
            </p>
            <p>
              {" "}
              {order.receiving_address_detail.province_code} {order.receiving_address_detail.zipcode},{" "}
              {order.receiving_address_detail.country_code}{" "}
            </p>
          </>
        ) : (
          ""
        )}
      </td>
      <td>{/* TODO: show SKU codes  */}</td>
    </tr>
  );
};

export const styles = {
  noResults: {
    backgroundColor: "#F8F8F9",
    fontStyle: "italic",
  },
};

export default InboundSearchBar;
