import React from "react";

import RestockSearchBar from "../../Restock/RestockSearchBar";
import { dateFormatter } from "../../../../utils/general";
import { RemoveItemButton } from "../../../reusable/page/buttons/RemoveItemButton";
import { Link } from "react-router-dom";

export const SelectRestocks = (props) => {
  const { restocks, addRestock, removeRestock, store, } = props;

  const onSelectRestockCustomHandler = props?.onSelectRestock || ((restock) => {});

  const onSelectRestock = (restock) => {
    onSelectRestockCustomHandler(restock);
    addRestock(restock);
  };

  const filters = {
    ...props.filters,
    store,
    items: restocks,
  }

  return (
    <>
      <RestockSearchBar
        onSelectItem={onSelectRestock}
        filters={filters}
        {...props.restockSearchBarState}
        store={store}
      />
      <RestockList restocks={restocks} onDelete={removeRestock} />
    </>
  );
};

export const RestockList = (props) => {
  const { restocks, onDelete } = props;
  return <table>{restocks.map((restock) => (
    <tr
      className="table-body"
      style={{
        width: "100%",
        fontVariantNumeric: "tabular-nums"
      }}
    >
      <td className="has-text-left">
        <p>
          <strong><Link to={`/restock/view/${restock.uuid}`}>{restock.name}</Link></strong>
        </p>
        <p className="has-text-grey">
          Created At: {dateFormatter(restock.created_at)}
        </p>
        <p className="has-text-grey">
          {restock.ship_date
            ? "Shipped At: " + dateFormatter(restock.ship_date)
            : ""}
        </p>
      </td>
      <td>
        <span>{restock.customer?.name} </span>
        {!!restock.shipping ? (
          <>
            <p>
              {" "}
              {restock?.customer?.first_name} {restock?.customer?.last_name}{" "}
            </p>
            <p>
              {restock.shipping.address1} {restock.shipping.address2}{" "}
            </p>
            <p>
              {" "}
              {restock.shipping.province_code} {restock.shipping.zipcode},{" "}
              {restock.shipping.country_code}{" "}
            </p>
          </>
        ) : (
          ""
        )}
      </td>
      {onDelete && <td><RemoveItemButton onClick={() => {
        onDelete(restock)
      }} /></td>}
    </tr>
  ))}</table>;
}