import React from "react";
import { Columns } from "react-bulma-components";
import { ProjectInstructions } from "../fields/ProjectInstructions";

export const ProjectInstructionsSection = (props) => {
  return <div className="section-container">
    <Columns>
      <Columns.Column size={3}>
        <p data-testid="target-due-date" className={'title is-4 mb-4'}>
          Project Instructions
        </p>
      </Columns.Column>
      <Columns.Column>
        <ProjectInstructions {...props}/>
      </Columns.Column>
    </Columns>
  </div>
}

export const ProjectInstructionsInlineSection = (props) => (<div className="section-container">
  <h5 className="title is-5 mb-4">Instructions</h5>
  <ProjectInstructions {...props}/>
</div>)