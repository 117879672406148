import React from 'react';

export const SaveButton = (props) => {
  const { onSave, isLoading, isDisabled, text } = props;
  return (
    <button
      className={`button is-primary ${isLoading ? "is-loading" : ""}`}
      onClick={onSave}
      disabled={isDisabled}
    >
      {text}
    </button>
  );
};