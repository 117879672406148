import React, { useState } from "react";
import { getOrdersByName } from "../../../../utils/gateway/OrderAPIGateway";
import { ValidationError } from "../../../reusable/ValidationError";

const OrderRangeInput = (props) => {
  const { orders, addOrder, store } = props;
  const [showInputs, setShowInputs] = useState(false);
  const [startValue, setStartValue] = useState("");
  const [endValue, setEndValue] = useState("");
  const [importErrors, setImportErrors] = useState([]);
  const [error, setError] = useState({ start: false, end: false, range: false });
  const [loading, setLoading] = useState(false);

  const handleToggleRangeInputs = () => setShowInputs(!showInputs);

  const onImportSuccess = (response) => {
    const { orders: ordersToAdd, missing_orders } = response;

    _.chain(ordersToAdd)
      .differenceBy(orders, "uuid")
      .sortBy("name")
      .value()
      .forEach((order) => {
        addOrder(order);
      });

    if (missing_orders.length) {
      const newImportErrors = missing_orders.map(
        (x) => `Error: could not find order with name: ${x}.`
      );
      setImportErrors(newImportErrors);
    }
  };

  const importOrders = () => {
    const start = parseInt(startValue);
    const end = parseInt(endValue);
    setLoading(true);
    setImportErrors([]);

    const names = Array.from({ length: end - start + 1 }, (_, i) => i + start)
      .slice(0, 500)
      .map((value) => value.toString());

    getOrdersByName(store.uuid, names)
      .then((response) => {
        onImportSuccess(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error while getting orders by name", error);
        setLoading(false);
      });
  };

  const handleInputChange = (e, type) => {
    if (isNaN(e.target.value)) {
      setError((prevState) => ({ ...prevState, [type]: true }));
    } else {
      setError((prevState) => ({ ...prevState, [type]: false }));
      if (type === "start") {
        setStartValue(e.target.value);
        if (parseInt(e.target.value) > parseInt(endValue)) {
          setError((prevState) => ({ ...prevState, range: true }));
        } else {
          setError((prevState) => ({ ...prevState, range: false }));
        }
      }
      if (type === "end") {
        setEndValue(e.target.value);
        if (parseInt(e.target.value) < parseInt(startValue)) {
          setError((prevState) => ({ ...prevState, range: true }));
        } else {
          setError((prevState) => ({ ...prevState, range: false }));
        }
      }
    }
  };

  if (!store) {
    return <></>;
  }

  return (
    <>
      <div className="is-flex is-justify-content-flex-end">
        <a className="is-text-small" onClick={handleToggleRangeInputs}>
          Add orders from range
        </a>
      </div>
      {showInputs && (
        <>
          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label">Start</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Enter start value"
                    value={startValue}
                    onChange={(e) => handleInputChange(e, "start")}
                  />
                  {error.start && <p className="help is-danger">Please enter a numeric value</p>}
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label">End</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder="Enter end value"
                    value={endValue}
                    onChange={(e) => handleInputChange(e, "end")}
                  />
                  {error.end && <p className="help is-danger">Please enter a numeric value</p>}
                  {error.range && (
                    <p className="help is-danger">End value should be larger than Start value</p>
                  )}
                </div>
              </div>
            </div>
            <div className="column is-narrow pt-4">
              <div className="field pt-4">
                <button className={`button is-primary is-small ${loading ? "is-loading" : ""}`} onClick={importOrders}>
                  Import
                </button>
              </div>
            </div>
          </div>
          {importErrors.length > 0 &&
            importErrors.map((importError) => {
              return <ValidationError key={importError} message={importError} />;
            })}
        </>
      )}
    </>
  );
};

export default OrderRangeInput;
