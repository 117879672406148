import * as React from "react";

export const UpRightArrowBox = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.84115 0.979454C2.04115 0.779451 2.31247 0.666992 2.59545 0.666992H4.17147V1.58129H2.59545C2.55499 1.58129 2.51636 1.59729 2.48779 1.62586L1.62583 2.48782C1.59726 2.51639 1.58126 2.55525 1.58126 2.59548V9.40495C1.58126 9.4454 1.59726 9.48403 1.62583 9.51283L2.48779 10.3748C2.51636 10.4034 2.55522 10.4194 2.59545 10.4194H9.40491C9.44537 10.4194 9.484 10.4034 9.51257 10.3748L10.3745 9.51283C10.4031 9.48426 10.4193 9.4454 10.4193 9.40495V7.82892H11.3336V9.40495C11.3336 9.68792 11.2212 9.95924 11.0212 10.1592L10.159 11.0212C9.95898 11.2212 9.68766 11.3337 9.40469 11.3337H2.59522C2.31224 11.3337 2.04092 11.2212 1.84092 11.0212L0.978966 10.1592C0.778963 9.95924 0.666504 9.68792 0.666504 9.40495V2.59548C0.666504 2.3125 0.778963 2.04118 0.978966 1.84118L1.84092 0.979225L1.84115 0.979454ZM9.40491 1.58129H6.57379V0.666992H9.40491C9.68789 0.666992 9.95921 0.779451 10.1592 0.979454L11.0212 1.84141C11.2212 2.04141 11.3336 2.31273 11.3336 2.59571V5.42683H10.4193V2.59571C10.4193 2.55525 10.4033 2.51662 10.3748 2.48805L10.2666 2.37993L5.71343 6.93314L5.06702 6.28673L9.62023 1.73352L9.5128 1.62609C9.48423 1.59752 9.44537 1.58152 9.40491 1.58152V1.58129Z"
        fill="#886FFF"
      />
    </svg>
  );
};
