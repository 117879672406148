import React from "react";
import clsx from "clsx";

const ReceivingAddress = (props) => {
  const { assignedReceivingAddress, selectedWarehouse, name } = props;
  return (
    <>
      {assignedReceivingAddress && (
        <div className="columns mt-2">
          <div className={clsx("column")}>
            <p className="heading is-size-6">Ship to the following address:</p>
            <p className="is-family-monospace">
              {assignedReceivingAddress.address2}, {name}
            </p>
            <p className="is-family-monospace">
              {assignedReceivingAddress.address1}
            </p>
            <p className="is-family-monospace">
              {assignedReceivingAddress.city},{" "}
              {assignedReceivingAddress.province_code}{" "}
              {assignedReceivingAddress.zipcode}
            </p>
          </div>
          <div className={clsx("column")}>
            <p className="heading is-size-6">Receiving Hours:</p>
            {assignedReceivingAddress.info?.trim() && (
              <>
                <p className="is-family-monospace">
                  {assignedReceivingAddress.info}
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ReceivingAddress;
