import React, { Component } from 'react';
import { Modal } from 'react-bulma-components';


class ConfirmModal extends Component {
  state = {
    processing: false,
    showFooter: true,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showFooter !== this.props.showFooter) {
      this.setState({
        showFooter: this.props.showFooter,
      });
    } 
  }

  confirmCallback = () =>
    this.setState({ 
      processing: false,
    });

  onClose = () => {
    this.confirmCallback;

    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  onClickConfirm = () => {
    this.setState({ 
      processing: !this.props.instantProcessing 
    });

    this.props.onConfirm(this.confirmCallback);
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        closeOnBlur={true}
        onClose={this.onClose}
        showClose={false}
      >
        <Modal.Card>
          <Modal.Card.Header onClose={this.props.onClose}>
            <Modal.Card.Title>
              {this.props.title ?? 'Confirm'}
            </Modal.Card.Title>
          </Modal.Card.Header>
          <Modal.Card.Body>
            {this.props.message ?
              <div className="is-size-5">{this.props.message}</div>
            : this.props.children
            }
          </Modal.Card.Body>

          {this.state.showFooter &&
            <Modal.Card.Footer>
              <div className={'is-fullwidth is-flex is-justify-content-' + ((this.props.onClose && this.props.onConfirm) ? 'space-between' : 'flex-end')}>
                {this.props.onClose &&
                  <button className="button is-text" onClick={this.props.onClose}>
                    {this.props.cancelText || 'Cancel'}
                  </button>
                }
                {this.props.onConfirm &&
                  <button
                    className={"button " + (this.props.confirmClasses || "is-primary")}
                    disabled={this.props.confirmDisabled}
                    data-tooltip={this.props.confirmDisabledTooltip}
                    onClick={this.onClickConfirm}
                  >
                    {this.props.confirmText || 'Confirm'}
                  </button>
                }
              </div>
            </Modal.Card.Footer>
          }
        </Modal.Card>
      </Modal>
    );
  }
}


export default ConfirmModal;
