import _ from "lodash";
import React, { Component } from "react";
import apiEndpoints from "../../utils/apiEndpoints.js";
import { removeParam } from "../../utils/general.js";
import { parseOptions } from "../../utils/grid/helpers.js";
import { IStore } from "../../interfaces/store.interface.js";

const defaultState = {
  data: null,
  errors: {},
  loading: true,
  queryString: "",
  totalSize: 0,
  isStoreDropdownActive: false,
  // `store` state var in OrderContext to be deprecated in favor of state var in UserContext
  store: {
    uuid: "",
    name: "",
  } as IStore,
  setStore: (_: IStore) => {},
  getOrders: () => {},
  clearCache: () => {},
  createOrder: async (_:any): Promise<any> => {},
  updateOrder: async (_orderId: any, _order: any): Promise<any> => {}
};

export const OrderContext = React.createContext(defaultState);
const TYPE_PURCHASE = "PURCHASE";

export default class OrderStore extends Component {
  state = defaultState;

  /*
  Below functions are for global store dropdown.
  Set storeName and storeId in StoreDropdown.
  Toggle active StoreDropdown.
  */

  setStore = (store) => {
    this.setState((prevState) => ({
      store,
    }));
  };

  toggleStoreDropdown = () => {
    this.setState((prevState) => ({
      isStoreDropdownActive: !this.state.isStoreDropdownActive,
    }));
  };

  /*
  Below functions are used for the Orders grid.
  */
  // Used in Orders ag-grid on ready
  getSalesOrders = (options) => {
    options = options || {};
    options.filterModel = options.filterModel || {};
    options.filterModel.type = { filter: "SALES" };
    return this.getOrders(options);
  };

  exportOrders = () => {
    let exportQueryString = removeParam(this.state.queryString, "offset");
    return apiEndpoints.getOrdersCSV(exportQueryString);
  };

  /*
  Below functions are used for both the Orders grid and to perform CRUD ops on orders.
  */

  clearCache = () => {
    this.setState({
      ...this.state,
      data: null,
      loading: true,
    });
  };

  // Used in onboarding
  getOrders = (options) => {
    const queryString = parseOptions(options);

    // API request already underway (and not initial fetch)
    if (!!this.state.loading && this.state.data !== null) {
      return Promise.resolve(this.state.data);
    }

    // Data is cached and no querystring changes
    if (!this.state.loading && queryString === this.state.queryString) {
      return Promise.resolve(this.state.data);
    }

    this.setState({ loading: true });
    return apiEndpoints
      .getOrders(queryString)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          ...this.state,
          data: data.results,
          loading: false,
          queryString: queryString,
          totalSize: data.count,
        });
        return data.results;
      });
  };

  // Used in Restock
  getPurchaseOrders = (options) => {
    options = options || {};
    options.filterModel = options.filterModel || {};
    options.filterModel.type = { filter: TYPE_PURCHASE };
    options.filterModel.return_request__isnull = { filter: true };
    return this.getOrders(options);
  };

  // Used in Edit Order / View Purchase Order
  getOrder = (orderId, options) => {
    let data = this.state.data || [];
    let existingOrder = _.find(data, (order) => order.uuid === orderId);
    let forceReload = !!options && !!options.forceReload;

    if (!!existingOrder && !forceReload) {
      return Promise.resolve(existingOrder);
    }

    return apiEndpoints
      .getOrder(orderId)
      .then((response) => response.json())
      .then((fetchedOrder) => {
        this.setState({
          data: data.map((existingOrder) =>
            existingOrder.uuid === orderId ? fetchedOrder : existingOrder
          ),
          loading: false,
        });
        return fetchedOrder;
      });
  };

  // Used in EditOrder, Edit PO
  createOrder = (order) => {
    var isValid = true;
    var data = this.state.data || [];
    var errors = {};

    return apiEndpoints
      .createOrder(order)
      .then((response) => {
        isValid = response.status === 201;
        return response.json();
      })
      .then((orderResponse) => {
        if (isValid) {
          if (data.length > 0) delete data[0].isFresh;
          data.unshift({
            ...orderResponse,
            isFresh: orderResponse.combined_status !== "DRAFT",
            isPending: orderResponse.combined_status === "DRAFT",
          });
          this.setState(data);
        } else {
          errors = orderResponse;
        }
        return {
          data: orderResponse,
          errors,
        };
      });
  };

  // Used in bulk order
  importOrders = (orders) => {
    let isValid = true;
    let data = this.state.data || [];
    let errors = {};

    // Upload orders, then files
    if (orders.filter((order) => !!order.files).length > 0) {
      let files = {};
      let orderTasks = orders.map((order) => {
        let orderFiles = order.files;
        delete order.files;

        return apiEndpoints
          .createOrder(order)
          .then((response) => response.json())
          .then((orderResponse) => {
            // Cache order data, as fresh/pending
            data.unshift({
              ...orderResponse,
              isFresh: orderResponse.combined_status !== "DRAFT",
              isPending: orderResponse.combined_status === "DRAFT",
            });
            // Collect files, keyed by order UUID
            if (!!orderFiles) {
              files[orderResponse.uuid] = orderFiles;
            }

            return orderResponse;
          });
      });

      return Promise.all(orderTasks).then((orderResponses) => {
        let uploadTasks = [];

        Object.keys(files).forEach((orderId) => {
          files[orderId].forEach((file) => {
            let formData = new FormData();
            formData.append("order", orderId);
            formData.append("file", file.file);
            uploadTasks.push(
              apiEndpoints
                .createAttachment(orderId, formData)
                .then((response) => response.json())
                .then((attachmentData) => {
                  // Stitch into order data
                  data = data.map((order) => {
                    if (order.uuid === attachmentData.order) {
                      order.attachments.push(attachmentData);
                    }
                    return order;
                  });
                })
            );
          });
        });
        this.setState({ data });
        return Promise.all(uploadTasks).then(() => ({ data: orderResponses }));
      });
    } else {
      // Regular bulk upload
      return apiEndpoints
        .createOrder(orders)
        .then((response) => {
          isValid = response.status === 201;
          return response.json();
        })
        .then((response) => {
          if (isValid) {
            response.forEach((orderResponse) =>
              data.unshift({
                ...orderResponse,
                isFresh: orderResponse.combined_status !== "DRAFT",
                isPending: orderResponse.combined_status === "DRAFT",
              })
            );
            this.setState(data);
          } else {
            errors = response;
          }

          return {
            data: response,
            errors,
          };
        });
    }
  };

  // Used in Edit Order
  updateOrder = (orderId, order) => {
    var isValid = true;
    var data = this.state.data;
    var errors = {};

    return apiEndpoints
      .editOrder(orderId, order)
      .then((response) => {
        isValid = response.status === 200;
        return response.json();
      })
      .then((orderResponse) => {
        if (isValid) {
          var index = _.findIndex(data, (existingOrder) => existingOrder.uuid === orderId);
          orderResponse.customer = order.customer;
          orderResponse.shipping = order.shipping;

          // data.splice(index, 1, orderResponse);
          this.setState({ data });
        } else {
          errors = orderResponse;
        }

        return { orderResponse, errors };
      });
  };

  // Used in Edit Order
  deleteOrder = (orderId) => {
    var isValid = true;
    return apiEndpoints
      .deleteOrder(orderId)
      .then((response) => (isValid = response.status === 204))
      .then(() => {
        if (isValid) {
          this.clearCache();
          this.getOrders();
        }
      });
  };

  render() {
    return (
      <OrderContext.Provider
        value={{
          ...this.state,
          getOrders: this.getOrders,
          getOrder: this.getOrder,
          getSalesOrders: this.getSalesOrders,
          getPurchaseOrders: this.getPurchaseOrders,
          createOrder: this.createOrder,
          updateOrder: this.updateOrder,
          deleteOrder: this.deleteOrder,
          importOrders: this.importOrders,
          exportOrders: this.exportOrders,
          clearCache: this.clearCache,
          setStore: this.setStore,
          toggleStoreDropdown: this.toggleStoreDropdown,
        }}
      >
        {this.props.children}
      </OrderContext.Provider>
    );
  }
}
