import {
  PAGE_TITLE as INBOUND_ORDER_PAGE_TITLE,
  PAGE_URL as INBOUND_ORDER_PAGE_URL,
  PAGE_TAB as INBOUND_ORDER_TAB,
} from "./InboundOrderHomePage";
import { Link, useHistory } from "react-router-dom";
import React, { useContext, useEffect } from "react";

import { PAGE_URL as HOME_PAGE_URL } from "./InboundOrderHomePage";
import { PAGE_URL as EDIT_PAGE_URL } from "./EditInboundOrderPage";
import InboundOrderForm from "./components/InboundOrderForm";
import NavBar from "../../NavBar";
import PageBody from "../../reusable/page/PageBody";
import PageFooter from "../../reusable/page/PageFooter";
import { PageHeader } from "../../reusable/page/PageHeader";
import { PageLoadingBar } from "../../reusable/page/loaders/PageLoadingBar";
import { Button } from "../../reusable/page/buttons/Button";
import { UserContext } from "../../contexts/UserStore";
import { createInboundOrder } from "../../../utils/gateway/InboundOrderAPIGateway";
import useCreateInboundOrderPage from "./useCreateInboundOrderPage";
import consts from "../../../utils/consts";
import { toast, Slide } from "react-toastify";
import { InboundOrderSubtitle, InboundOrderTitle } from "./components/InboundOrderSubtitle";

export const PAGE_URL = "/inbound/new";
export const PAGE_TITLE = "Create Inventory Shipment";

export const CreateInboundOrderPage = (props) => {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const { formState: passedFormState } = history.location.state || {};

  const { isSaving, isEditing, isLoading, setIsLoading, setIsSaving, formState } =
    useCreateInboundOrderPage({ formState: passedFormState });
  const { isInboundOrderFormValid } = formState;
  const pageTitle = `${PAGE_TITLE} ${formState?.inboundOrderState?.name}`;
  const status = formState?.inboundOrderState?.status || "DRAFT";

  // ensure the userContext is loaded
  useEffect(() => {
    if (userContext.store) {
      // set initial store on the formState to the current context store
      if (formState.inboundOrderState.store === null) {
        formState.setStore(userContext.store);
      }
      setIsLoading(false);
    }
  }, [userContext.store]);

  const handleCreateInboundOrder = (status) => {
    if (status === consts.STATUS_CREATED && !isInboundOrderFormValid()) {
      toast.error("Please fill out all required information before submitting. (dismiss)", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        transition: Slide,
      });
      return;
    }

    setIsSaving(true);

    // store selection widget of InboundOrderForm will update the userContext.store attribute
    createInboundOrder(formState, status)
      .then((resp) => onCreateSuccess(resp, status))
      .catch(onCreateError);
  };

  const onCreateSuccess = (inboundOrder, status) => {
    if (status === consts.STATUS_DRAFT) {
      toast.success("Saved", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        transition: Slide,
      });
      history.push(EDIT_PAGE_URL.replace(":inboundOrderId", inboundOrder.uuid));
    } else {
      toast.success("Created", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        transition: Slide,
      });
      history.push(HOME_PAGE_URL);
    }
  };

  const onCreateError = () => {
    setIsSaving(false);
    toast.error(
      "We were unable to save your changes. Please reach out to team@airhouse.io for assistance. (dismiss)",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        transition: Slide,
      }
    );
  };

  const isSaveDisabled = isSaving || isLoading;

  if (props.data.isAdmin) {
    return <></>;
  }

  return (
    <>
      <NavBar {...props} activeTab={INBOUND_ORDER_TAB} />
      <div className="container is-max-desktop max-width">
        <PageHeader
          title={<InboundOrderTitle title={pageTitle} />}
          returnLinkURL={INBOUND_ORDER_PAGE_URL}
          returnLinkText={INBOUND_ORDER_PAGE_TITLE}
          subtitle={<InboundOrderSubtitle status={status} showLinkToOldRestock={true} />}
        />
        <PageBody>
          {isLoading && <PageLoadingBar />}
          {!isLoading && <InboundOrderForm {...formState} isEditing={isEditing} />}
        </PageBody>
        <PageFooter>
          <Button
            onClick={() => handleCreateInboundOrder(consts.STATUS_DRAFT)}
            isLoading={isSaving}
            isDisabled={isSaveDisabled}
            isPrimary={true}
            text="Save Draft"
          />
          <Button
            onClick={() => handleCreateInboundOrder(consts.STATUS_CREATED)}
            isLoading={isSaving}
            isDisabled={isSaveDisabled}
            isPrimary={false}
            text="Submit"
          />
        </PageFooter>
      </div>
    </>
  );
};

export default CreateInboundOrderPage;
