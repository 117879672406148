import React from "react";
import FileAttachmentUpload from "../../../reusable/fileAttachmentUpload/FileAttachementUpload";
import moment from "moment";
import VariantList from "../../../VariantList";
import { AdditionalInformation } from "../sections/AdditionalInformationSection";
import { InboundList } from "../fields/SelectInbounds";
import { RestockList } from "../fields/SelectRestocks";
import { OrderList } from "../fields/SelectOrders";
import { IsFreight } from "../sections/SelectOrderSection";

export const ProjectRequestView = (props) => {
  const {
    created_at,
    instructions,
    selectProductState,
    selectOrdersState,
    selectRestocksState,
    selectInboundsState,
    projectInstructionsState,
    dueDateState,
    fileAttachmentUploadState,
    additionalInformationState,
    projectRequestType,
    inputInstructionsState,
    outputInstructionsState,
    isFreight,
  } = props;
  const { items: fileAttachmentItems } = fileAttachmentUploadState;

  const { variants } = selectProductState;

  const {
    hasOrders,
    hasRestocks,
    hasInbounds,
    hasInstruction,
    hasProduct,
    hasFileAttachmentUpload,
    hasPhotoRequired,
    hasQuoteRequired,
    hasDueDate,
    hasInput,
    hasOutput,
  } = projectRequestType;

  const hasAdditional = hasPhotoRequired || hasQuoteRequired;

  return (
    <>
      {hasDueDate && (
        <div className="section-container">
          <p className="title is-4">
            Target Due Date: {dateFormatter(dueDateState.date)}
          </p>
        </div>
      )}
      {hasOrders && (
        <div className="section-container">
          <p className="title is-4">Orders</p>
          {selectOrdersState.orders.length > 0 && (
            <OrderList orders={selectOrdersState.orders} />
          )}
          {!selectOrdersState.orders.length && (
            <div className="empty-state is-small">
              No orders in this project
            </div>
          )}
          <IsFreight isFreight={isFreight} isReadOnly={true}/>

        </div>
      )}
      {hasRestocks && (
        <div className="section-container">
          <p className="title is-4">Restocks</p>
          {selectRestocksState.restocks.length > 0 && (
            <RestockList restocks={selectRestocksState.restocks} />
          )}
          {!selectRestocksState.restocks.length && (
            <div className="empty-state is-small">
              No restocks in this project
            </div>
          )}
        </div>
      )}
      {hasInbounds && (
        <div className="section-container">
          <p className="title is-4">Inbounds</p>
          {selectInboundsState.inbounds.length > 0 && (
            <InboundList inbounds={selectInboundsState.inbounds} />
          )}
          {!selectInboundsState.inbounds.length && (
            <div className="empty-state is-small">
              No inbounds in this project
            </div>
          )}
        </div>
      )}
      {hasProduct && (
        <div className="section-container">
          <p className="title is-4">Products</p>
          {variants.length > 0 && (
            <VariantList
              className="dropdown-item"
              isDropdown={true}
              keyPrefix="variant-item-"
              options={{
                showSKU: true,
                showCount: true,
                showStockCountIcon: true,
              }}
              variants={variants.map((v) => ({ count: v.count, ...v.variant }))}
            />
          )}
          {!variants.length && (
            <div className="empty-state is-small">
              No products in this project
            </div>
          )}
        </div>
      )}
      {hasInstruction && (
        <div className="section-container">
          <p className="title is-4">Project Instructions</p>
          <p>
            {projectInstructionsState.instructions
              ? projectInstructionsState.instructions
              : "None"}
          </p>
        </div>
      )}
      {hasInput && (
        <div className="section-container">
          <p className="title is-4">Input Instructions</p>
          {inputInstructionsState.variantsState.variants.length > 0 &&<VariantList
            className="dropdown-item"
            isDropdown={true}
            keyPrefix="variant-item-"
            options={{
              showSKU: true,
              showCount: true,
              showStockCountIcon: true,
            }}
            variants={inputInstructionsState.variantsState.variants.map((v) => ({count: v.count, ...v.variant}))}
          />}
          {!inputInstructionsState.variantsState.variants.length && <div className="empty-state is-small">No products in this project</div>}
        </div>
      )}
      {hasOutput && (
        <div className="section-container">
          <p className="title is-4">Output Instructions</p>
          {outputInstructionsState.variantsState.variants.length > 0 &&<VariantList
            className="dropdown-item"
            isDropdown={true}
            keyPrefix="variant-item-"
            options={{
              showSKU: true,
              showCount: true,
              showStockCountIcon: true,
            }}
            variants={outputInstructionsState.variantsState.variants.map((v) => ({count: v.count, ...v.variant}))}
          />}
          {!outputInstructionsState.variantsState.variants.length && <div className="empty-state is-small">No products in this project</div>}
        </div>
      )}

      {hasFileAttachmentUpload && (
        <div className="section-container">
          <p className="title is-4">Uploaded Documents</p>
          {fileAttachmentItems.length > 0 && (
            <FileAttachmentUpload readOnly={true} items={fileAttachmentItems} />
          )}
          {!fileAttachmentItems.length && (
            <div className="empty-state is-small">
              No uploads in this project
            </div>
          )}
        </div>
      )}
      {hasAdditional && (
        <div className="section-container">
          <p className="title is-4">Additional Information</p>
          <AdditionalInformation
            hasPhotoRequired={hasPhotoRequired}
            hasQuoteRequired={hasQuoteRequired}
            isReadOnly={true}
            {...additionalInformationState}
          />
        </div>
      )}
    </>
  );
};

export const dateFormatter = (date) => {
  return date ? moment(date).format("MMM D, YYYY") : "-";
};

export const dateTimeFormatter = (date) =>
  moment(date).format("MMM D, YYYY h:mm a");
