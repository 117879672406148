import React from "react";
import FileAttachmentUpload from "../../../reusable/fileAttachmentUpload/FileAttachementUpload";

const DeliveryAttachmentSection = (props) => {
  const { fileAttachments, scrollId, isEditing } = props;

  return (
    <div className="scroll-section" id={scrollId}>
      <h4 className="is-dominique-subtitle mt-5">Delivery Attachments (Optional)</h4>
      <p className="mb-2">Add any attachments here (bill of lading, etc.)</p>
      <FileAttachmentUpload {...fileAttachments} />
    </div>
  );
};

export default DeliveryAttachmentSection;
