import React from 'react'

export const DEFAULT_PROJECT_REQUEST_PAGE_STATE = {
  isLoading: true,
  projectRequests: [],

};

export const useProjectRequestHomePage = () => {
  const [state, setState] = React.useState(DEFAULT_PROJECT_REQUEST_PAGE_STATE);

  const setIsLoading = (isLoading) => {
    setState((prevState) => ({
      ...prevState,
      isLoading
    }))
  }

  const loadProjectRequests = (projectRequests) => {
    setState(prevState => ({
      ...prevState,
      projectRequests,
      isLoading: false
    }));
  }


  return {...state, setIsLoading, loadProjectRequests};
};

export default useProjectRequestHomePage;
