import _ from 'lodash';
import React from 'react';
import { Level } from 'react-bulma-components';
import { toast } from 'react-toastify';
import { isSudoing } from '../authentication.js';
import { removeParam } from '../general.js';
import messages from '../messages.js';



const parseSort = (sortModel) => {
  // Helper function that adds sorting parameters to query string
  const field = sortModel.map(column => (
    column.sort === 'asc' ? '' : '-') + mapColumn(column.colId)
  ).join(',');

  return field && 'ordering=' + field;
}

const mapColumn = (columnName) => {
  switch (columnName) {
    case 'tracking_number':
      return 'fulfillments__shipments__tracking__tracking_number';
    default:
      return columnName;
  }
}

const parseFilters = (filterModel) => {
  // Helper function that adds filtering parameters to query string
  return _.map(filterModel, (data, columnName) => {
    if (columnName === 'source') {
      let filters = data.filter.split(',')
      if (filters.length > 1) {
        let filterString = [];
        filters.forEach(word => filterString.push(`source=${word}`))
        return filterString.join('&')
      } else if ((filters.length === 1) && (filters[0] === '')) {
        return ''
      }
      return `source=${data.filter.toLowerCase()}`;
    }
    if (columnName === 'combined_status') {
      return 'combined_status=' + data.filter;
      // Date range filters
    }
    if (data.filterType === 'date' && data.type === 'inRange') {
      if (columnName === 'ship_date') {
        const dateToPlusOne = moment(data.dateTo).add(1, 'days').format('YYYY-MM-DD');
        return mapColumn(columnName) + '=' + data.dateFrom + ',' + dateToPlusOne;
      }

      const stringStartDate = data.dateFrom.split(' ')[0];
      const stringEndDate = data.dateTo.split(' ')[0];
      return mapColumn(columnName)
        + '_start='
        + stringStartDate + 'T00:00'
        + '&' + mapColumn(columnName)
        + '_end=' + stringEndDate + 'T23:59';
    } else {
      return mapColumn(columnName, data.filter) + '=' + data.filter;
    }
  }).filter(segment => !!segment).join('&');
}

export const parseOptions = (options) => {
  // Translate search terms identical to "Order Source" choices into filters instead
  // Can accept multiple source arguments, but currently cannot mix source and search terms
  if (options?.searchTerm) {
    let searchTermsArray = decodeURIComponent(options.searchTerm).split(',');
    searchTermsArray.forEach((term, i) => searchTermsArray[i] = term.trim().toLowerCase());

    // Set up
    options.filterModel = options.filterModel || {};

    let orderSourceOptions = ['internal', 'shopify', 'squarespace'];
    let filterArray = [];
    let trueSearchTermsArray = []; // Currently not using this
    for (let i=0; i < searchTermsArray.length; i++) {
      if (orderSourceOptions.includes(searchTermsArray[i])) {
        filterArray.push(searchTermsArray[i]);
        delete options.searchTerm;
      } else {
        trueSearchTermsArray.push(searchTermsArray[i])
      }
    }

    options.filterModel = {
      ...options.filterModel,
      source: {
        filter: filterArray.join(',')
      }
    }
  }

  const filters = options?.filterModel && parseFilters(options.filterModel);
  const offset = options?.offset && 'offset=' + options.offset;
  const search = options?.searchTerm && 'search=' + options.searchTerm;
  const sort = options?.sortModel && parseSort(options.sortModel);
  const should_fulfill = options?.should_fulfill && 'should_fulfill=true'

  return [filters, offset, search, sort, should_fulfill].filter((item) => !!item).join('&');
}

export const getRouteQuery = (props) => {
  let routeQuery = props.history.location.search;
  routeQuery = removeParam(routeQuery, 'offset');

  if (!!routeQuery.length && routeQuery[0] === '?') {
    routeQuery = routeQuery.substr(1);
  }

  return routeQuery
}

export const saveQuery = (options, page, props) => {
  let uiQuery = parseOptions(options);
  uiQuery = removeParam(uiQuery, 'offset');
  props.history.push(`/${page}/?` + uiQuery);
}

export const exportGridCsv = (response, email, setExporting) => {
  if (response.ok) {
    let message = isSudoing() ? messages.CSV_EXPORT_ADMIN : messages.CSV_EXPORT(email);
    toast.info(message, {autoClose: 5000});
    setExporting();
  } else {
    toast.error(messages.CSV_EXPORT_ERROR, {autoClose: 5000});
  }
}

export const toggleGridExpand = (gridApi, expandedDetails, setExpanded) => {
  let expandedNodes = [];

  gridApi.setInfiniteRowCount(100, false);
  gridApi.ensureIndexVisible(0);
  gridApi.forEachNode(node => {
    if (!node.master) return;
    if (!expandedDetails) {
      node.setExpanded(true);
      expandedNodes.push(node.data.uuid);
    } else if (expandedDetails) {
      node.setExpanded(false);
    }
  });

  setExpanded(expandedNodes);
}

export const computeToggle = (event, gridApi, expandedDetails, expandedNodes, setExpanded) => {
  let expandedCount = 0;
  if (gridApi) {
    gridApi.forEachNode(node => {
      if (node.master && node.expanded) {
        expandedCount++;
      }
    });
  }

  if (expandedDetails && expandedCount === 0) {
    // Deactivate expand toggle if everything is collapsed
    setExpanded();
  } else if (expandedDetails && event.keepRenderedRows) {
    // Auto expand new data pages
    gridApi.forEachNode(node => {
      if (node.master && !node.expanded && expandedNodes.indexOf(node.data.uuid) === -1) {
        node.setExpanded(true);
      }
    });
  }
}

export const displayGridData = (options, data, totalSize, gridApi) => {
  const pageSize = 100;
  const offset = options.offset;

  let scrollLength;
  if (totalSize === data.length) {
    scrollLength = data.length;
  } else {
    if (data.length === 100) {
      scrollLength = offset + pageSize + pageSize;
    } else {
      scrollLength = totalSize;
    }
  }

  data.length === 0 ? gridApi.showNoRowsOverlay() : gridApi.hideOverlay();

  return scrollLength
}

export const gridAutoResize = (gridApi) => {
  if (!!gridApi) {
    let scrollBreakpoint = 1024;
    let minTableWidth = scrollBreakpoint - 48;
    window.innerWidth > scrollBreakpoint ?
      gridApi.sizeColumnsToFit() : gridApi.sizeColumnsToFit(minTableWidth);
  }
}

// Helper components
export const GridSplash = ({ splash }) =>
  <Level>
    <Level.Item className="has-text-centered pt-6 mt-6">
      <img src={splash}/>
    </Level.Item>
  </Level>
