import { Columns } from "react-bulma-components";
import SelectProducts from "../fields/SelectProducts";
import React from "react";

export const SelectProductsSection = (props) => {
  return (
    <Columns className="section-container">
      <Columns.Column size={3}>
        <p
          data-testid="upload-document-subheading"
          className={"title is-4 mb-4"}
        >
          Products
        </p>
        <p></p>
      </Columns.Column>
      <Columns.Column>
        <SelectProducts {...props} />
      </Columns.Column>
    </Columns>
  );
};

export const SelectProductsInlineSection = props => (<div className="section-container">
  <h5 className="title is-5 mb-4">Select Products</h5>
  <SelectProducts {...props} />
</div>)
