import React from "react";

import { Level } from "react-bulma-components";
import { Link } from "react-router-dom";

export const PageHeader = (props) => {
  const { title, subtitle, menu, adminMenu, isAdmin } = props;
  const { returnLinkURL, returnLinkText } = props;

  return (
    <div className="hero-header">
      <div className="container">
        <div className="level is-marginless is-paddingless">
          <div className="level-left">
            <div className="level-item">
              <div>
                <ReturnLink returnLinkURL={returnLinkURL} returnLinkText={returnLinkText} />
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title is-1 mb-2">{title}</h1>
                  </div>
                </div>
                <Subtitle content={subtitle} />
              </div>
            </div>
          </div>
          <div className="level-right">
            <div className="level-left">
              <div className="level-item">
                <div className="field has-addons">
                  {isAdmin && adminMenu}
                  <div className="control">{menu}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Subtitle = (props) => {
  const { content } = props;
  return <>{content && <div className="is-flex is-justify-content-flex-start">{content}</div>}</>;
};

export const ReturnLink = (props) => {
  const { returnLinkURL, returnLinkText } = props;
  return (
    <>
      {(returnLinkURL || returnLinkText) && (
        <Link className="heading" to={returnLinkURL}>
          <i className="fas fa-angle-left" /> {returnLinkText}
        </Link>
      )}
    </>
  );
};

export default PageHeader;
