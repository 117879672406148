import { get } from "../requests";

const PROJECT_REQUEST_TYPE_API_ENDPOINT = "api/project_type/";

export const listProjectRequestTypes = (filters) => {
  return get(
    PROJECT_REQUEST_TYPE_API_ENDPOINT,
    convertQueryFiltersToParams(filters)
  ).then((response) => {
    const { results } = response;
    if (results.length === 0) {
      return Promise.reject(results);
    }
    return Promise.resolve(
      response["results"].map(convertResponseToProjectRequestTypeState)
    );
  });
};

export const listOrderProjectTypes = () => {
  return listProjectRequestTypes({ hasOrders: true });
};

export const convertResponseToProjectRequestTypeState = (item) => {
  return {
    uuid: item.uuid,
    title: item.title,
    enabled: item.enabled,
    description: item.description,
    hasProduct: item.has_products,
    hasDueDate: item.has_due_date,
    hasInstruction: item.has_instructions,
    hasInput: item.has_input,
    hasOutput: item.has_output,
    hasAdditional: item.has_additionals,
    hasFileAttachmentUpload: item.has_file_attachments,
    hasOrders: item.has_orders,
    hasRestocks: item.has_restocks,
    hasInbounds: item.has_inbounds,
    hasWarehouses: item.has_warehouses,
    hasQuoteRequired: item.has_quote_required,
    hasPhotoRequired: item.has_photo_required,
    inputDescription: item.input_description,
    outputDescription: item.output_description,
  };
};

export const convertQueryFiltersToParams = (filters) => {
  if (filters) {
    const { hasOrders } = filters;
    return { has_orders: hasOrders };
  }
  return null;
};
