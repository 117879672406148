import React from "react";
import { Columns } from "react-bulma-components";
import FileAttachmentUpload from "../../../reusable/fileAttachmentUpload/FileAttachementUpload";

export const FileAttachmentUploadSection = (props) => {
  return (
    <div className="section-container">
      <Columns>
        <Columns.Column size={3}>
          <p data-testid="target-due-date" className={"title is-4 mb-4"}>
            Upload Attachments
          </p>
          <p>
            File types include JPEG, PNG, DOC, DOCX, PDF and others, with a maximum file size of 5
            MB. For videos, please provide a link.
          </p>
        </Columns.Column>
        <Columns.Column>
          <FileAttachmentUpload {...props} />
        </Columns.Column>
      </Columns>
    </div>
  );
};

export const FileAttachmentUploadInlineSection = (props) => (
  <div className="section-container">
    <h5 className="title is-5 mb-4">Upload Attachments</h5>
    <p className={"mb-4"}>
      File types include JPEG, PNG, DOC, DOCX, PDF and others, with a maximum file size of 5
      MB. For videos, please provide a link.
    </p>
    <FileAttachmentUpload {...props} />
  </div>
);
