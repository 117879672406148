import React from "react";
import messages from "../../../../../../utils/messages";

interface IRecipientModalErrorBar {
  ssResponse: string;
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}
export const RecipientModalErrorBar: React.FC<IRecipientModalErrorBar> = ({
  ssResponse,
  onClick,
}) => {
  if (ssResponse === messages.ADDRESS_VALID) return null;
  const helper =
    ssResponse === messages.ADDRESS_INVALID_ERROR ? "is-danger order-error" : "order-warnings";

  return (
    <div className={`help ${helper} is-flex is-justify-content-space-between`}>
      <div>
        <i className="icon-ic-warning"></i>
        <span className="ml-2">{ssResponse}</span>
      </div>
      <div>
        <a href="#0" onClick={onClick}>
          No thanks, I'll use my original address.
        </a>
      </div>
    </div>
  );
};
