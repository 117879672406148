import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { LicenseManager } from "ag-grid-enterprise";
import { Level, Loader } from 'react-bulma-components';

import './main.scss';
import './assets/icomoon/style.css';
import { isPostworks } from './utils/authentication';
import { getUserConfirmation } from './utils/navigation.jsx';
import PrivateRoute from './components/PrivateRoute.jsx';
import UserStore from './components/contexts/UserStore.tsx';
import OrderStore from './components/contexts/OrderStore';
import ReturnStore from './components/contexts/ReturnStore.jsx';
import ProductStore from './components/contexts/ProductStore.jsx';
import VariantStore from './components/contexts/VariantStore.jsx';
import InvoiceStore from './components/contexts/InvoiceStore.jsx';
import PreferencesStore from './components/contexts/PreferencesStore.jsx';

import {PAGE_URL as PROJECT_REQUEST_PAGE_URL} from "./components/pages/ProjectRequest/ProjectRequestHomePage";
import {PAGE_URL as CREATE_PROJECT_REQUEST_PAGE_URL} from "./components/pages/ProjectRequest/CreateProjectRequestPage";
import {PAGE_URL as EDIT_PROJECT_REQUEST_PAGE_URL} from "./components/pages/ProjectRequest/EditProjectRequestPage";
import ProjectRequestStore from "./components/contexts/ProjectRequestStore";

import {PAGE_URL as INBOUND_ORDER_PAGE_URL} from "./components/pages/Inbound/InboundOrderHomePage";
import {PAGE_URL as CREATE_INBOUND_ORDER_PAGE_URL} from "./components/pages/Inbound/CreateInboundOrderPage";
import {PAGE_URL as EDIT_INBOUND_ORDER_PAGE_URL} from "./components/pages/Inbound/EditInboundOrderPage";
import { PaymentModal } from "./components/pages/Settings/Payment/PaymentModal";
import { PaymentStore } from "./components/pages/Settings/Payment/PaymentStore";
import PaymentSettingsModal from './components/pages/Settings/Payment/PaymentSettingsModal';
import PaymentSettingsModalInbound from './components/pages/Settings/Payment/PaymentSettingsModalInbound';


LicenseManager.setLicenseKey(process.env.REACT_APP__AG_GRID);

document.title = isPostworks() ? 'Postworks' : 'Airhouse';


const Login = lazy(() => import('./components/pages/Login/Login.jsx'));
const Register = lazy(() => import('./components/pages/Login/Register.jsx'));
const ForgotPassword = lazy(() => import('./components/pages/Login/ForgotPassword.jsx'));
const ResetPassword = lazy(() => import('./components/pages/Login/ResetPassword.jsx'));
const Privacy = lazy(() => import('./components/pages/Privacy.jsx'));
const NotFound = lazy(() => import('./components/pages/NotFound.jsx'));
const Inventory = lazy(() => import('./components/pages/Inventory/Inventory.jsx'));
const Restock = lazy(() => import('./components/pages/Restock/Restock.jsx'));
const PurchaseOrder = lazy(() => import('./components/pages/Restock/PurchaseOrder.jsx'));
const BulkOrderUpload = lazy(() => import('./components/pages/Orders/Bulk/BulkUpload.jsx'));
const Onboarding = lazy(() => import('./components/pages/ConnectStore/Onboarding.jsx'));
const Products = lazy(() => import('./components/pages/Products/Products.jsx'));
const Bundles = lazy(() => import('./components/pages/Bundles/Bundles.jsx'));
const EditBundle = lazy(() => import('./components/pages/Bundles/EditBundle.jsx'));
const Warehouse = lazy(() => import('./components/pages/Warehouse.jsx'));
const Settings = lazy(() => import('./components/pages/Settings/Settings.jsx'));
const OrderHomePage = lazy(() => import('./components/pages/Orders/OrderHomePage.tsx'));
const EditOrder = lazy(() => import('./components/pages/Orders/Edit/EditOrder'));
const NewOrder = lazy(() => import('./components/pages/Orders/NewOrder/NewOrder'));
const NewOrderReturn = lazy(() => import('./components/pages/Returns/NewOrderReturn.jsx'));
const Returns = lazy(() => import('./components/pages/Returns/Returns.jsx'));
const EditReturn = lazy(() => import('./components/pages/Returns/EditReturn.jsx'));
const EditItem = lazy(() => import('./components/pages/Inventory/ItemDetails/EditItem.jsx'));
const NewBulkInvoice = lazy(() => import('./components/pages/Billing/NewBulkInvoice.jsx'));
const ManageInvoice = lazy(() => import('./components/pages/Billing/ManageInvoice.jsx'));
const Invoices = lazy(() => import('./components/pages/Billing/Invoices.jsx'));
const ProjectRequestHomePage = lazy(() => import('./components/pages/ProjectRequest/ProjectRequestHomePage.jsx'));
const CreateProjectRequestPage = lazy(() => import('./components/pages/ProjectRequest/CreateProjectRequestPage.jsx'))
const EditProjectRequestPage = lazy(() => import('./components/pages/ProjectRequest/EditProjectRequestPage.jsx'))
const RateCalculator = lazy(() => import('./components/pages/Settings/RateCalculator/RateCalculatorPage'))
const InboundOrderHomePage = lazy(() => import('./components/pages/Inbound/InboundOrderHomePage.jsx'));
const CreateInboundOrderPage = lazy(() => import('./components/pages/Inbound/CreateInboundOrderPage.jsx'))
const EditInboundOrderPage = lazy(() => import('./components/pages/Inbound/EditInboundOrderPage.jsx'))
const ShippingPage = lazy(() => import('./components/pages/Settings/Shipping/ShippingPage'));
const ShippingZonePage = lazy(() => import('./components/pages/Settings/Shipping/Zones/ZonePage'));
const ShippingZoneFallbackPage = lazy(() => import('./components/pages/Settings/Shipping/Zones/FallbackPage'));
const ShippingGroupDetailPage = lazy(() => import('./components/pages/Settings/Shipping/Zones/Groups/ShippingGroupDetailPage'));

const App = () => (
  <HttpsRedirect>
    <UserStore>
      <OrderStore>
        <ReturnStore>
          <ProductStore>
            <VariantStore>
              <InvoiceStore>
                <PreferencesStore>
                  <ProjectRequestStore>
                    <PaymentStore>
                      <Router getUserConfirmation={ getUserConfirmation }>
                        <Suspense fallback={
                            <Level>
                              <Level.Item>
                                <Loader style={{ width: '100px', height: '100px', borderLeftColor: '#886FFF', borderBottomColor: '#886FFF' }} />
                              </Level.Item>
                            </Level>
                          }>
                          <Switch>
                            <Route path="/register" component={Register} />
                            <Route path="/privacy" component={Privacy} />
                            <Route path="/login" component={Login} />
                            <Route path="/forgot-password" component={ForgotPassword} />
                            <Route path="/reset-password" component={ResetPassword} />

                            <PrivateRoute exact path="/" component={ Onboarding } />
                            <PrivateRoute exact path="/onboarding/products" component={ Products } />
                            <PrivateRoute exact path="/onboarding/warehouse" component={ Warehouse } />
                            <PrivateRoute exact path="/orders" component={ OrderHomePage } />
                            <PrivateRoute exact path="/orders/new" component={ NewOrder } />
                            <PrivateRoute path="/orders/edit/:orderId" component={ EditOrder } />
                            <PrivateRoute path="/orders/returns/:returnId" component={ NewOrderReturn } />
                            <PrivateRoute exact path="/orders/bulk-upload" component={ BulkOrderUpload } />
                            <PrivateRoute exact path="/inventory" component={ Inventory } />
                            <PrivateRoute exact path="/inventory/products" component={ Products } />
                            <PrivateRoute exact path="/inventory/variants/:variantId" component={ EditItem } />
                            <PrivateRoute exact path="/billing" component={Invoices} />
                            <PrivateRoute exact path="/billing/new" component={ NewBulkInvoice} />
                            <PrivateRoute path="/billing/:invoiceId" component={ ManageInvoice } />
                            <PrivateRoute exact path="/bundles" component={ Bundles } />
                            <PrivateRoute path="/bundles/edit/:productId" component={ EditBundle } />
                            <PrivateRoute exact path="/restock" component={ Restock } />
                            <PrivateRoute exact path="/restock/new">
                              <Redirect to={CREATE_INBOUND_ORDER_PAGE_URL} />
                            </PrivateRoute>
                            <PrivateRoute path="/restock/view/:purchaseOrderId" component={ PurchaseOrder } />
                            <PrivateRoute exact path="/returns" component={ Returns } />
                            <PrivateRoute path="/returns/:returnId" component={ EditReturn } />
                            <PrivateRoute exact path="/settings/:currentView" component={ Settings } />
                            <PrivateRoute exact path="/settings/shipping-estimator" component={ RateCalculator } />
                            <PrivateRoute exact path="/settings/shipping/zones/:shippingZoneId" component={ ShippingZonePage } />
                            <PrivateRoute exact path="/settings/shipping/zones/:shippingZoneId/groups" component={ ShippingGroupDetailPage } />
                            <PrivateRoute exact path="/settings/shipping/zones/:shippingZoneId/groups/add" component={ ShippingGroupDetailPage } />
                            <PrivateRoute exact path="/settings/shipping/zones/:shippingZoneId/groups/:shippingGroupId" component={ ShippingGroupDetailPage } />
                            <PrivateRoute exact path="/settings/shipping/zones/:shippingZoneId/fallback" component={ ShippingZoneFallbackPage } />

                            <PrivateRoute path={CREATE_PROJECT_REQUEST_PAGE_URL} component={CreateProjectRequestPage} />
                            <PrivateRoute path={EDIT_PROJECT_REQUEST_PAGE_URL} component={EditProjectRequestPage} />
                            <PrivateRoute path={PROJECT_REQUEST_PAGE_URL} component={ProjectRequestHomePage} />
                            <PrivateRoute path={CREATE_INBOUND_ORDER_PAGE_URL} component={CreateInboundOrderPage} />
                            <PrivateRoute path={EDIT_INBOUND_ORDER_PAGE_URL} component={EditInboundOrderPage} />
                            <PrivateRoute path={INBOUND_ORDER_PAGE_URL} component={InboundOrderHomePage} />
                            <Route component={ NotFound } />
                          </Switch>
                          <PaymentModal />
                          <PaymentSettingsModal />
                          <PaymentSettingsModalInbound />
                        </Suspense>
                      </Router>
                    </PaymentStore>
                  </ProjectRequestStore>
                </PreferencesStore>
              </InvoiceStore>
            </VariantStore>
          </ProductStore>
        </ReturnStore>
      </OrderStore>
    </UserStore>

    <ToastContainer
      position="top-center"
      autoClose={ 2000 }
      hideProgressBar
      newestOnTop={ false }
      closeOnClick
      closeButton={ false }
      rtl={ false }
      pauseOnVisibilityChange
      draggable
      pauseOnHover
    />
  </HttpsRedirect>
)

export default App;
