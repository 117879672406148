import React from "react";

export const DEFAULT_CREATE_PROJECT_REQUEST_PAGE_STATE = {
  isLoading: false,
  isSaving: false,
  isEditing: false,
  isError: false,
  errorMessage: [],
};

export const usePageState = (pageState) => {
  const [state, setState] = React.useState(() => ({
    ...DEFAULT_CREATE_PROJECT_REQUEST_PAGE_STATE,
    ...pageState,
  }));

  const setIsLoading = (isLoading) => {
    setState((prevState) => ({
      ...prevState,
      isLoading,
    }));
  };

  const setIsSaving = (isSaving) => {
    setState((prevState) => ({
      ...prevState,
      isSaving,
    }));
  };

  const setIsEditing = (isEditing) => {
    setState((prevState) => ({
      ...prevState,
      isEditing,
    }));
  };

  return { state, setIsLoading, setIsSaving, setIsEditing };
};
