import React, { useContext, useEffect, useState } from "react";
import { Modal, Columns } from "react-bulma-components";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../../contexts/UserStore";

const PaymentSettingsModalInbound = () => {
  const history = useHistory();
  const location = useLocation();
  const userContext = useContext(UserContext);
  const [isVisible, setIsVisible] = useState(false);
  let isAdmin = false;

  useEffect(() => {
    const path = location.pathname.split("/");
    const userIsOnInboundRoute = path[1] === "inbound";
    const showPopUp =
      !userContext.loading &&
      userIsOnInboundRoute &&
      !userContext.data?.account?.company?.has_payment_information &&
      !userContext.data?.account?.company?.restock_without_payment_details_enabled;
    setIsVisible(showPopUp);
  }, [userContext.store, location]);

  if (Boolean(userContext) && !userContext.loading && userContext.data?.isAdmin) {
    isAdmin = true;
  }

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleGoToPaymentSettingsClick = () => {
    setIsVisible(false);
    history.push("/settings/payment");
  };

  if (isAdmin) {
    return <></>;
  }

  return (
    <>
      <Modal show={isVisible} onClose={handleClose}>
        <Modal.Card>
          <Modal.Card.Header showClose={isAdmin} onClose={handleClose}>
            <Modal.Card.Title>Important: Missing Payment Information</Modal.Card.Title>
          </Modal.Card.Header>
          <Modal.Card.Body>
            <div>
              <p>
                Airhouse requires a payment method on file prior to sending inventory to the
                warehouse. Please add a credit card or bank account.
              </p>
            </div>
          </Modal.Card.Body>
          <Modal.Card.Footer>
            <Columns className="" style={{ flex: "auto", float: "right", textAlign: "right" }}>
              <Columns.Column>
                <button
                  className="button is-primary is-right mr-4"
                  onClick={handleGoToPaymentSettingsClick}
                >
                  Set up payment settings
                </button>
              </Columns.Column>
            </Columns>
          </Modal.Card.Footer>
        </Modal.Card>
      </Modal>
    </>
  );
};

export default PaymentSettingsModalInbound;
