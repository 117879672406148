import { useState } from "react";
import { useInboundSearchBar } from "../../Inbound/InboundSearchBar";

export const DEFAULT_STATE = {
  inbounds: [],
};

export const useSelectInbounds = (newState = {}) => {
  const [state, setState] = useState({
    ...DEFAULT_STATE,
    inbounds: newState.inbounds || []
  });

  const addInbound = (inbound) => {
    setState((prevState) => ({
      ...prevState,
      inbounds: [...prevState.inbounds, inbound],
    }));
  };

  const inboundSearchBarState = useInboundSearchBar();

  const removeInbound = (inbound) => {
    setState((prevState) => ({
      ...prevState,
      inbounds: prevState.inbounds.filter((o) => o !== inbound),
    }));
  };

  const loadInbounds = (inbounds) => {
    setState((prevState) => ({
      ...prevState,
      inbounds,
    }));
  }

  const resetInbounds = () => {
    setState((prevState) => ({
      ...prevState,
      inbounds: [],
    }));
  };

  return {
    ...state,
    addInbound,
    removeInbound,
    resetInbounds,
    loadInbounds,
    inboundSearchBarState,
  };
};
