import CustomDropdown from "../../../reusable/CustomDropdown";
import React from "react";
import { useDropdown } from "../../../reusable/dropdown/useDropdown";

export const useSelectStore = (props) => {
  const dropdownState = useDropdown(props);
  return { ...dropdownState.state, ...dropdownState };
};

export const SelectStore = (props) => {
  const onStoreSelected = props?.onStoreSelected || (() => {});

  const setCurrentItem = (item) => {
    props.setCurrentItem(item);
    onStoreSelected(item);
  };

  return (
    <CustomDropdown
      {...props}
      setCurrentItem={setCurrentItem}
      isDropdownActive={props.isActive}
      toggleDropdownMenu={props.toggleIsActive}
    />
  );
};
