import React from "react";
import { Columns } from "react-bulma-components";
import DatePicker from "../../../reusable/datepicker/DatePicker";

export const TargetDueDateSection = (props) => (
  <div className="section-container">
    <Columns>
      <Columns.Column size={3}>
        <p data-testid="target-due-date" className={"title is-4 mb-4"}>
          Target Due Date
        </p>
        <p>Please note that target completion dates are tentative and subject to Airhouse approval.
        </p>
      </Columns.Column>
      <Columns.Column>
        <div className="mb-1">
          <DatePicker {...props} disableWeekends={true} />
        </div>
      </Columns.Column>
    </Columns>
  </div>
);

export const TargetDueDateInlineSection = (props) => (
  <div className="section-container">
    <h5 className="title is-5 mb-4">Due Date</h5>
    <p className={"mb-4"}>Please note that target completion dates are tentative and subject to Airhouse approval.</p>
    <DatePicker {...props} />
  </div>
);
