import React, { useEffect, useRef } from 'react';


const CustomDropdown = (props) => {
  const ref = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const { readOnly } = props;

  const isDropdownActive = props.isDropdownActive || false; // State of open/closed dropdown menu
  const toggleDropdownMenu = props.toggleDropdownMenu || false; // Function will toggle open/close dropdown menu
  const currentItem = props.currentItem || null; // Current selection
  const items = props.items || []; // Array of items objects
  const setCurrentItem = props.setCurrentItem || (() => null); // Function will set clicked selection as currentItem
  const itemIdKey = props.itemIdKey || 'uuid';
  const displayName = props.displayName || 'name';
  const placeholderText = props.placeholderText || 'Select an option';
  const itemDisplay =  props.itemDisplay || DropdownItemDisplay;

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target) && props.isDropdownActive) {
      props.toggleDropdownMenu();
    }
  };

  const onDropdownClick = () => {
    if (!readOnly){
      toggleDropdownMenu();
    }
  }

  return  (
    <div 
      ref={ref}
      className={'is-fullwidth dropdown' + (isDropdownActive ? ' is-active': '')} 
      onClick={onDropdownClick}
    >
      <div className='is-fullwidth dropdown-trigger'>
        <button 
          className='is-fullwidth button is-justify-content-space-between' 
          aria-haspopup='true' aria-controls='dropdown-menu' style={styles.dropdownButton}
          disabled={readOnly}
        >
          <span style={styles.smallerFont}>
            {currentItem?.[displayName] ?? placeholderText}
          </span>
          <span className='is-pulled-left icon is-small'>
            <i className='icon-ic-caret' aria-hidden='true' />
          </span>
        </button>
      </div>
      
      <div className='is-fullwidth dropdown-menu' role='menu'>
        <div className='dropdown-content' style={styles.dropdownButton}>
          {items.map(item => {
            const isActive = currentItem?.[itemIdKey] === item[itemIdKey];
            return (
              <DropdownItem 
                key={item[itemIdKey]} 
                displayName={item[displayName]} 
                item={item} 
                setCurrentItem={setCurrentItem} 
                isActive={isActive} 
                itemDisplay={itemDisplay}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

const DropdownItem = (props) => {
  const { setCurrentItem, isActive, displayName, item } = props
  const ItemDisplay = props.itemDisplay;

  if (item.readOnly) {
    return (
      <div className='is-fullwidth dropdown-item' style={styles.smallerFont}>
        <ItemDisplay item={item} displayName={displayName} />
      </div>
    );
  }
  
  return (
    <a
      style={styles.smallerFont}
      href='#0'
      className={'is-fullwidth dropdown-item' + (isActive ? ' is-active': '')}
      onClick={() => {setCurrentItem(item)}}
    >
      <ItemDisplay item={item} displayName={displayName} />
    </a>
  )
};

const styles = {
  dropdownButton: {
    borderRadius: '4px',
    boxShadow: 'none',
  },
  smallerFont: {
    fontSize: '0.875rem',
  },
}
const DropdownItemDisplay = (props) => {
  const {displayName} = props;
  return displayName;
};


export default CustomDropdown;
