import React from "react";

export const ViewInAdmin = (props) => {
  const { url } = props;
  return (
    <a
      className="dropdown-item"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span>
        <i className="icon-ic-open" aria-hidden="true" /> View in Admin
      </span>
    </a>
  );
};
