import React, { useState, useEffect } from 'react';
import styleVariables from '../../../../sass/export.module.scss';
import consts from '../../../../utils/consts';
import { hex6ToHex8 } from '../../../../utils/general';

export const isLotValid = (lot) =>
  lot &&
  lot.lot_number &&
  lot.lot_number.length <= consts.LOT_NUMBER_CHARS_LIMIT &&
  lot.quantity > 0;

export const areItemLotsValid = (item) =>
  item &&
  item.lots &&
  item.lots.length > 0 &&
  item.lots.every(isLotValid) &&
  item.lots.reduce((acc, lot) => lot.quantity + acc, 0) === item.count;

const ItemLotsTable = ({ item, isEditing, addLot, updateLot, removeLot }) => {
  const lotsQuantity = item.lots.reduce((acc, lot) => acc + lot.quantity, 0);
  return (
    <div>
      <div
        className='p-3 is-size-7 has-text-weight-medium	'
        style={{
          ...styles.ItemLotsTableHeaderContainer,
          gridTemplateColumns: isEditing ? '1fr 1fr 100px' : '1fr 1fr',
        }}
      >
        <p>Lot Number</p>
        <p style={{ justifySelf: isEditing ? 'start' : 'end' }}>Quantity</p>
        {isEditing && <span />}
      </div>
      <div className='p-3' style={styles.ItemLotsTableBodyContainer}>
        {item.lots.map((lot, ind) => (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: isEditing ? '1fr 1fr 100px' : '1fr 1fr',
            }}
          >
            {isEditing ? (
              <>
                <input
                  style={{ width: '5rem' }}
                  className='input is-small'
                  type='text'
                  value={lot.lot_number}
                  onChange={(e) => updateLot(ind, 'lot_number', e.target.value)}
                />
                <input
                  style={{ width: '3.5rem' }}
                  className='input is-small'
                  type='number'
                  min={1}
                  value={lot.quantity}
                  onChange={(e) => updateLot(ind, 'quantity', Number(e.target.value))}
                />
                <div style={{ justifySelf: 'end' }}>
                  {ind !== 0 && (
                    <i
                      className='icon-ic-trash has-text-grey-light is-icon pl-2'
                      aria-hidden='true'
                      onClick={() => removeLot(ind)}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                <p>{lot.lot_number}</p>
                <p style={{ justifySelf: 'end' }}>{lot.quantity}</p>
              </>
            )}
          </div>
        ))}
      </div>
      <div className='pr-3 pt-3 is-align-items-center' style={styles.ItemLotsTableFooterContainer}>
        <div>
          {isEditing && (
            <button className='button is-ghost is-size-7' onClick={addLot}>
              Add Another Lot
            </button>
          )}
        </div>
        <div>
          <span className='is-size-7'>Total Quantity: {lotsQuantity}</span>
        </div>
        <div>
          <span className='is-size-7'>Unassigned Quantity: {item.count - lotsQuantity}</span>
        </div>
      </div>
    </div>
  );
};

const VariantTableBodyWithEditableCount = ({
  item,
  itemKey,
  isOpen,
  toggleOpen,
  isEditing,
  isProcessed,
  removeItem,
  updateCount,
  hasDetail,
  addLot,
  updateLot,
  removeLot,
}) => (
  <div
    className='py-5 pr-4 pl-0'
    style={{
      display: 'grid',
      gap: 15,
    }}
  >
    <div
      className='is-align-items-center'
      key={itemKey}
      style={styles.VariantTableBodyRowContainer}
    >
      <div
        style={{
          ...styles.VariantTableBodyRowNameContainer,
          gridTemplateColumns: hasDetail ? '30px auto' : 'auto',
        }}
      >
        {hasDetail && (
          <i
            className={`fa fa-angle-${
              isOpen ? 'down' : 'right'
            } has-text-grey-light is-icon pl-2 is-align-self-center`}
            aria-hidden='true'
            onClick={toggleOpen}
          />
        )}
        <div>
          <p>{item.combinedName}</p>
          <p>{item.sku}</p>
        </div>
      </div>
      <div style={{ justifySelf: 'center' }}>
        {isEditing ? (
          <input
            style={{ width: '3.5rem' }}
            className='input is-small'
            type='number'
            min={1}
            value={item.count}
            onChange={(e) => updateCount(itemKey, Number(e.target.value))}
          />
        ) : (
          `${item.total_count}`
        )}
      </div>
      {isEditing && (
        <i
          className='icon-ic-trash has-text-grey-light is-icon pl-2 pr-4'
          style={{ justifySelf: 'end' }}
          aria-hidden='true'
          onClick={() => removeItem(itemKey, 0)}
        />
      )}
      {isProcessed && <span style={{ justifySelf: 'end' }}>{item.received_count}</span>}
    </div>
    {hasDetail && isOpen && (
      <ItemLotsTable
        item={item}
        isEditing={isEditing}
        addLot={() => addLot(itemKey, item.lots)}
        updateLot={(lotInd, lotField, lotValue) =>
          updateLot(itemKey, item.lots, lotInd, lotField, lotValue)
        }
        removeLot={(lotInd) => removeLot(itemKey, item.lots, lotInd)}
      />
    )}
  </div>
);

const InfoDiv = ({ title, items }) => (
  <div className='py-2 px-0 is-align-items-center' style={styles.InfoDivContainer}>
    <span className='icon is-align-items-center' style={styles.InfoDivIconContainer}>
      <i className='fas fa-exclamation-circle' aria-hidden='true' />
    </span>
    <div>
      <p>{title}</p>
      {items && items.length > 0 && (
        <ul className='ml-4' style={{ listStyleType: 'initial' }}>
          {items.map((item) => (
            <li key={item.key}>{item.text}</li>
          ))}
        </ul>
      )}
    </div>
  </div>
);

const VariantTableWithEditableCount = ({
  errors,
  selectedVariants,
  removeItem,
  updateCount,
  addLot,
  updateLot,
  removeLot,
  isEditing,
  inboundOrderState,
  itemKey = 'uuid',
}) => {
  const [openVariants, setOpenVariants] = useState(
    selectedVariants.reduce((acc, item) => ({ ...acc, [item[itemKey]]: false }), {})
  );

  useEffect(() => {
    if (selectedVariants) {
      const newVariants = {};
      selectedVariants.forEach((variant) => {
        if (!Object.keys(openVariants).includes(variant[itemKey])) {
          newVariants[variant[itemKey]] = false;
        }
      });
      if (Object.keys(newVariants).length > 0) {
        setOpenVariants({ ...openVariants, ...newVariants });
      }
    }
  }, [selectedVariants, itemKey]);

  const isProcessed = ![consts.STATUS_NEW, consts.STATUS_DRAFT].includes(inboundOrderState.status);
  const hasVariants = selectedVariants.length > 0;
  const invalidLots = selectedVariants.flatMap(
    (item) =>
      item?.lots
        ?.filter((lot) => !isLotValid(lot))
        ?.map((lot) => ({
          ...lot,
          itemName: item.combinedName,
          itemSKU: item.sku,
        })) || []
  );
  return (
    <div>
      {errors.lotsInvalid && invalidLots && invalidLots.length > 0 && (
        <InfoDiv
          title='Error with Lot tracking information for the following product:'
          items={invalidLots.map((lot) => ({
            key: lot.itemSKU,
            text: `${lot.itemName} SKU: ${lot.itemSKU} Lot Number: ${lot.lot_number}`,
          }))}
        />
      )}
      <div className='mt-2'>
        {!hasVariants && (
          <div className='is-fullwidth'>
            <span className='box has-background-white-ter	has-text-grey-light p-3'>
              <span>No products selected yet</span>
            </span>
          </div>
        )}
        {hasVariants && (
          <>
            <div>
              <div
                className='pt-0 pr-4 pb-1 pl-0 is-size-7 has-text-weight-bold is-uppercase'
                style={styles.VariantTableHeaderContainer}
              >
                <span>Products Included</span>
                <span style={{ justifySelf: 'center' }}>Quantity</span>
                {isEditing && <span />}
                {isProcessed && <span style={{ justifySelf: 'end' }}>Quantity Received</span>}
              </div>
              <div>
                {selectedVariants.map((item) => (
                  <VariantTableBodyWithEditableCount
                    item={item}
                    itemKey={item[itemKey]}
                    isOpen={openVariants[item[itemKey]]}
                    isEditing={isEditing}
                    isProcessed={isProcessed}
                    hasDetail={item.track_lot_number}
                    toggleOpen={() =>
                      setOpenVariants({
                        ...openVariants,
                        [item[itemKey]]: !openVariants[item[itemKey]],
                      })
                    }
                    addLot={addLot}
                    updateLot={updateLot}
                    removeLot={removeLot}
                    removeItem={removeItem}
                    updateCount={updateCount}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  ItemLotsTableHeaderContainer: {
    display: 'grid',
    backgroundColor: styleVariables.greyLightest,
    border: `solid 1px ${hex6ToHex8(styleVariables.grey, 33)}`,
    letterSpacing: '0.05em',
  },
  ItemLotsTableBodyContainer: {
    display: 'grid',
    gap: 10,
    border: `solid 1px ${hex6ToHex8(styleVariables.grey, 33)}`,
    borderTop: 0,
    backgroundColor: hex6ToHex8(styleVariables.purpleLightest, 50),
  },
  ItemLotsTableFooterContainer: {
    display: 'inline-grid',
    width: '100%',
    gridTemplate: 'auto / 1fr auto auto',
    gap: 20,
  },
  VariantTableBodyRowContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplate: 'auto / 1fr 1fr 1fr',
  },
  VariantTableBodyRowNameContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
  },
  VariantTableHeaderContainer: {
    display: 'grid',
    gridTemplate: 'auto / 1fr 1fr 1fr',
    gridAutoFlow: 'column',
    color: styleVariables.greyDark,
    letterSpacing: '0.05em',
  },
  InfoDivContainer: {
    display: 'grid',
    gridTemplate: 'auto / 50px 1fr',
    backgroundColor: styleVariables.blueLight,
  },
  InfoDivIconContainer: {
    justifySelf: 'center',
    minHeight: 50,
    color: styleVariables.greyDark,
  },
};

export default VariantTableWithEditableCount;
