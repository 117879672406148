import { useState } from "react";

export const usePromiseLoading = <T extends (...args: any[]) => Promise<any>>(
  promise: T
): { caller: T; isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(false);

  const caller = async (...args: Parameters<T>) => {
    try {
      setIsLoading(true);
      const result = await promise(...args);
      return result;
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  return { caller: caller as T, isLoading };
};
