import React from "react";

export const Img = (props) => {
  const { src, alt, fileName } = props;
  const documentExtension = fileName.split(".").pop().toLowerCase();
  const imageTypes = ["png", "jpeg", "jpg"];
  const showThumbnail = src && imageTypes.indexOf(documentExtension) !== -1;
  return showThumbnail ? (
    <img
      src={src}
      alt={alt}
      className="image"
      style={{ maxWidth: "50px", maxHeight: "50px" }}
    />
  ) : (
    <span className="icon has-text-grey-light image">
      <i className="icon-ic-placeholder" />
    </span>
  );
};