import React, { createContext, useState } from "react";

export const DEFAULT_PAYMENT_MODAL_CONTEXT_STATE = {
  showLate: false,
  setShowLate: () => {},
  showSettings: false,
  setShowSettings: () => {},
  suspended: false,
  delinquent: false,
  missingPaymentSettings: false,
  errors: {},
  selectedOrder: null,
};

export const PaymentModalContext = createContext(DEFAULT_PAYMENT_MODAL_CONTEXT_STATE);

export const PaymentStore = ({ children }) => {
  const [showLate, setShowLate] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [suspended, setSuspended] = useState(false);
  const [delinquent, setDelinquent] = useState(false);
  const [missingPaymentSettings, setMissingPaymentSettings] = useState(false);

  return (
    <PaymentModalContext.Provider
      value={{
        showLate,
        setShowLate,
        showSettings,
        setShowSettings,
        suspended,
        setSuspended,
        delinquent,
        setDelinquent,
        missingPaymentSettings,
        setMissingPaymentSettings,
      }}
    >
      {children}
    </PaymentModalContext.Provider>
  );
};