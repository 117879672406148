import React from "react";
import { Columns } from "react-bulma-components";
import { SelectStore } from "../fields/SelectStore";

export const SelectStoreSection = (props) => {
  return (
    <section className="section-container">
      <Columns>
        <Columns.Column size={3}>
          <p className="title is-4">Store</p>
        </Columns.Column>
        <Columns.Column>
          <SelectStore {...props} />
        </Columns.Column>
      </Columns>
    </section>
  );
};
