import { useState } from "react";

const DEFAULT_FILE_LINK_STATE = {
  url: "",
  isInvalidURLError: false,
};

export const useFileLink = () => {
  const [state, setState] = useState(DEFAULT_FILE_LINK_STATE);

  const setURL = (url) => {
    setState((prevState) => ({
      ...prevState,
      url,
      isInvalidURLError: false,
    }));
  };

  const setIsInvalidURLError = (isInvalidURLError) => {
    setState((prevState) => ({
      ...prevState,
      isInvalidURLError,
    }));
  };

  return { ...state, setURL, setIsInvalidURLError };
};
