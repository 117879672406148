import React, { useState } from "react";
import clsx from "clsx";
import DatePicker from "../../../reusable/datepicker/DatePicker";
import { useDatePicker } from "../../../reusable/datepicker/useDatepicker";
import { ValidationError } from "../../../reusable/ValidationError";

const DEFAULT_PALLET = {
  palletLength: "",
  palletWidth: "",
  palletHeight: "",
  palletWeight: "",
};

const DEFAULT_STATE = {
  contactFirstName: "",
  contactLastName: "",
  contactEmail: "",
  contactPhone: "",
  facilityAddress: "",
  facilityHours: "",
  pallets: [{ ...DEFAULT_PALLET }],
  pickupNumber: "",
  pickupRequirementLimitedAccess: "",
  pickupRequirementInside: "",
  pickupRequirementLiftGate: "",
  pickupRequirementResidential: "",
  pickupRequirementSortSegregate: "",
  // freightCompany: "",
  // freightPickUpLocation: "",
  // freightReferenceNumber: "",
};

export const useFreightDetailsAirhouse = (inputState = {}) => {
  const [freightDetailsAirhouseState, setFreightDetailsAirhouseState] =
    useState({ ...DEFAULT_STATE, ...inputState?.freightDetailsAirhouseState });
  const pickupDateState = useDatePicker(
    inputState?.pickupDateState || { minDate: new Date() }
  );

  const updateValue = (key, value) => {
    setFreightDetailsAirhouseState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updatePalletValue = (key, value, palletIndex) => {
    setFreightDetailsAirhouseState((prevState) => {
      return {
        ...prevState,
        pallets: prevState.pallets.map((pallet, index) => {
          if (index === palletIndex) {
            return { ...pallet, [key]: value };
          }
          return pallet;
        }),
      };
    });
  };

  const addPallet = () => {
    setFreightDetailsAirhouseState((prevState) => {
      return {
        ...prevState,
        pallets: [...prevState.pallets, { ...DEFAULT_PALLET }],
      };
    });
  };

  const removePallet = (palletIndex) => {
    setFreightDetailsAirhouseState((prevState) => {
      return {
        ...prevState,
        pallets: prevState.pallets.filter(
          (pallet, index) => palletIndex !== index
        ),
      };
    });
  };

  const isFreightPalletMissing = () => {
    return freightDetailsAirhouseState?.pallets
      .map((pallet) => Object.values(pallet).some((x) => x === ""))
      .some((x) => x === true) || false;
  };

  const reset = () => {
    setFreightDetailsAirhouseState({ ...DEFAULT_STATE });
  };

  return {
    freightDetailsAirhouseState,
    pickupDateState,
    updateValue,
    updatePalletValue,
    addPallet,
    removePallet,
    setFreightDetailsAirhouseState,
    isFreightPalletMissing,
    reset,
  };
};

const FreightDetailsAirhouse = (props) => {
  const {
    freightDetailsAirhouseState,
    pickupDateState,
    updateValue,
    updatePalletValue,
    addPallet,
    removePallet,
    isEditing,
    errors,
  } = props;

  const {
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhone,
    facilityAddress,
    facilityHours,
    pallets,
    pickupNumber,
    pickupRequirementLimitedAccess,
    pickupRequirementInside,
    pickupRequirementLiftGate,
    pickupRequirementResidential,
    pickupRequirementSortSegregate,
  } = freightDetailsAirhouseState;

  const handleChange = (event) => {
    updateValue(event.target.name, event.target.value);
  };

  const handlePalletChange = (event, palletIndex) => {
    updatePalletValue(event.target.name, event.target.value, palletIndex);
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    updateValue(name, value === "yes");
  };

  const handleAddPallet = () => {
    addPallet();
  };

  const handleRemovePallet = (palletIndex) => {
    removePallet(palletIndex);
  };

  return (
    <>
      {/* Create new `columns` div for each row so that mobile single column layout follows same flow as desktop. */}
      {errors.freightInfoMissing && (
        <ValidationError message={"Please include all contact information."} />
      )}
      <div className="columns">
        <div className={clsx("column")}>
          <p className="heading">Contact first name</p>
          <input
            className="input"
            type="text"
            name="contactFirstName"
            value={contactFirstName}
            onChange={handleChange}
            disabled={!isEditing}
          />
        </div>
        <div className={clsx("column")}>
          <p className="heading">Contact last name</p>
          <input
            className="input"
            type="text"
            name="contactLastName"
            value={contactLastName}
            onChange={handleChange}
            disabled={!isEditing}
          />
        </div>
      </div>
      <div className="columns">
        <div className={clsx("column")}>
          <p className="heading">Contact email</p>
          <input
            className="input"
            type="text"
            name="contactEmail"
            value={contactEmail}
            onChange={handleChange}
            disabled={!isEditing}
          />
        </div>
        <div className={clsx("column")}>
          <p className="heading">Contact phone</p>
          <input
            className="input"
            type="text"
            name="contactPhone"
            value={contactPhone}
            onChange={handleChange}
            disabled={!isEditing}
          />
        </div>
      </div>
      <div className="columns">
        <div className={clsx("column")}>
          <p className="heading">Facility address</p>
          <input
            className="input"
            type="text"
            name="facilityAddress"
            value={facilityAddress}
            onChange={handleChange}
            disabled={!isEditing}
          />
        </div>
        <div className={clsx("column")}>
          <p className="heading">Facility hours of operation</p>
          <input
            className="input"
            type="text"
            name="facilityHours"
            value={facilityHours}
            onChange={handleChange}
            disabled={!isEditing}
          />
        </div>
      </div>

      {errors.freightPalletMissing && (
        <ValidationError message={"Please include complete pallet details."} />
      )}
      {pallets.map((pallet, palletIndex) => {
        const { palletLength, palletWidth, palletHeight, palletWeight } =
          pallet;

        return (
          <div className="columns" key={`pallet-${palletIndex}`}>
            <div className={clsx("column")}>
              <p className="heading">Pallet Size in inches (L x W x H) </p>
              <div className={clsx("columns", "is-flex", "is-vcentered")}>
                <div className={clsx("column")}>
                  {" "}
                  <input
                    className="input"
                    type="text"
                    name="palletLength"
                    value={palletLength}
                    onChange={(event) => handlePalletChange(event, palletIndex)}
                    disabled={!isEditing}
                  />
                </div>
                x
                <div className={clsx("column")}>
                  {" "}
                  <input
                    className="input"
                    type="text"
                    name="palletWidth"
                    value={palletWidth}
                    onChange={(event) => handlePalletChange(event, palletIndex)}
                    disabled={!isEditing}
                  />
                </div>
                x
                <div className={clsx("column")}>
                  {" "}
                  <input
                    className="input"
                    type="text"
                    name="palletHeight"
                    value={palletHeight}
                    onChange={(event) => handlePalletChange(event, palletIndex)}
                    disabled={!isEditing}
                  />
                </div>
              </div>
            </div>
            <div className={clsx("column")}>
              <div className={clsx("columns", "is-flex", "is-vcentered")}>
                <div className={clsx("column")}>
                  <p className="heading">Weight per pallet in pounds</p>
                  <input
                    className="input"
                    type="text"
                    name="palletWeight"
                    value={palletWeight}
                    onChange={(event) => handlePalletChange(event, palletIndex)}
                    disabled={!isEditing}
                  />
                </div>
                {palletIndex > 0 && (
                  <div className={clsx("column", "is-narrow")}>
                    <i
                      className="icon-ic-trash has-text-grey-light is-icon"
                      onClick={() => handleRemovePallet(palletIndex)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
      {isEditing && (
        <div className="columns">
          <div className={clsx("column")}>
            <a onClick={handleAddPallet}>Add another pallet</a>
          </div>
        </div>
      )}
      <div className="columns">
        <div className={clsx("column")}>
          {errors.freightDateMissing && (
            <ValidationError message={"Please add a valid date."} />
          )}
          <p className="heading">Requested pickup date</p>
          {/* {isEditing && <DatePicker {...pickupDateState} />} */}
          {isEditing && <DatePicker {...pickupDateState} />}
          {!isEditing && (
            <p>{pickupDateState.date || "No pickup date specified"}</p>
          )}
        </div>
        <div className={clsx("column")}>
          <p className="heading">Pick up number (if applicable)</p>
          <input
            className="input"
            type="text"
            name="pickupNumber"
            value={pickupNumber}
            onChange={handleChange}
            disabled={!isEditing}
          />
          <p className="heading">Pick up requirements</p>
          <div className="columns">
            <div className={clsx("column", "is-one-half")}>Limited Access</div>
            <div className={clsx("column", "is-one-quarter")}>
              <input
                className="is-checkradio"
                type="radio"
                id="pickupRequirementLimitedAccess-yes"
                name="pickupRequirementLimitedAccess"
                value="yes"
                checked={pickupRequirementLimitedAccess}
                onChange={handleRadioChange}
                disabled={!isEditing}
              />
              <label
                className="radio"
                htmlFor="pickupRequirementLimitedAccess-yes"
              >
                Yes
              </label>
            </div>
            <div className={clsx("column", "is-one-quarter")}>
              <input
                className="is-checkradio"
                type="radio"
                id="pickupRequirementLimitedAccess-no"
                name="pickupRequirementLimitedAccess"
                value="no"
                checked={!pickupRequirementLimitedAccess}
                onChange={handleRadioChange}
                disabled={!isEditing}
              />
              <label
                className="radio"
                htmlFor="pickupRequirementLimitedAccess-no"
              >
                No
              </label>
            </div>
          </div>
          <div className="columns">
            <div className={clsx("column", "is-one-half")}>Inside</div>
            <div className={clsx("column", "is-one-quarter")}>
              <input
                className="is-checkradio"
                type="radio"
                id="pickupRequirementInside-yes"
                name="pickupRequirementInside"
                value="yes"
                checked={pickupRequirementInside}
                onChange={handleRadioChange}
                disabled={!isEditing}
              />
              <label className="radio" htmlFor="pickupRequirementInside-yes">
                Yes
              </label>
            </div>
            <div className={clsx("column", "is-one-quarter")}>
              <input
                className="is-checkradio"
                type="radio"
                id="pickupRequirementInside-no"
                name="pickupRequirementInside"
                value="no"
                checked={!pickupRequirementInside}
                onChange={handleRadioChange}
                disabled={!isEditing}
              />
              <label className="radio" htmlFor="pickupRequirementInside-no">
                No
              </label>
            </div>
          </div>
          <div className="columns">
            <div className={clsx("column", "is-one-half")}>Lift Gate</div>
            <div className={clsx("column", "is-one-quarter")}>
              <input
                className="is-checkradio"
                type="radio"
                id="pickupRequirementLiftGate-yes"
                name="pickupRequirementLiftGate"
                value="yes"
                checked={pickupRequirementLiftGate}
                onChange={handleRadioChange}
                disabled={!isEditing}
              />
              <label className="radio" htmlFor="pickupRequirementLiftGate-yes">
                Yes
              </label>
            </div>
            <div className={clsx("column", "is-one-quarter")}>
              <input
                className="is-checkradio"
                type="radio"
                id="pickupRequirementLiftGate-no"
                name="pickupRequirementLiftGate"
                value="no"
                checked={!pickupRequirementLiftGate}
                onChange={handleRadioChange}
                disabled={!isEditing}
              />
              <label className="radio" htmlFor="pickupRequirementLiftGate-no">
                No
              </label>
            </div>
          </div>
          <div className="columns">
            <div className={clsx("column", "is-one-half")}>Residential</div>
            <div className={clsx("column", "is-one-quarter")}>
              <input
                className="is-checkradio"
                type="radio"
                id="pickupRequirementResidential-yes"
                name="pickupRequirementResidential"
                value="yes"
                checked={pickupRequirementResidential}
                onChange={handleRadioChange}
                disabled={!isEditing}
              />
              <label
                className="radio"
                htmlFor="pickupRequirementResidential-yes"
              >
                Yes
              </label>
            </div>
            <div className={clsx("column", "is-one-quarter")}>
              <input
                className="is-checkradio"
                type="radio"
                id="pickupRequirementResidential-no"
                name="pickupRequirementResidential"
                value="no"
                checked={!pickupRequirementResidential}
                onChange={handleRadioChange}
                disabled={!isEditing}
              />
              <label
                className="radio"
                htmlFor="pickupRequirementResidential-no"
              >
                No
              </label>
            </div>
          </div>
          <div className="columns">
            <div className={clsx("column", "is-one-half")}>
              Sort & Segregate
            </div>
            <div className={clsx("column", "is-one-quarter")}>
              <input
                className="is-checkradio"
                type="radio"
                id="pickupRequirementSortSegregate-yes"
                name="pickupRequirementSortSegregate"
                value="yes"
                checked={pickupRequirementSortSegregate}
                onChange={handleRadioChange}
                disabled={!isEditing}
              />
              <label
                className="radio"
                htmlFor="pickupRequirementSortSegregate-yes"
              >
                Yes
              </label>
            </div>
            <div className={clsx("column", "is-one-quarter")}>
              <input
                className="is-checkradio"
                type="radio"
                id="pickupRequirementSortSegregate-no"
                name="pickupRequirementSortSegregate"
                value="no"
                checked={!pickupRequirementSortSegregate}
                onChange={handleRadioChange}
                disabled={!isEditing}
              />
              <label
                className="radio"
                htmlFor="pickupRequirementSortSegregate-no"
              >
                No
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreightDetailsAirhouse;
