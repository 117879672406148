import React, { useState } from "react";
import CheckedCustomDropdown from "../../../reusable/CheckedCustomDropdown";
import { useDropdown } from "../../../reusable/dropdown/useDropdown";
import TableWithEditableQuantity from "../../../reusable/TableWithEditableQuantity";
import { ValidationError } from "../../../reusable/ValidationError";

const DEFAULT_SHIPPING_FORMATS_STATE = [
  {
    name: "Boxes",
    checked: false,
    quantity: 0,
  },
  {
    name: "Pallets",
    checked: false,
    quantity: 0,
  },
  {
    name: "Containers",
    checked: false,
    quantity: 0,
  },
];

export const useSelectShippingFormats = (inputState = []) => {
  const [shippingFormatsState, setShippingFormatsState] = useState(
    inputState.length ? inputState : DEFAULT_SHIPPING_FORMATS_STATE
  );

  const updateItem = (name, quantity, checked) => {
    setShippingFormatsState((prevState) =>
      prevState.map((item) => (item.name === name ? { ...item, quantity, checked } : item))
    );
  };

  const getQuantity = (name) => {
    const item = shippingFormatsState.find((item) => item.name === name);
    if (!item) {
      return 0;
    }
    return item.quantity;
  };

  const getTotalQuantity = () => {
    return _.sum(shippingFormatsState.map((item) => Number(item.quantity)));
  };

  const handleItemChecked = (e) => {
    const { name, checked } = e.target;

    if (checked) {
      updateItem(name, 1, checked);
    } else {
      updateItem(name, 0, checked);
    }
  };

  const hasContainer = () => getQuantity("Containers") > 0;
  const isShippingFormatsMissing = () => getTotalQuantity() === 0;

  const hasZeroQuantityShippingFormat = () =>
    shippingFormatsState.filter((p) => p.quantity == 0 && p.checked).length > 0;

  const { state: dropdownState, setIsActive, toggleIsActive } = useDropdown();
  const { isActive } = dropdownState;
  return {
    shippingFormatsState,
    isActive,
    setIsActive,
    toggleIsActive,
    handleItemChecked,
    updateItem,
    getQuantity,
    hasContainer,
    isShippingFormatsMissing,
    hasZeroQuantityShippingFormat,
  };
};

export const SelectShippingFormats = (props) => {
  const {
    shippingFormatsState,
    isActive,
    toggleIsActive,
    setCurrentItem,
    handleItemChecked,
    updateItem,
    isEditing,
    errors,
  } = props;
  return (
    <>
    <p className="mb-4">Specify the format your shipment will arrive in.</p>
      {errors.shippingFormatsMissing && (
        <ValidationError message={"Please add at least one shipping format."} />
      )}
      {errors.zeroQuantityShippingFormat && (
        <ValidationError
          message={
            "One or more selected shipping formats has a quanity of zero. Remove this shipping format or adjust the quantity to a positive number."
          }
        />
      )}
      {isEditing && (
        <CheckedCustomDropdown
          itemIdKey="name"
          displayName="name"
          items={shippingFormatsState}
          toggleDropdownMenu={toggleIsActive}
          isDropdownActive={isActive}
          setCurrentItem={setCurrentItem}
          itemDisplay={DropdownItemDisplay}
          handleItemChecked={handleItemChecked}
          placeholderText="Add Shipping Format Type"
        />
      )}
      <TableWithEditableQuantity
        items={shippingFormatsState}
        hasQuantity
        updateItem={updateItem}
        heading="Shipping Formats Included"
        isEditing={isEditing}
      />
    </>
  );
};

const DropdownItemDisplay = (props) => {
  const { displayName, item } = props;
  return (
    <>
      <h4 className="p-1">
        <strong>{displayName}</strong>
      </h4>
      <p className="p-1">{item.description}</p>
    </>
  );
};

export default SelectShippingFormats;
