import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { datadogRum } from '@datadog/browser-rum';

let environment;
if (window.location.href.includes('app.airhouse.io')) {
    environment = 'prod';
}
else if (window.location.href.includes('app.dev.airhouse.io')) {
    environment = 'dev';
}

if (environment) {
    datadogRum.init({
      applicationId: '30d720f2-2d45-4bd9-8a96-4203e6f98137',
      clientToken: 'pubf0e40f5c9b8768b2f85e320e9c02b540',
      site: 'datadoghq.com',
      service: 'airhouse-webapp',
      env: environment,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: '4.14.4',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask',
    });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
