import React from "react";
import { Columns, Level, Tag } from "react-bulma-components";
import VariantList from "../../../VariantList";
import ProductSearchBar, {
  useProductSearchBar,
} from "../../Products/ProductSearchBar";
import { currencyFormatter, numberFormatter } from "../../../../utils/general";
import { getTotalPrice } from "../../Orders/OrderHelpers";
import _ from "lodash";

const DEFAULT_SELECT_PRODUCTS_STATE = {
  variants: [],
};

export const useSelectProducts = (selectProductState) => {
  const [state, setState] = React.useState(() => ({
    ...DEFAULT_SELECT_PRODUCTS_STATE,
    ...selectProductState,
  }));

  const addVariant = (newVariant) => {
    const variant = {
      price: newVariant.price,
      count: 1,
      variant: newVariant,
    };

    setState((prevState) => ({
      ...prevState,
      variants: [...prevState.variants, variant],
    }));
  };

  const editVariant = (updatedVariant) => {
    setState((prevState) => ({
      ...prevState,
      variants: prevState.variants.map((item) => ({
        ...item,
        count:
          item.variant.uuid === updatedVariant.uuid
            ? parseInt(updatedVariant.count) || 1
            : item.count,
      })),
    }));
  };

  const removeVariant = (variant) => {
    const variants = state.variants;

    _.remove(variants, (v) => v.variant.uuid === variant.uuid);

    setState((prevState) => ({
      ...prevState,
      variants,
    }));
  };

  const loadVariants = (variants) => {
    setState((prevState) => ({
      ...prevState,
      variants,
    }));
  };

  return { ...state, addVariant, editVariant, removeVariant, loadVariants };
};

export const SelectProducts = (props) => {
  const { variants, filters, editVariant, removeVariant, addVariant, store, isDisabled, showTotalPrice } = props;
  const productSearchBarState = useProductSearchBar();

  const searchFilters = {
    ...filters,
    items: variants,
    store,
  };

  return (
    <div className="section-container">
      <ProductSearchBar
        onSelectItem={addVariant}
        filters={searchFilters}
        store={store}
        filterItems={variants}
        {...productSearchBarState}
      />
      {!variants.length && (
        <div className="table-body">
          <div className="empty-state is-small">
            <span
              className={
                store?.uuid ? "has-text-grey" : "has-text-grey-lighter"
              }
            >
              No products in this project
            </span>
          </div>
        </div>
      )}

      <div>
        {!!variants.length && (
          <>
            <Level className="is-paddingless is-marginless">
              <Level.Item className="level-right">
                <span className="heading">
                  Total QTY{" "}
                  {`${numberFormatter.format(
                    _.sumBy(variants, (item) => parseInt(item.count))
                  )}`}
                </span>
              </Level.Item>
            </Level>
          </>
        )}
      </div>
      <Columns>
        <Columns.Column className="is-12">
          {!!variants.length && <div className="table-header" />}
          <VariantList
            keyPrefix={"order-items-"}
            insideTable={true}
            variants={variants.map((item) => ({
              ...item.variant,
              count: item.count,
              canRestore: true,
            }))}
            options={{
              allowExceedStock: true,
              isNew: true,
              showSKU: true,
              tagSKU: true,
              showWeight: true,
              showPrice: false,
              showCount: true,
              showStockCountIcon: false,
              editCount: true,
              removable: true,
              warnDelayed: false,
              warnOutOfStock: false,
            }}
            onEditCount={editVariant}
            onRemove={removeVariant}
          />
        </Columns.Column>
      </Columns>

      {(!!showTotalPrice && !!variants.length) && (
        <Level.Item className="level-right pt-2">
          <Tag.Group gapless>
            <Tag className="is-rounded is-light has-text-weight-bold">
              Total Price
            </Tag>
            <Tag className="is-rounded is-light has-text-weight-bold">
              {currencyFormatter(getTotalPrice(variants))}
            </Tag>
          </Tag.Group>
        </Level.Item>
      )}
    </div>
  );
};

export default SelectProducts;
