import _ from 'lodash';
import React from 'react';
import { Columns } from 'react-bulma-components';
import consts from '../utils/consts';



const defaults = [
  'editCount',  // Render count in input box and allow editing
  'editNoBarcode',
  'removable',
  'showSKU',
  'showCount',
  'showPrice',
  'showStockStatus',  // Show current stock quantity of variant
  'showReceived',  // Show received quantity in Restock orders
  'showDifference',  // Show difference of actual and expected quantity in Restock orders
  'showStockCountIcon',  // Show danger icon based on stock availability and current item count
  'showWeight',  // Show weight of variant (1 unit)
  'checkShouldFulfill',
  'warnDelayed',
  'warnShouldFulfill',
  'warnOutOfStock',
  'linkFixSKU',
  'linkFixShouldFulfill',
  'disableUnavailable',
  'disableMissingSKU',
  'tagSKU',  // Show SKU as a tag instead of text
];


const ProductTitle = ({ variant, isDropdown, }) =>
  <Columns>
    <Columns.Column className="is-narrow" style={{ alignSelf: 'center',}}>
      {variant.image_src ?
        <img src={variant.image_src} alt="" className="variant-image" />
      : <span className="icon has-text-grey-light variant-image">
          <i className="icon-ic-placeholder" />
        </span>
      }
    </Columns.Column>
    <Columns.Column>
      <strong>
        <span className="has-text-grey-darker">{variant.product_name}</span>
      </strong>
      {!(variant.name === consts.DEFAULT_TITLE) && (isDropdown ?
          <span className="has-text-grey ml-1">/ {variant.name}</span>
        : <div className="has-text-grey">{variant.name}</div>
      )}
    </Columns.Column>
  </Columns>


export const SkuTag = ({ variant, options }) => {
  const warehouseAvailable = _.sumBy(variant.inventory?.items, inventoryItem => inventoryItem.available);
  const warehouseReserved = _.sumBy(variant.inventory?.items, inventoryItem => inventoryItem.reserved);
  const purchased = variant.inventory?.purchased || 0;
  const purchasedIndirect = variant.inventory?.purchased_indirect || 0;
  const purchasedTotal = purchased + purchasedIndirect;
  const forSale = warehouseAvailable - warehouseReserved - purchased - purchasedIndirect;

  const isBackordered = (warehouseAvailable === 0 && forSale < 0);
  const isOversold = (warehouseAvailable > 0 && ((warehouseReserved + purchasedTotal) > warehouseAvailable));
  const isOutOfStock = warehouseAvailable === 0;

  const tagClass = () => {
    if (variant.isRemoved) return 'is-removed-outlined';
    if (!variant.should_fulfill) return 'is-disabled'

    if (options.isCanceled) return 'is-canceled';

    if (options.isPending || options.isNew ) {
      if (options.warnOutOfStock || options.warnAvailable) {
        if (isBackordered || isOutOfStock) return 'is-danger';
        if (isOversold) return 'is-danger-outlined';
      }

      if (options.warnDelayed && variant.isDelayed) return 'is-pending';

      return 'is-pending-outlined'
    }

    // In progress fulfillments or shipments should always green
    if (options.isFulfilled || options.isShipped) return 'is-success';
  }

  const summary =
    `
    Total In Warehouse: ${warehouseAvailable}
    Total For Sale: ${forSale}
    Total Reserved: ${warehouseReserved}
    Total Purchased: ${purchasedTotal}
    `

  // Handle old packing format which did not include items as a field on inventory nor item.enabled
  const hasInventoryItems = variant.inventory?.items;
  const warehouseLevels = hasInventoryItems
    ? variant.inventory?.items
        ?.filter((inventoryItem) => inventoryItem.enabled)
        .map(
          (inventoryItem) =>
            `
        ${inventoryItem.warehouse} (${
              inventoryItem.available - inventoryItem.reserved
            })
        Available: ${inventoryItem.available}
        Reserved: ${inventoryItem.reserved}
        `
        )
        .join("")
    : "";


  return (
    <span className="ml-4">
      <span className={options.tagSKU ? `tag is-rounded ${tagClass()}` : ''}>
        {!!variant.sku?.trim() ?
          variant.sku : options.linkFixSKU ?
            <a
              href={'/inventory/products?tab=error&search=' + variant.name}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                className="has-text-danger tooltip"
                data-tooltip="Required field"
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                <span>Missing SKU</span>
              </span>
            </a>
          : <>&mdash;</>
        }
        {(options.warnDelayed && variant.isDelayed) &&
          <span className="has-text-warning tooltip is-tooltip-right" data-tooltip="Delayed">
            <i className="icon-ic-dates" style={{ color: '#8B6D20'}}/>
          </span>
        }
        {(options.warnOutOfStock && isOutOfStock) &&
          <span
            className="has-text-danger tooltip is-tooltip-multiline ml-1"
            data-tooltip={`Out of stock.\n${summary}\n${warehouseLevels}`}
          >
            <i className="icon-ic-warning has-text-danger" />
          </span>
        }
        {(options.warnAvailable && isOversold) &&
          <span
            className="has-text-danger tooltip is-tooltip-multiline ml-1"
            data-tooltip={`Insufficient stock to fulfill order.\n${summary}\n${warehouseLevels}`}
          >
            <i className="icon-ic-warning has-text-danger" />
          </span>
        }
      </span>
    </span>
  );
}


const ReceivedDifference = ({ variant }) => {
  const difference = variant.received - variant.count;
  let tagClass = 'is-pending'
  let description = 'Pending';

  if (!!variant.received) {
    // Over received
    if (difference > 0) {
      tagClass = 'is-info';
      description = 'Over Received';
    }
    // Under received
    if (difference < 0) {
      tagClass = 'is-danger';
      description = 'Under Received';
    }
    // Just right
    if (difference === 0) {
      tagClass = 'is-success';
      description = 'Received';
    }
  }

  return <><span className={`tag is-rounded ${tagClass} mr-0`}>{description}</span></>
}


const VariantItem = (props) => {
  const { variant, onSelect, onEditCount, onRemove, onRestore, isDropdown, } = props;
  const options = { ...props.options };

  const isDisabled = (options.disableUnavailable && !props.variant.shop_available) || (options.disableMissingSKU && !variant.sku);

  const onChangeItemCount = (event) => {
    let count = event.target.value;

    // The boolean `allowExceedStock` is required when deducting inventory - when adding inventory, default to `true`
    const allowExceedStock = options.allowExceedStock == undefined ? true : options.allowExceedStock;
    if (!allowExceedStock && count > variant.shop_available) {
      count = variant.shop_available;
    }
    onEditCount({ ...variant, count });
  }

  // Variant data in Order Detail doesn't include shop_available
  const warehouseAvailable = _.sumBy(variant.inventory?.items, inventoryItem => inventoryItem.available);
  const warehouseReserved = _.sumBy(variant.inventory?.items, inventoryItem => inventoryItem.reserved);
  const purchased = variant?.purchased || 0;
  const forSale = warehouseAvailable - warehouseReserved - purchased;

  defaults.forEach(param => options[param] = !!props?.options[param]);

  return (
    <tr
      className={
        (!!props.insideTable ? 'table-body' : '') +
        (!!isDropdown ? 'dropdown-highlight is-marginless ' : '') +
        (isDisabled ? 'disabled ' : '')
      }
      style={{
        width: '100%',
        cursor: isDisabled ? 'not-allowed' : '',
        fontVariantNumeric: 'tabular-nums',
      }}
      onClick={(!onSelect || isDisabled) ? () => null : () => onSelect(variant)}
    >

      {/* Cell contains the stylized product and variant name */}
      <td
        className="has-text-left"
        style={{width: isDropdown ? '100%' : '40%'}, styles.verticalAlign}
      >
        {(options.checkShouldFulfill && !variant.should_fulfill) ?
          options.linkFixShouldFulfill ?
            <a
              href={'/inventory/products?tab=notFulfilling&search=' + variant.name}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                className="has-text-danger tooltip is-tooltip-right is-flex"
                data-tooltip="Not marked for fulfillment"
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                <ProductTitle variant={variant} isDisabled={isDisabled} isDropdown={isDropdown} />
                <span>
                  <i className="icon-ic-warning has-text-danger pl-1" />
                </span>
              </span>
            </a>
          : <span className="has-text-danger is-flex">
              <ProductTitle variant={variant} isDisabled={isDisabled} isDropdown={isDropdown} />
              <span>
                <i className="icon-ic-warning has-text-danger pl-1" />
              </span>
            </span>
        : (options.warnShouldFulfill && !variant.should_fulfill) ?
            <span
              className="has-text-warning tooltip is-tooltip-right is-flex"
              data-tooltip="Will be marked for fulfillment"
              style={{textDecoration: 'underline'}}
            >
              <ProductTitle variant={variant} isDisabled={isDisabled} isDropdown={isDropdown} />
              <span>
                <i className="icon-ic-warning pl-1" />
              </span>
            </span>
          : <ProductTitle variant={variant} isDisabled={isDisabled} isDropdown={isDropdown} />
        }
      </td>

      {/* Cell shows SKU and conditional style */}
      {options.showSKU &&
        <td className={"has-text-left" + isDropdown ? '' : ' px-0'} style={styles.verticalAlign}>
          <SkuTag
            variant={variant}
            options={options}
            warehouseAvailable={warehouseAvailable}
            warehouseReserved={warehouseReserved}
            purchased={purchased}
            forSale={forSale}
          />
        </td>
      }

      {options.showWeight &&
        <td className="has-text-right is-narrow" style={styles.verticalAlign}>
          <span className="has-text-grey">
            {parseFloat(variant.weight) ?
              `${parseFloat(variant.weight)} ${variant.weight_unit}` : '0 lb'
            }
          </span>
        </td>
      }

      {options.showPrice &&
        <td className="has-text-right is-narrow" style={styles.verticalAlign}>
          <span className="has-text-grey">${variant.price}</span>
        </td>
      }

      {/* Dropdown-only cell shows stock volume and stockout flags */}
      {isDropdown && options.showStockStatus && (variant.shop_available == null ?
        <td className="has-text-right is-narrow" style={styles.verticalAlign}>
          <span className="has-text-grey">
            Out of Stock
            <i className="icon-ic-warning has-text-danger pl-1" />
          </span>
        </td>
      : <td className="has-text-right is-narrow" style={styles.verticalAlign}>
          <span className="has-text-grey">
            {variant.shop_available} in stock
            {variant.shop_available <= 0 &&
              <i className="icon-ic-warning has-text-danger pl-1" />
            }
          </span>
        </td>
      )}

      {/* Cell contains danger icon to signify stock issues in consideration of current input count value */}
      {options.showStockCountIcon && options.editCount &&
        <td className="has-text-right is-narrow" style={styles.verticalAlign}>
          {(variant.shop_available || forSale) - variant.count < 0 && (options.allowExceedStock ?
            <span
              className="has-text-danger tooltip is-tooltip-multiline pr-3"
              data-tooltip="Out of stock items will not be fulfilled until inventory is restocked."
            >
              <i className="icon-ic-warning has-text-danger pl-1" />
              <span className="has-text-danger pl-1">Out of Stock</span>
            </span>
          : <span
              className="has-text-grey-lighter tooltip"
              data-tooltip="This is the maximum quantity available."
            >
              <i className="icon-ic-warning has-text-danger" />
              <span className="has-text-danger is-size-7 pl-1">
                Insufficient stock to fulfill order.
              </span>
            </span>
          )}
        </td>
      }

      {/* Cell shows order item counts input box */}
      {options.showCount &&
        <td className="has-text-right" style={styles.verticalAlign}>
          {options.editCount && variant.isEditable !== false && !!onEditCount ?
            <div className="control m-0 is-flex is-justify-content-flex-end">
              <span style={styles.verticalAlign}>
                {variant.editDiff != null && variant.editDiff !== 0 &&
                  <span className="is-info tag ml-0 mr-1">
                    <span className="icon is-small" style={styles.caretIcons}>
                      <i className={(variant.editDiff > 0) ? "fas fa-caret-up" : "fas fa-caret-down"} />
                    </span>
                    <span>{variant.editDiff > 0 && '+'}{variant.editDiff}</span>
                  </span>
                }
              </span>
              <input
                style={{width: '3.5rem',}}
                className="input is-small"
                type="number"
                min={1}
                value={variant.count}
                onChange={onChangeItemCount}
              />
            </div>
          : // No editing
            <span
              className={'has-text-centered ' + isDisabled ? 'has-text-grey' : ''}
              style={{textDecoration: variant.isRemoved ? 'line-through' : 'none'}}
            >
              {variant.count}
            </span>
          }
        </td>
      }

      {/* Show the fulfillable count for all variants. In the case where the item count is 2 and one of the items is
      refunded, the item count remains 2 but the fulfillable count becomes 1. This is useful for merchants who have
      a lot of edited orders. */}
      {options.showFulfillableCount &&
        <td className="has-text-right" style={styles.verticalAlign}>
          <span className={'has-text-centered ' + isDisabled ? 'has-text-grey' : ''}>
            {options.isCanceled ? 0 : variant.fulfillableCount}
          </span>
        </td>
      }

      {options.showFulfilledCount &&
        <td className="has-text-right" style={styles.verticalAlign}>
          <span className={'has-text-centered ' + isDisabled ? 'has-text-grey' : ''}>
            {variant.fulfilledCount}
          </span>
        </td>
      }

      {/* 9th cell shows received */}
      {options.showReceived &&
        <td className="has-text-right" style={styles.verticalAlign}>
          {variant.received || '-'}
        </td>
      }

      {/* 10th cell shows difference */}
      {options.showDifference &&
        <td className="has-text-right" style={styles.verticalAlign}>
          <ReceivedDifference variant={variant} />
        </td>
      }

      {/* 11th cell contains remove button */}
      {(options.removable && variant.isEditable !== false && !!onRemove) &&
        <td className="is-narrow pl-0" style={styles.verticalAlign}>
          <i className="icon-ic-trash has-text-grey-light is-icon" onClick={() => onRemove(variant)} />
        </td>
      }

      {/* 12th cell contains restore button */}
      {(variant.isRemoved && variant.canRestore && !!onRestore) &&
        <td className="is-narrow" style={styles.verticalAlign}>
          <span className="has-text-success">
            <i className="fas fa-plus-square is-icon" onClick={() => onRestore(variant)} />
          </span>
        </td>
      }

    </tr>
  );
}


const VariantList = (props) => {
  const { keyPrefix, variants, options } = props;

  // Push disabled items to the bottom
  const variantsSorted = !!options?.disableMissingSKU ?
    variants.sort((a, b) => {
      if (!!a.sku && !b.sku) return -1;
      if (!a.sku && !!b.sku) return 1;
      return 0;
    })
  : variants;

  return (
    <table className="table modern">
      {!!options?.headers &&
        <thead className="table-header">
          <tr>
            <th className="has-text-left heading">Items</th>
            <th className="has-text-left heading">{options.headers.sku}</th>
            <th className="has-text-right is-2 heading">{options.headers.count}</th>
            <th className="has-text-right is-2 heading">{options.headers.received}</th>
            <th className="has-text-right is-2 heading">{options.headers.difference}</th>
          </tr>
        </thead>
      }

      {/* Pending table headers */}
      {!!options?.showPendingItemsHeaders &&
        <thead className="table-header">
          <tr>
            <th className="has-text-left heading">Items</th>
            <th className="has-text-left heading">SKU</th>
            <th className="has-text-right heading">Weight</th>
            <th className="has-text-right heading">Price</th>
            <th className="has-text-right heading">Count</th>
            <th className="has-text-right heading">Fulfillable</th>
          </tr>
        </thead>
      }

      {/* Fulfilled or In Progress table headers */}
      {(!!options?.showInFulfillmentItemsHeaders || !!options?.showShippedItemsHeaders) &&
        <thead className="table-header">
          <tr>
            <th className="has-text-left heading">Items</th>
            <th className="has-text-left heading">SKU</th>
            <th className="has-text-right heading">Weight</th>
            <th className="has-text-right heading">Price</th>
            <th className="has-text-right heading">Fulfilled</th>
          </tr>
        </thead>
      }

      <tbody>
        {variantsSorted.map(variant =>
          <VariantItem
            key={(keyPrefix || '') + 'variant-item-' + variant.uuid}
            variant={variant}
            {...props}
          />
        )}
      </tbody>

      {options?.showFooter &&
        <tfoot>
          <tr className="has-text-grey has-text-small">
            <td></td>
            <td></td>
            <td className="has-text-grey is-text-small has-text-right is-2 py-1 px-2 mr-0">
              {!!options.showCount && `Total ${ _.sumBy(variants, variant => variant.count)}`}
            </td>
            <td className="has-text-grey is-text-small has-text-right is-2 py-1 px-2 mr-0">
              {!!options.headers.received && `Total ${ _.sumBy(variants, variant => variant.received || 0) }`}
            </td>
            <td className="has-text-grey is-text-small has-text-right">
              {!!options.headers.difference && _.sumBy(variants, variant =>
                !!variant.received ? variant.received - variant.count : 0
              )}
            </td>
          </tr>
        </tfoot>
      }
    </table>
  );
}


const styles = {
  verticalAlign: {
    verticalAlign: 'middle',
  },
  caretIcons: {
    marginRight: '0.125rem',
  },
  editDiff0: {
    opacity: 0,
    minWidth: '3rem',
  },
};


export default VariantList;
