import React from 'react';


export const ValidationError = ({ message }) => {
    return (
      <span className='tag is-danger friendly mb-2'>
        <span className='icon'>
          <i className='icon-ic-warning' />
        </span>
        <span className='ml-1'>{message}</span>
      </span>
    );
  }

export const ValidationErrors = ({ errors }) => {
  return errors != null ? errors.map((error, i) => <span key={i}><ValidationError message={error} /></span>) : null;
}
