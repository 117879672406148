import React, { useEffect, useState } from 'react';
import { Tag, Columns } from 'react-bulma-components';
import _ from 'lodash';
import isEqual from 'lodash/isEqual';

import apiEndpoints from '../../../utils/apiEndpoints.js';
import { editOrderDataType } from './Edit/config/editOrder.state.js';

interface IAdminPanel {
  data: editOrderDataType & {
    processing?: boolean;
    admin_tags?: Array<any>;
    admin_note: string;
  };
}

const INITIAL_STATE = {
  // orderTags is an array containing OrderTag objects
  orderTags: [] as any[],
  // savedTags and currentTags are arrays containing tag names (strings)
  savedTags: [] as string[],
  currentTags: [] as string[],
  notesCurrentText: '',
  notesText: '',
  tagOptions: [] as string[],
  tagOptionsLowercase: [],
  suggestions: [] as string[],
  tagCurrentText: '',
  isEditing: false,
};
const AdminPanel: React.FC<IAdminPanel> = ({ data }) => {
  const [state, setState] = useState({
    ...INITIAL_STATE,
    orderTags: data.admin_tags?.map((res) => res) || [],
    savedTags: data.admin_tags?.map((res) => res.name) || [],
    currentTags: data.admin_tags?.map(res => res.name) || [],
    notesCurrentText: data.admin_note,
    notesText: data.admin_note,
  });

  useEffect(() => {
    fetchTagOptions();
  }, []);

  // Fetch all tag options from admin info API endpoint to populate
  const fetchTagOptions = () => {
    apiEndpoints
      .getTags()
      .then((response) => response.json())
      .then((data) => {
        setState((prev) => ({
          ...prev,
          tagOptions: data.results.map((res: any) => res.name),
          tagOptionsLowercase: data.results.map((res: any) => res.name.toLowerCase()),
        }));
      });
  };

  // Onchange handler for tag input text
  const handleTagTextChange = (e: any) => {
    const value = e.target.value;
    let suggestions: string[] = [];
    if (value.length) {
      const regex = new RegExp(`^${value}`, 'i');
      suggestions = state.tagOptions.sort().filter((sug) => regex.test(sug));
    }
    setState((prev) => ({
      ...prev,
      suggestions,
      tagCurrentText: value,
    }));
  };

  // Add tag by clicking suggestion
  const addSuggestedTag = (newTag: string) => {
    setState((prev) => ({
      ...prev,
      suggestions: [],
      tagCurrentText: '',
    }));
    if (!state.currentTags.includes(newTag)) {
      setState((prev) => ({
        ...prev,
        currentTags: [...state.currentTags, newTag],
      }));
    }
  };

  // Add tag while in editing phase
  const addTocurrentTags = (e: any) => {
    e.preventDefault();
    addTagOnEnter(e);
  };

  // Add tag by clicking enter while in editing phase
  const addTagOnEnter = (e: any) => {
    if (e.key === 'Enter' && state.tagCurrentText) {
      let lowercaseTags = state.currentTags.map((tag) => tag.toLowerCase());
      if (
        !state.currentTags.includes(state.tagCurrentText) &&
        !lowercaseTags.includes(state.tagCurrentText.toLowerCase())
      ) {
        setState((prev) => ({
          ...prev,
          currentTags: [...state.currentTags, state.tagCurrentText],
          tagCurrentText: '',
        }));
      }
    }
  };

  // Remove tag while in editing phase
  const removeTag = (i: number) => {
    const newTags = [...state.currentTags];
    newTags.splice(i, 1);
    setState((prev) => ({
      ...prev,
      currentTags: newTags,
    }));
  };

  // Click cancel button, discard edits to tags and note
  const handleCancel = () => {
    setState((prev) => ({
      ...prev,
      currentTags: state.savedTags,
      notesCurrentText: state.notesText,
      suggestions: [],
      tagCurrentText: '',
      isEditing: false,
    }));
  };

  // Click save button, post to order endpoint and tags endpoint. Should optimize in the future.
  const handleSave = (e: any) => {
    e.preventDefault();
    // Create or delete OrderTag based on if currentTags is different from savedTags
    if (!isEqual(state.savedTags, state.currentTags)) {
      // Traverse through added tags and check if they differ from the saved tags. If yes then create new tag object.
      let savedTagsLowered = state.savedTags.map((tag) => tag.toLowerCase());
      for (let i = 0; i < state.currentTags.length; i++) {
        let lowered = state.currentTags[i].toLowerCase();
        if (!savedTagsLowered.includes(lowered)) {
          apiEndpoints.createTag({
            order_id: data.uuid,
            name: state.currentTags[i],
          });
        }
      }
      // Create deleteArray with OrderTag objects to be deleted
      let deleteArray = [];
      for (let i = 0; i < state.savedTags.length; i++) {
        if (!state.currentTags.includes(state.savedTags[i])) {
          // Look up OrderTag object based on name attribute
          for (let j = 0; j < state.orderTags.length; j++) {
            if (state.savedTags[i] === state.orderTags[j].name) {
              deleteArray.push(state.orderTags[j].uuid);
            }
          }
        }
      }
      if (deleteArray) {
        for (let i = 0; i < deleteArray.length; i++) {
          apiEndpoints.deleteTag(deleteArray[i]);
        }
      }
    }
    // Update admin note only if text is different from existing note text
    if (state.notesText !== state.notesCurrentText) {
      apiEndpoints.updateAdminNote(data.uuid, {
        admin_note: state.notesCurrentText,
      });
    }

    // Fetch the new order data
    apiEndpoints
      .getOrder(data.uuid)
      .then((response) => response.json())
      .then(() =>
        setState((prev) => ({
          ...prev,
          savedTags: state.currentTags,
          notesText: state.notesCurrentText,
          isEditing: false,
        }))
      );
    // Fetch the newly added tags as options
    fetchTagOptions();
  };

  return (
    <div className='py-1 px-2' style={styles.adminNotesContainer}>
      <Columns>
        <Columns.Column>
          <p className='heading mb-1'>Admin Notes</p>
          {state.isEditing ? (
            <div className='is-flex is-flex-wrap-wrap'>
              <ul style={{ ...styles.tagsContainer, flexWrap: 'wrap' }}>
                {state.currentTags.map((tag, i) => (
                  <li key={tag}>
                    <Tag color='warning'>
                      {tag}
                      <button
                        type='submit'
                        className='delete is-small'
                        onClick={() => removeTag(i)}
                      ></button>
                    </Tag>
                  </li>
                ))}
                <li>
                  <form onSubmit={addTocurrentTags}>
                    <input
                      style={styles.tagInput}
                      className='is-small'
                      placeholder='Add tags'
                      type='text'
                      value={state.tagCurrentText}
                      onKeyDown={addTagOnEnter}
                      onChange={handleTagTextChange}
                    />
                  </form>
                </li>
              </ul>

              {!!state.suggestions.length && (
                // Display suggestion box. Do not show suggestions for tags that have already been added.
                <div style={{ ...styles.suggestionsContainer, position: 'absolute' }}>
                  <ul className='px-3'>
                    {state.suggestions.map(
                      (tag, i) =>
                        !state.currentTags.includes(tag) && (
                          <li key={i} onClick={() => addSuggestedTag(tag)}>
                            {tag}
                          </li>
                        )
                    )}
                  </ul>
                </div>
              )}

              <textarea
                className='textarea is-small mb-1'
                defaultValue={state.notesText}
                onChange={(e) => {
                  const value = e.target?.value || '';
                  setState((prev) => ({ ...prev, notesCurrentText: value }));
                }}
              />
              <div className='is-flex is-justify-content-flex-end'>
                <button className='button is-text is-size-7 mr-2 is-admin' onClick={handleCancel}>
                  Cancel
                </button>
                <form onSubmit={handleSave}>
                  <button className='button is-size-7 is-secondary' type='submit'>
                    Save
                  </button>
                </form>
              </div>
            </div>
          ) : state.savedTags.length || state.notesText ? (
            <div>
              <div>
                {state.savedTags.map((tag, i) => (
                  <Tag key={i} className='is-warning'>
                    {tag}
                  </Tag>
                ))}
              </div>
              <div className='is-size-7 mt-2'>{state.notesText}</div>
            </div>
          ) : (
            <div>No notes</div>
          )}

          {!state.isEditing && (
            <button
              className='button is-text is-size-7 is-admin'
              onClick={() => setState((prev) => ({ ...prev, isEditing: true }))}
            >
              Edit
            </button>
          )}
        </Columns.Column>
        <Columns.Column className='is-full'>
          <small>
            Processing:{' '}
            {data.processing ? (
              <span className='icon has-text-success'>
                <i className='fas fa-check-square' />
              </span>
            ) : (
              <span className='icon has-text-danger'>
                <i className='fas fa-ban' />
              </span>
            )}
          </small>
        </Columns.Column>
      </Columns>
    </div>
  );
};

const styles = {
  adminNotesContainer: {
    backgroundColor: '#F7EBFB',
    border: `1px solid #CD41FF`,
    color: '#CD41FF',
  },
  tagsContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    listStyle: 'none',
    marginBottom: '5px',
    backgroundColor: 'white',
    padding: '2px',
    width: '100%',
  },
  tagInput: {
    outline: 'none',
    borderColor: 'transparent',
    width: '100%',
  },
  suggestionsContainer: {
    display: 'inline-block',
    zIndex: 99,
    backgroundColor: 'white',
    borderRadius: '0 0 1px 1px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
    marginTop: '2rem',
    cursor: 'pointer',
    width: '16rem',
  },
  buttonColor: {
    backgroundColor: '#FDF2C8',
  },
};

export default AdminPanel;
