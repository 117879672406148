import React, { Component } from 'react';
import _ from 'lodash';

import apiEndpoints from '../../utils/apiEndpoints.js';
import { removeParam } from '../../utils/general.js';


const defaultState = {
  data: [],
  loading: true,
  queryString: '',
  totalSize: 0,
  exportInventory: null,
}

const columnToField = columnName => {
  switch (columnName) {
    case 'product_name':
      return 'product__name';
    case 'last_update':
      return 'inventory__updated_at';
    default:
      return columnName;
  }
}

const buildQueryString = (options) => {
  const parseSort = (sortModel) => {
    const field = sortModel.map(column => 
      (column.sort === 'asc' ? '' : '-') + columnToField(column.colId)
    ).join(',');

    return field && `ordering=${field}`;
  }

  const parseFilters = (filterModel) =>
    _.map(filterModel, (data, columnName) => {
      // Date range filters
      if (data.filterType === 'date' && data.type === 'inRange') {
        return columnToField(columnName) + '__gte=' + data.dateFrom + 'T00:00'
          + '&' + columnToField(columnName) + '__lte=' + data.dateTo + 'T23:59';
      } else if (data.filterType === 'text' && data.type === 'equals' ) {
        return columnToField(columnName) + '=' + data.filter;
      } else {
        return columnToField(columnName) + '__icontains=' + data.filter;
      }
    }).filter((segment) => !!segment).join('&');

  const sort = options?.sortModel && parseSort(options.sortModel);
  const filters = options?.filterModel && parseFilters(options.filterModel);
  const search = options?.searchTerm && 'search=' + options.searchTerm;
  const offset = options?.offset && 'offset=' + options.offset;
  
  return [sort, filters, search, offset].filter((item) => !!item).join('&');
}


export const VariantContext = React.createContext(defaultState);

export default class VariantStore extends Component {
  state = defaultState;

  getVariants = async (options) => {
    const queryString = buildQueryString(options);

    // Data is cached and no querystring changes
    if (!this.state.loading && (queryString === this.state.queryString)) {
      return this.state.data
    }

    this.setState({ loading: true });

    try {
      const url = apiEndpoints.API_BASE + `api/variants/` + (queryString ? `?${queryString}` : '');
      const response = await apiEndpoints.getData(url);
      const data = await response.json();

      this.setState({
        data: data.results,
        loading: false,
        queryString: queryString,
        totalSize: data.count,
      });
      return data.results;
    } catch (err) {
      console.error(err);
    }
  }

  getVariant = async (variantId) => {
    const allData = this.state.data || [];
    const existingVariant = allData?.find(variant => variant.uuid === variantId);

    if (existingVariant) {
      return existingVariant;
    }

    try {
      const url = apiEndpoints.API_BASE + `api/variants/${variantId}/`;
      const response = await apiEndpoints.getData(url);
      const data = await response.json();

      this.setState({
        data: allData.map(existingVariant => 
          existingVariant.uuid === (variantId ? data : existingVariant)
        ),
        loading: false,
      });
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  exportInventory = () => {
    let exportQueryString = removeParam(this.state.queryString, 'offset');
    return apiEndpoints.getVariantsCSV(exportQueryString);
  }

  clearCache = () =>
    this.setState({
      data: [],
      loading: true,
    });

  render() {
    return (
      <VariantContext.Provider value={{
        ...this.state,
        getVariants: this.getVariants,
        getVariant: this.getVariant,
        exportInventory: this.exportInventory,
        clearCache: this.clearCache,
      }}>
        {this.props.children}
      </VariantContext.Provider>
    )
  }
}
