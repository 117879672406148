export default {
  ORDER_CREATED: "Order created!",
  ORDER_UPDATED: "Order updated!",
  ORDER_DELETED: "Order deleted!",
  ORDER_CANCELED: "Order has been canceled.",
  ORDER_PAUSED: "Order has been held.",
  ORDER_UNPAUSED: "Order hold has been removed.",
  FULFILLMENT_CANCELED: "Fulfillment has been canceled.",
  ORDER_ADD_PRODUCTS_ERROR: "Please add products to submit this order.",
  ORDER_ADD_RECIPIENT_ERROR: "Please add a recipient to submit this order.",
  ORDER_SELECT_SHIPPING_SPEED_ERROR: "Please select a Shipping Speed to use with this Shipping Account.",
  ORDER_TRACKING_DATA_ERROR: "Please provide a tracking carrier and number for all Shipping Label files.",
  ORDER_IMPORT_HOLD: (orderName) =>
    `Order (${orderName}) instructions pending review.`,
  ORDERS_IMPORT_SUCCESS: "Orders successfully imported!",
  ORDERS_IMPORT_ERROR: "Please fix the following errors with the order import.",
  CSV_EXPORT: (email) =>
    `Exporting... Please check your email (${email}) in a few minutes!`,
  CSV_EXPORT_ADMIN:
    "Exporting... Please check your admin user email in a few minutes!",
  CSV_EXPORT_ERROR: "Error exporting CSV. We have been notified.",
  ORDERS_EXPORT_ERROR: "Error exporting CSV. We have been notified.",
  ACCOUNT_UPDATED: "Information updated!",
  ACCOUNT_PASSWORD_CHANGED: "Password changed!",
  PREFERENCES_FORM_ERROR: "Please fill in the required fields.",
  PURCHASE_ORDER_CREATE_ERROR:
    "Please fix the following errors with the inventory shipment.",
  PURCHASE_ORDER_CREATED: "Inventory shipment created!",
  SKU_MANAGEMENT_ADDED: (count) =>
    `${count} Variant${count > 1 ? "s" : ""} added to be fulfilled.`,
  SKU_MANAGEMENT_DELETED: (count) =>
    `${count} Variant${count > 1 ? "s" : ""} will no longer be fulfilled.`,
  CONFIRM_UNSAVED: "Are you sure you don't want to save your changes?",
  INVENTORY_RESERVES_UPDATED: (count) =>
    `${count} inventory reserve${count > 1 ? "s" : ""} updated.`,
  FULFILLMENT_CANCELLED: "Fulfillment cancelled!",
  INVITATION_SUCCESS: (email) => `Sent invitation to: ${email}`,
  INVITATION_INVALID_ERROR: (email) => `Invalid email address: ${email}`,
  INVITATION_ACCEPTED_ERROR: (email) => `Invitation already accepted: ${email}`,
  INVITATION_PENDING_ERROR: (email) => `Invite already sent to: ${email}`,
  INVITATION_REGISTERED_ERROR: (email) =>
    `Someone already registered with: ${email}`,
  INVALID_EMAIL: (email) => `Invalid email address: ${email}`,
  ERROR_RESETTING_PASSWORD: "Error resetting password",
  // Address validation messages
  ADDRESS_SS_UNREACHABLE: "This address could not be validated at this time.",
  ADDRESS_REGION_RECOMMENDATIONS:
    "Please take a look at the state/province and/or country input.",
  ADDRESS_VALID: "Address validated.",
  ADDRESS_INVALID_ERROR:
    "We were unable to validate your address. This may create some issues with your shipment.",
  ADDRESS_US_AMBIGUOUS_ERROR:
    "This address matches multiple addresses. Use a suggested address to avoid issues with your shipment.",
  ADDRESS_US_SUGGESTIONS:
    "Your address may be missing some information. We have some suggestions for you.",
  ADDRESS_US_MISWRITTEN_ERROR:
    "Your address may be missing some information. We have some suggestions for you.",
  ADDRESS_INTL_CITY_ERROR:
    "Your address has only been validated down to the city level. This may create some issues with your shipment.",
  ADDRESS_INTL_STREET_ERROR:
    "Your address has only been validated down to the street level. This may create some issues with your shipment.",
  ADDRESS_ERROR_BAR_SUGGESTIONS:
    "Your address may be missing some information. We've suggested some valid alternatives below.",
  RETURN_CREATED: "Return created!",
  RETURN_CREATE_ERROR: "Please fix the following errors",
  RETURN_CREATE_REQUEST_ERROR:
    "There was an error when attempting to create the return",
  VALIDATION_ERROR: 'Please fix the errors below before saving.',
  PROJECT_WAREHOUSE_REQUIRED: 'Please select a warehouse for the project.',
};
