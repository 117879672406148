import { useState, useEffect } from "react";
import SmartyStreetsSDK, { internationalStreet, usStreet } from "smartystreets-javascript-sdk";

export const useSmartyStreets = () => {
  const [intlClient, setIntlClient] = useState<smartyStreetIntClientType>(
    placeHolderFunction as unknown as smartyStreetIntClientType
  );
  const [usStreetClient, setUsStreetClient] = useState<smartyStreetUsClient>(
    placeHolderFunction as unknown as smartyStreetUsClient
  );
  const [getUSLookup, setGetUsLookup] = useState<() => usStreet.Lookup>(
    () => ({} as usStreet.Lookup)
  );
  const [getIntlLookup, setGetIntlLookup] = useState<() => internationalStreet.Lookup>(
    () => ({} as internationalStreet.Lookup)
  );

  useEffect(() => {
    const SmartyStreetsCore = SmartyStreetsSDK.core;
    const websiteKey = process.env.REACT_APP__SS as string;
    const smartyStreetsSharedCredentials = new SmartyStreetsCore.SharedCredentials(websiteKey);
    const usStreetClientBuilder = new SmartyStreetsCore.ClientBuilder(
      smartyStreetsSharedCredentials
    );

    const intlClient = SmartyStreetsCore.buildClient.internationalStreet(
      smartyStreetsSharedCredentials
    );
    setIntlClient(intlClient);
    setUsStreetClient(usStreetClientBuilder.buildUsStreetApiClient());
    setGetUsLookup(() => () => new SmartyStreetsSDK.usStreet.Lookup());
    setGetIntlLookup(() => () => {
      return new (SmartyStreetsSDK.internationalStreet
        .Lookup as any)() as internationalStreet.Lookup;
    });
  }, []);

  return {
    getUSLookup,
    getIntlLookup,
    usStreetClient,
    intlClient,
  };
};

type smartyStreetIntClientType = SmartyStreetsSDK.core.Client<
  SmartyStreetsSDK.internationalStreet.Lookup,
  SmartyStreetsSDK.internationalStreet.Lookup
>;

type smartyStreetUsClient = SmartyStreetsSDK.core.Client<
  usStreet.Lookup | SmartyStreetsSDK.core.Batch<usStreet.Lookup>,
  SmartyStreetsSDK.core.Batch<usStreet.Lookup>
>;

const placeHolderFunction = () => ({});
