import React, { useState } from 'react';
import { validateVariants } from '../../../../utils/gateway/InboundOrderAPIGateway';
import Papa from 'papaparse';
import { ACCEPTED_CSV_TYPES } from '../../../../utils/consts';

const DEFAULT_STATE = {
  csvErrors: [],
};

const acceptedFileTypes = ACCEPTED_CSV_TYPES.join(',');

export const useInboundCSVUpload = () => {
  const [state, setState] = useState(DEFAULT_STATE);

  const addErrors = (errors) => {
    setState((prevState) => ({
      ...prevState,
      csvErrors: [...prevState.csvErrors]
        .concat(errors)
        .map((message) => `CSV upload error: ${message}`),
    }));
  };

  const reset = () => {
    setState(DEFAULT_STATE);
  };

  return { ...state, addErrors, reset };
};

const InboundCSVUpload = (props) => {
  const { addErrors, reset, store, loadCSVVariants, handleInboundCSVUploadClickedCallback } = props;

  const handleInboundCSVUploadClicked = (event) => {
    event.currentTarget.value = '';
    handleInboundCSVUploadClickedCallback();
  };

  const handleFileUpload = (event) => {
    if (!event.target.files) {
      return;
    }
    reset();

    const file = event.target.files[0];

    Papa.parse(file, {
      header: true,
      skipEmptyLines: 'greedy',
      complete: (result) => {
        validateRecords(result);
      },

      error: (_err, _file, _inputElem, reason) => {
        if (error) {
          addErrors([reason]);
        }
      },
    });
  };

  const onValidateSuccess = (response, skuToCount) => {
    // If there are errors, show the errors to the user. If not, load the reponse data which includes price / name.
    const { missing_variants, existing_variants } = response;

    if (missing_variants.length) {
      const csvUploadSKUErrors = missing_variants.map(
        (x) => `Error: csv contains unknown sku: ${x}. Remove unknown sku and upload again.`
      );
      addErrors(csvUploadSKUErrors);
    } else {
      const variants = existing_variants.map((v) => ({
        ...v,
        count: skuToCount[v.sku],
      }));
      loadCSVVariants(variants);
    }
  };

  const onValidateError = (error) => {
    addErrors(error);
  };

  const validateRecords = (result) => {
    if (!!result.errors?.length) {
      addErrors(result.errors.map((err) => err.message));
      return;
    }

    if (!result.data.length) {
      return;
    }
    const newErrors = [];

    const skuToCount = {};
    // Validate count
    result.data.forEach((rowMap, rowIndex) => {
      const { sku, count } = rowMap;

      const countInt = parseInt(count, 10);
      if (Number.isNaN(countInt) || countInt <= 0) {
        newErrors.push(
          `CSV Error: Count must be a number greater than or equal to 1. Row ${
            rowIndex + 1
          }. Add number and then upload again.`
        );
      }
      if (sku in skuToCount) {
        newErrors.push(
          `CSV Error: Duplicate sku: ${sku}. Row ${
            rowIndex + 1
          }. Remove duplicate and upload again.`
        );
      }
      skuToCount[sku] = countInt;
    });

    // Check validity of sku's server side and get list of invalid sku's
    if (newErrors.length) {
      addErrors(newErrors);
    } else {
      validateVariants(store.uuid, Object.keys(skuToCount))
        .then((response) => onValidateSuccess(response, skuToCount))
        .catch(onValidateError);
    }
  };

  return (
    <div className="is-flex is-flex-direction-column">
      <div className="file">
        <label className="file-label">
          <input
            className="file-input"
            type="file"
            name="CSV Upload"
            accept={acceptedFileTypes}
            onChange={handleFileUpload}
            onClick={(event) => handleInboundCSVUploadClicked(event)} // allow user to upload same file mulitple times
          />
          <span className="file-cta">
            <span className="file-icon">
              <i className="fas fa-upload"></i>
            </span>
            <span className="file-label">Upload a .csv file</span>
          </span>
        </label>
      </div>
      <a href="/static/sample-inbound-upload.csv" className="is-text-small has-text-right pt-1">
        Sample .csv file
      </a>
    </div>
  );
};

export default InboundCSVUpload;
