import React, { useState } from "react";
import { Columns, Modal } from "react-bulma-components";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import messages from "../../../../../utils/messages.js";
import SuggestionButton from "../SuggestionButton.jsx";
import MultipleAddressModal from "./components/MultipleAddressModal";
import { compareStrings } from "../../../../../utils/general.js";
import {
  RECIPIENT_MODAL_NO_ERRORS,
  getRecipientModalInitialState,
} from "./config/recipientModal.state";
import { priorityCountries } from "../../../../../configs/consts";
import { useSmartyStreets } from "../../../../../utils/hooks/useSmartyStreets";
import { recipientModalstyles } from "./config/recipientModal.styles";
import { IRecipientModal, RecipientKeys } from "./config/recipientModal.interfaces";
import {
  CHANGE_CONTACTS,
  REQUIRED_RECIPIENT_MODAL_COUNTRIES,
  recipientRequiresOneOf,
} from "./config/recipientModal.consts";
import { IRecipient } from "../../../../../interfaces/recipient.interfaces";
import { RecipientModalInput } from "./components/RecipientModalInput";
import { RecipientModalErrorBar } from "./components/RecipientModalErrorBar";
import { RecipientModalInputErrorBar } from "./components/RecipientModalInputErrorBar";
import { usStreet } from "smartystreets-javascript-sdk";

const RecipientModal: React.FC<IRecipientModal> = (props) => {
  const [state, setState] = useState(getRecipientModalInitialState(props));
  const { getIntlLookup, getUSLookup, intlClient, usStreetClient } = useSmartyStreets();
  // Check if required fields are filled out
  const validateNaively = () => {
    let errors: any = {};
    const provinceRequired = REQUIRED_RECIPIENT_MODAL_COUNTRIES.some(
      (country) => country === state.data.shipping.country_code
    );
    // Zip code not required for address validation

    const requiredShippingFields = ["address1", "city", "country_code"] as RecipientKeys[];
    if (provinceRequired) {
      requiredShippingFields.push("province_code");
    }
    // Require one of the following fields: first/last name, company
    let missingRecipientFields = recipientRequiresOneOf.filter(
      (field) =>
        // !(!!state.data.customer && !!state.data.customer[field] && !!state.data.customer[field].trim()) &&
        !(
          !!state.data.shipping &&
          !!state.data.shipping[field] &&
          !!state.data.shipping[field].trim()
        )
    );

    if (missingRecipientFields.length === 3) {
      missingRecipientFields.forEach(
        (emptyField) => (errors[emptyField] = "First Name, Last Name, or Company required.")
      );
    }
    // Required shipping address fields
    requiredShippingFields
      .filter((field) => !state.data.shipping[field])
      .forEach((emptyField) => (errors[emptyField] = "This field is required."));

    setState((prev) => ({ ...prev, errors }));

    return Object.keys(errors).length === 0;
  };

  // Validate US addresses via SmartyStreets
  const ssValidateUS = async (): Promise<{
    ssResponse: string;
    ssReturnMultiple: Array<usStreet.Candidate>;
    ssReturnSingle: usStreet.Candidate | Record<string, any>;
  }> => {
    let payloadLookup = getUSLookup();
    payloadLookup.street = state.data.shipping.address1;
    payloadLookup.street2 = state.data.shipping.address2;
    payloadLookup.city = state.data.shipping.city;
    payloadLookup.state = state.data.shipping.province_code;
    payloadLookup.zipCode = state.data.shipping.zipcode;
    payloadLookup.maxCandidates = 3;

    try {
      const response = await usStreetClient.send(payloadLookup);
      const lookup = response.lookups[0];

      let ssResponse = messages.ADDRESS_INVALID_ERROR;

      if (lookup.result.length > 1) {
        ssResponse = messages.ADDRESS_US_AMBIGUOUS_ERROR;
        const ssReturnMultiple = lookup.result;
        setState((prev) => ({
          ...prev,
          ssReturnMultiple,
        }));
        return { ssResponse, ssReturnMultiple, ssReturnSingle: {} };
      } else if (lookup.result.length === 1) {
        ssResponse = messages.ADDRESS_VALID;
        const ssReturnSingle = lookup.result[0];
        setState((prev) => ({
          ...prev,
          ssReturnSingle,
        }));
        return { ssResponse, ssReturnMultiple: [], ssReturnSingle };
      } else {
        setState((prev) => ({
          ...prev,
          ssReturnSingle: {},
        }));
      }

      setState((prev) => ({ ...prev, ssResponse }));
      return { ssResponse, ssReturnMultiple: [], ssReturnSingle: {} };
    } catch (err) {
      console.error(err);
      const ssResponse = messages.ADDRESS_SS_UNREACHABLE;
      setState((prev) => ({
        ...prev,
        ssReturnSingle: {},
        ssResponse,
      }));
      return { ssResponse, ssReturnMultiple: [], ssReturnSingle: {} };
    }
  };

  // Validate non-US addresses with SmartyStreets
  const ssValidateIntl = async (): Promise<string> => {
    let lookup = getIntlLookup();
    lookup.address1 = state.data.shipping.address1;
    lookup.address2 = state.data.shipping.address2;
    lookup.locality = state.data.shipping.city;
    lookup.administrativeArea = state.data.shipping.province_code;
    lookup.postalCode = state.data.shipping.zipcode;
    lookup.country = state.data.shipping.country_code;

    try {
      const response = await intlClient.send(lookup);

      const analysis = response.result[0].analysis;
      let ssResponse = messages.ADDRESS_INVALID_ERROR;
      if (["Verified", "Ambiguous"].includes(analysis.verificationStatus)) {
        if (analysis.addressPrecision === "Locality") {
          ssResponse = messages.ADDRESS_INTL_CITY_ERROR;
        } else if (analysis.addressPrecision === "Thoroughfare") {
          ssResponse = messages.ADDRESS_INTL_STREET_ERROR;
        } else if (["Premise", "DeliveryPoint"].includes(analysis.addressPrecision)) {
          ssResponse = messages.ADDRESS_VALID;
        }
      }

      setState((prev) => ({ ...prev, ssResponse }));
      return ssResponse;
    } catch (err) {
      console.error(err);
      const ssResponse = messages.ADDRESS_SS_UNREACHABLE;
      setState((prev) => ({
        ...prev,
        ssResponse,
      }));
      return ssResponse;
    }
  };

  // Parse smartystreets result to suggestions
  const parseSuggestionsUS = (
    ssReturnSingle: any,
    ssResponseData: string
  ): { ssSuggestions: any; ssResponse: string } => {
    let ssSuggestions: any = {};
    let address1 = ssReturnSingle["deliveryLine1"];
    let address2 = ssReturnSingle["deliveryLine2"];
    let city = ssReturnSingle["components"]["cityName"];
    let province_code = ssReturnSingle["components"]["state"];
    let zip = ssReturnSingle["components"]["zipCode"];

    if (!compareStrings(address1, state.data.shipping.address1)) {
      ssSuggestions["address1"] = address1;
    }

    if (address2 !== state.data.shipping.address2 && address2 !== undefined) {
      ssSuggestions["address2"] = address2;
    }

    if (!compareStrings(city, state.data.shipping.city)) {
      ssSuggestions["city"] = city;
    }

    if (!compareStrings(province_code, state.data.shipping.province_code)) {
      ssSuggestions["province_code"] = province_code;
    }

    if (zip !== state.data.shipping.zipcode) {
      ssSuggestions["zipcode"] = zip;
    }

    let ssResponse = ssResponseData;
    if (Object.entries(ssSuggestions).length) {
      ssResponse = messages.ADDRESS_US_SUGGESTIONS;
      setState((prev) => ({
        ...prev,
        ssResponse,
      }));
    }

    setState((prev) => ({ ...prev, ssSuggestions }));
    return { ssSuggestions, ssResponse };
  };

  // Click to replace form field with suggestion
  const replaceField = (suggestion: RecipientKeys) => {
    let currentSuggestion = state.ssSuggestions[suggestion];
    let ssSuggestions = state.ssSuggestions;
    delete ssSuggestions[suggestion];
    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        shipping: {
          ...prev.data.shipping,
          [suggestion]: currentSuggestion,
        },
      },
      ssSuggestions,
      ssResponse: !Object.entries(ssSuggestions).length ? messages.ADDRESS_VALID : prev.ssResponse,
    }));
  };

  // Save details from the chosen address in the multiple address modal
  const replaceCandidate = () => {
    const newData = {
      ...state.data,
      shipping: {
        ...state.data.shipping,
        address1: state.selectedMultiple.deliveryLine1,
        address2: state.selectedMultiple.deliveryLine2,
        city: state.selectedMultiple.components.cityName,
        province_code: state.selectedMultiple.components.state,
        zipcode: state.selectedMultiple.components.zipCode,
      },
    };
    const ssResponse = messages.ADDRESS_VALID;
    setState((prev) => ({
      ...prev,
      data: newData,
      ssResponse,
      ssReturnMultiple: [],
      editing: false,
    }));
    saveAndClose(newData, ssResponse);
  };

  // Set selected candidate from multiple address modal
  const setSelectCandidate = (candidate: usStreet.Candidate) => {
    setState((prev) => ({
      ...prev,
      selectedMultiple: candidate,
    }));
  };

  const onSave = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    // If required fields are not filled out, exit
    if (!validateNaively()) return;

    // If address validation is not turned on for store, don't validate and save as is
    if (!props.store?.address_validation) {
      saveAndClose(state.data, "");
      return;
    }

    // If ssResponse is not null and user is not editing, the save button works as an override (similar to no thanks)
    if (state.ssResponse && !state.editing) {
      saveAndClose(state.data, state.ssResponse);
    } else if (["US", "USA"].includes(state.data.shipping.country_code)) {
      const { ssResponse: ssResponseData, ssReturnMultiple, ssReturnSingle } = await ssValidateUS();
      let ssResponse = ssResponseData;
      let ssSuggestions = {};
      // If SmartyStreets is down, save as is and exit.
      if (ssResponse === messages.ADDRESS_SS_UNREACHABLE) saveAndClose(state.data, ssResponse);
      if (Object.entries(ssReturnSingle).length) {
        const { ssResponse: ssResponseParsedData, ssSuggestions: ssSuggestionsParsedData } =
          parseSuggestionsUS(ssReturnSingle, ssResponse);
        ssSuggestions = ssSuggestionsParsedData;
        ssResponse = ssResponseParsedData;
      } else {
        setState((prev) => ({ ...prev, ssSuggestions: {} }));
      }

      setState((prev) => ({ ...prev, editing: false }));

      // Clean save without any lingering errors or suggestions
      if (
        ssResponse === messages.ADDRESS_VALID &&
        !Object.entries(ssReturnMultiple).length &&
        !Object.entries(ssSuggestions).length
      ) {
        saveAndClose(state.data, ssResponse);
      }
    } else {
      const ssResponse = await ssValidateIntl();
      saveAndClose(state.data, ssResponse);
    }
  };

  const saveAndClose = (data: IRecipient, ssResponse: string) => {
    props.onSave(data, ssResponse);
    props.onClose();
    setState((prev) => ({
      ...prev,
      editing: false,
    }));
  };

  const clickNoThanks = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    saveAndClose(state.data, state.ssResponse);
  };

  // Close modal, same as cancel
  const onClose = () => {
    setState((prev) => ({
      ...prev,
      data: {
        customer: props.customer,
        shipping: props.shipping,
      },
      errors: { ...RECIPIENT_MODAL_NO_ERRORS },
      ssSuggestions: {},
      editing: false,
    }));
    props.onClose();
  };

  // onChange handlers for customer and shipping fields
  const onChangeContact = (value: string, type: CHANGE_CONTACTS) => {
    if (type === "email") {
      setState((prev) => ({
        ...prev,
        data: {
          ...state.data,
          customer: {
            ...state.data.customer,
            [type]: value,
          },
        },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        data: {
          ...state.data,
          shipping: {
            ...state.data.shipping,
            [type]: value,
          },
        },
      }));
    }
  };

  const onEditForm = () => {
    if (state.ssResponse != null && !state.editing) {
      setState((prev) => ({ ...prev, editing: true }));
    }
  };

  const onChangeAddressInfo = (value: string, addressField: RecipientKeys) => {
    onEditForm();
    setState((prev) => ({
      ...prev,
      data: {
        ...state.data,
        shipping: {
          ...state.data.shipping,
          [addressField]: value,
        },
      },
    }));
  };

  const onChangeStateCountry = (value: string, type: string) => {
    onEditForm();
    if (type === "province") {
      setState((prev) => ({
        ...prev,
        data: {
          ...state.data,
          shipping: {
            ...state.data.shipping,
            province_code: value,
          },
        },
      }));
    } else if (type === "country") {
      setState((prev) => ({
        ...prev,
        data: {
          ...state.data,
          shipping: {
            ...state.data.shipping,
            country_code: value,
            province_code: "",
          },
        },
      }));
    }
  };

  return (
    <Modal show={props.show} onClose={onClose} closeOnBlur={true} showClose={false}>
      {state.ssReturnMultiple.length ? (
        <MultipleAddressModal
          replaceCandidate={replaceCandidate}
          setSelectCandidate={setSelectCandidate}
          shipping={state.data.shipping}
          candidates={state.ssReturnMultiple}
          keepOriginalAddress={() => setState((prev) => ({ ...prev, ssReturnMultiple: [] }))}
        />
      ) : (
        <Modal.Card className="modal-card is-wide">
          <Modal.Card.Header onClose={onClose}>
            <Modal.Card.Title>Add Recipient</Modal.Card.Title>
          </Modal.Card.Header>
          <Modal.Card.Body>
            <div>
              {state.ssResponse && (
                <RecipientModalErrorBar onClick={clickNoThanks} ssResponse={state.ssResponse} />
              )}
            </div>
            <div className="mt-2">
              <RecipientModalInputErrorBar
                countryLong={props.countryLong}
                provinceLong={props.provinceLong}
              />
            </div>
            <form className="is-flex">
              <div className="p-3 mr-3" style={recipientModalstyles.contactBox}>
                <p className="heading">Contact Information</p>
                <Columns>
                  <RecipientModalInput
                    value={(state.data.shipping && state.data.shipping.first_name) || ""}
                    onChange={(value) => onChangeContact(value, CHANGE_CONTACTS.FIRST_NAME)}
                    error={state.errors.first_name}
                    placeholder="First Name"
                  />
                  <RecipientModalInput
                    value={(state.data.shipping && state.data.shipping.last_name) || ""}
                    onChange={(value) => onChangeContact(value, CHANGE_CONTACTS.LAST_NAME)}
                    error={state.errors.last_name}
                    placeholder="Last Name"
                  />
                </Columns>
                <Columns>
                  <RecipientModalInput
                    value={(state.data.customer && state.data.customer.email) || ""}
                    onChange={(value) => onChangeContact(value, CHANGE_CONTACTS.EMAIL)}
                    error={state.errors.email}
                    placeholder="Email"
                  />
                </Columns>
                <Columns>
                  <RecipientModalInput
                    value={(state.data.shipping && state.data.shipping.phone) || ""}
                    onChange={(value) => onChangeContact(value, CHANGE_CONTACTS.PHONE)}
                    error={state.errors.phone}
                    placeholder="Phone"
                  />
                </Columns>
              </div>
              <div className="px-3 pt-3 ml-3" style={recipientModalstyles.shippingBox}>
                <p className="heading">Shipping Address</p>
                <Columns>
                  <RecipientModalInput
                    value={state.data.shipping.company}
                    onChange={(value) => onChangeAddressInfo(value, "company")}
                    error={state.errors.company}
                    placeholder="Company"
                  />
                </Columns>
                <Columns>
                  <RecipientModalInput
                    value={state.data.shipping.address1}
                    onChange={(value) => onChangeAddressInfo(value, "address1")}
                    error={state.errors.address1}
                    placeholder="Address"
                  >
                    {state.ssSuggestions.address1 && (
                      <div className="mt-2">
                        <SuggestionButton
                          replaceField={() => replaceField("address1")}
                          field={state.ssSuggestions.address1}
                        />
                      </div>
                    )}
                  </RecipientModalInput>
                </Columns>
                <Columns>
                  <RecipientModalInput
                    value={state.data.shipping.address2}
                    onChange={(value) => onChangeAddressInfo(value, "address2")}
                    error={state.errors.address2}
                    placeholder="Apt, suite, etc."
                  />
                </Columns>
                <Columns>
                  <RecipientModalInput
                    value={state.data.shipping.city}
                    onChange={(value) => onChangeAddressInfo(value, "city")}
                    error={state.errors.city}
                    placeholder="City"
                  >
                    {state.ssSuggestions.city && (
                      <div className="mt-2">
                        <SuggestionButton
                          replaceField={() => replaceField("city")}
                          field={state.ssSuggestions.city}
                        />
                      </div>
                    )}
                  </RecipientModalInput>
                </Columns>
                <Columns>
                  <Columns.Column>
                    <div className="field m-0 p-0">
                      <div className="control has-icons-right">
                        <div className="select is-fullwidth">
                          <CountryDropdown
                            classes="pl-3"
                            value={state.data.shipping.country_code}
                            valueType="short"
                            onChange={(value) => onChangeStateCountry(value, "country")}
                            priorityOptions={priorityCountries}
                          />
                        </div>
                      </div>
                    </div>
                    {state.errors.country_code && (
                      <p className="help is-danger is-size-7 py-2 mb-0">
                        {state.errors.country_code}
                      </p>
                    )}
                  </Columns.Column>
                  <Columns.Column>
                    <div className="field m-0 p-0">
                      <div className="control has-icons-right">
                        <div className="select is-fullwidth">
                          <RegionDropdown
                            classes="pl-3"
                            country={state.data.shipping.country_code}
                            countryValueType="short"
                            value={state.data.shipping.province_code}
                            valueType="short"
                            onChange={(value) => onChangeStateCountry(value, "province")}
                            disabled={!state.data.shipping.country_code}
                            defaultOptionLabel="State/Province Code"
                          />
                        </div>
                      </div>
                    </div>
                    {state.errors.province_code && (
                      <p className="help is-danger is-size-7 py-2 mb-0">
                        {state.errors.province_code}
                      </p>
                    )}
                    {state.ssSuggestions.province_code && (
                      <div className="mt-2">
                        <SuggestionButton
                          replaceField={() => replaceField("province_code")}
                          field={state.ssSuggestions.province_code}
                        />
                      </div>
                    )}
                  </Columns.Column>
                </Columns>
                <Columns>
                  <RecipientModalInput
                    value={state.data.shipping.zipcode}
                    onChange={(value) => onChangeAddressInfo(value, "zipcode")}
                    error={state.errors.zipcode}
                    placeholder="ZIP/Postal Code"
                  >
                    {state.ssSuggestions.zipcode && (
                      <div className="mt-2">
                        <SuggestionButton
                          replaceField={() => replaceField("zipcode")}
                          field={state.ssSuggestions.zipcode}
                        />
                      </div>
                    )}
                  </RecipientModalInput>
                </Columns>
              </div>
            </form>
          </Modal.Card.Body>
          <Modal.Card.Footer>
            <div className="is-flex-direction-column is-fullwidth mr-3 px-1">
              <div className="is-flex is-justify-content-space-between is-fullwidth">
                <button className="button is-text" onClick={onClose}>
                  Cancel
                </button>
                <button className="button is-primary" type="submit" onClick={onSave}>
                  {state.editing ? <>Revalidate &amp; Save</> : <>Save</>}
                </button>
              </div>
            </div>
          </Modal.Card.Footer>
        </Modal.Card>
      )}
    </Modal>
  );
};

export default RecipientModal;
