import React from 'react';

export const Button = (props) => {
  const { onClick, isLoading, isDisabled, isPrimary, text, additionalClasses } = props;
  return (
    <button
      className={`button modern ${isPrimary ? 'is-primary' : 'is-secondary'} ${isLoading ? 'is-loading' : ''} ${additionalClasses}`}
      onClick={onClick}
      disabled={isDisabled}
    >
      {text}
    </button>
  );
};
