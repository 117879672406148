import React, { Component } from 'react';
import _ from 'lodash';

import apiEndpoints from '../../utils/apiEndpoints.js';


const defaultState = {
  questions: [],
  answers: [],
  loading: true,
  readOnly: true,
};

export const PreferencesContext = React.createContext(defaultState);

export default class PreferencesStore extends Component {
  state = defaultState;

  getPreferences = () =>     
    this.state.loading
      ? Promise
          .all([
            apiEndpoints.getStoreAttributes()
              .then(response => response.json())
              .then(questions => _.keyBy(questions.results, result => result.slug)),
            apiEndpoints.getStoreValues()
              .then(response => response.json())
              .then(answers => _.keyBy(answers.results, result => result.attribute))
          ])
          .then(
            preferences =>
              this.setState({
                questions: preferences[0],
                answers: preferences[1],
                loading: false,
                readOnly: Object.keys(preferences[1]).length > 0,
              })
          )
      : Promise.resolve(this.state);

  answerQuestion = ({store, question, answerValue}) => {
    let answers = this.state.answers;

    // Mung multiple choice answers into a single string
    if (question.input_type === 'MULTI') {
      let values = !!answers[question.uuid] && !!answers[question.uuid].value
        ? answers[question.uuid].value.split('|')
        : [];
      let answerValueIndex = _.findIndex(values, value => value === answerValue);
      (answerValueIndex > -1)
        // Remove existing answerValue from multiple choice values
        ? values.splice(answerValueIndex, 1)
        // Append answerValue to multiple choice values
        : values.push(answerValue);

      answerValue = values.join('|');
    }

    answers[question.uuid] = {
      ...answers[question.uuid],
      attribute: question.uuid,
      store: store,
      value: answerValue,
    };
    this.setState({ answers });
  }

  addNotes = (answer, notes) => {
    let answers = this.state.answers;
    !!answers[answer.attribute]
      ? answers[answer.attribute].text = notes
      : answers[answer.attribute] = answer

    this.setState({ answers });
  }
  
  savePreferences = () =>
    Object.keys(this.state.answers)
      .forEach(key => !!this.state.answers[key].uuid
        ? apiEndpoints.editStoreValue(this.state.answers[key])
        : apiEndpoints.createStoreValue(this.state.answers[key])
      );

  render () {
    return <PreferencesContext.Provider value={{
      ...this.state,
      getPreferences: this.getPreferences,
      answerQuestion: this.answerQuestion,
      addNotes: this.addNotes,
      savePreferences: this.savePreferences,
    }}>
      { this.props.children }
    </PreferencesContext.Provider>
  }
}
