import React, { useEffect, useState } from "react";
import { getWholesaleCost } from "../../../../utils/gateway/ProjectRequestAPIGateway";

export const WholesaleCostEstimator = (props) => {
  const [wholesaleCosts, setWholesaleCosts] = useState({});
  const { isFreight, warehouse, orders } = props;

  useEffect(() => {
    getWholesaleCost(
      orders.map((order) => order.uuid),
      isFreight,
      warehouse
    ).then((res) => setWholesaleCosts(res));
  }, [orders, warehouse, isFreight]);

  console.log(wholesaleCosts);

  return (
    <div className="container m-5">
      <div className="column has-text-grey">
        <div className="columns border-bottom-grey-lighter">
          <div className="column is-three-quarters has-text-weight-semibold	">ESTIMATED COST</div>
        </div>
        <div className="columns border-bottom-grey-lighter">
          <div className="column is-three-quarters">Order Fee</div>
          <div className="column">${wholesaleCosts?.orderFee}</div>
        </div>
        <div className="columns  border-bottom-grey-lighter">
          <div className="column is-three-quarters">Pick Fee</div>
          <div className="column">${wholesaleCosts?.pickTotal}</div>
        </div>
        <div className="columns">
          <div className="column is-three-quarters has-text-weight-semibold	">
            Estimated Total (USD)
          </div>
          <div className="column">${wholesaleCosts?.total}</div>
        </div>
      </div>
      <div className="box modern grey mt-5 column has-text-grey">
        <div>
          <p>Estimated Cost is calculated based on the following:</p>
          <br></br>
          <ul style={styles.list}>
            <li>Order Fee</li>
            <li>
              <ul style={styles.nestedList}>
                <li>This is different based on whether its freight or small parcel</li>
              </ul>
            </li>
            <br></br>
            <li>Pick Fee</li>
            <li>
              <ul style={styles.nestedList}>
                <li>Each picks * each rate + case picks * case rate</li>
                <li>Note that there is a minimum ${wholesaleCosts?.pickMinimum} pick fee</li>
              </ul>
            </li>
          </ul>
          <br></br>
          <p>Total = Order Fee + Pick Fee</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  list: {
    listStyleType: "none",
    marginLeft: "0px",
    paddingLeft: "0",
  },
  nestedList: {
    listStyleType: "disc",
    marginLeft: "20px",
    paddingLeft: "0",
  },
};

export default WholesaleCostEstimator;
