import React, { memo } from "react";
import { IRecipient } from "../../../../interfaces/recipient.interfaces";

interface IRecipientComponent extends IRecipient {
  hasNoRecipient?: boolean;
  isNewOrder: boolean;
}

const Recipient: React.FC<IRecipientComponent> = memo(
  ({ customer, isNewOrder, shipping, hasNoRecipient = false }) => {
    const hasCustomerData = () => {
      if (customer == null) {
        return false;
      }
      const relevantFields = (({ email, phone }) => ({ email, phone }))(customer);
      return Object.values(relevantFields).filter((value) => value).length > 0;
    };

    const missingShippingFields = () => {
      const relevantFields = (({ address1, city, province_code, country_code }) => ({
        address1,
        city,
        province_code,
        country_code,
      }))(shipping);
      return Object.entries(relevantFields)
        .filter((kv) => !kv[1])
        .map((key) => key[0]);
    };

    const arrayStringFormatter = (array: string[]) => {
      if (!array.length) {
        return "";
      } else if (array.length === 1) {
        return array[0];
      } else {
        return array.join(", ");
      }
    };

    // Don't show shipping address if load the create order page for the first time
    if (isNewOrder && hasNoRecipient) {
      return null;
    }

    return (
      <div className="has-text-grey-darker">
        <div>
          <h1 className="heading">Shipping Address</h1>
          <p>
            {shipping.first_name} {shipping.last_name}
          </p>
          <p>{shipping.company}</p>
          <p>{shipping.address1}</p>
          <p>{shipping.address2}</p>
          <p>
            {shipping.city}, {shipping.province_code} {shipping.zipcode && shipping.zipcode + ", "}
            {shipping.country_code}
          </p>
        </div>

        {!!missingShippingFields().length && (
          <p className="tag is-danger mt-1">
            <span>Missing {arrayStringFormatter(missingShippingFields())}</span>
          </p>
        )}

        {hasCustomerData() && (
          <div className="mt-4 mx-0">
            <p className="heading">Contact</p>
            <p>{customer.email}</p>
            <p>{shipping.phone || customer.phone}</p>
          </div>
        )}
      </div>
    );
  }
);

export default Recipient;
