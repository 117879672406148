import React, { useState } from "react";

export const useFreightCoordination = (inputState = false) => {
  const [isAirhouseCoordinatingFreight, setIsAirhouseCoordinatingFreight] = useState(inputState);
  return {
    isAirhouseCoordinatingFreight,
    setIsAirhouseCoordinatingFreight,
  };
};

const FreightCoordination = (props) => {
  const { isAirhouseCoordinatingFreight, setIsAirhouseCoordinatingFreight, isEditing } = props;

  const handleFreightCoordinationChange = (value) => {
    setIsAirhouseCoordinatingFreight(value);
  };

  return (
    <>
      <div className="level is-paddingless">
        <h4 className="title is-4 mt-5">Freight Coordination</h4>
      </div>
      <p>Do you need Airhouse to coordinate your freight pickup?</p>
      {!isEditing && <p>{isAirhouseCoordinatingFreight ? "Yes" : "No"}</p>}
      {isEditing && (
        <div className="field mt-2">
          <div className="control mt-2">
            <input
              className="is-checkradio"
              id="airhouse-freight-coordination-radio"
              type="radio"
              checked={isAirhouseCoordinatingFreight}
              onChange={() => handleFreightCoordinationChange(true)}
            />
            <label className="radio" htmlFor="airhouse-freight-coordination-radio">
              Yes
            </label>
          </div>
          <div className="control mt-2">
            <input
              className="is-checkradio"
              id="not-airhouse-freight-coordination-radio"
              type="radio"
              checked={!isAirhouseCoordinatingFreight}
              onChange={() => handleFreightCoordinationChange(false)}
            />
            <label className="radio" htmlFor="not-airhouse-freight-coordination-radio">
              No
            </label>
          </div>
        </div>
      )}

      {isAirhouseCoordinatingFreight ? (
        <div className="box has-background-success-light has-text-centered">
          After submitting this restock order, your Account Specialist will reach out to you with
          your freight quote.
        </div>
      ) : (
        <div className="box has-background-success-light has-text-centered">
          All deliveries must be scheduled 48 hours in advance. <br/>Please contact{" "}
          <a href="mailto:receiving@airhouse.io">receiving@airhouse.io</a>{" "}
          to schedule an appointment.{" "}
          <a href="https://help.airhouse.io/how-to-schedule-freight-delivery-for-receiving-restock" target="_blank">
            See our docs for scheduling info.
          </a>
        </div>
      )}
    </>
  );
};

export default FreightCoordination;
