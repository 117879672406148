import React, { useEffect, useRef } from "react";

const CheckedCustomDropdown = (props) => {
  const ref = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const isDropdownActive = props.isDropdownActive || false; // State of open/closed dropdown menu
  const toggleDropdownMenu = props.toggleDropdownMenu || false; // Function will toggle open/close dropdown menu
  const currentItem = props.currentItem || null; // Current selection
  const items = props.items || []; // Array of items objects
  const itemIdKey = props.itemIdKey || "uuid";
  const displayName = props.displayName || "name";
  const placeholderText = props.placeholderText || "Select an option";
  const { handleItemChecked } = props;

  const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      props.isDropdownActive
    ) {
      props.toggleDropdownMenu();
    }
  };

  return (
    <div
      ref={ref}
      className={`is-fullwidth dropdown${isDropdownActive ? " is-active" : ""}`}
      onClick={toggleDropdownMenu}
    >
      <div className="is-fullwidth dropdown-trigger">
        <button
          className="is-fullwidth button is-justify-content-space-between"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          style={styles.dropdownButton}
        >
          <span style={styles.smallerFont}>
            {currentItem?.[displayName] ?? placeholderText}
          </span>
          <span className="is-pulled-left icon is-small">
            <i className="icon-ic-caret" aria-hidden="true" />
          </span>
        </button>
      </div>

      <div className="is-fullwidth dropdown-menu" role="menu">
        <div className="dropdown-content" style={styles.dropdownButton}>
          {items.map((item) => {
            return (
              <CheckedDropdownItem
                key={item[itemIdKey]}
                displayName={item[displayName]}
                item={item}
                checked={item.checked}
                handleItemChecked={handleItemChecked}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const CheckedDropdownItem = (props) => {
  const { displayName, handleItemChecked, checked } = props;
  return (
    <>
      <label className="checkbox m-2 is-flex is-vcentered">
        <input
          name={displayName}
          type="checkbox"
          className="mr-2"
          checked={checked}
          onChange={handleItemChecked}
        />
        {displayName}
      </label>
    </>
  );
};

const styles = {
  dropdownButton: {
    borderRadius: "4px",
    boxShadow: "none",
  },
  smallerFont: {
    fontSize: "0.875rem",
  },
};

export default CheckedCustomDropdown;
