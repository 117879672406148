import React from "react";
import EditTrackingNumbers, { useEditTrackingNumbers } from "../components/EditTrackingNumbers";
import FreightCoordination, { useFreightCoordination } from "../components/FreightCoordination";
import FreightDetailsAirhouse, {
  useFreightDetailsAirhouse,
} from "../components/FreightDetailsAirhouse";
import FreightDetails, { useFreightDetails } from "../components/FreightDetails";
import ReceivingAddress from "../components/ReceivingAddress";

export const useCarrierDetails = (inputState = {}, inboundOrderState) => {
  const freightCoordination = useFreightCoordination(
    inputState.freightCoordination?.isAirhouseCoordinatingFreight || false
  );
  const freightDetails = useFreightDetails(inputState?.freightDetails || {});
  const freightDetailsAirhouse = useFreightDetailsAirhouse(
    inputState?.freightDetailsAirhouse || {}
  );
  const trackingNumbersState = useEditTrackingNumbers(
    inputState.trackingNumbersState || {},
    inboundOrderState
  );

  const isFreightInfoMissing = () => {
    if (freightCoordination?.isAirhouseCoordinatingFreight) {
      const {
        contactFirstName,
        contactLastName,
        contactEmail,
        contactPhone,
        facilityAddress,
        facilityHours,
      } = freightDetailsAirhouse.freightDetailsAirhouseState;
      const fields = [
        contactFirstName,
        contactLastName,
        contactEmail,
        contactPhone,
        facilityAddress,
        facilityHours,
      ];
      return fields.some((x) => x === "");
    } else {
      const { freightCompany, contactFirstName, contactLastName, contactEmail, contactPhone } =
        freightDetails.freightDetailsState;
      const fields = [
        freightCompany,
        contactFirstName,
        contactLastName,
        contactEmail,
        contactPhone,
      ];
      return fields.some((x) => x === "");
    }
  };

  const isFreightDateMissing = () => {
    if (freightCoordination?.isAirhouseCoordinatingFreight) {
      return freightDetailsAirhouse?.pickupDateState?.date === null;
    } else {
      return freightDetails?.deliveryDateState?.date === null;
    }
  };

  const isFreightNumberMissing = () => {
    if (freightCoordination && !freightCoordination.isAirhouseCoordinatingFreight) {
      return freightDetails?.freightDetailsState?.referenceNumber === "";
    }
    return false;
  };

  const clearFreightDetails = () => {
    freightDetails.reset();
    freightDetailsAirhouse.reset();
  };

  return {
    trackingNumbersState,
    freightCoordination,
    freightDetails,
    freightDetailsAirhouse,
    isFreightInfoMissing,
    isFreightDateMissing,
    isFreightNumberMissing,
    clearFreightDetails,
  };
};

const CarrierDetailsSection = (props) => {
  const {
    isFreight,
    assignedReceivingAddress,
    selectedWarehouse,
    setCarrierType,
    trackingNumbersState,
    freightCoordination,
    freightDetails,
    freightDetailsAirhouse,
    scrollId,
    hasContainer,
    isEditing,
    errors,
    name,
  } = props;

  const { isAirhouseCoordinatingFreight } = freightCoordination;

  return (
    <>
      <div className="scroll-section" id={scrollId}>
        <h4 className="is-dominique-subtitle mt-5">Carrier Details</h4>
        <p>Select your carrier type</p>
        {isEditing && (
          <div className="field mt-2">
            <div className="control mt-2">
              <input
                className="is-checkradio"
                id="shipping-standard-radio"
                type="radio"
                checked={!isFreight}
                onChange={() => setCarrierType("non-freight")}
              />
              <label className="radio" htmlFor="shipping-standard-radio">
                Small Parcel (USPS, UPS, FedEx, DHL etc.)
              </label>
            </div>
            <div className="control mt-2">
              <input
                className="is-checkradio"
                id="shipping-freight-radio"
                type="radio"
                checked={isFreight}
                onChange={() => setCarrierType("freight")}
              />
              <label className="radio" htmlFor="shipping-freight-radio">
                Freight
              </label>
            </div>
          </div>
        )}
        {!isEditing && <p className="mt-4 ml-4">{isFreight ? "Freight" : "Small Parcel"}</p>}

        <ReceivingAddress
          assignedReceivingAddress={assignedReceivingAddress}
          selectedWarehouse={selectedWarehouse}
          name={name}
        />

        {isFreight ? (
          <>
            <FreightCoordination {...freightCoordination} isEditing={isEditing} />
            {isAirhouseCoordinatingFreight ? (
              <FreightDetailsAirhouse
                {...freightDetailsAirhouse}
                isEditing={isEditing}
                errors={errors}
              />
            ) : (
              <FreightDetails
                {...freightDetails}
                hasContainer={hasContainer}
                isEditing={isEditing}
                errors={errors}
              />
            )}
          </>
        ) : (
          <>
            {isEditing && <h5 className="is-dominique-subtitle mt-4">Add tracking numbers</h5>}
            {!isEditing && <h5 className="is-dominique-subtitle mt-4">Tracking numbers</h5>}
            <EditTrackingNumbers {...trackingNumbersState} isEditing={isEditing} errors={errors} />
          </>
        )}
      </div>
    </>
  );
};

export default CarrierDetailsSection;
