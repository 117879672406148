import { getToken } from "./authentication";
import { parseOptions } from "./grid/helpers";

export const getAPIBase = (protocol, hostname) => {
  if (process.env.REACT_APP_SERVER_URL) {
    return process.env.REACT_APP_SERVER_URL;
  }
  const splitHostname = hostname.split(".");

  if (splitHostname.length > 1) {
    const newHostname = hostname.replace("app", "api");

    return protocol + "//" + newHostname + "/";
  }

  return protocol + "//localhost:8000/";
};

export const { hostname, protocol } = window.location;
export const API_BASE = getAPIBase(protocol, hostname);

export const getDefaulJSONHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "Token " + getToken(),
  };
};

export const getDefaultZipHeaders = () => {
  return {
    "Content-Type": "application/zip",
    Authorization: "Token " + getToken(),
  };
};

export const handleFetchSuccess = async (response) => {
  const body = await response.json();

  if (!response.ok) {
    throw body;
  }

  return body;
};

// delete does not return a body so we need to avoid attempting to parse an empty body
export const handleDeleteSuccess = (response) => {
  if (!response.ok) {
    return Promise.reject(response);
  }

  return Promise.resolve(response);
};

export const get = (uri, filters) => {
  let url = API_BASE + uri;

  if (filters) {
    url += `?${new URLSearchParams(filters)}`;
  }

  return fetch(url, {
    method: "get",
    headers: getDefaulJSONHeaders(),
  }).then(handleFetchSuccess);
};

export const getZip = (uri) => {
  let url = API_BASE + uri;

  return fetch(url, {
    method: "get",
    headers: getDefaultZipHeaders(),
  });
};

export const post = (uri, data, formData) => {
  return fetch(API_BASE + uri, {
    method: "post",
    headers: getDefaulJSONHeaders(),
    body: formData ? formData : JSON.stringify(data),
  }).then(handleFetchSuccess);
};

export const postResponse = (uri, data, formData) => {
  return fetch(API_BASE + uri, {
    method: "post",
    headers: getDefaulJSONHeaders(),
    body: formData ? formData : JSON.stringify(data),
  })
};

export const patch = (uri, data) =>
  fetch(uri, {
    method: "PATCH",
    headers: getDefaulJSONHeaders(),
    body: JSON.stringify(data),
  }).then(handleFetchSuccess);

export const put = (uri, data) =>
  fetch(API_BASE + uri, {
    method: "put",
    headers: getDefaulJSONHeaders(),
    body: JSON.stringify(data),
  }).then(handleFetchSuccess);

// delete is a reserverd word
export const del = (uri) =>
  fetch(API_BASE + uri, {
    method: "delete",
    headers: getDefaulJSONHeaders(),
  }).then(handleDeleteSuccess);

export const objectToQueryString = (obj) => {
  const queryParams = new URLSearchParams();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      queryParams.append(key, obj[key]);
    }
  }

  return queryParams.toString();
};
