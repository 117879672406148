import _ from "lodash";
import React, { Component } from "react";
import apiEndpoints from "../../utils/apiEndpoints.js";
import consts from "../../utils/consts.js";
import { IUserStore } from "../../interfaces/stateInterfaces/user-store.interface";
import { DEFAULT_COMPANY } from "../../interfaces/company.interfaces";
import { IStore } from "../../interfaces/store.interface.js";

const defaultState = {
  data: {
    account: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      uuid: "",
      is_staff: false,
      is_superuser: false,
      get_suspended_invoices: false,
      get_delinquent_invoices: false,
      company: DEFAULT_COMPANY,
    },
    isAdmin: false,
    isDemo: false,
    password: {
      new_password1: "",
      new_password2: "",
    },
    stores: [] as IStore[],
    sudo: null,
  },
  store: null as IStore | null,
  errors: {},
  loading: true,
  refreshUser: () => {},
};

export const UserContext = React.createContext(defaultState);

export default class UserStore extends Component<{children: React.ReactNode}> {
  state = defaultState;

  componentDidMount() {
    this.getUser();
  }

  refreshUser = async () => {
    try {
      const url = apiEndpoints.API_BASE + "auth/user/";
      const response = await apiEndpoints.getData(url);
      const data = await response.json();

      this.setState({
        data: {
          ...this.state.data,
          account: data,
          stores: data.company.stores,
        },
        store: data.company.stores.length && data.company.stores[0],
      });
    } catch (err) {
      console.error(err);
    }
  };

  checkDomainAuthorization = ({
    atPostworks,
    isPostworksCompany,
    isAdmin,
  }: {
    atPostworks: boolean;
    isPostworksCompany: boolean;
    isAdmin: boolean;
  }) => {
    // Helper
    let isAuthorized = isAdmin || isPostworksCompany === atPostworks;
    if (!isAuthorized) {
      this.logout();
      window.location.replace("/");
    }
  };

  getUser = async () => {
    // Return cache if already loaded
    if (!this.state.loading) {
      return Promise.resolve(this.state.data);
    }

    try {
      const response = await apiEndpoints.getData(`${apiEndpoints.API_BASE}auth/user/`);
      const data = await response.json();

      if (response.ok) {
        let { stores } = data.company;
        if (data.is_superuser) {
          stores = await this.getStores();
        }

        this.setState(
          {
            data: {
              ...this.state.data,
              account: data,
              isAdmin: data.is_superuser || !!localStorage.getItem("sudoToken"),
              isDemo: data.company?.status === consts.STATUS_DEMO,
              stores,
            },
            store: stores.length && stores[0],
            loading: false,
          },
          () =>
            this.checkDomainAuthorization({
              atPostworks: window.location.hostname.indexOf("postworks") !== -1,
              isPostworksCompany: this.state.data.account.company.domain === "POSTWORKS",
              isAdmin: this.state.data.account.is_superuser,
            })
        );

        // Segment identity
        // window.analytics.identify(data.uuid, { ...data });
      } else {
        console.warn(data);
        !!localStorage.getItem("sudoToken")
          ? localStorage.removeItem("sudoToken")
          : localStorage.removeItem("token");
      }
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  trackWithSegment = () => {
    (window as any).analytics.identify((this.state as unknown as IUserStore).data.account?.uuid, {
      ...this.state.data.account,
    });
  };

  getStores = async () => {
    try {
      let storesArray: Array<any> = [];
      let hasNext = true;
      let url = apiEndpoints.API_BASE + "api/stores/";

      while (hasNext) {
        let response = await apiEndpoints.getData(url);
        let json = await response.json();

        storesArray.push.apply(storesArray, json.results);
        hasNext = !!json.next;
        url = json.next;
      }
      return storesArray;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  getSudo = async () => {
    if (!!this.state.data.sudo) {
      return Promise.resolve(this.state.data.sudo);
    }

    try {
      const response = await apiEndpoints.getSudo();
      const data = await response.json();
      let sudo: Array<any> = [];

      // Return errors if there are any
      if (response.ok) {
        sudo = Object.entries(_.groupBy(data, "company"))
          .map((companyUsers) => ({
            company: companyUsers[0],
            token: companyUsers[1][0].token,
          }))
          .sort((a, b) => {
            if (a.company.toLowerCase() >= b.company.toLowerCase()) {
              return 1;
            } else if (a.company.toLowerCase() < b.company.toLowerCase()) {
              return -1;
            }
            return 0;
          });
      }

      this.setState({
        data: {
          ...this.state.data,
          sudo,
        },
      });

      return sudo;
    } catch (err) {
      console.error(err);
    }
  };

  setSudo = (token: string) => {
    localStorage.setItem("sudoToken", token);
    localStorage.removeItem("productsSelected");
    window.location.reload();
  };

  exitSudo = () => {
    localStorage.removeItem("sudoToken");
    localStorage.removeItem("productsSelected");
    window.location.reload();
  };

  isSalesDemo = () => !this.state.loading && this.state.data.account.company.name === "Postworks";

  saveAccount = async (payload: any) => {
    try {
      const response = await apiEndpoints.putData(
        `${apiEndpoints.API_BASE}api/users/my-profile/`,
        payload
      );
      const data = await response.json();

      if (response.ok) {
        this.setState({
          data: {
            ...this.state.data,
            account: {
              ...this.state.data.account,
              first_name: data.first_name,
              last_name: data.last_name,
              phone: data.phone,
              email: data.email,
            },
          },
          errors: {},
        });
      } else {
        this.setState({ errors: data });
      }
    } catch (err) {
      console.error(err);
    }
  };

  changePassword = async (password: string) => {
    try {
      const response = await apiEndpoints.postData(
        `${apiEndpoints.API_BASE}auth/password/change/`,
        password
      );
      const data = await response.json();
      this.setState({ errors: response.ok ? {} : data });
    } catch (err) {
      console.error(err);
    }
  };

  login = async ({ email, password }: { email: string; password: string }) => {
    try {
      const response = await apiEndpoints.login({ email, password });
      const data = await response.json();

      // Return errors if there are any
      if (response.ok) {
        localStorage.setItem("token", data.key);
        this.getUser();
        return {};
      } else {
        return data;
      }
    } catch (err) {
      console.error(err);
    }
  };

  logout = () => {
    this.setState(defaultState);
    localStorage.removeItem("sudoToken");
    localStorage.removeItem("token");
    localStorage.removeItem("productsSelected");
    localStorage.removeItem("demoPurchaseOrder");
  };

  isDemo = () => this.state.data.account.company.status.toUpperCase() === "DEMO";

  isAppInstalled = () =>
    this.state.data.account.company.status.toUpperCase() === consts.STATUS_APP_INSTALLED;

  isOnboarded = () => this.state.data.account.company.status.toUpperCase() === "ONBOARDED";

  setStore = (store: IUserStore) => {
    this.setState((prevState) => ({ ...prevState, store }));
  };

  render() {
    return (
      <UserContext.Provider
        value={
          {
            ...this.state,
            saveAccount: this.saveAccount,
            changePassword: this.changePassword,
            login: this.login,
            logout: this.logout,
            refreshUser: this.refreshUser,
            getUser: this.getUser,
            getSudo: this.getSudo,
            setSudo: this.setSudo,
            exitSudo: this.exitSudo,
            isSalesDemo: this.isSalesDemo,
            isDemo: this.isDemo,
            isAppInstalled: this.isAppInstalled,
            isOnboarded: this.isOnboarded,
            trackWithSegment: this.trackWithSegment,
            setStore: this.setStore,
          } as IUserStore
        }
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
