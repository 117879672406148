import React, { useEffect, useState, useContext } from "react";
import WarehouseSelectionSection from "./WarehouseSelectionSection";
import CarrierDetailsSection, { useCarrierDetails } from "./CarrierDetailsSection";

const DEFAULT_STATE = {
  isFreight: false, // initial value "", set value true/false
  selectedWarehouse: "", // initial value "", set value some object
  assignedReceivingAddress: "", // initial value "", set value some object
  warehouses: [],
  storeAssignedReceivings: [],
};

export const useShippingDetailsSection = (inputState = {}, inboundOrderState) => {
  const [shippingDetailsState, setShippingDetailsState] = useState({
    ...DEFAULT_STATE,
    isFreight: inputState?.shippingDetailsState?.isFreight || false,
    selectedWarehouse: inputState?.shippingDetailsState?.selectedWarehouse,
    assignedReceivingAddress: inputState?.shippingDetailsState?.assignedReceivingAddress,
  });
  const carrierDetails = useCarrierDetails(inputState?.carrierDetails || {}, inboundOrderState);

  const loadShippingDetailsState = (loadedState) => {
    setShippingDetailsState((prevState) => ({
      ...prevState,
      ...loadedState,
    }));
  };

  const getSelectedWarehouse = () => shippingDetailsState.selectedWarehouse;
  const getAssignedReceivingAddress = () => shippingDetailsState.assignedReceivingAddress;
  const getIsFreight = () => shippingDetailsState.isFreight;

  const setCarrierType = (carrierType) => {
    if (carrierType === "freight") {
      setShippingDetailsState((prevState) => ({
        ...prevState,
        isFreight: true,
      }));
    } else {
      setShippingDetailsState((prevState) => ({
        ...prevState,
        isFreight: false,
      }));

      // clear any existing freight details to avoid serializing freight details for a parcel shipment
      carrierDetails.clearFreightDetails();
    }
  };

  const setSelectedWarehouse = (warehouse) => {
    setShippingDetailsState((prevState) => ({
      ...prevState,
      selectedWarehouse: warehouse,
    }));
  };

  const setWarehouses = (warehouses) => {
    setShippingDetailsState((prevState) => ({
      ...prevState,
      warehouses,
    }));
  };

  const setStoreAssignedReceivings = (storeAssignedReceivings) => {
    setShippingDetailsState((prevState) => ({
      ...prevState,
      storeAssignedReceivings,
    }));
  };

  const setAssignedReceivingAddress = () => {
    const { isFreight, selectedWarehouse, storeAssignedReceivings } = shippingDetailsState;

    if (isFreight === null || !selectedWarehouse || !storeAssignedReceivings) {
      return;
    }

    const { receivings: warehouseReceivingAddresses } = selectedWarehouse;
    if (warehouseReceivingAddresses.length == 0 || storeAssignedReceivings.length == 0) {
      return;
    }

    // filter warehouseReceivingAddresses by storeAssignedReceivings
    const receivingAddresses = _.intersectionBy(
      warehouseReceivingAddresses,
      storeAssignedReceivings,
      "uuid"
    );

    let assignedReceivingAddress = null;

    if (receivingAddresses.length === 1) {
      assignedReceivingAddress = receivingAddresses[0];
    } else if (receivingAddresses.length > 1) {
      if (isFreight && receivingAddresses.some((address) => address.is_freight_address)) {
        assignedReceivingAddress = receivingAddresses.find((address) => address.is_freight_address);
      } else if (!isFreight && receivingAddresses.some((address) => !address.is_freight_address)) {
        assignedReceivingAddress = receivingAddresses.find(
          (address) => !address.is_freight_address
        );
      } else {
        assignedReceivingAddress = receivingAddresses[0];
      }
    }
    setShippingDetailsState((prevState) => ({
      ...prevState,
      assignedReceivingAddress,
    }));
  };

  const isWarehouseMissing = () => {
    return (
      shippingDetailsState.selectedWarehouse === undefined ||
      shippingDetailsState.selectedWarehouse === null ||
      shippingDetailsState.selectedWarehouse === ""
    );
  };

  return {
    shippingDetailsState,
    carrierDetails,
    getSelectedWarehouse,
    getAssignedReceivingAddress,
    getIsFreight,
    setCarrierType,
    setSelectedWarehouse,
    setWarehouses,
    setStoreAssignedReceivings,
    setAssignedReceivingAddress,
    loadShippingDetailsState,
    isWarehouseMissing,
  };
};

const ShippingDetailsSection = (props) => {
  const {
    shippingDetailsState,
    setCarrierType,
    setSelectedWarehouse,
    setWarehouses,
    setStoreAssignedReceivings,
    setAssignedReceivingAddress,
    carrierDetails,
    carrierDetailsId,
    hasContainer,
    store,
    isEditing,
    errors,
    name,
  } = props;

  const { isFreight, selectedWarehouse, storeAssignedReceivings } = shippingDetailsState;

  // on first render, set receiving addresses and warehouses
  useEffect(() => {
    if (store) {
      const { warehouses, receivings } = store;
      setWarehouses(warehouses || []);
      setStoreAssignedReceivings(receivings || []);

      // automatically select the warehouse if there's only one
      if (warehouses && warehouses.length == 1) {
        setSelectedWarehouse(warehouses[0]);
      }
    }
  }, [store]);

  // on change of isFreight or selectedWarehouse, update assignedReceivingAddress
  useEffect(() => {
    setAssignedReceivingAddress();
  }, [isFreight, selectedWarehouse, storeAssignedReceivings]);

  return (
    <>
      <WarehouseSelectionSection
        {...shippingDetailsState}
        setSelectedWarehouse={setSelectedWarehouse}
        isEditing={isEditing}
        errors={errors}
      />
      <CarrierDetailsSection
        {...shippingDetailsState}
        {...carrierDetails}
        name={name}
        setCarrierType={setCarrierType}
        scrollId={carrierDetailsId}
        hasContainer={hasContainer}
        isEditing={isEditing}
        errors={errors}
      />
    </>
  );
};

export default ShippingDetailsSection;
