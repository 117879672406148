import _ from "lodash";
import { isPostworks } from "../authentication";
import consts from "../consts";
import orderStatus, {
  getRemovedVariantIds,
  isFulfillmentDelayed,
  isItemOutOfStock
} from "./orderStatus";

/* COMPARATORS

The comparator function takes two parameters.

*/

// The first parameter is a JavaScript Date object for the selected date in the filter
// (with the time set to midnight).
// The second parameter is the current value of the cell in the row being evaluated.
// The function must return:
//   - Any number < 0 if the cell value is less than the filter date.
//   - 0 if the dates are the same.
//   - Any number > 0 if the cell value is greater than the filter date.

export const dateComparator = (filterLocalDateMidnight, cellValue) => {
  const cellDateTime = new Date(cellValue);
  if (cellDateTime < filterLocalDateMidnight) {
    return -1;
  }
  if (cellDateTime > filterLocalDateMidnight) {
    return 1;
  }
  return 0;
};

/* CELL RENDERERS

By default the grid renders values into the cells as strings.
If you want something more complex you use a cell renderer.

*/

// Show checkmark for boolean values
export const checkboxBoolRenderer = (params) =>
  `<input type="checkbox" disabled ${params.value ? "checked" : ""} />`;

/* Order */

export const orderStatusRenderer = (params) => {
  if (!params.value) return null;

  const status = orderStatus.getDisplayName(params.value);
  const classes = orderStatus.getClasses(params.value);
  // const delayed = orderStatus.isOrderDelayed(params.data);
  // const outOfStock = orderStatus.isOrderOutOfStock(params.data);

  let markup = `<span class="${classes.join(" ")}">${status}</span>`;
  let gap = 0;

  if (params.data.webhook_lock && params.data.type !== "PURCHASE") {
    markup += `
      <span class="has-text-danger tooltip is-tooltip-left fixed" style="margin-left: ${gap}px" data-tooltip="Edited (Not Synced)">
        <i class="fas fa-unlink"></i>
      </span>
      `;
    gap += 15;
  }

  // if (delayed) {
  //   markup += `
  //     <span class="has-text-warning tooltip is-tooltip-left fixed" style="margin-left: ${gap}px" data-tooltip="Delayed">
  //       <i class="icon-ic-dates"></i>
  //     </span>
  //     `;
  //   gap += 15;
  // }

  // if (outOfStock) {
  //   markup += `
  //     <span class="has-text-danger tooltip is-tooltip-left fixed" style="margin-left: ${gap}px" data-tooltip="Out of Stock">
  //       <i class="icon-ic-warning"></i>
  //     </span>
  //     `;
  // }

  return !!status && markup;
};

export const returnOrderStatusRenderer = (params) => {
  if (!params.value) return null;

  const status = orderStatus.getDisplayName(params.value);
  const classes = [...orderStatus.getClasses(params.value)];

  const hasDamagedItems =
    params.data.return_items.filter((item) => item.damaged_count > 0).length >
    0;

  if (hasDamagedItems) {
    const isSuccessIndex = classes.indexOf("is-success-outlined");
    if (isSuccessIndex !== -1) {
      classes.splice(isSuccessIndex, 1);
    }
    classes.push("is-danger");
  }

  const statusText = hasDamagedItems ? `${status} - Damaged` : status;

  const markup = `<span class="${classes.join(" ")}">${statusText}</span>`;

  return !!status && markup;
};

export const returnItemsRenderer = (params) =>
  [...new Set(params.data.return_items.map((item) => item.return_reason))]
    .map(
      (returnReason) =>
        `<span class="tag is-rounded is-success tooltip is-tooltip-right" data-tooltip="Fulfilled">${returnReason}</span>`
    )
    .join("");

// Instead of string, encapsulate items in tags with quantity
export const fulfilledSkusRenderer = (params) => {
  return (
    `${fulfilledItemsRenderer(params)} ${pendingItemsRenderer(
      params
    )} ${externalItemsRenderer(params)}` || null
  );
};

// Same idea as above
export const barcodesRenderer = (params) =>
  `${fulfilledItemsRenderer(params, "barcode")} ${pendingItemsRenderer(
    params,
    "barcode"
  )} ${externalItemsRenderer(params, "barcode")}` || null;

// Shared

export const dashNullRenderer = (params) => {
  // Not quite falsy - only render dash if null or empty string
  return params.value == null || params.value === ""
    ? '<span class="has-text-grey-light">&mdash;</span>'
    : params.value;
};

/* VALUE FORMATTERS

  Value formatters allow you to format values for display.
  This is useful when data is one type (e.g. numeric)
  but needs to be converted for human reading
  (e.g. putting in currency symbols and number formatting).

  */

export const fulfilledSkusFormatter = (params) =>
  _.flatten(
    [
      fulfilledItemsFormatter(params),
      pendingItemsFormatter(params),
      externalItemsFormatter(params),
    ].filter((skus) => !!skus)
  );

export const barcodesFormatter = (params) =>
  _.flatten(
    [
      fulfilledItemsFormatter(params, "barcode"),
      pendingItemsFormatter(params, "barcode"),
      externalItemsFormatter(params, "barcode"),
    ].filter((skus) => !!skus)
  );

/* VALUE GETTERS

  Normally columns are configured with field attributes so the column knows what field to take values from in the data.
  Instead of providing a field it is possible to provide a valueGetter instead.
  A Value Getter is a a function that gets called allowing values
  to be pulled from literally anywhere, including executing any expressions you wish along the way.

  */

/* Order */
export const storeGetter = (params, stores) => {
  const storeId = params.data?.store || params.data?.store_id;

  if (!storeId) return `-`;

  const store = _.find(stores, (store) => store.uuid === storeId);
  if (store) return store.name;
};

// Round value to at most 2 decimal places
export const roundedDecimalGetter = (value) => {
  if (value == null) {
    return 0;
  }
  return Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100;
};

/* Internal helpers

  Export only for usage in tests.

  */

const fulfilledItemsRenderer = (params, warehouseIdentifier) =>
  !!fulfilledItemsGetter(params) &&
  fulfilledItemsGetter(params)
    .map((items) =>
      items
        .map(
          (item) =>
            `${(item.isDelayed
              ? '<span class="tag is-rounded is-pending tooltip is-tooltip-right" data-tooltip="Delayed">'
              : '<span class="tag is-rounded is-success tooltip is-tooltip-right" data-tooltip="Fulfilled">') +
            (item.variant[warehouseIdentifier || "sku"] || "&mdash;")
            } ` + `<span class="qty-count">x ${item.count}</span></span>`
        )
        .join(" ")
    )
    .join("");

const pendingItemsRenderer = (params, warehouseIdentifier) =>
  !!pendingItemsGetter(params) &&
  pendingItemsGetter(params)
    .map(
      (item) =>
        `${(item.isRemoved
          ? '<span class="tag is-rounded is-removed-outlined tooltip is-tooltip-right" data-tooltip="Removed">'
          : item.isOutOfStock
            ? '<span class="tag is-rounded is-danger tooltip is-tooltip-right" data-tooltip="Out of Stock">'
            : '<span class="tag is-rounded is-pending-outlined tooltip is-tooltip-right" data-tooltip="Pending">') +
        (item.variant[warehouseIdentifier || "sku"] || "&mdash;")
        } ` + `<span class="qty-count">x ${item.count}</span></span>`
    )
    .join(" ");

const externalItemsRenderer = (params, warehouseIdentifier) =>
  !!externalItemsGetter(params) &&
  externalItemsGetter(params)
    .map(
      (item) =>
        `<span class="tag is-rounded is-disabled tooltip is-tooltip-right" data-tooltip="Not fulfilled through ${isPostworks() ? "Postworks" : "Airhouse"
        }">${item.variant[warehouseIdentifier || "sku"] || "&mdash;"} ` +
        `<span class="qty-count">x ${item.count}</span></span>`
    )
    .join(" ");

const externalItemsGetter = (params) => {
  if (!params.data || !params.data.items) return null;

  const aleadyFulfilledItemIds = _.flatten(
    params.data.fulfillments
      .filter((fulfillment) => fulfillment.process !== "READ")
      .map((fulfillment) => fulfillment.items.map((item) => item.uuid))
  );

  return (
    params.data.items
      // Not flagged to be fulfilled by Airhouse
      .filter((item) => !item.variant.should_fulfill)
      // Not already fulfilled by Airhouse
      .filter(
        (item) =>
          !_.find(
            aleadyFulfilledItemIds,
            (item_uuid) => item_uuid === item.uuid
          )
      )
  );
};

// Iterate over array
export const fulfilledItemsGetter = (params) => {
  if (!params.data) return null;

  const externalItems = externalItemsGetter(params);

  return params.data.fulfillments.map((fulfillment) =>
    fulfillment.items
      // Exclude items we didn't/don't fulfill
      .filter(
        (item) =>
          !_.find(
            externalItems,
            (externalItem) => externalItem.uuid === item.uuid
          )
      )
      // Hydrate item
      .map((fulfillmentItem) => ({
        ..._.find(
          params.data.items,
          (item) => item.uuid === fulfillmentItem.uuid
        ),
        isDelayed: isFulfillmentDelayed(fulfillment),
      }))
  );
};

export const pendingItemsGetter = (params) => {
  if (!params.data) return null;

  const fulfilledItems = _.flatten(fulfilledItemsGetter(params));
  const removedVariantIds = getRemovedVariantIds(params.data);
  const isCanceledOrder =
    params.data.combined_status === consts.STATUS_CANCELLED;

  return (
    params.data.items
      // Only items we intend to fulfill
      .filter((item) => item.variant.should_fulfill)
      // Exclude items already fulfilled
      .filter(
        (item) =>
          !_.find(
            fulfilledItems,
            (fulfilledItem) => fulfilledItem.uuid === item.uuid
          )
      )
      .map((item) => {
        const isRemoved = removedVariantIds.indexOf(item.variant.uuid) !== -1;
        return {
          ...item,
          isRemoved: isRemoved || isCanceledOrder,
          isOutOfStock: !isRemoved && isItemOutOfStock(item),
        };
      })
  );
};

// Iterate over array
const fulfilledItemsFormatter = (params, warehouseIdentifier) =>
  !!fulfilledItemsGetter(params) &&
  fulfilledItemsGetter(params).map((items) =>
    items
      .map(
        (item) => `${item.variant[warehouseIdentifier || "sku"]},${item.count}`
      )
      .join("\n")
  );

export const pendingItemsFormatter = (params, warehouseIdentifier) =>
  !!pendingItemsGetter(params) &&
  pendingItemsGetter(params)
    .map(
      (item) => `${item.variant[warehouseIdentifier || "sku"]},${item.count}`
    )
    .join("\n");

const externalItemsFormatter = (params, warehouseIdentifier) =>
  !!externalItemsGetter(params) &&
  externalItemsGetter(params)
    .map(
      (item) => `${item.variant[warehouseIdentifier || "sku"]},${item.count}`
    )
    .join("\n");
