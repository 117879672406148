import OrderSearchBar, { OrderListItem } from "../../Orders/OrderSearchBar";
import React from "react";
import { dateFormatter } from "../../../../utils/general";
import { RemoveItemButton } from "../../../reusable/page/buttons/RemoveItemButton";
import { Link } from "react-router-dom";
import OrderRangeInput from "./OrderRangeInput";

export const SelectOrders = (props) => {
  const { orders, addOrder, removeOrder, store } = props;

  const onSelectOrder = (order) => {
    addOrder(order);
  };

  const filters = {
    items: orders,
  };

  return (
    <>
      <OrderSearchBar
        onSelectItem={onSelectOrder}
        filters={filters}
        store={store}
        {...props.orderSearchBarState}
      />
      <OrderRangeInput {...props} />
      <OrderList orders={orders} onDelete={removeOrder} />
    </>
  );
};

export const OrderList = (props) => {
  const { orders, onDelete } = props;
  return (
    <table>
      <tbody>
        {orders.map((order) => (
          <tr
            key={order.uuid}
            className="table-body"
            style={{
              width: "100%",
              fontVariantNumeric: "tabular-nums",
            }}
          >
            <td className="has-text-left">
              <p>
                <strong>
                  <Link to={`/orders/edit/${order.uuid}`}>{order.name}</Link>
                </strong>
              </p>
              <p className="has-text-grey">Created At: {dateFormatter(order.created_at)}</p>
              <p className="has-text-grey">
                {order.ship_date ? "Shipped At: " + dateFormatter(order.ship_date) : ""}
              </p>
            </td>
            <td>
              <span>{order.customer?.name} </span>
              {!!order.shipping ? (
                <>
                  <p>
                    {" "}
                    {order?.customer?.first_name} {order?.customer?.last_name}{" "}
                  </p>
                  <p>
                    {order.shipping.address1} {order.shipping.address2}{" "}
                  </p>
                  <p>
                    {" "}
                    {order.shipping.province_code} {order.shipping.zipcode},{" "}
                    {order.shipping.country_code}{" "}
                  </p>
                </>
              ) : (
                ""
              )}
            </td>
            {onDelete && (
              <td>
                <RemoveItemButton
                  onClick={() => {
                    onDelete(order);
                  }}
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
