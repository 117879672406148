import React, { memo, useState } from "react";
import { Modal } from "react-bulma-components";
import { usStreet } from "smartystreets-javascript-sdk";
import { MultipleAddresStyles } from "../config/recipientModal.styles";
import { IRecipientShipping } from "../../../../../../interfaces/shipping.interfaces";

interface IMultipleAddressModal {
  shipping: IRecipientShipping;
  candidates: usStreet.Candidate[];
  keepOriginalAddress: () => void;
  setSelectCandidate: (candidate: usStreet.Candidate) => void;
  replaceCandidate: () => void;
}
const MultipleAddressModal: React.FC<IMultipleAddressModal> = ({
  shipping,
  candidates,
  keepOriginalAddress,
  setSelectCandidate,
  replaceCandidate,
}) => {
  const [activeCandidateIndex, setActiveCandidateIndex] = useState<number | null>(null);

  const editOriginalAddress = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    keepOriginalAddress();
  };

  const selectCandidate = (candidate: usStreet.Candidate, i: number) => {
    setSelectCandidate(candidate);
    setActiveCandidateIndex(i);
  };

  const clickConfirm = () => {
    activeCandidateIndex !== null ? replaceCandidate() : keepOriginalAddress();
  };

  return (
    <Modal.Card className="modal-card is-wide">
      <Modal.Card.Header>
        <Modal.Card.Title>Address Verification</Modal.Card.Title>
      </Modal.Card.Header>
      <Modal.Card.Body className="is-flex-direction-column">
        <div className="pt-2 pb-5">
          <p>
            The address you entered matches several addresses. Would you like to use a suggested
            address?
          </p>
          <div className="tile is-ancestor mx-2">
            <div className="tile is-parent">
              <div
                className="tile is-child box is-shadowless"
                style={MultipleAddresStyles.originalAddressTile}
              >
                <label className="radio">
                  <input
                    type="radio"
                    name="candidates"
                    style={MultipleAddresStyles.radioInputLine}
                    onChange={() => setActiveCandidateIndex(null)}
                  />
                  <span className="has-text-grey-light">Original address:</span>
                  <a className="ml-5" href="#0" onClick={editOriginalAddress}>
                    edit
                  </a>
                  <div className="my-1 mt-2" style={MultipleAddresStyles.addressLines}>
                    {shipping.address1} {shipping.address2}
                  </div>
                  <div style={MultipleAddresStyles.addressLines}>
                    {shipping.city} {shipping.province_code} {shipping.zipcode}
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="tile is-ancestor mx-2">
            {candidates.map((candidate, i) => (
              <div className="tile is-parent" key={`${candidate.deliveryLine1}-${i}`}>
                <div
                  className="tile is-child box is-shadowless"
                  style={
                    activeCandidateIndex === i ? MultipleAddresStyles.candidateClicked : undefined
                  }
                >
                  <label className="radio">
                    <input
                      type="radio"
                      name="candidates"
                      style={MultipleAddresStyles.radioInputLine}
                      onChange={() => selectCandidate(candidate, i)}
                    />
                    <span className="has-text-grey-light">Suggested address:</span>
                    <div className="my-1 mt-2" style={MultipleAddresStyles.addressLines}>
                      {candidate.deliveryLine1} {candidate.deliveryLine2}
                    </div>
                    <div style={MultipleAddresStyles.addressLines}>{candidate.lastLine}</div>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal.Card.Body>
      <Modal.Card.Footer>
        <button className="button is-text" type="submit" onClick={editOriginalAddress}>
          Back
        </button>
        <button className="button is-primary" type="submit" onClick={clickConfirm}>
          Confirm
        </button>
      </Modal.Card.Footer>
    </Modal.Card>
  );
};

export default memo(MultipleAddressModal);
