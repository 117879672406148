import React, { useCallback, useState } from "react";
import { Level } from "react-bulma-components";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { dateFormatter, dateTimeFormatter } from "./ProjectRequestView";
import { ProductRequestStatusTag } from "./ProductRequestStatusTag";
import { Link } from "react-router-dom";
import useDebounce from "../../../../utils/hooks/useDebounce";
import { listProjectRequest } from "../../../../utils/gateway/ProjectRequestAPIGateway";

export const ProjectRequestGridFilters = (props) => {
  return (
    <Level className="filter-bar is-marginless">
      <span className="is-hidden-mobile">
        <div className="field mb-0" />
      </span>
      <span className="is-flex">
        <div className="control has-icons-left">
          <input
            className="input"
            type="text"
            placeholder="Search Projects"
            value={props.searchText}
            onChange={props.onChange}
            disabled={props.isLoading}
          />
          <span className="icon is-small is-left">
            <i className="icon-ic-search" />
          </span>
        </div>
      </span>
    </Level>
  );
};

export const gridDateFormatter = (date) => {
  return (date.value)?dateFormatter(date.value):'-';
}

export const reduceComponentArray = (previousValue, currentValue) => {
  if (!previousValue) {
    return <>{currentValue}</>
  }
  return <>{previousValue}, {currentValue}</>
}

export const ProjectRequestStatusTagRenderer = (props) => {
  const cellValue = props.valueFormatted? props.valueFormatted: props.value;
  return <ProductRequestStatusTag status={cellValue} />;
  ;
}

export const ProjectOutboundOrdersRenderer = (params) => {
  const orders = params.value;
  return orders
    .map((order) => <Link to={`/orders/edit/${order.uuid}`}>{order.name}</Link>)
    .reduce(reduceComponentArray, "")
}

export const ProjectInboundOrdersRenderer = (params) => {
  const restocks = params.value;
  return restocks
    .map((restock) => <Link to={`/restock/view/${restock.uuid}`}>{restock.name}</Link>)
    .reduce(reduceComponentArray, "");
}

export const gridDateTimeFormatter = (dateTime) => {
  return (dateTime.value)? dateTimeFormatter(dateTime.value):'-';
}
export const ProjectRequestGrid = (props) => {

  const { onCellClicked } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const gridRef = React.useRef();

  const externalStyles = {
    grid: {
      height: 'calc(100vh - 298px)',
      width: '100%',
    }
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value.slice(0, 50));
  };
  const onGridReady = useCallback((params) => {}, []);

  const datasource = () => {
    return {
      getRows: async (params) => {
        const options = {
          // sortModel: params.request.sortModel,
          // filterModel: this.gridApi.getFilterModel(),
          search: encodeURIComponent(debouncedSearchTerm),
          offset: params.request.startRow,
        };

        const data = await listProjectRequest(options);
        params.success({
          rowData: data.results,
          rowCount: data.count
        });
      },
    };
  };

  return (
    <div className="ag-theme-balham" style={externalStyles.grid}>
      <ProjectRequestGridFilters
        value={searchTerm}
        onChange={handleSearchTermChange}
      />
      <AgGridReact
        ref={gridRef}
        suppressNoRowsOverlay
        suppressCellSelection
        enableSorting="true"
        enableFilter="true"
        pagination
        paginationPageSize={50}
        onCellClicked={onCellClicked}
        rowStyle={{ cursor: "pointer", padding: "1rem" }}
        rowClassRules={{
          "is-fresh": (params) => !!params.data && !!params.data?.isFresh,
          "is-pending": (params) => !!params.data && !!params.data?.isPending,
        }}
        getRowHeight={(params) => 60}
        cacheBlockSize={100}
        defaultColDef={{
          flex: 1,
          resizable: true,
          headerComponentParams: {
            menuIcon: "fa-bars",
          },
        }}
        sideBar={{
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Columns",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
              },
            },
          ],
        }}
        onGridReady={onGridReady}
        serverSideDatasource={datasource()}
        rowModelType="serverSide"
        serverSideStoreType="partial"
        getContextMenuItems={(params) => [
          {
            name: 'Open in new tab',
            action: () => window.open(`${window.location.origin}/projects/edit/${params.node.data.uuid}/`, '_blank'),
            icon: '<span class="icon-ic-open"></span>',
          },
          'copy',
        ]}
        >
        <AgGridColumn
          field='name'
          filter='agTextColumnFilter'
          headerName='Project Request'
        />
        <AgGridColumn
          field='projectRequestType.title'
          filter='agTextColumnFilter'
          headerName='Project Type'
          initialHide={true}
        />
        <AgGridColumn
          field='restocks'
          filter='agTextColumnFilter'
          headerName='Inbound'
          cellRendererFramework={ProjectInboundOrdersRenderer}
        />
        <AgGridColumn
          field='orders'
          filter='agTextColumnFilter'
          headerName='Outbound'
          cellRendererFramework={ProjectOutboundOrdersRenderer}
        />
        <AgGridColumn
          field='status'
          cellRendererFramework={ProjectRequestStatusTagRenderer}
          filter='agTextColumnFilter'
          headerName='Status'
        />
        <AgGridColumn
          field='billing.regularHours'
          filter='agTextColumnFilter'
          headerName='Regular Labor'
        />
        <AgGridColumn
          field='createdAt'
          filter='agTextColumnFilter'
          headerName='Request Date'
          valueFormatter={gridDateTimeFormatter}
        />
        <AgGridColumn
          field='completedDate'
          filter='agTextColumnFilter'
          headerName='Completed Date'
          valueFormatter={gridDateFormatter}
        />
      </AgGridReact>
    </div>
  );
};

export default ProjectRequestGrid;