import React from "react";
import { Columns } from "react-bulma-components";
import { SelectWarehouse } from "../../../reusable/SelectWarehouse";

export const SelectWarehouseSection = (props) => {
  return (
    <Columns className="section-container">
      <Columns.Column size={3}>
        <p
          data-testid="upload-document-subheading"
          className={"title is-4 mb-4"}
        >
          Warehouse
        </p>
        <p>Select the warehouse associated with this project.
        </p>
      </Columns.Column>
      <Columns.Column>
        <SelectWarehouse {...props} />
      </Columns.Column>
    </Columns>
  );
};

export const SelectWarehouseInlineSection = (props) => (
  <div className="section-container">
    <h5 className="title is-5 mb-4">Warehouse</h5>
    <p className={"mb-4"}>Select the warehouse associated with this project.
    </p>
    <SelectWarehouse
      {...props}
    />
  </div>
);
