import { toTitleCase } from './orderStatus.js';


const tagClasses = ["tag", "is-rounded"];


export const statusMap = {
  'PAID': {
    displayName: 'Paid',
    filterable: true,
    classes: [...tagClasses, 'pill-green'],
  },
  'PARTIALLY_PAID': {
    displayName: 'Partially Paid',
    filterable: true,
    classes: [...tagClasses, 'pill-yellow'],
  },
  'PENDING': {
    displayName: 'Pending',
    filterable: true,
    classes: [...tagClasses, 'pill-yellow'],
  },
  'AUTHORIZED': {
    displayName: 'Authorized',
    filterable: true,
    classes: [...tagClasses, 'pill-blue'],
  },
  'REFUNDED': {
    displayName: 'Refunded',
    filterable: true,
    classes: [...tagClasses, 'pill-red'],
  },
  'PARTIALLY_REFUNDED': {
    displayName: 'Partially Refunded',
    filterable: true,
    classes: [...tagClasses, 'pill-blue'],
  },
  'VOIDED': {
    displayName: 'Voided',
    filterable: true,
    classes: [...tagClasses, 'pill-red'],
  },
}

export const getDisplayName = status =>
  statusMap[status.trim().toUpperCase()]
    ? statusMap[status.trim().toUpperCase()].displayName
    : toTitleCase(status);

export const getClasses = status =>
  statusMap[status.trim().toUpperCase()]
    ? statusMap[status.trim().toUpperCase()].classes
    : ['tag', 'is-rounded'];

export default {
  getDisplayName,
  getClasses,
  statusMap,
}