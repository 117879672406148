import {
  PAGE_TITLE as INBOUND_ORDER_PAGE_TITLE,
  PAGE_URL as INBOUND_ORDER_PAGE_URL,
  PAGE_TAB as INBOUND_ORDER_TAB,
} from "./InboundOrderHomePage";
import { Link, useHistory } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import {
  getInboundOrder,
  updateInboundOrder,
  deleteInboundOrder,
} from "../../../utils/gateway/InboundOrderAPIGateway";

import { PAGE_URL as HOME_PAGE_URL } from "./InboundOrderHomePage";
import { PAGE_URL as CREATE_PAGE_URL } from "./CreateInboundOrderPage";
import InboundOrderForm from "./components/InboundOrderForm";
import NavBar from "../../NavBar";
import { OrderContext } from "../../contexts/OrderStore";
import PageBody from "../../reusable/page/PageBody";
import PageFooter from "../../reusable/page/PageFooter";
import PageHeader from "../../reusable/page/PageHeader";
import { PageLoadingBar } from "../../reusable/page/loaders/PageLoadingBar";
import { Button } from "../../reusable/page/buttons/Button";
import { convertProjectRequestStateToPayload } from "../../../utils/gateway/ProjectRequestAPIGateway";
import { useEditInboundOrderPage } from "./useEditInboundOrderPage";
import consts from "../../../utils/consts";
import { toast, Slide } from "react-toastify";
import { InboundOrderSubtitle, InboundOrderTitle } from "./components/InboundOrderSubtitle";
import { AdminControl } from "../../reusable/page/menu/AdminControl";
import { ViewInAdmin } from "../../reusable/page/menu/ViewInAdmin";
import { isSudoing } from "../../../utils/authentication";
import apiEndpoints from "../../../utils/apiEndpoints";


export const ADMIN_URL = `${apiEndpoints.API_BASE}admin/restock/inboundorder/`;
export const PAGE_URL = "/inbound/edit/:inboundOrderId";
export const DEFAULT_PAGE_TITLE = "Edit Inventory Shipment";

export const EditInboundOrderPage = (props) => {
  const history = useHistory();
  const isAdmin = isSudoing();

  const { inboundOrderId } = props.match.params;

  const { orderIsLoaded, pageState, formState, loadInboundOrder, setIsLoading, setIsEditing } =
    useEditInboundOrderPage();

  const { isEditing, isSaving, isLoading } = pageState;
  const isSaveDisabled = isSaving || isLoading;
  const { inboundOrderState, isInboundOrderFormValid, clearErrors } = formState;
  const pageTitle = `${DEFAULT_PAGE_TITLE} ${inboundOrderState?.name}`;
  const { store, status, createdAt, uuid: itemUUID } = inboundOrderState;

  // If this order has already been submitted
  if (isEditing && status !== consts.STATUS_DRAFT) {
    setIsEditing(false);
  }

  useEffect(() => {
    getInboundOrder(inboundOrderId)
      .then(loadInboundOrder)
      .then(() => setIsLoading(false));
    // console.log("formState", formState);
  }, []);

  const handleUpdateInboundOrder = (status) => {
    if (status === consts.STATUS_CREATED && !isInboundOrderFormValid()) {
      toast.error("Please fill out all required information before submitting. (dismiss)", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        transition: Slide,
      });
      return;
    }

    // In the case where the user makes changes and saves a draft, clear any outstanding errors.
    clearErrors();

    const { projectRequestTypeState, projectRequestFormState } = formState;
    const projectRequest = projectRequestTypeState.currentItem.uuid
      ? convertProjectRequestStateToPayload(
          { projectRequestTypeState, ...projectRequestFormState },
          store
        )
      : null;
    formState.projectRequest = projectRequest;

    const noExistingProjectRequest = formState.inboundOrderState.projectRequests.length === 0;
    const projectRequestAdded = noExistingProjectRequest && projectRequest !== null;

    updateInboundOrder(formState.inboundOrderState.uuid, formState, status)
      .then((resp) => {
        // ensure project requests are updated with the server response so we can disable further (unsupported) editing
        const { inbound_projects } = resp;
        formState.setProjectRequests((inbound_projects && inbound_projects) || []);
        if (status === consts.STATUS_CREATED) {
          toast.success("Created", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            transition: Slide,
          });
          history.push(HOME_PAGE_URL);
        } else {
          toast.success("Saved", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            transition: Slide,
          });
        }
      })
      .catch(() => {
        toast.error(
          "We were unable to save your changes. Please reach out to team@airhouse.io for assistance. (dismiss)",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            transition: Slide,
          }
        );
      });
  };

  const handleDeleteInboundOrder = () => {
    toast.success("Deleting...", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      transition: Slide,
    });

    // Make redirect play nice with toast
    setTimeout(function () {
      deleteInboundOrder(formState.inboundOrderState.uuid)
        .then(history.push(HOME_PAGE_URL))
        .then(history.go());
    }, 1000);
  };

  const removeTracking = (state) => {
    state.formState.deliveryDetailsState.shippingDetails.carrierDetails.trackingNumbersState.trackingNumbers =
      [];
  };

  const resetPallets = (state) => {
    const oldPallets =
      state.formState.deliveryDetailsState.shippingDetails.carrierDetails.freightDetailsAirhouse
        .freightDetailsAirhouseState.pallets;
    const newPallets = oldPallets.map(({ uuid, ...keepers }) => keepers);
    state.formState.deliveryDetailsState.shippingDetails.carrierDetails.freightDetailsAirhouse.freightDetailsAirhouseState.pallets =
      newPallets;
  };

  const onDuplicateButtonClick = () => {
    const duplicatedState = JSON.parse(
      JSON.stringify({
        formState,
      })
    );

    // cleanup duplicated state removing tracking numbers and resetting pallets
    removeTracking(duplicatedState);
    resetPallets(duplicatedState);

    history.push({
      pathname: CREATE_PAGE_URL,
      state: duplicatedState,
    });
  };

  return (
    <>
      <NavBar {...props} activeTab={INBOUND_ORDER_TAB} />
      {isLoading && <PageLoadingBar />}
      {!isLoading && (
        <>
          <div className="container is-max-desktop max-width">
            <PageHeader
              title={<InboundOrderTitle title={pageTitle} />}
              returnLinkURL={INBOUND_ORDER_PAGE_URL}
              returnLinkText={INBOUND_ORDER_PAGE_TITLE}
              subtitle={<InboundOrderSubtitle status={status} createdAt={createdAt} showLinkToOldRestock={false} />}
              menu={<HeaderMenu onDuplicateButtonClick={onDuplicateButtonClick} isAdmin={props.data.isAdmin}/>}
              isAdmin={isAdmin}
              adminMenu={<AdminControl options={<><ViewInAdmin url={`${ADMIN_URL}${itemUUID}`} /></>} />}
            />

            <PageBody>
              <InboundOrderForm {...formState} isEditing={isEditing} />
            </PageBody>

            <PageFooter>
              {(status === consts.STATUS_DRAFT && props.data.isAdmin) && (
                <>
                  <Button
                    onClick={handleDeleteInboundOrder}
                    isLoading={isSaving}
                    isPrimary={false}
                    text="Delete"
                  />
                  <Button
                    onClick={() => handleUpdateInboundOrder(consts.STATUS_DRAFT)}
                    isLoading={isSaving}
                    isDisabled={isSaveDisabled}
                    isPrimary={true}
                    text="Save"
                  />
                  <Button
                    onClick={() => handleUpdateInboundOrder(consts.STATUS_CREATED)}
                    isLoading={isSaving}
                    isDisabled={isSaveDisabled}
                    isPrimary={false}
                    text="Submit"
                  />
                </>
              )}
            </PageFooter>
          </div>
        </>
      )}
    </>
  );
};

const DuplicateButton = (props) => {
  const { isEnabled } = props;
  const duplicateIcon = (
    <>
      <i className="icon-ic-duplicate" />
      <span className="ml-2">Duplicate</span>
    </>
  );
  const yesDuplicate = (
    <a className="dropdown-item" onClick={props.onClick}>
      {duplicateIcon}
    </a>
  );
  const noDuplicate = <div className="dropdown-item">{duplicateIcon}</div>;

  return isEnabled ? yesDuplicate : noDuplicate;
};

export const HeaderMenu = (props) => {
  const { isLoading, onEditButtonClick, onDuplicateButtonClick, isAdmin } = props;
  const canEdit = true;
  const canDuplicate = true;

  if (!isAdmin) {
    return <></>
  }

  return <DuplicateButton isEnabled={canDuplicate} onClick={onDuplicateButtonClick} />;
};

export default EditInboundOrderPage;
