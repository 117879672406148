import React from 'react';

export const DEFAULT_SEARCH_BAR_STATE = {
  isLoading: false,
  items: [],
  searchTerm: '',
  isMenuOpen: false,
}

export const useAbstractSearchBar = () => {

  const [state, setState] = React.useState(DEFAULT_SEARCH_BAR_STATE);

  const setItems = (items) => {

    setState(prevState => ({
      ...prevState,
      items,
      isLoading: false,
    }));
  }

  const showMenu = () =>
    setState(prevState => ({
      ...prevState,
      isLoading: true,
      isMenuOpen: true,
    }));

  const setSearchTerm = (searchTerm) => {
    setState(prevState => ({
      ...prevState,
      searchTerm,
      isLoading: true,
      isMenuOpen: true,
    }))};

  const hideMenu = (event) => {
    setState(prevState => ({...prevState, isMenuOpen: false }));
  }

  const resetSearchMenu = () => {
    setState(prevState => ({
      ...prevState,
      searchTerm: '',
      items: [],
      isMenuOpen: false,
    }));
  }


  return {...state, setItems, showMenu, setSearchTerm, hideMenu, resetSearchMenu}
}