import React, { useState } from "react";
import { usePageState } from "../../reusable/page/usePageState";
import { useProjectRequestForm } from "./components/ProjectRequestForm";

export const DEFAULT_CREATE_PROJECT_REQUEST_PAGE_STATE = {
  isObjectSelectionComplete: false,
};

export const useCreateProjectRequestPage = (props) => {

  const { formState: newFormState } = props;

  const [pageState, setPageState] = useState(
    DEFAULT_CREATE_PROJECT_REQUEST_PAGE_STATE
  );

  const setIsObjectSelectionComplete = (isObjectSelectionComplete) => {
    setPageState((prevState) => ({
      ...prevState,
      isObjectSelectionComplete,
    }));
  };

  const { state, setIsLoading, setIsSaving } = usePageState();
  const formState = useProjectRequestForm(newFormState);

  return {
    ...state,
    ...pageState,
    setIsObjectSelectionComplete,
    formState,
    setIsLoading,
    setIsSaving,
  };
};
