import _ from 'lodash';
import React, { Component } from 'react';
import apiEndpoints from '../../utils/apiEndpoints.js';
import { removeParam } from '../../utils/general.js';
import { parseOptions } from '../../utils/grid/helpers.js';



var moment = require('moment');


const defaultState = {
  data: null,
  errors: {},
  loading: true,
  queryString: '',
  totalSize: 0,
  isStoreDropdownActive: false,
  store: {
    uuid: '',
  },
}

export const ReturnContext = React.createContext(defaultState);

export default class ReturnStore extends Component {
  state = defaultState;

  setStore = (store) => {
    this.setState((prevState) => ({
      store
    }));
  }


  getOrderReturns = (options) => {
    let queryString = parseOptions(options);

    // API request already underway (and not initial fetch)
    if (!!this.state.loading && this.state.data !== null) {
      return Promise.resolve(this.state.data);
    }

    // Data is cached and no querystring changes
    if (!this.state.loading && (queryString === this.state.queryString)) {
      return Promise.resolve(this.state.data);
    }

    this.setState({ loading: true });

    return apiEndpoints.getOrderReturns(queryString)
      .then(response => {
        return response.json();
      }).then(data => {
        this.setState({
          ...this.state,
          data: data.results,
          loading: false,
          queryString: queryString,
          totalSize: data.count,
        });
        return data.results;
      });
  }

  createOrder = order => {
    var isValid = true;
    var data = this.state.data || [];
    var errors = {};

    return apiEndpoints.createOrder(order)
      .then(response => {
        isValid = response.status === 201;
        return response.json();
      }).then(orderResponse => {
        if (isValid) {
          if (data.length > 0) delete data[0].isFresh;
          data.unshift({
            ...orderResponse,
            isFresh: orderResponse.combined_status !== 'DRAFT',
            isPending: orderResponse.combined_status === 'DRAFT',
          });
          this.setState(data);
        } else {
          errors = orderResponse;
        }
        return {
          data: orderResponse,
          errors,
        };
      });
  }

  createReturn = newReturn => {
    var isValid = true;
    var data = this.state.data || [];
    var errors = {};

    return apiEndpoints.createOrderReturn(newReturn)
      .then(response => {
        isValid = response.status === 201;
        return response.json();
      }).then(orderResponse => {
        if (isValid) {
          if (data.length > 0) delete data[0].isFresh;
          data.unshift({
            ...orderResponse,
            isFresh: orderResponse.combined_status !== 'DRAFT',
            isPending: orderResponse.combined_status === 'DRAFT',
          });
          this.setState(data);
        } else {
          errors = orderResponse;
        }
        return {
          data: orderResponse,
          errors,
        };
      });
  }

  updateOrderReturn = (returnId, returnData) => {
    var isValid = true;
    var data = this.state.data;
    var errors = {};

    return apiEndpoints.editOrderReturn(returnId, returnData)
      .then((response) => {
        isValid = response.status === 200;
        return response.json();
      });
  }



  exportReturns = () => {
    let exportQueryString = removeParam(this.state.queryString, 'offset');
    return apiEndpoints.getReturnsCSV(exportQueryString);
  }


  clearCache = () => {
    this.setState({
      ...this.state,
      data: null,
      loading: true,
    });
  }

  // Used in Edit Order
  getOrderReturn = (returnId, options) => {
    let data = this.state.data || [];

    return apiEndpoints.getOrderReturn(returnId)
      .then(response => response.json())
      .then(fetchedOrder => {
        this.setState({
          data: data.map(existingOrder =>
            existingOrder.uuid === returnId ? fetchedOrder : existingOrder
          ),
          loading: false,
        });
        return fetchedOrder;
      });
  }

  getOrder = (orderId, options) => {
    let data = this.state.data || [];
    let existingOrder = _.find(data, order => order.uuid === orderId);
    let forceReload = !!options && !!options.forceReload;

    if (!!existingOrder && !forceReload) {
      return Promise.resolve(existingOrder)
    }

    return apiEndpoints.getOrder(orderId)
      .then(response => response.json())
      .then(fetchedOrder => {
        return fetchedOrder;
      });
  }

  render() {
    return (
      <ReturnContext.Provider value={{
        ...this.state,
        setStore: this.setStore, 
        getOrderReturns: this.getOrderReturns,
        getOrderReturn: this.getOrderReturn,
        createReturn: this.createReturn,
        updateOrderReturn: this.updateOrderReturn,
        getOrder: this.getOrder,
        clearCache: this.clearCache,
        createOrder: this.createOrder,
        toggleStoreDropdown: this.toggleStoreDropdown,
      }}>
        {this.props.children}
      </ReturnContext.Provider>
    )
  }
}
