import React from "react";


export const PROJECT_REQUEST_TABLE_DEFAULT_STATE = {
  items: ""
}

export const useProjectRequestTable = () => {
  const [state, setState] = React.useState(PROJECT_REQUEST_TABLE_DEFAULT_STATE);

  const loadItems = (items) => {
    setState(prevState => ({
      ...prevState,
      items,
    }));
  }

  return {state, loadItems};
}