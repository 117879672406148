import React from "react";
import SelectProducts, { useSelectProducts } from "./SelectProducts";

export const useInputInstruction = (newState = {}) => {
  const variantsState = useSelectProducts(newState);

  const loadInputInstructions = (inputVariants) => {
    variantsState.loadVariants(inputVariants);
  }

  return {variantsState, loadInputInstructions};
};

export const InputInstructionsField = (props) => {
  const { variantsState, store, filters } = props;

  return <SelectProducts store={store} {...variantsState} filters={filters} />;
}