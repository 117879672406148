import React from "react";

export const AdminControl = (props) => {
  const { options } = props;
  return (
    <div className="dropdown is-hoverable mr-2">
      <div className="dropdown-trigger">
        <button
          className="button is-admin is-pulled-right is-hidden-mobile"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          <span className="icon is-small"><i className="icon-ic-admin" aria-hidden="true" /></span>
          <span>Admin Controls</span>
          <span className="icon is-small">
            <i className="icon-ic-caret" aria-hidden="true" />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">{ options }</div>
      </div>
    </div>
  );
};
