import { PaymentMethod } from "./payment.interfaces";

export interface IPrimaryBilling {
  user: IPrimaryBillingUser;
  balance_id: string;
  send_emails_to: any[];
  send_emails_cc: any[];
  default_payment_method: string;
  payment_methods: PaymentMethod[];
}

export interface IBaseUser {
  pk: string;
  email: string;
  first_name: string;
  last_name: string;
  send_bill: boolean;
}

export interface IPrimaryBillingUser extends IBaseUser {
  phone: string;
  authorized_to_connect_domains: string[];
  is_staff: boolean;
  is_superuser: boolean;
}

export const DEFAULT_PRIMARY_BILLING: IPrimaryBilling = {
  balance_id: "",
  send_emails_cc: [],
  send_emails_to: [],
  default_payment_method: "",
  payment_methods: [],
  user: {
    phone: "",
    authorized_to_connect_domains: [],
    is_staff: false,
    is_superuser: false,
    email: "",
    first_name: "",
    last_name: "",
    pk: "",
    send_bill: false,
  },
};
