import React, { useState } from "react";
import _ from "lodash";
import TrackingNumbersTable from "../../../reusable/tracking/TrackingNumbersTable";
import TrackingNumbersInput, {
  useMultipleTrackingInput,
} from "../../../reusable/tracking/TrackingNumbersInput";
import { addTrackingNumberAfterOrderSubmission } from "../../../../utils/gateway/InboundOrderAPIGateway";
import consts from "../../../../utils/consts";
import { ValidationError } from "../../../reusable/ValidationError";

export const useEditTrackingNumbers = (inputState = {}, inboundOrderState) => {
  const [trackingNumbers, setTrackingNumbers] = useState(inputState.trackingNumbers || []);
  const trackingInputState = useMultipleTrackingInput();

  const addTrackingNumber = () => {
    let { trackingCarrier, trackingNumber } = trackingInputState;
    trackingCarrier = trackingCarrier || "Unknown";

    const newTrackingNumber = {
      trackingCarrier,
      trackingNumber,
    };

    if (inboundOrderState.status !== consts.STATUS_DRAFT) {
      addTrackingNumberAfterOrderSubmission(
        inboundOrderState.uuid,
        trackingCarrier,
        trackingNumber
      );
    }

    setTrackingNumbers(trackingNumbers.concat(newTrackingNumber));
    trackingInputState.reset();
  };

  const removeTrackingNumber = (removedItem) => {
    setTrackingNumbers((prevState) => prevState.filter((item) => !_.isEqual(item, removedItem)));
  };

  const hasTrackingNumbers = () => {
    return trackingNumbers.length > 0;
  };

  return {
    trackingInputState,
    trackingNumbers,
    addTrackingNumber,
    removeTrackingNumber,
    setTrackingNumbers,
    hasTrackingNumbers,
  };
};

export const EditTrackingNumbers = ({
  trackingInputState,
  trackingNumbers,
  addTrackingNumber,
  removeTrackingNumber,
  isEditing,
  errors,
}) => {
  return (
    <>
      {errors.parcelTrackingNumberMissing && (
        <ValidationError message={"Please add a tracking number. If you don't have one yet, you can save this inventory shipment as a draft and add the tracking number later."} />
      )}

      {isEditing && (
        <TrackingNumbersInput
          trackingInputState={trackingInputState}
          addTrackingNumber={addTrackingNumber}
        />
      )}
      <TrackingNumbersTable
        items={trackingNumbers}
        removeItem={removeTrackingNumber}
        isEditing={isEditing}
      />
    </>
  );
};

export default EditTrackingNumbers;
