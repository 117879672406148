import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import useDebounce from "../../../../utils/hooks/useDebounce";
import { getInboundOrderVariants } from "../../../../utils/gateway/VariantAPIGateway";

/* Ag-grid documentation:
 * - row selection
 *   https://www.ag-grid.com/react-data-grid/row-selection/
 * - grid update transactions
 *   https://www.ag-grid.com/react-data-grid/data-update-transactions/
 */
const SelectVariantsGrid = (props) => {
  const { selectedVariants, variantChecked, store } = props;

  // search hooks
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 100);

  // after the search term changes or the page loads for the first time
  useEffect(() => {
    // only search once context is loaded
    // initial search will be made with an empty string once the page loads
    if (store) {
      search(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, store]);

  // ag-grid hooks
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);

  // if an item is deleted in the table, we need to uncheck it in the grid
  useEffect(() => {
    gridRef.current.api.forEachNode((rowNode) => {
      const variantId = rowNode.data?.uuid;
      // if (!(variantId in selectedVariants)) {
      if (selectedVariants.filter((item) => item.variantId === variantId).length === 0) {
        rowNode.setSelected(false);
      }
    });
  });

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
    };
  }, []);

  const productNameGetter = (params) => {
    const { product_name, name } = params.data;
    const isMissingName = name === "Default Title"
    return isMissingName ? product_name : `${product_name} / ${name}`
  };

  const [columnDefs] = useState([
    {
      headerName: "Product",
      checkboxSelection: true,
      valueGetter: productNameGetter,
      cellClass: "cell-vertical-align",
    },
    {
      field: "sku",
      headerName: "SKU",
      cellClass: "cell-vertical-align",
    },
    {
      field: "available",
      headerName: "In Warehouse",
      cellClass: "cell-vertical-align",
    },
  ]);

  // mark rows selected in grid if they have already been checked previously
  // docs: https://www.ag-grid.com/react-data-grid/grid-options/#reference-rendering-processRowPostCreate
  const processRowPostCreate = (params) => {
    const variantId = params.node.data?.uuid;
    if (selectedVariants.filter((item) => item.variantId === variantId).length > 0) {
      params.node.setSelected(true);
    }
  };

  const search = (searchTerm) => {
    getInboundOrderVariants(encodeURIComponent(searchTerm), store).then((result) => {
      setRowData(result);
    });
  };

  const handleSearchTermChange = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  const onRowSelected = useCallback(async (event) => {
    const { data, selected } = event.node;
    variantChecked(data, selected);
  }, []);

  return (
    <>
      <input
        className="input"
        type="text"
        placeholder="Filter products by typing a product name, SKU, or barcode."
        aria-haspopup="true"
        aria-controls="dropdown-menu"
        value={searchTerm}
        onChange={(event) => handleSearchTermChange(event.target.value)}
      />
      <div
        className="ag-theme-balham"
        style={{ height: 400, width: "100%"}}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          rowSelection="multiple"
          suppressRowClickSelection
          defaultColDef={defaultColDef}
          onRowSelected={onRowSelected}
          processRowPostCreate={processRowPostCreate}
        />
      </div>
    </>
  );
};

export default SelectVariantsGrid;
