import React from "react";
import { Columns } from "react-bulma-components";
import { OutputInstructionsField } from "../fields/OutputInstructionsField";

export const OutputInstructionsSection = (props) => {
  const { description } = props;
  return <div className="section-container">
    <Columns>
      <Columns.Column size={3}>
        <p data-testid="target-due-date" className={'title is-4 mb-4'}>
          Output Products
        </p>
        {description && <p>{description}</p>}
      </Columns.Column>
      <Columns.Column>
        <OutputInstructionsField {...props}/>
      </Columns.Column>
    </Columns>
  </div>
}

export const OutputInstructionsInlineSection = (props) => (<div className="section-container">
  <h5 className="title is-5 mb-4">Output Products</h5>
  {props.description && <p>{props.description}</p>}
  <OutputInstructionsField {...props}/>
</div>)