import { IStore } from "./store.interface";
import { DEFAULT_PRIMARY_BILLING, IBaseUser, IPrimaryBilling } from "./user.interfaces";

export interface ICompany {
  domain: string;
  name: string;
  status: string;
  stores: Array<IStore>;
  is_demo: true;
  users: IBaseUser[];
  invitations: Invitation[];
  returns_enabled: boolean;
  file_upload_enabled: boolean;
  project_requests_enabled: boolean;
  edi_enabled: boolean;
  inbound_enabled: boolean;
  rate_calculator_enabled: boolean;
  notifications_enabled: boolean;
  primary_billing_user: IPrimaryBilling;
  get_delinquent_invoices: IInvoices[];
  get_suspended_invoices: IInvoices[];
  has_payment_information: boolean;
  next_billing_date: string;
  restock_without_payment_details_enabled: boolean;
}

export interface Invitation {
  email: string;
}

export interface IInvoices {
  uuid: string;
  due_date: string;
  date_range_suspension: string;
}

export const DEFAULT_COMPANY: ICompany = {
  domain: "",
  name: "",
  status: "",
  stores: [],
  is_demo: true,
  users: [],
  invitations: [],
  returns_enabled: false,
  file_upload_enabled: false,
  project_requests_enabled: false,
  edi_enabled: false,
  inbound_enabled: false,
  rate_calculator_enabled: false,
  notifications_enabled: false,
  primary_billing_user: DEFAULT_PRIMARY_BILLING,
  get_delinquent_invoices: [],
  get_suspended_invoices: [],
  has_payment_information: false,
  next_billing_date: "",
  restock_without_payment_details_enabled: false,
};
