import React, { useState, useContext } from "react";
import { ValidationError } from "../../../reusable/ValidationError";

const HELP_EMAIL = "team@airhouse.io";

const WarehouseSelectionSection = (props) => {
  const {
    warehouses,
    selectedWarehouse,
    setSelectedWarehouse,
    isEditing,
    errors,
  } = props;

  return (
    <>
      <h4 className="is-dominique-subtitle mt-4">Warehouse Selection</h4>
      {errors.warehouseMissing && (
        <>
          <ValidationError
            message={"Please select a warehouse to receive this inventory shipment."}
          />
          <br />
        </>
      )}

      {warehouses.length === 0 && (
        <p>
          Warehouses not yet assigned. Email <a href={`mailto:"${HELP_EMAIL}"`}>{HELP_EMAIL}</a> to
          complete warehouse assignment.
        </p>
      )}

      {isEditing && warehouses.length >= 0 && (
        <p className="mb-1">Select your destination warehouse</p>
      )}

      {/* If multiple warehouses are assigned to the client, have client select the appropriate warehouse. */}
      {isEditing && warehouses.length > 1 && (
        <>
          <p className="has-text-grey-light">
            If you want to send inventory to multiple warehouses, create separate inventory
            shipments.
          </p>
        </>
      )}

      {isEditing && warehouses.length >= 0 && (
        <div className="field mt-4">
          {warehouses.map((warehouse) => (
            <div className="control mt-2" key={`warehouse-${warehouse.uuid}`}>
              <input
                className="is-checkradio"
                id={`warehouseSelect-${warehouse.uuid}`}
                type="radio"
                checked={(selectedWarehouse && selectedWarehouse.uuid === warehouse.uuid) || ""}
                onChange={() => setSelectedWarehouse(warehouse)}
              />
              <label className="radio" htmlFor={`warehouseSelect-${warehouse.uuid}`}>
                {warehouse.display}
              </label>
            </div>
          ))}
        </div>
      )}

      {!isEditing && selectedWarehouse && selectedWarehouse.display}
      {!isEditing && !selectedWarehouse && <p>No warehouse selected</p>}
    </>
  );
};

export default WarehouseSelectionSection;
