import React from "react";


const STATUS = {
  INFO: 'info',
  OK: 'ok',
  ERROR: '',
}


export const StatusTag = (props) => {
  const {name, description, classNames} = props


  return <div className={`mr-2 tag is-rounded ${classNames}`}>
    {name}
    <span
      className="tooltip is-tooltip-right ml-1"
      data-tooltip={description}
    >
      <i className="icon-ic-info" />
    </span>
  </div>
}
