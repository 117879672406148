import React from 'react';

const DEFAULT_STATE = '';

export const useTrackingNumber = () => {
  const [state, setState] = React.useState();
  return [state, setState];
};

export const TrackingNumber = (props) => {
  const { trackingNumber, setTrackingNumber } = props;

  const onTrackingNumberChange = (e) => {
    setTrackingNumber(e.target.value);
  };

  return (
    <input
      className="input"
      value={trackingNumber || ''}
      type="text"
      placeholder="Add tracking number"
      onChange={onTrackingNumberChange}
    />
  );
};

export default TrackingNumber;
