import _ from 'lodash';

import apiEndpoints from './apiEndpoints.js';


// Constants
export const shopify = 'shopify';
export const squarespace = 'squarespace';


// Integrations with update_inventory, update_fulfillment
export const shop_ss = [shopify, squarespace];


// For display only, to avoid cumbersome title casing
export const getStoreSourceDisplay = (store) =>
  !!store.shopify ? 'Shopify'
  : !!store.squarespace ? 'Squarespace' : ''


export const getStoreSource = (store) =>
  !!store.shopify ? 'shopify'
  : !!store.squarespace ? 'squarespace'
  : !!store.orderful ? 'orderful' : 'internal'

export const getSource = (obj) =>
  !!obj.shopify ? 'shopify'
  : !!obj.squarespace ? 'squarespace'
    : !!obj.public ? 'public' : 'internal'


// Use when we have the store_id but not the full store object
export const getStore = (storesArray, storeId) => {
  const stores = storesArray || [];
  const store = _.find(stores, store => store.uuid === storeId);

  if (store) {
    return store
  }

  return null;
}


// Usually used with orders, since storeId is a string
export const getStoreSlug = (storesArray, storeId) => {
  const stores = storesArray || [];
  const store = _.find(stores, store => store.uuid === storeId);

  if (store) {
    if (store.shopify) return store.shopify.slug;
    if (store.squarespace) return store.squarespace.slug;
  }

  return null;
}


// Used in Settings
export const createSlug = (store) => {
  let source = getStoreSource(store);

  switch (source) {
    case shopify:
      return `${store[source].slug}.myshopify.com`
    case squarespace:
      return `${store[source].slug}.squarespace.com`
    default:
      return null
  }
}


export const createOrderUrl = (slug, order) => {
  switch (order.source) {
    case shopify:
      return `https://${slug}.myshopify.com/admin/orders/${order.shopify.sync_id}`
    case squarespace:
      return `https://${slug}.squarespace.com/config/commerce/orders`
    default:
      return "#"
  }
}


export const createVariantUrl = (slug, product, variant) => {
  if (product?.shopify && variant?.shopify) {
    return `https://${slug}.myshopify.com/admin/products/${product.shopify.sync_id}/variants/${variant.shopify.sync_id}`
  } else if (product?.squarespace && variant?.squarespace) {
    return `https://${slug}.squarespace.com/config/commerce/inventory`
  } else {
    return null
  }
}


export const createBulkVariantUrl = (store) => {
  let source = getStoreSource(store);

  switch (source) {
    case shopify:
      return `https://${store[source].slug}.myshopify.com/admin/bulk?resource_name=Product&order=title+asc&edit=variants.sku&show=&ids=&app_context=&metafield_titles=&metafield_options=`
    case squarespace:
      return `https://${store[source].slug}.squarespace.com/config/commerce/inventory`
    default:
      return null
  }
}

// Backend admin

export const adminStoreUrl = (store_uuid) => `${apiEndpoints.API_BASE}admin/stores/store/${store_uuid}/`

export const adminOrderUrl = (order_uuid) => `${apiEndpoints.API_BASE}admin/products/order/${order_uuid}/`

export const adminVariantUrl = (variant_uuid) => `${apiEndpoints.API_BASE}admin/products/variant/${variant_uuid}/`

export const adminPackageUrl = `${apiEndpoints.API_BASE}admin/fulfillment/package/`;
