import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import SelectVariants, { useSelectVariants } from "../components/SelectVariants";
import { useInboundCSVUpload } from "../components/InboundCSVUpload";
import Header from "../components/Header";
import SelectShippingFormats, {
  useSelectShippingFormats,
} from "../components/SelectShippingFormats";
import SelectPackingSupplies, {
  useSelectPackingSupplies,
} from "../components/SelectPackingSupplies";
import PackingNotes, { usePackingNotes } from "../fields/PackingNotes";
import InboundCSVUpload from "../components/InboundCSVUpload";
import { ValidationError } from "../../../reusable/ValidationError";

export const usePackingListSection = (packingListFormState = {}) => {
  const [CSVErrors, setCSVErrors] = useState([]);
  const [csvUploaded, setCSVUploaded] = useState(false);

  const shippingFormatsState = useSelectShippingFormats(
    packingListFormState?.shippingFormatsState?.shippingFormatsState || {}
  );
  const selectVariants = useSelectVariants(packingListFormState?.selectVariants || {});
  const packingSuppliesState = useSelectPackingSupplies(
    packingListFormState?.packingSuppliesState?.packingSuppliesState || {}
  );
  const packingNotes = usePackingNotes(packingListFormState?.packingNotes || {});
  const inboundCSVUpload = useInboundCSVUpload();

  const loadCSVVariants = (records) => {
    records.forEach((record) => {
      const { uuid, name, sku, price, count } = record;
      // console.log(record);
      selectVariants.updateOrCreateRow(uuid, name, sku, price, count, 0);
    });
  };

  const handleInboundCSVUploadClickedCallback = () => {
    setCSVUploaded(true);
  };

  return {
    shippingFormatsState,
    selectVariants,
    packingSuppliesState,
    packingNotes,
    inboundCSVUpload,
    loadCSVVariants,
    CSVErrors,
    setCSVErrors,
    handleInboundCSVUploadClickedCallback,
    csvUploaded,
  };
};

export const PackingListSection = (props) => {
  const {
    packingListId,
    shippingFormatId,
    productsSelectorId,
    packingSuppliesId,
    packingNotesId,
    shippingFormatsState,
    selectVariants,
    packingSuppliesState,
    packingNotes,
    name,
    store,
    isEditing,
    inboundOrderState,
    inboundCSVUpload,
    loadCSVVariants,
    errors,
    handleInboundCSVUploadClickedCallback,
    csvUploaded,
  } = props;
  const { csvErrors } = inboundCSVUpload;
  return (
    <div className="section-container">
      <div className="scroll-section" id={packingListId}>
        <h2 className="is-dominique-title">Packing List</h2>
        <div>
          <span>Label the outside of every box with the inventory shipment code </span>
          <span className="is-dominique-danger-text">{name}</span>
        </div>
      </div>

      <div className="scroll-section" id={shippingFormatId}>
        <h3 className="is-dominique-subtitle mt-6">Shipping Formats</h3>
        <SelectShippingFormats {...shippingFormatsState} isEditing={isEditing} errors={errors} />
      </div>

      <div className="scroll-section" id={productsSelectorId}>
        <div className="level is-paddingless mt-6">
          <div className="level-left">
            <div className="level-item">
              <h4 className="is-dominique-subtitle">Products</h4>
            </div>
          </div>
          {isEditing && (
            <div className="level-right">
              <InboundCSVUpload
                {...inboundCSVUpload}
                store={store}
                loadCSVVariants={loadCSVVariants}
                handleInboundCSVUploadClickedCallback={handleInboundCSVUploadClickedCallback}
              />
            </div>
          )}
        </div>
        {csvErrors &&
          csvErrors.map((csvError) => {
            return <ValidationError key={csvError} message={csvError} />;
          })}

        {!csvUploaded && isEditing && (
          <p>
            Toggle the checkbox next to the products you would like to add to your inventory
            shipment or upload a CSV file.
          </p>
        )}
        <div className="field">
          <div className="control">
            <SelectVariants
              {...selectVariants}
              inboundOrderState={inboundOrderState}
              store={store}
              isEditing={isEditing}
              csvUploaded={csvUploaded}
              errors={errors}
            />
          </div>
        </div>
      </div>

      <div className="scroll-section" id={packingSuppliesId}>
        <h4 className="is-dominique-subtitle mt-6">Packaging Supplies (Optional)</h4>
        <p className="mb-3">Please specify what packaging supplies you will be sending.</p>
        <SelectPackingSupplies {...packingSuppliesState} isEditing={isEditing} errors={errors} />
      </div>

      <PackingNotes {...packingNotes} scrollId={packingNotesId} isEditing={isEditing} />
    </div>
  );
};

export default { usePackingListSection, PackingListSection };
