import React from "react";
import SelectProducts, { useSelectProducts } from "./SelectProducts";

export const useOutputInstruction = (newState = {}) => {
  const variantsState = useSelectProducts(newState);

  const loadOutputInstructions = (outputVariants) => {
    variantsState.loadVariants(outputVariants);
  }

  return { variantsState, loadOutputInstructions };
};

export const OutputInstructionsField = (props) => {
  const { variantsState, store } = props;

  return <SelectProducts store={store} {...variantsState} />;
}