import React, { useRef, useState, useCallback, useEffect } from "react";
import { Level } from "react-bulma-components";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import { orderStatusRenderer } from "../../../../utils/grid/cellRendering";

import useDebounce from "../../../../utils/hooks/useDebounce";
import { dateFormatter } from "../../../../utils/general";
import { getInboundOrders } from "../../../../utils/gateway/InboundOrderAPIGateway";

export const InboundOrderGridFilters = (props) => {
  const { searchTerm, handleSearchTermChange } = props;
  return (
    <Level className="filter-bar is-marginless">
      <span className="is-hidden-mobile">
        <div className="field mb-0" />
      </span>
      <span className="is-flex">
        <div className="control has-icons-left">
          <input
            className="input"
            type="text"
            placeholder="Search Restocks"
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
          <span className="icon is-small is-left">
            <i className="icon-ic-search" />
          </span>
        </div>
      </span>
    </Level>
  );
};

export const gridDateFormatter = (date) => {
  return date.value ? dateFormatter(date.value) : "-";
};

const restockContentsFormatter = (params) => {
  let result = "";

  if (params.data) {
    const { num_boxes, num_pallets, num_containers } = params.data;

    if (num_boxes > 0) {
      result += ` ${num_boxes} Box`;
      if (num_boxes > 1) {
        result += "es";
      }
    }

    if (num_pallets > 0) {
      result += ` ${num_pallets} Pallet`;
      if (num_pallets > 1) {
        result += "s";
      }
    }

    if (num_containers > 0) {
      result += ` ${num_containers} Container`;
      if (num_containers > 1) {
        result += "s";
      }
    }
  }

  return result;
};

const restockReceivingGetter = (params) => params.data?.receiving_address_detail?.province_code;

export const InboundOrderGrid = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  // page state
  const firstRender = useRef(true);

  // nasty hack to make ag-grid see the value of filter state variables
  // if you don't do this createServerSideDatasource won't see react state
  // https://stackoverflow.com/a/67618367
  const debouncedSearchTermRef = useRef(null);
  debouncedSearchTermRef.current = debouncedSearchTerm;

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value.slice(0, 50));
  };

  const { inboundOrders, onCellClicked } = props;
  const gridRef = useRef();

  useEffect(() => {
    // trigger data load on filter change only after initial render
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    gridRef.current.api.refreshServerSideStore({ purge: true });
  }, [debouncedSearchTerm]);

  const createServerSideDatasource = () => {
    return {
      getRows: async (params) => {
        const options = {
          searchTerm: encodeURIComponent(debouncedSearchTermRef.current),
          offset: params.request.startRow,
        };

        const response = await getInboundOrders(options);
        if (response.success) {
          params.success({
            rowData: response.results,
            rowCount: response.count,
          });
        } else {
          params.fail();
        }
      },
    };
  };

  const onGridReady = (params) => {
    const datasource = createServerSideDatasource();
    params.api.setServerSideDatasource(datasource);
  };

  const externalStyles = {
    grid: {
      height: "calc(100vh - 298px)",
      width: "100%",
    },
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "name",
      headerName: "ID",
      minWidth: 40,
      suppressMenu: true,
      cellStyle: { "font-weight": "bold" },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 64,
      suppressMenu: true,
      cellRenderer: orderStatusRenderer,
    },
    {
      field: "submitted_date",
      headerName: "Submitted Date",
      minWidth: 100,
      suppressMenu: true,
      valueFormatter: gridDateFormatter,
    },
    {
      field: "received_date",
      headerName: "Received Date",
      minWidth: 100,
      suppressMenu: true,
      valueFormatter: gridDateFormatter,
    },
    {
      headerName: "Destination",
      minWidth: 100,
      suppressMenu: true,
      valueGetter: restockReceivingGetter,
    },
    {
      headerName: "Contents",
      minWidth: 88,
      suppressMenu: true,
      valueGetter: restockContentsFormatter,
    },
    {
      field: "sku_count",
      headerName: "SKU",
      suppressMenu: true,
    },
    {
      field: "item_count",
      headerName: "Quantity",
      suppressMenu: true,
    },
  ]);

  return (
    <>
      <InboundOrderGridFilters
        searchTerm={searchTerm}
        handleSearchTermChange={handleSearchTermChange}
      />
      <div className="ag-theme-balham" style={externalStyles.grid}>
        <AgGridReact
          ref={gridRef}
          rowData={inboundOrders}
          suppressNoRowsOverlay
          suppressCellSelection
          enableSorting="true"
          enableFilter="true"
          pagination
          paginationPageSize={50}
          onCellClicked={onCellClicked}
          rowStyle={{ cursor: "pointer", padding: "1rem" }}
          rowClassRules={{
            "is-fresh": (params) => !!params.data && !!params.data?.isFresh,
            "is-pending": (params) => !!params.data && !!params.data?.isPending,
          }}
          getRowHeight={(params) => 60}
          cacheBlockSize={100}
          defaultColDef={{
            flex: 1,
            resizable: true,
            headerComponentParams: {
              menuIcon: "fa-bars",
            },
          }}
          sideBar={{
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivotMode: true,
                  suppressColumnFilter: true,
                },
              },
            ],
          }}
          getContextMenuItems={(params) => [
            {
              name: "Open in new tab",
              action: () =>
                window.open(
                  `${window.location.origin}/inbound/edit/${params.node.data.uuid}/`,
                  "_blank"
                ),
              icon: '<span class="icon-ic-open"></span>',
            },
            "copy",
          ]}
          onGridReady={onGridReady}
          rowModelType="serverSide"
          serverSideStoreType="partial"
          columnDefs={columnDefs}
        />
      </div>
    </>
  );
};
