import React, { useContext, useEffect, useState } from 'react';
import CustomDropdown from '../../../reusable/CustomDropdown';
import { useDropdown } from '../../../reusable/dropdown/useDropdown';
import { useProjectRequestType } from './useProjectRequestType';
import { LoadingBar } from '../../../reusable/page/loaders/LoadingBar';

export const DEFAULT_SELECT_PROJECT_REQUEST_TYPE_STATE = {
  isLoading: false,
};

export const useSelectProjectRequestType = (props) => {
  const projectRequestType = props || {};
  const [state, setState] = useState(() => ({
    ...DEFAULT_SELECT_PROJECT_REQUEST_TYPE_STATE,
  }));
  const {
    projectRequestTypes,
    getProjectRequestTypes,
    isLoading: isLoadingProjectTypes,
  } = useProjectRequestType();

  const isLoading = state.isLoading || isLoadingProjectTypes;

  const setIsLoading = (isLoading) => {
    setState((prevState) => ({
      ...prevState,
      isLoading,
    }));
  };

  const useDropdownProps = {
    currentItem: projectRequestType,
  };

  const {
    state: dropdownState,
    setIsActive,
    toggleIsActive,
    setCurrentItem,
  } = useDropdown(useDropdownProps);
  const { items, isActive, currentItem } = dropdownState;
  return {
    items,
    isLoading,
    setIsLoading,
    isActive,
    setIsActive,
    toggleIsActive,
    setCurrentItem,
    currentItem,
    projectRequestTypes,
    getProjectRequestTypes,
  };
};

export const SelectProjectRequestType = (props) => {
  const {
    currentItem,
    projectRequestTypes,
    isLoading,
    setIsLoading,
    isActive,
    toggleIsActive,
    setCurrentItem,
    hasOrders,
    hasRestocks,
    hasInbounds,
  } = props;

  let projectTypes = projectRequestTypes;
  if (typeof hasOrders === 'boolean') {
    projectTypes = projectTypes.filter((i) => hasOrders === i.hasOrders);
  }
  if (typeof hasRestocks === 'boolean') {
    projectTypes = projectTypes.filter((i) => hasRestocks === i.hasRestocks);
  }
  if (typeof hasInbounds === 'boolean') {
    projectTypes = projectTypes.filter((i) => hasInbounds === i.hasInbounds);
  }

  return (
    <>
      {!isLoading && (
        <CustomDropdown
          items={projectTypes}
          currentItem={currentItem}
          toggleDropdownMenu={toggleIsActive}
          isDropdownActive={isActive}
          displayName={'title'}
          setCurrentItem={setCurrentItem}
          disable
          itemDisplay={DropdownItemDisplay}
        />
      )}
      {isLoading && <LoadingBar />}
    </>
  );
};

const DropdownItemDisplay = (props) => {
  const { displayName, item } = props;
  return (
    <>
      <h4 className="p-1">
        <strong>{displayName}</strong>
      </h4>
      <p className="p-1">{item.description}</p>
    </>
  );
};

export default SelectProjectRequestType;
