import React from "react";

const DEFAULT_DROPDOWN_STATE = {
  isActive: false,
  items: [],
  currentItem: {},
  placeholderText: "Select an option",
  displayName: "",
  itemIdKey: "uuid",
};

export const useDropdown = (props) => {
  const [state, setState] = React.useState(() => ({
    ...DEFAULT_DROPDOWN_STATE,
    ...props,
  }));

  const setItems = (items) => {
    setState((prevState) => ({
      ...prevState,
      items,
    }));
  };
  const setCurrentItem = (currentItem) => {
    setState((prevState) => ({
      ...prevState,
      currentItem,
    }));
  };

  const setIsActive = (isActive) => {
    setState((prevState) => ({
      ...prevState,
      isActive,
    }));
  };

  const toggleIsActive = () => {
    setState((prevState) => ({
      ...prevState,
      isActive: !prevState.isActive,
    }));
  };

  return { state, setIsActive, toggleIsActive, setCurrentItem, setItems };
};
