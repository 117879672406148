import React from "react";
import { Link } from "react-router-dom";
import orderStatus from "../../../../utils/grid/orderStatus";

export const InboundOrderTitle = (props) => {
  const { title } = props;
  return <p className="is-dominique-header">{title}</p>;
};

export const InboundOrderSubtitle = (props) => {
  const { status, createdAt, showLinkToOldRestock } = props;
  const classes = orderStatus.getClasses(status === "processing" ? "open" : status).join(" ");
  const displayName = orderStatus.getDisplayName(status === "processing" ? "open" : status);
  let dateString = "";
  if (createdAt) {
    const date = new Date(Date.parse(createdAt));
    dateString = date.toLocaleString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "America/Los_Angeles",
    });
  }
  return (
    <div className="is-flex-direction-column">
      {createdAt && <p>Created {dateString} PST</p>}
      <p className="mt-2 mb-2">
        New to sending an inventory shipment?{" "}
        <a
          target="_blank"
          href="https://help.airhouse.io/how-to-use-restock"
          className="has-text-right pt-1"
        >
          Refer to our docs for best practices
        </a>
      </p>
      <p className={classes}>{displayName}</p>
    </div>
  );
};
