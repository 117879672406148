import { usePageState } from "../../reusable/page/usePageState";
import { useInboundOrderForm } from "./components/InboundOrderForm";

const useCreateInboundOrderPage = (props) => {
  const { formState: inputFormState } = props;

  const { state, setIsLoading, setIsSaving } = usePageState({
    isLoading: true,
    isEditing: true,
  });
  const formState = useInboundOrderForm(inputFormState);

  return {
    ...state,
    formState,
    setIsLoading,
    setIsSaving,
  };
};

export default useCreateInboundOrderPage;
