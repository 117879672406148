export const recipientModalstyles = {
  contactBox: {
    width: "50%",
  },
  shippingBox: {
    width: "60%",
  },
  hidden: {
    display: "none",
  },
};

export const MultipleAddresStyles = {
  originalAddressTile: {
    border: 'none'
  },
  candidateClicked: {
    border: '1px solid #886FFF',
    boxShadow: 'inset 0 0.0625em 0.125em rgba(8, 0, 24, 0.05)',
    backgroundColor: '#EFE9FA'
  },
  radioInputLine: {
    marginRight: '17px'
  },
  addressLines: {
    marginLeft: '30px'
  }
}