import React, { Component } from 'react';
import { Notification } from 'react-bulma-components';

import apiEndpoints from '../utils/apiEndpoints.js';
import { getToken } from '../utils/authentication.js';


class MigrateNotification extends Component {
  getStoreClientID = () => {
    const prodClientID = 'e7ded3444b2ca754822693b274586802';
    const devClientID = 'cb7d2cf78681d840287d11ea368fa7db';
    const hostname = window.location.hostname;

    let clientID = hostname.includes('.dev.')
        ? devClientID : prodClientID;

    return clientID;
  }

  getConnectUrl = () => {
    const redirect_uri = apiEndpoints.API_BASE + 'api/shopify-stores/migrate/';
    const scope = [
      'read_orders',
      'read_all_orders',
      'write_orders',
      'read_fulfillments',
      'write_fulfillments',
      'read_products',
      'write_products',
      'read_inventory',
      'write_inventory',
      'read_shipping',
      'write_shipping',
      'read_analytics',
    ];
    const state = [this.getStoreClientID(), getToken()].join(',');
    const storeSlug = this.props.account.company.stores[0].slug;

    const url = 'https://'
      + storeSlug + '.myshopify.com/admin/oauth/authorize'
      + '?client_id=' + this.getStoreClientID()
      + '&scope=' + scope.join(',')
      + '&state=' + state
      + '&redirect_uri=' + redirect_uri;

    return url;
  }

  render() {
    if (this.props.account.company.stores.length === 0) return null;

    return (
      <Notification className="has-text-centered migration-box is-success">
        <i className="fas fa-rocket" style={{ marginRight: 10 }} aria-hidden="true"></i>
        Postworks is now Airhouse!
        <a
          className="button is-small is-secondary"
          style={{ marginLeft: '.5rem', marginTop: '-.25rem' }}
          href={ this.getConnectUrl() }
        >
          Migrate to Airhouse
        </a>
      </Notification>
    )
  }
}


export default MigrateNotification;
