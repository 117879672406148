import { Columns } from "react-bulma-components";
import SelectProjectRequestType from "../fields/SelectProjectRequestType";
import React from "react";


export const SelectProjectRequestTypeSection = (props) => <>
  <div className="section-container">
    <Columns>
      <Columns.Column size={3}>
        <p
          data-testid="upload-document-subheading"
          className={'title is-4 mb-4'}
        >
          Select Project Type
        </p>
        <p>
          Additional services that can be added to existing orders and other store fulfillment needs.
        </p>
      </Columns.Column>
      <Columns.Column>
        <SelectProjectRequestType {...props} />
      </Columns.Column>
    </Columns>
  </div>
</>;


export const SelectProjectRequestTypeInlineSection = (props) => (<div className="section-container">
  <h5 className="title is-5 mb-4">Select Project Type</h5>
  <SelectProjectRequestType {...props} />
</div>);
