import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from '../utils/authentication.js';
import { UserContext } from './contexts/UserStore.tsx';


const PrivateRoute = ({component: Component, ...rest }) => (
  <Route {...rest} render={
    (props) => (
      isAuthenticated() === true
        ? <UserContext.Consumer>
            {(context) => (
              <Component {...props} {...context} />
            )}
          </UserContext.Consumer>
        : <Redirect to='/login' />
    )
  }/>
)



export default PrivateRoute;