import React from "react";
import VariantList from "../../VariantList";
import { getVariants } from "../../../utils/gateway/VariantAPIGateway";
import AbstractSearchBar from "../../reusable/searchBar/AbstractSearchBar";
import { useAbstractSearchBar } from "../../reusable/searchBar/useAbstractSearchBar";

export const useProductSearchBar = useAbstractSearchBar;

const fetchVariants = async (searchTerm, filters = {}) => {
  const { store, eligibleVariants, items, orders, restocks } = filters;

  const variants = await getVariants(searchTerm, { store, orders, restocks, eligibleVariants });

  return variants
    .filter((v) => !items.find((i) => i.variant.uuid === v.uuid))
    .filter((v) => !eligibleVariants?.length || eligibleVariants.find((i) => i.variant.uuid === v.uuid))
    .sort((a, b) => b.shop_available - a.shop_available);
};

const DEFAULT_PLACEHOLDER = "Search products by name or sku.";
export const ProductSearchBar = (props) => {
  const { store, filters, setItems } = props;
  const placeholder = props.placeholder || DEFAULT_PLACEHOLDER;

  const disabled = !store?.uuid;
  return (
    <AbstractSearchBar
      {...props}
      disabled={disabled}
      filters={filters}
      search={fetchVariants}
      onSearchComplete={setItems}
      itemListDisplay={ProductListDisplay}
      placeholder={placeholder}
    />
  );
};

export const ProductListDisplay = (props) => {
  const { items, onSelectItem } = props;

  return (
    <VariantList
      className="dropdown-item"
      isDropdown={true}
      keyPrefix="items-search-"
      onSelect={(item) => onSelectItem(item)}
      options={{
        showSKU: true,
        showStockStatus: true,
      }}
      variants={items}
    />
  );
};

export const styles = {
  noResults: {
    backgroundColor: "#F8F8F9",
    fontStyle: "italic",
  },
};

export default ProductSearchBar;
