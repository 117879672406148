import React, { Component } from 'react';
import _ from 'lodash';

import apiEndpoints from '../../utils/apiEndpoints.js';
import { objectFromQueryString } from '../../utils/general.js';


const defaultState = {
  data: [],
  loading: true,
  queryString: '',
  next: null,
  count: 0,
}

const parseFilters = (filterModel) => {
  return _.map(filterModel, (data, columnName) => 
    columnName + '=' + data.filter
  ).join('&');
}

const buildQueryString = (options) => {
  let filter = !!options && !!options.filterModel ? parseFilters(options.filterModel) : null;
  let search = !!options && !!options.searchTerm ? 'search=' + options.searchTerm : null;
  let offset = !!options && !!options.offset ? 'offset=' + options.offset : null;
  let limit = !!options && !!options.limit ? 'limit=' + options.limit : null;

  return [filter, search, offset, limit].filter(item => !!item).join('&');
}


export const ProductContext = React.createContext(defaultState);

// TODO: Reset store on logout
export default class ProductStore extends Component {
  state = defaultState;

  getProducts = (options) => {
    let queryString = buildQueryString(options);

    if (!this.state.loading && (queryString === this.state.queryString)) {
      return Promise.resolve(this.state.data);
    }

    return apiEndpoints
      .getProducts(queryString)
      .then(response => response.json())
      .then(data => {
        // Bump out of stock products to the end
        const inStock = data.results.filter(
          product => product.variants.filter(
            variant => !!variant.inventory ? 
              variant.inventory.items.filter(inventoryItem => 
                inventoryItem.available > 0
              ).length > 0
            : []
          ).length > 0
        );
        const outOfStock = _.difference(data.results, inStock);
        const sortedData = inStock.concat(outOfStock);

        this.setState({
          data: sortedData,
          loading: false,
          queryString: queryString,
          next: data.next,
          count: data.count,
        });

        return sortedData;
      });
  }

  refreshProducts = () =>
    this.setState({ loading: true }, () =>
      apiEndpoints.getProducts(this.state.queryString)
        .then(response => response.json())
        .then(data => this.setState({ data: data.results, loading: false }))
    );

  loadMore = () => {
    // Repeat existing request (including querystring params) with next offset
    let nextQuery = !!this.state.next ? this.state.next.split('?')[1] : '';
    let queryObject = objectFromQueryString(nextQuery);
    let offset = queryObject['offset'];
    let queryString = !!this.state.queryString
      ? this.state.queryString + '&offset=' + offset
      : this.state.queryString + 'offset=' + offset;

    return apiEndpoints
      .getProducts(queryString)
      .then(response => response.json())
      .then(data => ({
        // Combine new results page with existing data
        ...data,
        results: this.state.data.concat(data.results),
      }))
      .then(data => {
        // Bump out of stock products to the end
        let inStock = data.results.filter(
          (product) =>  product.variants.filter(
            (variant) => Array.isArray(variant.inventory) && variant.inventory.filter(
              (inv) => inv.count > 0
            ).length > 0
          ).length > 0
        );
        let outOfStock = _.difference(data.results, inStock);
        let sortedData = inStock.concat(outOfStock);
        this.setState({
          ...this.state.data,
          data: sortedData,
          loading: false,
          queryString,
          next: data.next,
          count: data.count,
        });
        return sortedData;
      });
  }

  clearCache = callBack =>
    this.setState({
      ...this.state,
      data: [],
      loading: true,
      queryString: '',
    }, () => {
      if (!!callBack) {
        callBack();
      }
    });

  render() {
    return (
      <ProductContext.Provider value={{
        ...this.state,
        getProducts: this.getProducts,
        refreshProducts: this.refreshProducts,
        loadMore: this.loadMore,
        clearCache: this.clearCache,
      }}>
        {this.props.children}
      </ProductContext.Provider>
    )
  }
}
