import React from 'react';
import ReactDOM from 'react-dom';

import ConfirmModal from '../components/reusable/ConfirmModal.jsx';
import messages from './messages.js';


const getTitle = message => {
  switch(message) {
    case messages.CONFIRM_UNSAVED:
      return "Abandon unsaved changes?";
    default:
      return null;
  }
}


export function getUserConfirmation (message, callback) {
  const modal = document.createElement('div');
  document.body.appendChild(modal);

  const withCleanup = (answer) => {
    ReactDOM.unmountComponentAtNode(modal);
    document.body.removeChild(modal);
    callback(answer);
  }

  ReactDOM.render(
    <ConfirmModal
      show={ true }
      message={ message }
      title={ getTitle(message) }
      onConfirm={ () => withCleanup(true) }
      onClose={ () => withCleanup(false) }
    />,
    modal
  )  
}
