import consts from "../../../../utils/consts";
import { currencyFormatter } from "../../../../utils/general";
import React, { useState } from "react";
import { ViewLinkButton } from "../EditProjectRequestPage";

export const DEFAULT_PROJECT_CHARGES = [];

export const useProjectCharges = () => {
  const [chargeState, setChargeState] = useState(DEFAULT_PROJECT_CHARGES);
  return { chargeState, setChargeState };
};

const DEFAULT_PROJECT_BILLING_STATE = {
  regularHourlyRate: null,
  regularHours: null,
  overtimeHourlyRate: null,
  overtimeHours: null,
  additionalCharges: null,
  charge: null,
  invoiceId: null,
};

export const useProjectBilling = (state) => {
  const [projectBillingState, setProjectBillingState] = useState(
    state || DEFAULT_PROJECT_BILLING_STATE
  );

  return {
    state: { ...projectBillingState },
    setProjectBillingState,
  };
};

export const ProjectRequestBilling = (props) => {
  const {
    regularHours,
    regularHourlyRate,
    overtimeHours,
    overtimeHourlyRate,
    additionalCharges,
    charge,
    invoiceId,
  } = props.billing;
  const invoiceURL = `/billing/${invoiceId}`;

  return (
    <div className="is-marginless p-4" style={styles.boxBorderTop}>
      <article className="media">
        <header className="media-left" style={styles.mediaLeft}>
          <span className="heading">
            <i className="icon-ic-billing" /> Billing
          </span>
        </header>
        <div className="media-content">
          <div className="media" style={{ borderTop: "0px" }}>
            <div className="media-content" style={{ marginRight: "5rem", marginBottom: "2rem" }}>
              <div className="columns">
                <div className="column is-half p-1 is-14px-text">Title</div>
                <div className="column p-1 is-14px-text">Quantity</div>
                <div className="column p-1 is-14px-text has-text-right">Cost</div>
              </div>
              {(regularHours > 0) && (
                <HourlyCharge
                  hours={regularHours}
                  rate={regularHourlyRate}
                  title={"Regular Hours"}
                />
              )}
              {(overtimeHours > 0) && (
                <HourlyCharge
                  hours={overtimeHours}
                  rate={overtimeHourlyRate}
                  title={"Overtime Hours"}
                />
              )}
              {additionalCharges.map((chargeItem) => {
                const { charge: chargeValue, invoiceText } = chargeItem;

                return (
                  <div className="columns" key={invoiceText}>
                    <div className="column is-half p-1 is-14px-text">{invoiceText}</div>
                    <div className="column p-1 is-14px-text"></div>
                    <div className="column p-1 is-14px-text has-text-right">
                      {currencyFormatter(chargeValue)}
                    </div>
                  </div>
                );
              })}
              {charge > 0 && (
                <>
                  <hr style={styles.hrStyle} />
                  <div className="columns">
                    <div className="column is-half p-1 is-14px-text has-text-weight-semibold">
                      Total
                    </div>
                    <div className="column p-1 is-14px-text has-text-right has-text-weight-semibold">
                      {currencyFormatter(charge)}
                    </div>
                  </div>
                </>
              )}
            </div>
            {invoiceId && (
              <aside className="media-right has-text-right">
                <ViewLinkButton text={"View Invoice"} link={invoiceURL} />
              </aside>
            )}
          </div>
        </div>
      </article>
    </div>
  );
};

const HourlyCharge = ({ title, rate, hours }) => {
  return (
    <div className="columns">
      <div className="column is-half p-1 is-14px-text">{title}</div>
      <div className="column p-1 is-14px-text">{hours}</div>
      <div className="column p-1 is-14px-text has-text-right">
        {currencyFormatter(rate * hours)}
      </div>
    </div>
  );
};

const styles = {
  shipmentItems: {
    border: "solid 1px #C6C3CC",
    boxShadow: "0 4px 8px rgba(216, 221, 219, .25)",
  },
  boxBorderTop: {
    borderTop: "1px solid rgb(216, 221, 219)",
  },
  hrStyle: {
    marginLeft: "-3px",
    marginRight: "-3px",
    marginTop: "5px",
    marginBottom: "10px",
    borderTop: "1px solid #ccc",
  },
  mediaLeft: {
    width: "6rem",
  },
  mediaBox: {
    border: "none",
    width: "100%",
    alignItems: "center",
  },
  shipmentHeader: {
    backgroundColor: "rgb(224 237 255 / 25%)",
  },
};
