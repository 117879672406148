import React from 'react';
import { Loader as BulmaLoader } from 'react-bulma-components';

import variables from '../../sass/export.module.scss';


const Loader = ({ size }) => {
  switch (size) {
    case 'small': 
      return <BulmaLoader style={styles.smallSpinner} />
    case 'inline': 
      return <BulmaLoader style={styles.inlineSpinner} />
    default:
      return <BulmaLoader style={styles.bigSpinner} />
  }
}

const styles = {
  bigSpinner: {
    width: '8rem',
    height: '8rem',
    borderLeftColor: variables.purple,
    borderBottomColor: variables.purple,
  },
  smallSpinner: {
    width: '1rem',
    height: '1rem',
    borderLeftColor: variables.purple,
    borderBottomColor: variables.purple,
    margin: '0 auto',
    padding: '1rem',
  },
  inlineSpinner: {
    width: '1rem',
    height: '1rem',
    borderLeftColor: variables.purple,
    borderBottomColor: variables.purple,
    margin: '0 15px',
    padding: '0.5rem',
    display: 'inline-block',
  },
}

export default Loader;
