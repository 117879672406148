import React from "react";
import { currencyFormatter } from "../../../../../utils/general";

interface IOrderShippingPrice {
  price?: string;
  currency?: string;
}
export const OrderShippingPrice: React.FC<IOrderShippingPrice> = (props) => {

  const { price, currency } = props;

  return <>
    {price && (
      <div className="mt-5">
        <div>
          <p className="heading">Shipping Paid</p>
        </div>
        <div>
          {currencyFormatter(price, currency)}
        </div>
      </div>
    )}
  </>
};
