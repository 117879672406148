import { API_BASE, get, post, put } from "../requests";
import { convertResponseToProjectRequestTypeState } from "./ProjectRequestTypeAPIGateway";

const PROJECT_REQUEST_API_ENDPOINT = "api/project/";

export const listProjectRequest = (filters) => {
  return get(PROJECT_REQUEST_API_ENDPOINT, filters).then((response) => ({
    count: response.count,
    results: response.results.map(convertProjectRequestAPIResponse),
  }));
};

export const getProjectRequest = (id) => {
  return get(`${PROJECT_REQUEST_API_ENDPOINT + id}/`).then((response) =>
    convertProjectRequestAPIResponse(response)
  );
};

export const createProjectRequest = (projectRequestState, store) => {
  const payload = convertProjectRequestStateToPayload(projectRequestState, store);
  return post(PROJECT_REQUEST_API_ENDPOINT, payload);
};

export const getWholesaleCost = (orders, isFreight, warehouse) => {
  const payload = { orders: orders, isFreight: isFreight, warehouse: warehouse };
  return get(`${PROJECT_REQUEST_API_ENDPOINT}calculate_wholesale_costs`, payload)
    .then((response) => convertWholesaleCostAPIResponse(response))
    .catch((error) => {
      console.error("Error fetching wholesale costs:", error);
      return DEFAULT_WHOLESALE_STATE;
    });
};

export const deleteProjectRequest = () => {};

export const updateProjectRequest = (id, projectRequestState, store) => {
  const payload = convertProjectRequestStateToPayload(projectRequestState, store);
  return put(`${PROJECT_REQUEST_API_ENDPOINT + id}/`, payload).then(
    convertProjectRequestAPIResponse
  );
};

export const convertProjectRequestAPIResponse = (apiResponseJson) => {
  return {
    uuid: apiResponseJson.uuid,
    name: apiResponseJson.name,
    dueDate: apiResponseJson.due_date,
    completedDate: apiResponseJson.completed_date,
    instructions: apiResponseJson.instructions,
    inputVariants: apiResponseJson.input_variants,
    outputVariants: apiResponseJson.output_variants,
    status: apiResponseJson.status,
    isPhotoRequired: apiResponseJson.is_photo_required,
    isQuoteRequired: apiResponseJson.is_quote_required,
    createdAt: apiResponseJson.created_at,
    variants: apiResponseJson.variants,
    storeId: apiResponseJson.store,
    warehouse: apiResponseJson.warehouse,
    projectRequestType: convertResponseToProjectRequestTypeState(apiResponseJson.project_type),
    orders: apiResponseJson.orders,
    restocks: apiResponseJson.restocks,
    inbounds: apiResponseJson.inbounds,
    estimatedLaborHours: apiResponseJson.estimated_labor_hours,
    isFreight: apiResponseJson.is_palletized,
    fileAttachmentUpload: apiResponseJson.attachments?.map((attachment) => {
      const type = attachment.type.toLowerCase();
      let { url } = attachment;
      if (type === "file" && !url) {
        url = `${API_BASE}${PROJECT_REQUEST_API_ENDPOINT}${apiResponseJson.uuid}/attachments/${attachment.uuid}`;
      }

      return {
        ...attachment,
        url,
      };
    }),
    billing: convertProjectBillingToState(apiResponseJson || {}),
  };
};

export const convertProjectRequestStateToPayload = (projectRequestState, store) => {
  const {
    dueDateState,
    projectInstructionsState,
    selectOrdersState,
    selectRestocksState,
    selectInboundsState,
    projectRequestTypeState,
    fileAttachmentUploadState,
    selectProductState,
    additionalInformationState,
    inputInstructionsState,
    outputInstructionsState,
    selectWarehouseState,
    isFreight,
  } = projectRequestState;
  const { date } = dueDateState;
  const { instructions } = projectInstructionsState;
  const { currentItem: projectRequestType } = projectRequestTypeState || {};
  const { orders } = selectOrdersState;
  const { restocks } = selectRestocksState;
  const { inbounds } = selectInboundsState;
  const { isPhotoRequired, isQuoteRequired } = additionalInformationState;
  const { currentItem: warehouse } = selectWarehouseState;

  const variants = selectProductState.variants.map(variantMapper);

  const inputVariants = inputInstructionsState.variantsState.variants.map(variantMapper);
  const outputVariants = outputInstructionsState.variantsState.variants.map(variantMapper);
  const payload = {
    due_date: date,
    instructions,
    input_variants: inputVariants,
    output_variants: outputVariants,
    project_type_id: projectRequestType?.uuid,
    store: store.uuid,
    warehouse_id: warehouse?.uuid,
    variants,
    is_photo_required: isPhotoRequired,
    is_quote_required: isQuoteRequired,
    attachments: fileAttachmentUploadState.items.map((item) => ({
      uuid: item.uuid,
      url: item.url,
      attachment_type: item.documentType?.uuid,
      file_name: item.file?.name,
      file_size: item.file?.size,
      file_content: item.fileContent,
      file_type: getFileType(item.file?.name),
      type: item.type.toLowerCase(),
    })),
    orders_id: orders.map((o) => o.uuid),
    restocks_id: restocks.map((r) => r.uuid),
    inbounds_id: inbounds.map((r) => r.uuid),
    is_palletized: isFreight,
  };
  return payload;
};

export const getFileType = (fileName) => {
  const fileType = fileName?.split(".").pop().toUpperCase();

  if (fileType === "JPG") {
    return "JPEG";
  }

  return fileType;
};

export const convertProjectBillingToState = (apiResponseJson) => {
  const regularHours = (apiResponseJson.regular_labor_hours)?parseFloat(apiResponseJson.regular_labor_hours):null;
  const overtimeHours = parseFloat(apiResponseJson.overtime_labor_hours || 0);
  const regularHourlyRate = parseFloat(apiResponseJson.regular_hourly_rate || 0);
  const overtimeHourlyRate =
    regularHourlyRate * parseFloat(apiResponseJson.overtime_hourly_rate_multiplier || 1.5);
  const additionalCharges = apiResponseJson?.charges?.map(convertProjectChargesToState);

  const additionalChargesSum =
    (additionalCharges && _.sum(additionalCharges.map((c) => c.charge))) || 0;
  let charge =
    regularHours * regularHourlyRate + overtimeHours * overtimeHourlyRate + additionalChargesSum;
  const invoiceId = apiResponseJson?.invoice_project_item?.invoice;
  const invoiceDisplayStatus = apiResponseJson?.invoice_project_item?.invoice_display_status;

  // if a charge exists on the invoice, show the actual charge instead of what we are calculating here.
  // it should be the same but stranger things happen and we don't want to ever show something different from what we invoice
  if (apiResponseJson?.invoice_project_item) {
    charge = parseFloat(apiResponseJson.invoice_project_item.charge);
  }

  return {
    regularHours,
    overtimeHours,
    regularHourlyRate,
    overtimeHourlyRate,
    additionalCharges,
    charge,
    invoiceId,
    invoiceDisplayStatus,
  };
};

export const convertProjectChargesToState = (projectChargestResponse) => {
  return {
    charge: parseFloat(projectChargestResponse.charge || 0),
    invoiceText: projectChargestResponse.invoice_text,
  };
};

const variantMapper = (variant) => ({
  count: variant.count,
  variant_id: variant.variant.uuid,
});

const DEFAULT_WHOLESALE_STATE = {
  eachPickTotal: 0,
  casePickTotal: 0,
  eachPickRate: 0,
  casePickRate: 0,
  pickTotal: 0,
  orderFee: 0,
  total: 0,
};

export const convertWholesaleCostAPIResponse = (apiResponseJson) => {
  return {
    eachPickTotal: apiResponseJson.each_pick_total,
    casePickTotal: apiResponseJson.case_pick_total,
    eachPickRate: apiResponseJson.each_pick_rate,
    casePickRate: apiResponseJson.case_pick_rate,
    pickTotal: apiResponseJson.pick_total,
    orderFee: apiResponseJson.order_fee,
    pickMinimum: apiResponseJson.pick_minimum,
    total: apiResponseJson.charge,
  };
};