import variables from '../sass/export.module.scss';


export default {
  bigSpinner: {
    width: '8rem',
    height: '8rem',
    borderLeftColor: variables.purple,
    borderBottomColor: variables.purple,
  },
  littleSpinner: {
    width: '1rem',
    height: '1rem',
    borderLeftColor: variables.purple,
    borderBottomColor: variables.purple,
    margin: '0 auto',
    padding: '1rem',
  },
  inlineSpinner: {
    width: '1rem',
    height: '1rem',
    borderLeftColor: variables.purple,
    borderBottomColor: variables.purple,
    margin: '0 15px',
    padding: '0.5rem',
    display: 'inline-block',
  },
  grid: {
    height: 'calc(100vh - 298px)',
    width: '100%',
  },
}
