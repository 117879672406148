import React from "react";

export const PageBody = (props) => {
  const { children } = props;
  return (
    <div className="container is-fluid" style={{ paddingBottom: "6rem" }}>
      {children}
    </div>
  );
};

export default PageBody;
