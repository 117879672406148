import "../CreateInboundOrderForm.scss";

import DeliverySection, { useDeliveryDetails } from "../sections/DeliverySection";
import { PackingListSection, usePackingListSection } from "../sections/PackingListSection";
import React, { useContext, useEffect, useState } from "react";

import { InboundOrderProjectRequestSection } from "../sections/InboundOrderProjectRequestSection";
import { UserContext } from "../../../contexts/UserStore";
import clsx from "clsx";
import generateUniqueInboundOrderName from "../utils";
import { isSudoing } from "../../../../utils/authentication";
import { useProjectRequestForm } from "../../ProjectRequest/components/ProjectRequestForm";
import useScrollSpy from "../../../../utils/hooks/useScrollSpy";
import { useSelectProjectRequestType } from "../../ProjectRequest/fields/SelectProjectRequestType";
import { SelectStoreSection } from "../sections/SelectStoreSection";
import { useSelectStore } from "../fields/SelectStore";
import consts from "../../../../utils/consts";
import { Link } from "react-router-dom";

const DEFAULT_ERROR_STATE = {
  shippingFormatsMissing: false,
  productsMissing: false,
  zeroQuantityProducts: false,
  warehouseMissing: false,
  freightInfoMissing: false,
  freightContainerInfoMissing: false,
  freightPalletMissing: false,
  freightDateMissing: false,
  freightNumberMissing: false,
  duplicateSkuSelected: false,
  lotsInvalid: false,
};

export const useInboundOrderForm = (inputState = {}) => {
  const [inboundOrderState, setInboundOrderState] = useState({
    uuid: null,
    createdAt: null,
    name: generateUniqueInboundOrderName(),
    isLoading: true,
    status: consts.STATUS_DRAFT,
    store: inputState?.inboundOrderState?.store || null,
    projectRequests: inputState?.inboundOrderState?.projectReqeusts || [],
  });
  const [errors, setErrors] = useState(DEFAULT_ERROR_STATE);

  const setStore = (store) => {
    setInboundOrderState((prevState) => ({
      ...prevState,
      store,
    }));
  };

  const setProjectRequests = (projectRequests) => {
    setInboundOrderState((prevState) => ({
      ...prevState,
      projectRequests,
    }));
  };

  const packingListState = usePackingListSection(inputState?.packingListState);
  const deliveryDetailsState = useDeliveryDetails(
    inputState?.deliveryDetailsState,
    inboundOrderState
  );

  const projectRequestTypeState = useSelectProjectRequestType();
  const projectRequestFormState = useProjectRequestForm();

  const isInboundOrderFormValid = () => {
    const { getIsFreight } = deliveryDetailsState.shippingDetails;
    const { isShippingFormatsMissing, hasZeroQuantityShippingFormat } =
      packingListState.shippingFormatsState;
    const { isProductsMissing, hasZeroQuantityProducts, hasDuplicateSkuSelected, areLotsInvalid } = packingListState.selectVariants;
    const { isPackagingSupplyMissing, hasZeroQuantityPackagingSupply } =
      packingListState.packingSuppliesState;
    const { isWarehouseMissing } = deliveryDetailsState.shippingDetails;
    const { carrierDetails } = deliveryDetailsState.shippingDetails;
    const { isFreightInfoMissing, isFreightDateMissing, isFreightNumberMissing, freightCoordination } = carrierDetails;
    const { isFreightPalletMissing } = carrierDetails.freightDetailsAirhouse;
    const { isAirhouseCoordinatingFreight } = freightCoordination;

    const newErrors = {
      lotsInvalid: areLotsInvalid(),
      shippingFormatsMissing: isShippingFormatsMissing(),
      zeroQuantityShippingFormat: hasZeroQuantityShippingFormat(),
      productsMissing: isProductsMissing() && isPackagingSupplyMissing(),
      packagingSupplyMissing: isProductsMissing() && isPackagingSupplyMissing(),
      zeroQuantityProducts: hasZeroQuantityProducts(),
      zeroQuantityPackagingSupply: hasZeroQuantityPackagingSupply(),
      warehouseMissing: isWarehouseMissing(),
      parcelTrackingNumberMissing: !getIsFreight() && isParcelTrackingNumberMissing(),
      freightInfoMissing: getIsFreight() && isFreightInfoMissing(),
      freightContainerInfoMissing: getIsFreight() && isFreightContainerInfoMissing(),
      freightPalletMissing: getIsFreight() && isAirhouseCoordinatingFreight && isFreightPalletMissing(),
      freightDateMissing: getIsFreight() && isFreightDateMissing(),
      freightNumberMissing: getIsFreight() && isFreightNumberMissing(),
      duplicateSkuSelected: hasDuplicateSkuSelected(),
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((value) => value === false);
  };

  const isFreightContainerInfoMissing = () => {
    const { isAirhouseCoordinatingFreight } =
      deliveryDetailsState?.shippingDetails?.carrierDetails?.freightCoordination;
    const { hasContainer } = packingListState?.shippingFormatsState;

    if (!isAirhouseCoordinatingFreight && hasContainer()) {
      const { containerNumber, bolOrTrackingNumber } =
        deliveryDetailsState?.shippingDetails?.carrierDetails?.freightDetails?.freightDetailsState;
      const fields = [containerNumber, bolOrTrackingNumber];
      return fields.some((x) => x === "");
    }
    return false;
  };

  const isParcelTrackingNumberMissing = () => {
    const { hasTrackingNumbers } =
      deliveryDetailsState?.shippingDetails?.carrierDetails?.trackingNumbersState;
    return !hasTrackingNumbers();
  };

  const clearErrors = () => {
    setErrors(DEFAULT_ERROR_STATE);
  };

  return {
    inboundOrderState,
    errors,
    setStore,
    setProjectRequests,
    setInboundOrderState,
    packingListState,
    deliveryDetailsState,
    projectRequestTypeState,
    projectRequestFormState,
    isInboundOrderFormValid,
    clearErrors,
  };
};

const InboundOrderForm = (props) => {
  const userContext = useContext(UserContext);

  const {
    inboundOrderState,
    errors,
    setStore,
    packingListState,
    deliveryDetailsState,
    projectRequestTypeState,
    projectRequestFormState,
    isEditing,
  } = props;
  const { name, store, projectRequests } = inboundOrderState;
  const { hasContainer } = packingListState.shippingFormatsState;

  // store selection -- only used by CreateInboundOrderPage
  const selectStoreState = useSelectStore({ currentItem: store });
  useEffect(() => {
    selectStoreState.setCurrentItem(userContext.store);
  }, [userContext.store]);

  // store selection -- ensure store dialog matches order's store after duplication
  useEffect(() => {
    if (inboundOrderState?.store) {
      selectStoreState.setCurrentItem(inboundOrderState.store);
    }
  }, []);

  const companyStores = userContext.data.account.company.stores || [];

  const handleStoreChanged = (store) => {
    // need to set store on both context and locally here

    // TODO: ask CX what we should do here
    userContext.setStore(store);
    setStore(store);
  };

  // project requests
  const isProjectRequestEnabled = userContext.data.account.company.project_requests_enabled;
  const hasProjectRequests = isProjectRequestEnabled || isSudoing();

  const PACKING_LIST_ID = "Packing List";
  const SHIPPING_FORMAT_ID = ">Shipping Format";
  const PRODUCTS_SELECTOR_ID = ">Products";
  const PACKING_SUPPLIES = ">Packaging Supplies";
  const PACKING_NOTES = ">Packaging Notes";
  const DELIVERY_DETAILS = "Delivery Details";
  const CARRIER_DETAILS = ">Carrier Details";
  const DELIVERY_NOTES = ">Delivery Notes";
  const DELIVERY_ATTACHMENTS = ">Attachments";
  const PROJECT_REQUEST = "Project Request";

  const scrollIds = [
    PACKING_LIST_ID,
    SHIPPING_FORMAT_ID,
    PRODUCTS_SELECTOR_ID,
    PACKING_SUPPLIES,
    PACKING_NOTES,
    DELIVERY_DETAILS,
    CARRIER_DETAILS,
    DELIVERY_NOTES,
    DELIVERY_ATTACHMENTS,
    PROJECT_REQUEST,
  ];

  const packingListScrollIds = {
    packingListId: PACKING_LIST_ID,
    shippingFormatId: SHIPPING_FORMAT_ID,
    productsSelectorId: PRODUCTS_SELECTOR_ID,
    packingSuppliesId: PACKING_SUPPLIES,
    packingNotesId: PACKING_NOTES,
  };

  const deliveryDetailsScrollIds = {
    deliveryDetailsId: DELIVERY_DETAILS,
    carrierDetailsId: CARRIER_DETAILS,
    deliveryNotesId: DELIVERY_NOTES,
    deliveryAttachmentsId: DELIVERY_ATTACHMENTS,
  };

  const isCreating = !inboundOrderState.uuid;

  const activeId = useScrollSpy(scrollIds, 100); // 54 is navigation height
  const scrollToTop = (e) => {
    e.preventDefault();
    // @ts-ignore
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <>
      <div className="columns">
        <div className={clsx("column", "is-narrow")}>
          <aside className="menu sticky-side-nav">
            <ul className="menu-list">
              {scrollIds.map((id, index) => (
                <li key={`menu-item-${scrollIds[index]}`}>
                  <a
                    href={`#${id}`}
                    className={clsx(
                      id === activeId && "is-active",
                      id.startsWith(">") && "ml-4",
                      !id.startsWith(">") && "mt-4"
                    )}
                  >
                    {id.startsWith(">") ? id.replace(">", "") : id}
                  </a>
                </li>
              ))}
              <li>
                <a
                  href="src/components/pages/Inbound/components/InboundOrderForm"
                  className="mt-4"
                  onClick={(e) => scrollToTop(e)}
                >
                  Back to top
                </a>
              </li>
            </ul>
          </aside>
        </div>
        <div className="column">
          {isCreating && companyStores.length > 1 && (
            <SelectStoreSection
              {...selectStoreState}
              items={companyStores}
              onStoreSelected={handleStoreChanged}
            />
          )}
          <PackingListSection
            {...packingListScrollIds}
            {...packingListState}
            name={name}
            inboundOrderState={inboundOrderState}
            store={store}
            isEditing={isEditing}
            errors={errors}
          />
          <DeliverySection
            {...deliveryDetailsScrollIds}
            {...deliveryDetailsState}
            name={name}
            hasContainer={hasContainer}
            store={store}
            isEditing={isEditing}
            errors={errors}
          />
          {hasProjectRequests && !projectRequests.length && (
            <>
              {isEditing ? (
                <InboundOrderProjectRequestSection
                  {...projectRequestFormState}
                  projectRequestTypeState={projectRequestTypeState}
                  store={store}
                />
              ) : (
                <>
                  <h4 className="title is-4 mt-5">Project Requests</h4>
                  <p>
                    To attach a project request to this order visit the Projects tab on the menu
                    bar.
                  </p>
                </>
              )}
            </>
          )}
          {hasProjectRequests && projectRequests.length ? (
            <div className="section-container">
              <div className="scroll-section" id={PROJECT_REQUEST}>
                <h3 className="title is-3">Project Request</h3>
              </div>
              <p className="mt-4">
                To edit projects associated with this inventory shipment, follow the links below.
              </p>
              <ul className="mt-3">
                {projectRequests.map((pr) => (
                  <li key={pr.uuid}>
                    <Link to={`/projects/edit/${pr.uuid}`}>
                      {pr.name}: {pr.project_type.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default InboundOrderForm;
