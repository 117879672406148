import React from "react";

import { Level, Loader } from "react-bulma-components";
import ProjectRequestGrid, {
  ProjectRequestGridFilters,
} from "./ProjectRequestGrid";

export const ProjectRequestTable = (props) => {
  const externalStyles = {};
  const { isLoading, projectRequests, onCellClicked } = props;
  return (
    <div className="container is-fluid">
      {isLoading && (
        <Level>
          <Level.Item>
            <Loader style={externalStyles.grid} />
          </Level.Item>
        </Level>
      )}
      {!isLoading && (
        <>

          <ProjectRequestGrid isLoading={isLoading} projectRequests={projectRequests}
            onCellClicked={onCellClicked}/>
        </>
      )}
    </div>
  );
};

export default ProjectRequestTable;
