import React from "react";
import { Columns,  } from 'react-bulma-components';
import ProjectRequestForm from "../../ProjectRequest/components/ProjectRequestForm";
import SelectProjectRequestType from "../../ProjectRequest/fields/SelectProjectRequestType";

// export const ProjectRequestSection = (props) => {
//   const { projectRequestTypeState } = props;
//   return <section className="section-container">
//     <Columns>
//       <Columns.Column className="is-3">
//         <h2 className={"title is-4"}>Project Request</h2>
//       </Columns.Column>
//       <Columns.Column className="is-9">
//         <SelectProjectRequestType {...projectRequestTypeState} hasRestocks={true} />
//         <ProjectRequestForm {...props} projectRequestType={projectRequestTypeState?.currentItem} isInline={true} />
//       </Columns.Column>
//     </Columns>
//   </section>;
// }

export const InboundOrderProjectRequestSection = (props) => {
  const { projectRequestTypeState } = props;
  return (
    <div className="section-container">
      <Columns>
        <Columns.Column size={3}>
          <p
            data-testid="upload-document-subheading"
            className={"title is-4 mb-4"}
          >
            Project Request
          </p>
          <p>
            Additional services that can be added to existing orders and other
            store fulfillment needs.
          </p>
        </Columns.Column>
        <Columns.Column>
          {" "}
          <div className="section-container">

            {/* NOTE: want to select types only where hasOrders == true (otherwise we see all types) */}
            <SelectProjectRequestType {...projectRequestTypeState} hasInbounds={true} />
          </div>
          <ProjectRequestForm
            isInline={true}
            {...props}

            // don't allow orders or restocks to be searched, we are creatin a new project for THIS order
            hasOrders={false}
            hasRestocks={false}
            hasInbounds={false}
            projectRequestType={projectRequestTypeState?.currentItem}
          />
        </Columns.Column>
      </Columns>
    </div>
  );
}
