import React, { useState } from "react";

import { useTrackingCarrier, TrackingCarrier } from "./TrackingCarrier";
import { useTrackingNumber, TrackingNumber } from "./TrackingNumber";

export const useMultipleTrackingInput = () => {
  const [trackingNumber, setTrackingNumber] = useTrackingNumber();
  const [trackingCarrier, setTrackingCarrier] = useTrackingCarrier();
  const [trackingError, setTrackingError] = useState({});

  const reset = () => {
    setTrackingCarrier("");
    setTrackingNumber("");
  };

  return {
    trackingNumber,
    setTrackingNumber,
    trackingCarrier,
    setTrackingCarrier,
    trackingError,
    setTrackingError,
    reset,
  };
};

const TrackingNumbersInput = (props) => {
  const { trackingInputState, addTrackingNumber } = props;
  const {
    trackingNumber,
    setTrackingNumber,
    trackingCarrier,
    setTrackingCarrier,
  } = trackingInputState;
  return (
    <>
      <div className="field has-addons">
        <p className="control">
          <TrackingCarrier
            trackingCarrier={trackingCarrier}
            setTrackingCarrier={setTrackingCarrier}
          />
        </p>
        <p className="control is-expanded">
          <TrackingNumber
            trackingNumber={trackingNumber}
            setTrackingNumber={setTrackingNumber}
          />
        </p>
        <p className="control">
          <button className="button is-secondary" onClick={addTrackingNumber}>
            Add
          </button>
        </p>
      </div>
    </>
  );
};

export default TrackingNumbersInput;
