import React from 'react';

const SuggestionButton = (props) => {
  return (
    <button className="button is-text is-small p-2" onClick={props.replaceField}>
      <p>
        Use suggested: <strong>{props.field}</strong>
      </p>
    </button>
  )
}

export default SuggestionButton;
