import React from 'react';

export const DuplicateButton = (props) => {
  const { isEnabled } = props;
  const duplicateIcon = (
    <>
      <i className="icon-ic-duplicate" />
      <span className="ml-2">Duplicate</span>
    </>
  );
  const yesDuplicate = (
    <a className="dropdown-item" onClick={props.onClick}>
      {duplicateIcon}
    </a>
  );
  const noDuplicate = <div className="dropdown-item">{duplicateIcon}</div>;

  return isEnabled ? yesDuplicate : noDuplicate;
};