import React from 'react';
import { Link } from 'react-router-dom';
import { Loader, Tag } from 'react-bulma-components';
import externalStyles from '../../../utils/styles';
import orderStatus from '../../../utils/grid/orderStatus';
import { currencyFormatter } from '../../../utils/general';
import paymentStatus from '../../../utils/grid/paymentStatus';
import messages from '../../../utils/messages';
import { shopify } from '../../../utils/ecomm';
import { isSudoing } from '../../../utils/authentication';
import { Recipient } from './Recipient';
import AdminPanel from './AdminPanel';
import consts, { COMBINED_STATUS_PARTIAL_DEFAULT } from '../../../utils/consts';
import apiEndpoints from '../../../utils/apiEndpoints.js';
import { toast } from 'react-toastify';
import { UpRightArrowBox } from '../../../assets/icons/components/UpRightArrowBox';
import { InvoiceDisplayStatus } from '../Billing/InvoiceHelpers';
import { editOrderDataType, itemsType } from './Edit/config/editOrder.state';
import sum from 'lodash/sum';
import has from 'lodash/has';
import flatten from 'lodash/flatten';
import {
  IFulfillment,
  IFulfillmentShipments,
  ItemsHydrated,
} from '../../../interfaces/fulfillment.interfaces';
import { OrderShippingPrice } from "./NewOrder/OrderShippingInfo/OrderShippingPrice";

const moment = require('moment-timezone');

export const EditOrderOverview: React.FC<{
  isAdmin: boolean;
  data: editOrderDataType & {
    force_ship?: boolean;
    partial_fulfillment?: boolean;
    high_priority?: boolean;
    shipping_name?: string;
    shipping_account?: string;
    ship_window_start?: string;
    ship_window_end?: string;
    shipping_price?: string;
    shipping_currency?: string;
  };
}> = ({ data, isAdmin }) => {
  const [forceShip, setForceShip] = React.useState(data?.force_ship || false);
  const [partialFulfillment, setPartialFulfillment] = React.useState(
    data?.partial_fulfillment || false
  );
  const [highPriority, setHighPriority] = React.useState(data?.high_priority || false);

  const toggleForceShipClick = (orderId: string) => {
    const newToggleState = !forceShip;
    setForceShip(newToggleState);

    apiEndpoints.toggleForceShip(orderId).then((response) => {
      if (!response.ok) {
        setForceShip(!newToggleState);
        toast.error('Error communicating with server');
      }
    });
  };

  const togglePartialFulfillmentClick = (orderId: string) => {
    const newToggleState = !partialFulfillment;
    setPartialFulfillment(newToggleState);

    apiEndpoints.togglePartialFulfillment(orderId).then((response) => {
      if (!response.ok) {
        setPartialFulfillment(!newToggleState);
        toast.error('Error communicating with server');
      }
    });
  };

  const toggleHighPriorityClick = (orderId: string) => {
    const newToggleState = !highPriority;
    setHighPriority(newToggleState);

    apiEndpoints.toggleHighPriority(orderId).then((response) => {
      if (!response.ok) {
        setHighPriority(!newToggleState);
        toast.error('Error communicating with server');
      }
    });
  };

  let totalCost = '';
  const invoicedShipments: Array<IFulfillmentShipments & { shipmentIndex: number }> = [];

  if (data && has(data, 'fulfillments')) {
    // Need to compute shipmentIndex relative to airhouse and non-airhouse shipments so that
    // index lines up with `ShipmentStatus` shipment index
    let shipmentIndex = 1;
    data.fulfillments.forEach((fulfillment) => {
      fulfillment.shipments.forEach((shipment) => {
        const isAirhouseShipped = fulfillment.process === consts.PROCESS_SHIP;
        const hasNonDraftInvoiceFulfillmentItems =
          shipment?.invoice_fulfillment_items
            .map((ifi) => ifi.invoice_status !== consts.STATUS_DRAFT)
            .some((v) => v === true) || false;

        if (isAirhouseShipped && hasNonDraftInvoiceFulfillmentItems) {
          invoicedShipments.push({
            shipmentIndex,
            ...shipment,
          });
        }
        shipmentIndex += 1;
      });
    });

    totalCost = sum(
      invoicedShipments.map((shipment) =>
        sum(
          (shipment?.invoice_fulfillment_items &&
            shipment.invoice_fulfillment_items.map(
              (ifi) => (ifi.invoice_status !== consts.STATUS_DRAFT && ifi.total) || 0
            )) ||
            0
        )
      )
    ).toFixed(2);
  }

  if (isAdmin) console.log('invoicedShipments', invoicedShipments);
  if (isAdmin) console.log('data', data);

  const hasPartialFulfillment =
    data.combined_status.toLowerCase() === COMBINED_STATUS_PARTIAL_DEFAULT &&
    data.items.some((item) => !item.fulfillment_items?.length);
  const customFulfillmentButtonsEnabled = data.fulfillments.length === 0 || hasPartialFulfillment;

  return (
    <div>
      <div className="mt-5">
        <div>
          <p className="heading">Order Status</p>
        </div>
        <div className={'mr-2 ' + orderStatus.getClasses(data.combined_status).join(' ')}>
          {orderStatus.getDisplayName(data.combined_status)}
          <span
            className="tooltip is-tooltip-right is-tooltip-multiline ml-1"
            data-tooltip={orderStatus.getDescription(data.combined_status)}
          >
            <i className="icon-ic-info" />
          </span>
        </div>
      </div>

      <div className="mt-5">
        <div>
          <p className="heading">Set High Priority</p>
          <span
            className="tooltip is-tooltip-right is-tooltip-multiline pl-1"
            data-tooltip="This order will be given higher priority than other unfulfilled orders."
          >
            <i className="icon-ic-info" />
          </span>
        </div>
        <div className="control p-2">
          <input
            className="switch is-small"
            id="prioritySwitch"
            type="checkbox"
            name="prioritySwitch"
            checked={highPriority}
            onChange={() => toggleHighPriorityClick(data.uuid)}
            disabled={!customFulfillmentButtonsEnabled}
          />
          <label htmlFor="prioritySwitch" className="has-text-weight-light is-size-6"></label>
        </div>
      </div>

      <div>
        <div>
          <p className="heading">Bypass Checks</p>
          <span
            className="tooltip is-tooltip-right is-tooltip-multiline pl-1"
            data-tooltip="Disregard store rules. If this is a Shopify order, also disregard Shopify financial status."
          >
            <i className="icon-ic-info" />
          </span>
        </div>
        <div className="control p-2">
          <input
            className="switch is-small"
            id="forceShipSwitch"
            type="checkbox"
            name="forceShipSwitch"
            checked={forceShip}
            onChange={() => toggleForceShipClick(data.uuid)}
            disabled={!customFulfillmentButtonsEnabled}
          />
          <label htmlFor="forceShipSwitch" className="has-text-weight-light is-size-6"></label>
        </div>
      </div>

      <div>
        <div>
          <p className="heading">Enable Partial Fulfillment</p>
          <span
            className="tooltip is-tooltip-right is-tooltip-multiline pl-1"
            data-tooltip="Allow this order to be sent in multiple shipments as items become available. Additional costs will apply to each shipment."
          >
            <i className="icon-ic-info" />
          </span>
        </div>
        <div className="control p-2">
          <input
            className="switch is-small"
            id="splitFulfillSwitch"
            type="checkbox"
            name="splitFulfillSwitch"
            checked={partialFulfillment}
            onChange={() => togglePartialFulfillmentClick(data.uuid)}
            disabled={!customFulfillmentButtonsEnabled}
          />
          <label htmlFor="splitFulfillSwitch" className="has-text-weight-light is-size-6"></label>
        </div>
      </div>

      {!!data.shopify && (
        <div className="mt-5">
          <div>
            <p className="heading">Payment Status</p>
          </div>
          <div>
            <span
              className={
                'mr-2 ' + paymentStatus.getClasses(data.shopify.financial_status).join(' ')
              }
            >
              {paymentStatus.getDisplayName(data.shopify.financial_status)}
            </span>
          </div>
        </div>
      )}

      <div className="mt-5">
        <div>
          <p className="heading">Shipping Speed</p>
        </div>
        <div>
          {data.shipping_name ?? <span className="tag is-disabled">Default Shipping Method</span>}
        </div>
      </div>

      <OrderShippingPrice
        price={data.shipping_price}
        currency={data.shipping_currency}
      />


      {!!data.shipping_account && (
        <div className="mt-5">
          <div>
            <p className="heading">Shipping Account</p>
          </div>
          <div>{data.shipping_account}</div>
        </div>
      )}

      {(!!data.ship_window_start || !!data.ship_window_end) && (
        <div className="mt-5" style={{ display: 'block' }}>
          <p className="heading">Ship Date</p>
          {
            !!data.ship_window_start ? (
              !!data.ship_window_end ? (
                // Both start and end exists
                <p>
                  {moment(data.ship_window_start).format('MMMM D, YYYY')} -{' '}
                  {moment(data.ship_window_end).format('MMMM D, YYYY')}
                </p>
              ) : (
                // Only start exists"
                <p>After {moment(data.ship_window_start).format('MMMM D, YYYY')}</p>
              )
            ) : (
              !!data.ship_window_end && (
                // Only end exists
                <p>Before {moment(data.ship_window_end).format('MMMM D, YYYY')}</p>
              )
            )
            // No shipping window
          }
        </div>
      )}

      {data.shipping && (
        <div className="mt-5">
          <Recipient isNewOrder={false} customer={data.customer} shipping={data.shipping} />
        </div>
      )}

      {(!!data.notes.length || !!data.attachments.length) && (
        <div className="mt-5">
          <div>
            <p className="heading">Notes & Instructions</p>
          </div>
          <div>{(data.notes[0] as any).body}</div>
        </div>
      )}

      {invoicedShipments.length > 0 && +totalCost > 0 && (
        <div className="pt-5">
          <div className="columns py-0 my-0">
            <div className="column is-5 py-0 my-0">
              <p className="heading">Total Cost</p>
            </div>
            <div className="column is-3 py-0 my-0 has-text-right">
              <span className="is-14px-text">{`$${currencyFormatter(totalCost)}`}</span>
            </div>
          </div>

          {invoicedShipments.map((shipment) => {
            return (
              <div key={shipment.uuid} className="pt-2 pb-2">
                <p className="heading ">{`Shipment ${shipment.shipmentIndex}`}</p>
                {shipment.invoice_fulfillment_items.map((ifi) => {
                  return (
                    ifi.invoice_status !== consts.STATUS_DRAFT && (
                      <div key={ifi.uuid} className="columns pt-1">
                        <div className="column is-5 py-0 my-0">
                          <Link
                            className="is-flex is-align-items-center is-14px-link"
                            to={`/billing/${ifi.invoice_id}`}
                          >
                            <span className="pr-1">Invoice</span>
                            <span className="pr-2">
                              <UpRightArrowBox />
                            </span>
                            <InvoiceDisplayStatus displayStatus={ifi.invoice_display_status} />
                          </Link>
                        </div>
                        <div className="column is-3 py-0 my-0 has-text-right">
                          <span className="is-14px-text">{`${currencyFormatter(ifi.total)}`}</span>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            );
          })}
        </div>
      )}

      {data.source === consts.SHOPIFY && (
        <>
          <div className="mt-5">
            <div>
              <p className="heading">Shopify Tags</p>
            </div>
            <div className="has-text-grey">
              {!!data.shopify.tags.length
                ? data.shopify.tags.map((tag, tagIndex) => (
                    <Tag key={`shop-tag-${tagIndex}`} className={'is-light is-small'}>
                      {tag}
                    </Tag>
                  ))
                : 'No tags'}
            </div>
          </div>
          <div className="mt-5">
            <div>
              <p className="heading">Shopify Notes</p>
            </div>
            <div className="has-text-grey" style={{ overflowWrap: 'break-word' }}>
              {data.shopify.note.trim() || 'No notes from customer'}
            </div>
          </div>
        </>
      )}

      {isSudoing() && data.uuid && (
        <div className="mt-5 pr-2">
          <AdminPanel data={data as any} />
        </div>
      )}
    </div>
  );
};

export const AddressErrorBar: React.FC<{ ssResponse: string }> = ({ ssResponse }) => {
  let errBar;

  if (ssResponse === messages.ADDRESS_VALID) {
    errBar = (
      <div className="help order-success">
        <span className="icon">
          <i className="fas fa-check-circle" />
        </span>
        <span>Address validated.</span>
      </div>
    );
  } else if (ssResponse === messages.ADDRESS_INVALID_ERROR) {
    errBar = (
      <div className="help is-danger order-errors">
        <span className="icon">
          <i className="icon-ic-warning" />
        </span>
        <span>{ssResponse}</span>
      </div>
    );
  } else {
    errBar = (
      <div className="help order-warnings">
        <span className="icon">
          <i className="icon-ic-warning" />
        </span>
        <span>{ssResponse}</span>
      </div>
    );
  }

  return <div className="is-inline-block">{errBar}</div>;
};

// Utils functions
const isItemShipped = (fulfillments: Array<IFulfillment>, item: ItemsHydrated) => {
  return !!flatten(
    fulfillments.map((fulfillment) =>
      flatten(fulfillment.shipments.map((shipment) => shipment.items))
    )
  ).find((shippedItemId) => shippedItemId === item.uuid);
};

export const isItemEditable = (
  item: ItemsHydrated,
  isDuplicating: boolean,
  removedVariantIds: Array<string>,
  fulfilledVariantIds: Array<string>,
  fulfillments: Array<IFulfillment>
) => {
  const { uuid, should_fulfill } = item.variant;
  // Newly added item editable
  return (
    !item.uuid ||
    // Editable during order duplication
    (isDuplicating && !removedVariantIds.includes(uuid)) ||
    // Existing item editable if: not yet fulfilled
    (should_fulfill && !fulfilledVariantIds.includes(uuid) && !removedVariantIds.includes(uuid)) ||
    // Existing item editable if: fulfilled, not yet shipped
    (should_fulfill && !isItemShipped(fulfillments, item) && !removedVariantIds.includes(uuid))
  );
};

export const calculateDiff = (item: ItemsHydrated, originalData: any, isDuplicating: boolean) => {
  if (!originalData || isDuplicating) return null;

  let originalItem = originalData.items.find(
    (originalItem: ItemsHydrated) => originalItem.uuid === item.uuid
  );

  return !!originalItem ? item.count - originalItem.count : item.count;
};

export const getTotalPrice = (items: Array<itemsType>) => {
  if (items.length) {
    return items
      .map((item) => +item.price * +item.count)
      .reduce((total, itemPrice) => total + itemPrice)
      .toFixed(2);
  }
  return 0;
};

// Modals on order
export const CancelProgressMessage: React.FC<{
  source?: string;
  p1Order?: boolean;
  p2Order?: boolean;
  fulfillments?: Array<IFulfillment>;
  p1Fulfillment?: boolean;
  p2Fulfillment?: boolean;
  shouldCancelOrder?: boolean;
  cancelOrderResponse?: {
    airhouse: string;
  };
}> = ({
  source,
  p1Order,
  p2Order,
  fulfillments,
  p1Fulfillment,
  p2Fulfillment,
  shouldCancelOrder,
  cancelOrderResponse,
}) => {
  const fulfillmentStatus = (fulfillment: IFulfillment & { response?: any }, type: string) => {
    if (p1Fulfillment) return <Loader style={externalStyles.inlineSpinner} />;

    if (p2Fulfillment) {
      if (fulfillment.response.status == undefined) {
        return (
          <span className="icon has-text-danger">
            <i className="icon-ic-close" />
          </span>
        );
      }

      switch (fulfillment.response.status[type]) {
        case 'PROCESSED':
          return (
            <span className="icon has-text-success">
              <i className="icon-ic-checkmark" />
            </span>
          );
        default:
          return (
            <span className="icon has-text-danger">
              <i className="icon-ic-close" />
            </span>
          );
      }
    }
  };

  const orderStatus = () => {
    if (p1Order) return <Loader style={externalStyles.inlineSpinner} />;

    if (p2Order) {
      switch (cancelOrderResponse?.airhouse) {
        case 'PROCESSED':
          return (
            <span className="icon has-text-success">
              <i className="icon-ic-checkmark" />
            </span>
          );
        default:
          return (
            <span className="icon has-text-danger">
              <i className="icon-ic-close" />
            </span>
          );
      }
    }
  };

  return (
    <ul>
      {fulfillments?.map((fulfillment) => (
        <div key={fulfillment.uuid}>
          <p className="heading">{fulfillment.name} Cancel Status:</p>
          <ul className="has-text-grey mt-0">
            <li className="is-size-6">
              <span>Warehouse Status: </span>
              <span style={{ verticalAlign: 'middle' }}>
                {fulfillmentStatus(fulfillment, 'warehouse')}
              </span>
            </li>
            {source === shopify && (
              <li className="is-size-6">
                Shopify Status: {fulfillmentStatus(fulfillment, 'shopify')}
              </li>
            )}
          </ul>
        </div>
      ))}
      {shouldCancelOrder && source === consts.SHOPIFY && (
        <div>
          <p className="heading">Cancel Order Status:</p>
          <ul className="has-text-grey mt-0">
            <li className="is-size-6">
              <span>Shopify status: </span>
              <span style={{ verticalAlign: 'middle' }}>{orderStatus()}</span>
            </li>
          </ul>
        </div>
      )}
    </ul>
  );
};

export const CancelOrderConfirmMessage: React.FC<{
  shouldCancelOrder: boolean;
  toggleShouldCancel: () => void;
  toggleShouldNotCancel: () => void;
}> = ({ shouldCancelOrder, toggleShouldCancel, toggleShouldNotCancel }) => (
  <>
    <div>Would you like to cancel the order as well?</div>
    <div className="tile is-ancestor mt-1">
      <div className="tile is-parent">
        <div className="tile is-child">
          <label className="radio">
            <input
              type="radio"
              name="cancelorder"
              defaultChecked={shouldCancelOrder}
              onChange={toggleShouldCancel}
            />
            <span className="is-size-6 ml-2">Cancel Entire Order</span>
            <div className="is-size-7 has-text-grey-light mt-1">
              The order will be cancelled. To fulfill again you will need to create a new order.
            </div>
          </label>
        </div>
      </div>
      <div className="tile is-parent">
        <div className="tile is-child">
          <label className="radio">
            <input
              type="radio"
              name="cancelorder"
              defaultChecked={!shouldCancelOrder}
              onChange={toggleShouldNotCancel}
            />
            <span className="is-size-6 ml-2">Cancel Open Fulfillments</span>
            <div className="is-size-7 has-text-grey-light mt-1">
              In progress fulfillments will be canceled. You can resubmit at a later time.
            </div>
          </label>
        </div>
      </div>
    </div>
  </>
);
