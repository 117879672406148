import React, { useState } from "react";
import NavBar from "../../NavBar";
import { Link, useHistory } from "react-router-dom";
import { PageHeader } from "../../reusable/page/PageHeader";
import PageFooter from "../../reusable/page/PageFooter";
import externalStyles from "../../../utils/styles";

import {
  ADMIN_URL,
  PAGE_TITLE as PROJECT_REQUEST_PAGE_TITLE,
  PAGE_URL as PROJECT_REQUEST_PAGE_URL,
} from "./ProjectRequestHomePage";
import PageBody from "../../reusable/page/PageBody";
import { isSudoing } from "../../../utils/authentication";
import { ProjectRequestContext } from "../../contexts/ProjectRequestStore";
import { Columns, Loader } from "react-bulma-components";
import { ProjectRequestView } from "./components/ProjectRequestView";
import { SaveButton } from "../../reusable/page/buttons/SaveButton";
import { Overview } from "../../reusable/page/content/Overview";
import {
  getProjectRequest,
  updateProjectRequest,
} from "../../../utils/gateway/ProjectRequestAPIGateway";
import { useEditProjectRequestPage } from "./useEditProjectRequestPage";
import { ProductRequestStatusTag } from "./components/ProductRequestStatusTag";
import { currencyFormatter, dateFormatter } from "../../../utils/general";
import { UserContext } from "../../contexts/UserStore";
import { PageLoadingBar } from "../../reusable/page/loaders/PageLoadingBar";
import ProjectRequestForm from "./components/ProjectRequestForm";
import { PAGE_URL as CREATE_PAGE_URL } from "./CreateProjectRequestPage";
import { ViewInAdmin } from "../../reusable/page/menu/ViewInAdmin";
import { AdminControl } from "../../reusable/page/menu/AdminControl";
import { EditButton } from "../../reusable/page/menu/button/EditButton";
import { DuplicateButton } from "../../reusable/page/menu/button/DuplicateButton";
import consts from "../../../utils/consts";
import { UpRightArrowBox } from "../../../assets/icons/components/UpRightArrowBox";
import { ProjectRequestBilling } from "./components/ProjectBillingInformation";
import { adminOrderUrl } from "../../../utils/ecomm";
import { InvoiceDisplayStatus } from "../Billing/InvoiceHelpers";

export const PAGE_URL = "/projects/edit/:projectRequestId";
export const DEFAULT_PAGE_TITLE = "Edit Project Request";

export const EditProjectRequestPage = (props) => {
  const userContext = React.useContext(UserContext);
  const history = useHistory();
  const [isFreight, setIsFreight] = useState(false);

  const { projectRequestId } = props.match.params;

  const isProjectRequestEnabled = props.data.account.company.project_requests_enabled;

  const hasProjectRequests = isProjectRequestEnabled || isSudoing();
  const isAdmin = isSudoing();
  const {
    state,
    setIsSaving,
    projectRequestType,
    loadProjectRequest,
    formState,
    setIsEditing,
    storeId,
  } = useEditProjectRequestPage();

  React.useEffect(() => {
    getProjectRequest(projectRequestId).then((responseBody) => {
      setIsFreight(responseBody.isFreight);
      loadProjectRequest(responseBody);
    });
  }, []);

  const store = userContext.data.stores.find((s) => s.uuid === storeId);

  const { isSaving, isLoading, isEditing } = state;
  const isSaveDisabled = isSaving || isLoading;
  const pageTitle =
    `${projectRequestType?.title} - ${formState.projectRequestState.name}` || DEFAULT_PAGE_TITLE;

  const onSaveProjectRequestChanges = () => {
    setIsSaving(true);
    updateProjectRequest(formState.projectRequestState.uuid, { ...formState, isFreight} , store)
      .then((newProjectRequestState) => {
        setIsEditing(false);
        loadProjectRequest(newProjectRequestState);
      })
      .finally(() => setIsSaving(false));
  };

  const onDuplicateButtonClick = () => {
    const state = JSON.parse(
      JSON.stringify({
        formState,
        projectRequestType,
        store,
      })
    );
    history.push({
      pathname: CREATE_PAGE_URL,
      state,
    });
  };

  // if zero, don't show billing info
  const hasBillingInformation = formState?.billingState?.state?.charge !== 0;

  return hasProjectRequests ? (
    <>
      <NavBar {...props} activeTab={PROJECT_REQUEST_PAGE_TITLE} />
      {isLoading && <PageLoadingBar />}
      {!isLoading && (
        <>
          <PageHeader
            title={pageTitle}
            returnLinkURL={PROJECT_REQUEST_PAGE_URL}
            returnLinkText={PROJECT_REQUEST_PAGE_TITLE}
            menu={
              <HeaderMenu
                isAdmin={props.data.isAdmin}
                onEditButtonClick={() => setIsEditing(true)}
                onDuplicateButtonClick={onDuplicateButtonClick}
              />
            }
            adminMenu={
              <AdminControl
                options={
                  <>
                    <ViewInAdmin url={`${ADMIN_URL}${formState.projectRequestState.uuid}`} />
                  </>
                }
              />
            }
            isAdmin={isAdmin}
          />
          <PageBody>
            <div className="container is-max-desktop max-width">
              <Columns>
                <Overview>
                  <ProjectRequestEditOverview
                    {...formState.projectRequestState}
                    warehouse={formState.selectWarehouseState?.currentItem}
                    billing={formState.billingState.state}
                  />
                </Overview>
                <Columns.Column>
                  {hasBillingInformation && (
                    <ProjectRequestBilling
                      charges={formState.chargesState.chargeState}
                      billing={formState.billingState.state}
                    />
                  )}

                  {isEditing && (
                    <ProjectRequestForm
                      store={store}
                      {...formState}
                      projectRequestType={projectRequestType}
                      isFreight={isFreight}
                      setIsFreight={setIsFreight}
                    />
                  )}
                  {!isEditing && (
                    <ProjectRequestView {...formState} projectRequestType={projectRequestType} isFreight={isFreight} />
                  )}
                </Columns.Column>
              </Columns>
            </div>
          </PageBody>
          {isEditing && (
            <PageFooter>
              <Link className="button is-text" to="/returns">
                Go back
              </Link>

              <SaveButton
                onSave={onSaveProjectRequestChanges}
                isLoading={isSaving}
                isDisabled={isSaveDisabled}
                text="Save Changes"
              />
            </PageFooter>
          )}
        </>
      )}
    </>
  ) : (
    ""
  );
};

export const HeaderMenu = (props) => {
  const { isLoading, onEditButtonClick, onDuplicateButtonClick, isAdmin } = props;
  const canEdit = true;
  const canDuplicate = true;

  if (!isAdmin) {
    return <></>;
  }

  return (
    <div className={"dropdown mr-4 is-hoverable"}>
      <div className="dropdown-trigger">
        <button
          className="button is-primary is-pulled-right is-hidden-mobile"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          <span>Project</span>
          <span className="icon is-small">
            <i className="icon-ic-caret" aria-hidden="true" />
          </span>
        </button>
      </div>
      <div
        className="dropdown-menu"
        id="dropdown-menu"
        role="menu"
        style={{ right: 0, left: "auto" }}
      >
        <div className="dropdown-content">
          {isLoading && <Loader style={externalStyles.inlineSpinner} />}
          {!isLoading && (
            <>
              <DuplicateButton isEnabled={canDuplicate} onClick={onDuplicateButtonClick} />
              <EditButton isEnabled={canEdit} onClick={onEditButtonClick} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const ProjectRequestEditOverview = (props) => {
  const { status, createdAt, completedDate, warehouse, store, billing } = props;
  return (
    <>
      <ProductRequestStatusTag status={status} />

      <div className="mt-5">
        <p className="heading">Date Submitted</p>
        <p>{dateFormatter(createdAt)}</p>
      </div>
      {completedDate && (
        <div className="mt-5">
          <p className="heading">Date Completed</p>
          <p>{dateFormatter(completedDate)}</p>
        </div>
      )}
      {warehouse && (
        <div className="mt-5">
          <p className="heading">Warehouse</p>
          <p>{warehouse?.display}</p>
        </div>
      )}
      {billing?.charge > 0 && (
        <>
          <div className="mt-5">
            <div className="columns py-0 my-0">
              <div className="column is-5 py-0 my-0">
                <p className="heading">Total Cost</p>
              </div>
              <div className="column is-3 py-0 my-0 has-text-right">
                <span className="is-14px-text">{`${currencyFormatter(billing.charge)}`}</span>
              </div>
            </div>
            {billing?.invoiceDisplayStatus && (
              <div className="columns py-0 my-0">
                <div className="column is-5 py-0 my-0">
                  <Link
                    className="is-flex is-align-items-center is-14px-link"
                    to={`/billing/${billing?.invoiceId}`}
                  >
                    <span className="pr-1">Invoice</span>
                    <span className="pr-2">
                      <UpRightArrowBox />
                    </span>
                    <InvoiceDisplayStatus displayStatus={billing.invoiceDisplayStatus} />
                  </Link>
                </div>
                <div className="column is-3 py-0 my-0 has-text-right">
                  <span className="is-14px-text">{`${currencyFormatter(billing.charge)}`}</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

const ProjectTotalCost = (props) => {};

export const ViewLinkButton = ({ text, link }) => {
  return (
    <Link
      className="is-flex is-align-items-center button is-secondary is-small is-uppercase"
      to={link}
    >
      <span className="mr-1">{text}</span>
      <UpRightArrowBox />
    </Link>
  );
};

const styles = {
  shipmentItems: {
    border: "solid 1px #C6C3CC",
    boxShadow: "0 4px 8px rgba(216, 221, 219, .25)",
  },
  boxBorderTop: {
    borderTop: "1px solid rgb(216, 221, 219)",
  },
  hrStyle: {
    marginLeft: "-3px",
    marginRight: "-3px",
    marginTop: "5px",
    marginBottom: "10px",
    borderTop: "1px solid #ccc",
  },
  mediaLeft: {
    width: "6rem",
  },
  mediaBox: {
    border: "none",
    width: "100%",
    alignItems: "center",
  },
  shipmentHeader: {
    backgroundColor: "rgb(224 237 255 / 25%)",
  },
};

export default EditProjectRequestPage;
