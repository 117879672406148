import React, { useState } from "react";
import clsx from "clsx";
import DatePicker from "../../../reusable/datepicker/DatePicker";
import { useDatePicker } from "../../../reusable/datepicker/useDatepicker";
import { ValidationError } from "../../../reusable/ValidationError";

const DEFAULT_STATE = {
  freightCompany: "",
  freightPickupLocation: "", // optional
  contactFirstName: "",
  contactLastName: "",
  contactEmail: "",
  contactPhone: "",
  referenceNumber: "",
  containerNumber: "",
  bolOrTrackingNumber: "",
};

export const useFreightDetails = (inputState = {}) => {
  const [freightDetailsState, setFreightDetailsState] = useState(() => ({
    ...DEFAULT_STATE,
    ...inputState,
  }));
  const deliveryDateState = useDatePicker(inputState.deliveryDateState || { minDate: new Date() });

  // TODO: refactor to use a simpler API here
  const updateValue = (key, value) => {
    setFreightDetailsState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const reset = () => {
    setFreightDetailsState({ ...DEFAULT_STATE });
  };

  return {
    freightDetailsState,
    deliveryDateState,
    updateValue,
    setFreightDetailsState,
    reset,
  };
};

const FreightDetails = (props) => {
  const { freightDetailsState, deliveryDateState, updateValue, hasContainer, isEditing, errors } =
    props;
  const {
    freightCompany,
    freightPickupLocation,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhone,
    referenceNumber,
    containerNumber,
    bolOrTrackingNumber,
  } = freightDetailsState;

  const handleChange = (event) => {
    updateValue(event.target.name, event.target.value);
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    updateValue(name, value === "yes");
  };

  return (
    <>
      {/*Create new `columns` div for each row so that mobile single column layout follows same flow as desktop.*/}
      {errors.freightInfoMissing && (
        <ValidationError message={"Please include all contact information."} />
      )}
      <div className="columns">
        <div className={clsx("column")}>
          <p className="heading">Freight company</p>
          <input
            className="input"
            type="text"
            name="freightCompany"
            value={freightCompany}
            onChange={handleChange}
            disabled={!isEditing}
          />
        </div>
        <div className={clsx("column")}>
          <p className="heading">Freight Pick Up location (Optional)</p>
          <input
            className="input"
            type="text"
            name="freightPickupLocation"
            value={freightPickupLocation}
            onChange={handleChange}
            disabled={!isEditing}
          />
        </div>
      </div>
      <div className="columns">
        <div className={clsx("column")}>
          <p className="heading">Contact first name</p>
          <input
            className="input"
            type="text"
            name="contactFirstName"
            value={contactFirstName}
            onChange={handleChange}
            disabled={!isEditing}
          />
        </div>
        <div className={clsx("column")}>
          <p className="heading">Contact last name</p>
          <input
            className="input"
            type="text"
            name="contactLastName"
            value={contactLastName}
            onChange={handleChange}
            disabled={!isEditing}
          />
        </div>
      </div>
      <div className="columns">
        <div className={clsx("column")}>
          <p className="heading">Contact email</p>
          <input
            className="input"
            type="text"
            name="contactEmail"
            value={contactEmail}
            onChange={handleChange}
            disabled={!isEditing}
          />
        </div>
        <div className={clsx("column")}>
          <p className="heading">Contact phone</p>
          <input
            className="input"
            type="text"
            name="contactPhone"
            value={contactPhone}
            onChange={handleChange}
            disabled={!isEditing}
          />
        </div>
      </div>

      {hasContainer() ? (
        <div className="columns">
          <div className={clsx("column")}>
            <p className="heading">Container Number</p>
            <input
              className="input"
              type="text"
              name="containerNumber"
              value={containerNumber}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
          <div className={clsx("column")}>
            <p className="heading">BOL or Tracking Number</p>
            <input
              className="input"
              type="text"
              name="bolOrTrackingNumber"
              value={bolOrTrackingNumber}
              onChange={handleChange}
              disabled={!isEditing}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="columns">
        <div className={clsx("column")}>
          {errors.freightDateMissing && <ValidationError message={"Please add a valid date."} />}
          <p className="heading">Requested Delivery date</p>
          {/* {isEditing && <DatePicker {...deliveryDateState} />} */}
          {isEditing && <DatePicker {...deliveryDateState} />}
          {!isEditing && <p>{deliveryDateState.date || "No pickup date specified"}</p>}
        </div>
        <div className={clsx("column")}>
          {errors.freightNumberMissing && (
            <ValidationError message={"Please add a reference number."} />
          )}
          <p className="heading">reference number/id</p>
          <input
            className="input"
            type="text"
            name="referenceNumber"
            value={referenceNumber}
            onChange={handleChange}
            disabled={!isEditing}
          />
        </div>
      </div>
    </>
  );
};

export default FreightDetails;
