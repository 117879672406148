import { useOrderSearchBar } from "../../Orders/OrderSearchBar";
import { useState } from "react";

export const DEFAULT_SELECT_ORDERS_STATE = {
  orders: [],
};

export const useSelectOrders = (newState = {}) => {
  const ordersFilter = {orders: newState.orders || []};
  const [state, setState] = useState({...DEFAULT_SELECT_ORDERS_STATE, ...ordersFilter});
  const orderSearchBarState = useOrderSearchBar();

  const addOrder = (order) => {
    setState((prevState) => ({
      ...prevState,
      orders: [...prevState.orders, order],
    }));
  };

  const removeOrder = (order) => {
    setState((prevState) => ({
      ...prevState,
      orders: prevState.orders.filter((o) => o !== order),
    }));
  };

  const loadOrders = (orders) => {
    setState((prevState) => ({
      ...prevState,
      orders
    }))
  }

  const resetOrders = () => {
    setState((prevState) => ({
      ...prevState,
      orders: [],
    }));
  };

  return {
    ...state,
    addOrder,
    removeOrder,
    resetOrders,
    loadOrders,
    orderSearchBarState,
  };
};
