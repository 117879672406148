import React from 'react';
import { StatusTag } from "../../../reusable/page/Tag/StatusTag";


const PRODUCT_REQUEST_STATUS_TYPES = {
  NEW: {name: 'New', description: '...', 'classNames': 'is-disabled-outlined' },
  PENDING: {name: 'Pending', description: '...', 'classNames': 'is-pending'},
  PROCESSING: {name: 'Processing', description: '...', 'classNames': 'is-pending' },
  COMPLETE: {name: 'Completed', description: '...', 'classNames': 'is-success-outlined' },
}

export const ProductRequestStatusTag = (props) => {

  const { status } = props;

  const statusInfo = PRODUCT_REQUEST_STATUS_TYPES[status] || {name: status, description: '...', 'classNames': '' };

  return <StatusTag {...statusInfo} />;
}