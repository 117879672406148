import React, { Component } from "react";
import { Loader } from "react-bulma-components";

import consts from "../../../utils/consts";
import externalStyles from "../../../utils/styles";
import { toTitleCase } from "../../../utils/grid/orderStatus";

import {
  INVOICE_DISPLAY_STATUS_PAYMENT_ERROR,
  INVOICE_DISPLAY_STATUS_BAD_DEBT,
  INVOICE_DISPLAY_STATUS_OVERDUE,
  INVOICE_DISPLAY_STATUS_PARTIALLY_PAID,
  INVOICE_DISPLAY_STATUS_UNPAID,
  INVOICE_DISPLAY_STATUS_PAID,
  INVOICE_DISPLAY_STATUS_UPCOMING,
  INVOICE_DISPLAY_STATUS_PROCESSING,
  INVOICE_DISPLAY_STATUS_MANUAL_BILLING,
} from "../../../utils/consts";

export const InvoiceCreateIcon = ({ response_code }) => {
  switch (response_code) {
    case 201:
      return (
        <span className="icon has-text-success">
          <i className="icon-ic-checkmark" />
        </span>
      );
    case 400:
      return (
        <span className="icon has-text-danger">
          <i className="icon-ic-close" />
        </span>
      );
    case 500:
      return (
        <span className="icon has-text-danger">
          <i className="icon-ic-close" />
        </span>
      );
    default:
      return <Loader style={externalStyles.inlineSpinner} />;
  }
};

// export const InvoiceStatus = ({ status }) => {
//   let tagType = "";
//   switch (status) {
//     case consts.STATUS_PAID:
//       tagType = "is-success";
//       break;
//     case consts.STATUS_PUBLISHED:
//       tagType = "is-info";
//       break;
//     default:
//       tagType = "is-warning";
//   }

//   return (
//     <>
//       <span>
//         <p className="heading mr-2">Status</p>
//       </span>
//       <span className={`tag ${tagType}`}>{toTitleCase(status)}</span>
//     </>
//   );
// };

export const InvoiceDisplayStatus = ({ displayStatus }) => {
  let classes = ["tag", "is-rounded", "is-uppercase"];
  switch (displayStatus) {
    case INVOICE_DISPLAY_STATUS_UPCOMING:
      classes = [...classes, "pill-upcoming"];
      break;
    case INVOICE_DISPLAY_STATUS_PAID:
      classes = [...classes, "pill-paid"];
      break;
    case INVOICE_DISPLAY_STATUS_UNPAID:
      classes = [...classes, "pill-unpaid"];
      break;
    case INVOICE_DISPLAY_STATUS_PARTIALLY_PAID:
      classes = [...classes, "pill-partially-paid"];
      break;
    case INVOICE_DISPLAY_STATUS_OVERDUE:
      classes = [...classes, "pill-overdue"];
      break;
    case INVOICE_DISPLAY_STATUS_PAYMENT_ERROR:
      classes = [...classes, "pill-payment-error"];
      break;
    case INVOICE_DISPLAY_STATUS_BAD_DEBT:
      classes = [...classes, "pill-payment-error"];
      break;
    case INVOICE_DISPLAY_STATUS_PROCESSING:
      classes = [...classes, "pill-processing"];
      break;
    case INVOICE_DISPLAY_STATUS_MANUAL_BILLING:
      classes = [...classes, "pill-manual-billing"];
      break;
    default:
      classes = [...classes];
  }

  return <span className={`${classes.join(" ")}`}>{toTitleCase(displayStatus)}</span>;
};

export class CustomTooltip extends Component {
  getReactContainerClasses = () => ["custom-weight-tooltip"];

  render() {
    const isHeader = this.props.rowIndex === undefined;

    return isHeader ? (
      <div className="custom-weight-tooltip">
        <p className="has-text-weight-bold">
          Rated weight is the weight utilized by the carrier to calculate the label cost as well as
          any associated additional handling surcharges.
        </p>
        <p>Rated weight will always differ from the package's physical weight due to:</p>
        <ol>
          <li>
            1) carriers rounding up to the nearest pound (or ounce, if {"< 1"} lb) upon shipment
          </li>
          <li>
            2) dimensional weight measuring higher than the physical weight{" "}
            <a href="https://help.airhouse.io/surcharges-and-calculating-shipping-costs-us">
              (read more)
            </a>
          </li>
          <li>
            3) the package being re-weighed in transit and found to be heavier than the label weight
          </li>
        </ol>
      </div>
    ) : (
      <span></span>
    );
  }
}
