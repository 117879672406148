import { RecipientKeys } from "./recipientModal.interfaces";

export enum CHANGE_CONTACTS {
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  EMAIL = "email",
  PHONE = "phone",
}

export const recipientRequiresOneOf = ["first_name", "last_name", "company"] as RecipientKeys[];
export const REQUIRED_RECIPIENT_MODAL_COUNTRIES = ["US", "CA", "MX"];
