import React, { useState } from "react";

export const usePackingNotes = (inputState = "") => {
  const [state, setState] = useState(inputState);

  const setText = (text) => {
    setState((prevState) => ({
      ...prevState,
      text,
    }));
  };

  const getText = () => {
    return state.text;
  };

  return { ...state, setText, getText };
};

const PackingNotes = (props) => {
  const { text, setText, scrollId, isEditing } = props;

  const handleChange = (e) => {
    const text = e.target.value;
    setText(text);
  };

  return (
    <div className="scroll-section" id={scrollId}>
      <h4 className="is-dominique-subtitle mt-6">Packaging Notes (Optional)</h4>
      <p>Add any packaging notes here.</p>
      {!isEditing && <p>{text}</p>}
      {isEditing && (
        <textarea
          className="textarea mb-1 mt-2"
          value={text}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export default PackingNotes;
