import React from 'react';
import NavBar from '../../NavBar';

import { Link } from 'react-router-dom';
import { PageHeader } from '../../reusable/page/PageHeader';
import PageBody from '../../reusable/page/PageBody';
import ProjectRequestTable from './components/ProjectRequestTable';
import { isSudoing } from '../../../utils/authentication';
import { listProjectRequest } from '../../../utils/gateway/ProjectRequestAPIGateway';
import useProjectRequestHomePage from './useProjectRequestHomePage';
import { useProjectRequestTable } from './components/useProjectRequestTable';
import { ProjectRequestContext } from '../../contexts/ProjectRequestStore';
import { Level, Loader } from 'react-bulma-components';
import externalStyles from '../../../utils/styles';
import { PageLoadingBar } from '../../reusable/page/loaders/PageLoadingBar';
import { PAGE_URL as EDIT_PAGE_URL } from './EditProjectRequestPage';
import apiEndpoints from '../../../utils/apiEndpoints';

export const PAGE_TITLE = 'Projects';
export const PAGE_URL = '/projects/';
export const ADMIN_URL = `${apiEndpoints.API_BASE}admin/projects/projectrequest/`;

export const ProjectRequestHomePage = (props) => {
  const { isLoading, projectRequests, setIsLoading, loadProjectRequests, setIsSaving } =
    useProjectRequestHomePage();

  const projectRequestContext = React.useContext(ProjectRequestContext);

  React.useEffect(() => {
    setIsLoading(false);
  }, []);

  const { state: tableState, loadItems } = useProjectRequestTable();

  const isProjectRequestEnabled = props.data.account.company.project_requests_enabled;

  const hasProjectRequests = isProjectRequestEnabled || isSudoing();

  const onCellClicked = (params) => {
    const url = EDIT_PAGE_URL.replace(':projectRequestId', params.data.uuid);
    props.history.push(url);
  };

  return hasProjectRequests ? (
    <>
      <NavBar {...props} activeTab={PAGE_TITLE} />
      <PageHeader title={PAGE_TITLE} menu={<ProjectRequestPageMenu isAdmin={props.data.isAdmin} isDemo={props.data.isDemo} />} />
      <PageBody>
        {isLoading && <PageLoadingBar />}
        {!isLoading && (
          <ProjectRequestTable
            isLoading={isLoading}
            projectRequests={projectRequests}
            onCellClicked={onCellClicked}
          />
        )}
      </PageBody>
    </>
  ) : (
    ''
  );
};

export const ProjectRequestPageMenu = ({ isAdmin, isDemo }) => {
  if (!isAdmin && !isDemo) {
    return <></>;
  }

  return (
    <Link to='/projects/new'>
      <button className='button is-secondary'>Create Project Request</button>
    </Link>
  );
};

export default ProjectRequestHomePage;
