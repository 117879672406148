import React, { useState } from "react";
import CheckedCustomDropdown from "../../../reusable/CheckedCustomDropdown";
import { useDropdown } from "../../../reusable/dropdown/useDropdown";
import TableWithEditableQuantity from "../../../reusable/TableWithEditableQuantity";
import { ValidationError } from "../../../reusable/ValidationError";

const DEFAULT_PACKING_SUPPLIES_STATE = [
  {
    name: "Heavy Duty Packing Tape",
    checked: false,
    quantity: 0,
  },
  {
    name: "Mailers (Bags)",
    checked: false,
    quantity: 0,
  },
  {
    name: "Paper Inserts",
    checked: false,
    quantity: 0,
  },
  {
    name: "Stickers & Small Items",
    checked: false,
    quantity: 0,
  },
  {
    name: "Crinkle Paper",
    checked: false,
    quantity: 0,
  },
  {
    name: "Packaging Paper",
    checked: false,
    quantity: 0,
  },
  {
    name: "Shipping Boxes",
    checked: false,
    quantity: 0,
  },
];

export const useSelectPackingSupplies = (inputState = []) => {
  const [packingSuppliesState, setPackingSuppliesState] = useState(
    inputState.length ? inputState : DEFAULT_PACKING_SUPPLIES_STATE
  );

  const updateItem = (name, quantity, checked) => {
    setPackingSuppliesState((prevState) =>
      prevState.map((item) => (item.name === name ? { ...item, quantity, checked } : item))
    );
  };

  const getQuantity = (name) => {
    const item = packingSuppliesState.find((item) => item.name === name);
    if (!item) {
      return 0;
    }
    return item.quantity;
  };

  const getPackingSuppliesState = () => {
    return packingSuppliesState;
  };

  const handleItemChecked = (e) => {
    const { name, checked } = e.target;
    // console.log(name, checked);

    if (checked) {
      updateItem(name, 1, checked);
    } else {
      updateItem(name, 0, checked);
    }
  };

  const isPackagingSupplyMissing = () =>
    packingSuppliesState.filter((p) => p.quantity > 0).length == 0;

  const hasZeroQuantityPackagingSupply = () =>
    packingSuppliesState.filter((p) => p.quantity == 0 && p.checked).length > 0;

  const { state: dropdownState, setIsActive, toggleIsActive } = useDropdown();
  const { isActive } = dropdownState;
  return {
    packingSuppliesState,
    isActive,
    setIsActive,
    toggleIsActive,
    handleItemChecked,
    updateItem,
    getQuantity,
    getPackingSuppliesState,
    isPackagingSupplyMissing,
    hasZeroQuantityPackagingSupply,
  };
};

export const SelectPackingSupplies = (props) => {
  const {
    packingSuppliesState,
    isActive,
    toggleIsActive,
    setCurrentItem,
    handleItemChecked,
    updateItem,
    isEditing,
    errors,
  } = props;

  return (
    <>
      {isEditing && (
        <>
          {errors.packagingSupplyMissing && (
            <ValidationError message={"Please add at least one product or packaging component."} />
          )}
          {errors.zeroQuantityPackagingSupply && (
            <ValidationError
              message={
                "One or more selected packaging supplies has a quanity of zero. Remove this packaging component or adjust the quantity to a positive number."
              }
            />
          )}
          <CheckedCustomDropdown
            itemIdKey="name"
            displayName="name"
            items={packingSuppliesState}
            toggleDropdownMenu={toggleIsActive}
            isDropdownActive={isActive}
            setCurrentItem={setCurrentItem}
            handleItemChecked={handleItemChecked}
            placeholderText="Add Packaging Supplies"
          />
        </>
      )}
      <TableWithEditableQuantity
        items={packingSuppliesState}
        hasQuantity
        updateItem={updateItem}
        isEditing={isEditing}
        heading="Packaging Supplies Included"
      />
    </>
  );
};

export default SelectPackingSupplies;
