import React from "react";

interface IRecipientModalInputErrorBar {
  countryLong: boolean | undefined;
  provinceLong: boolean | undefined;
}

export const RecipientModalInputErrorBar: React.FC<IRecipientModalInputErrorBar> = ({
  countryLong,
  provinceLong,
}) => {
  if (!provinceLong && !countryLong) return null;

  const content =
    provinceLong && countryLong
      ? "Please select your state/province and country."
      : `Please select your ${provinceLong ? "state/province" : "country"}.`;

  return (
    <div className="help is-danger order-error is-flex is-justify-content-space-between">
      <div>
        <i className="icon-ic-warning"></i>
        <span className="ml-2">{content}</span>
      </div>
    </div>
  );
};
