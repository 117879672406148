import { getSudoerToken, getToken, isSudoing } from './authentication.js';


// Domain computation
const getAPIBase = (protocol, hostname) => {
  
  if (process.env.REACT_APP_SERVER_URL) {
    return process.env.REACT_APP_SERVER_URL
  }
  const splitHostname = hostname.split('.')

  if (splitHostname.length > 1) {
    const newHostname = hostname.replace('app', 'api')

    return protocol + '//' + newHostname + '/'
  }

  return protocol + '//localhost:8000/'
}

const hostname = window.location.hostname;
const protocol = window.location.protocol;
const API_BASE = getAPIBase(protocol, hostname);

const getData = async (url) =>
  await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const downloadFile = async (url, fileName) => {
  const attachment = await getAttachment(url, 'application/pdf');
  var binaryData = await attachment.blob();
  var blobUrl = (window.URL || window.webkitURL).createObjectURL(new Blob([binaryData]))

  const link = document.createElement('a');
  link.href = blobUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);

}

const getAttachment = async (url, contentType) =>
  await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': contentType,
      'Authorization': 'Token ' + getToken()
    }
  })

const getDataWithBlob = async (url) =>
  await fetch(url, {
    method: 'get',
    headers: {
      'Content-Type': 'application/zip',
      'Authorization': 'Token ' + getToken(),
      // 'responseType': "blob",
    }
  });

const postData = async (url, data) =>
  await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(data),
  });

const patchData = async (url, data) =>
  await fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(data)
  });

const putData = async (url, data) =>
  await fetch(url, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(data),
  });

const deleteData = async (url) =>
  await fetch(url, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const register = (data) =>
  fetch(API_BASE + 'auth/register/', {
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  });

// KEEP
const login = async (data) =>
  await fetch(API_BASE + 'auth/login/', {
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  });

const getUser = () =>
  fetch(API_BASE + 'auth/user/', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

// Keep since token may be different
const getSudo = async () =>
  await fetch(API_BASE + 'api/users/sudo/', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + (getSudoerToken() || getToken())
    }
  });

const inviteUsers = data =>
  fetch(API_BASE + 'auth/send-json-invite/', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(data)
  });

const getProducts = (queryString) =>
  fetch(API_BASE + 'api/products/' + (!!queryString ? '?' + queryString : ''), {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const getProduct = productId =>
  fetch(API_BASE + 'api/products/' + productId + '/', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const updateProductVariants = data =>
  fetch(API_BASE + 'api/products/update-variants/', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(data)
  });

const getOrders = (queryString) =>
  fetch(API_BASE + 'api/orders/' + (!!queryString ? '?' + queryString : ''), {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const getOrder = (orderId) =>
  fetch(API_BASE + 'api/orders/' + orderId + '/', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const createOrder = (order) =>
  fetch(API_BASE + 'api/orders/', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(order)
  });

const editOrder = (orderId, order) =>
  fetch(API_BASE + 'api/orders/' + orderId + '/', {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(order)
  });

const deleteOrder = (orderId) =>
  fetch(API_BASE + 'api/orders/' + orderId + '/', {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const getOrderReturns = (queryString) =>
  fetch(API_BASE + 'api/returns/' + (!!queryString ? '?' + queryString : ''), {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const createOrderReturn = (newReturn) =>
fetch(API_BASE + 'api/returns/', {
  method: 'post',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Token ' + getToken()
  },
  body: JSON.stringify(newReturn)
});

const editOrderReturn = (returnId, order) =>
  fetch(API_BASE + 'api/returns/' + returnId + '/', {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(order)
  });

const deleteOrderReturn = (returnId) =>
  fetch(API_BASE + 'api/returns/' + returnId + '/', {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });



const getOrderReturn = (returnId) =>
fetch(API_BASE + 'api/returns/' + returnId + '/', {
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Token ' + getToken()
  }
});

const patchInventoryItems = data =>
  fetch(API_BASE + 'api/inventoryitems/', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(data)
  });

const getVariant = variantId =>
  fetch(API_BASE + 'api/variants/' + variantId + '/', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const getVariants = (queryString) =>
  fetch(API_BASE + 'api/variants/' + (!!queryString ? '?' + queryString : ''), {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const patchVariants = data =>
  fetch(API_BASE + 'api/variants/', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(data)
  });

const getBundleVariants = queryString =>
  fetch(API_BASE + 'api/variant-bundles/' + (!!queryString ? '?' + queryString : ''), {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const createBundleVariant = data =>
  fetch(API_BASE + 'api/variant-bundles/', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(data)
  });

const patchBundleVariant = (data, bundleVariantId) =>
  fetch(API_BASE + 'api/variant-bundles/' + bundleVariantId + '/', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(data)
  });

const deleteBundleVariant = bundleVariantId =>
  fetch(API_BASE + 'api/variant-bundles/' + bundleVariantId + '/', {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const getOrdersCSV = queryString => {
  let url = API_BASE
    + 'api/orders.csv'
    + '?limit=1000000'
    + '&token=' + getToken()
    + (isSudoing() ? '&sudoer=' + getSudoerToken() : '')
    + (!!queryString ? '&' + queryString : '');
  return fetch(url, { method: 'get' });
}

const getVariantsCSV = queryString => {
  let url = API_BASE
    + 'api/variants.csv'
    + '?limit=1000000'
    + '&token=' + getToken()
    + (isSudoing() ? '&sudoer=' + getSudoerToken() : '')
    + (!!queryString ? '&' + queryString : '');
  return fetch(url, { method: 'get' });
}

const shopifyConnectPreflight = (shop) =>
  fetch(API_BASE + `api/stores/collaborate/?shop=${shop}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    }
  });

const getStores = async () =>
  await fetch(API_BASE + 'api/stores/', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const getStoreAttributes = () =>
  fetch(API_BASE + 'api/store-attribute/', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const getStoreValues = () =>
  fetch(API_BASE + 'api/store-value/', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    }
  });

const createStoreValue = data =>
  fetch(API_BASE + 'api/store-value/', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(data)
  });

const editStoreValue = data =>
  fetch(API_BASE + 'api/store-value/' + data.uuid + '/', {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(data)
  });

const createShipment = data =>
  fetch(API_BASE + `api/shipments/`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken(),
    },
    body: JSON.stringify(data),
  });

const updateShipment = (shipmentId, data) =>
  fetch(API_BASE + 'api/shipments/' + shipmentId + '/', {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(data)
  });

const createAttachment = (orderId, formData) =>
  fetch(API_BASE + `api/orders/${ orderId }/attachment/`, {
    method: 'post',
    headers: {
      'Authorization': 'Token ' + getToken()
    },
    body: formData,
  });

const addFulfillmentItems = (fulfillmentId, data) =>
  fetch(API_BASE + 'api/fulfillments/' + fulfillmentId + '/add-items/', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken(),
    },
    body: JSON.stringify(data),
  });

const updateFulfillmentItems = (fulfillmentId, data) =>
  fetch(API_BASE + 'api/fulfillments/' + fulfillmentId + '/update-items/', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(data)
  });

const removeFulfillmentItems = (fulfillmentId, data) =>
  fetch(API_BASE + 'api/fulfillments/' + fulfillmentId + '/remove-items/', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken(),
    },
    body: JSON.stringify(data),
  });

const resetPassword = data =>
  fetch(API_BASE + 'auth/password/reset/', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

const resetPasswordConfirm = data =>
  fetch(API_BASE + 'auth/password/reset/confirm/', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  const getTags = () =>
  fetch(API_BASE + 'api/admin_tags/', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken(),
    },
  });

const createTag = (data) =>
  fetch(API_BASE + 'api/admin_tags/', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken(),
    },
    body: JSON.stringify(data),
  });

  const deleteTag = (tagId) =>
  fetch(API_BASE + 'api/admin_tags/' + tagId + '/', {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken(),
    }
  });

  const updateAdminNote = (orderId, data) =>
  fetch(API_BASE + `api/orders/${ orderId }/admin_note/`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
    body: JSON.stringify(data),
  });

const initiateShopifyStoreSync = (store) =>
  fetch(API_BASE + `api/stores/${store.uuid}/initiate-shopify-store-sync/`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken(),
    },
  });

const toggleForceShip = (orderId) =>
  fetch(API_BASE + `api/orders/${ orderId }/toggle_force_ship/`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
  });

const togglePartialFulfillment = (orderId) =>
  fetch(API_BASE + `api/orders/${ orderId }/toggle_partial_fulfillment/`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
  });

const toggleHighPriority = (orderId) =>
  fetch(API_BASE + `api/orders/${orderId}/toggle_high_priority/`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken()
    },
  });

  const createOrderfulStore = async (data) =>
    await fetch(API_BASE + 'api/stores/create_orderful_store/', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + getToken()
      },
      body: JSON.stringify(data),
    });

const getPackages = (query, store) =>
  fetch(API_BASE + `api/packages/${query}/?store=${store}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken(),
    },
  });

const getFixedOrderCosts = (store) =>
  fetch(API_BASE + `api/fixed_order_costs/?store=${store}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Token ' + getToken(),
    },
  });


export default {
  getAPIBase,
  API_BASE,
  register,
  login,
  getUser,
  getSudo,
  inviteUsers,
  getProducts,
  getProduct,
  updateProductVariants,
  getOrders,
  getOrder,
  createOrder,
  editOrder,
  deleteOrder,
  getOrderReturns,
  getOrderReturn,
  createOrderReturn,
  editOrderReturn,
  deleteOrderReturn,
  patchInventoryItems,
  getVariant,
  getVariants,
  patchVariants,
  getBundleVariants,
  createBundleVariant,
  patchBundleVariant,
  deleteBundleVariant,
  getOrdersCSV,
  getVariantsCSV,
  shopifyConnectPreflight,
  getStores,
  getStoreAttributes,
  getStoreValues,
  createStoreValue,
  editStoreValue,
  createShipment,
  updateShipment,
  createAttachment,
  addFulfillmentItems,
  updateFulfillmentItems,
  removeFulfillmentItems,
  resetPassword,
  resetPasswordConfirm,
  getTags,
  createTag,
  deleteTag,
  updateAdminNote,
  getData,
  postData,
  patchData,
  putData,
  deleteData,
  initiateShopifyStoreSync,
  toggleForceShip,
  togglePartialFulfillment,
  toggleHighPriority,
  getDataWithBlob,
  createOrderfulStore,
  getAttachment,
  downloadFile,
  getPackages,
  getFixedOrderCosts,
}
