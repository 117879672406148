import { Columns } from "react-bulma-components";
import React from "react";


export const FileLink = (props) => {
  const { isInvalidURLError, setIsInvalidURLError } = props;
  const { url, setURL, onSave } = props;

  const onURLChange = (e) => {
    setURL(e.target.value);
  };

  const onSaveButtonClicked = () => {
    if(isValidURL(url)) {
      onSave(url);
      setURL("");
    } else {
      setIsInvalidURLError(true);
    }
  };


  const urlInputClasses = ["input"];

  if (isInvalidURLError) {
    urlInputClasses.push("is-error");
  } else {
    urlInputClasses.push("is-normal");
  }

  return <Columns>
    <Columns.Column className="is-4">
      <input type="text" className={urlInputClasses.join(" ")} value={url} onChange={onURLChange} />
      {isInvalidURLError && <p>The URL is not valid</p>}
    </Columns.Column>
    <Columns.Column>
      <button className="button is-primary" onClick={onSaveButtonClicked}>save</button>
    </Columns.Column>
  </Columns>;
};

export const isValidURL = (urlString) => {

  let url;

    try {
      url = new URL(urlString);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}