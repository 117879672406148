import React from "react";
import { Columns } from "react-bulma-components";
import { SelectStore } from "../fields/SelectStore";

export const SelectStoreSection = (props) => {

  return (
    <section className="section-container">
      <p className="is-dominique-title mb-4">Store</p>
      <SelectStore {...props} />
    </section>
  );
};
