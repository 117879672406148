var moment = require('moment');


export const dateFormatter = (date) => moment(date).format('MMM D, YYYY h:mm a');
export const datePartFormatter = (date) => moment(date).format('MMM D, YYYY');

export const THREE_BUSINESS_DAYS_AGO = (() => {
  switch (moment().isoWeekday()) {
    // Monday, Tuesday, Wednesday
    case 1:
    case 2:
    case 3:
      return moment().subtract(5, 'days').format('YYYY-MM-DDTHH:mm');
    // Sunday
    case 7:
      return moment().subtract(4, 'days').format('YYYY-MM-DDTHH:mm');
    // Thursday, Friday
    default:
      return moment().subtract(3, 'days').format('YYYY-MM-DDTHH:mm');
  }
})();

export const TWO_BUSINESS_DAYS_AFTER = (() => {
  switch (moment().isoWeekday()) {
    // Monday, Tuesday, Wednesday
    case 1:
    case 2:
    case 3:
      return moment().add(2, 'days').format('YYYY-MM-DDTHH:mm');
    // Thursday
    case 4:
      return moment().add(4, 'days').format('YYYY-MM-DDTHH:mm');
    // Friday
    case 5:
      return moment().add(4, 'days').format('YYYY-MM-DDTHH:mm');
    // Saturday
    case 6:
      return  moment().add(3, 'days').format('YYYY-MM-DDTHH:mm');
    // Sunday
    case 7:
      return moment().add(2, 'days').format('YYYY-MM-DDTHH:mm');
  }
})();

export const numberFormatter = new Intl.NumberFormat('en-US');
// export const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });


export const removeParam = (queryString, paramName) =>
  queryString
    .split('&')
    .filter((param) => param.split('=')[0] !== paramName)
    .join('&');


export const objectFromQueryString = queryString => {
  let params = queryString
    .split('&')
    .map((param) => {
        let key = param.split('=')[0];
        let value = param.split('=')[1];
        let data = {};
        data[key] = value;
        return data;
    });

  return Object.assign({}, ...params);
}


export const capitalize = (word) => {
  if (word == null) return word

  return word.toLowerCase().charAt(0).toUpperCase() + word.slice(1)
}

// Delay execution of function
export const delay = (milliseconds) => new Promise(result => setTimeout(result, milliseconds));

// Compare strings, case insensitive, remove punctuation and spaces
export const compareStrings = (a, b) => {
  const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\s]/g;
  a = a.replace(regex, '')
  b = b.replace(regex, '')
  return a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0;
}

export const decimalFormatter= (value) => {
  if (value != null && !isNaN(value)) {
    return value.toFixed(2);
  }

  return `0.00`;
}

export const currencyFormatter= (value, currency='USD') => {
  if (value != null && !isNaN(value)) {
    return value.toLocaleString('en-US', { style: 'currency', currency: currency });
  }

  return `$0.00`;
}


// Take an array

export const arrayStringFormatter = (array) => {
  if (!array.length) {
    return '';
  } else if (array.length === 1) {
    return array[0];
  } else if (array.length === 2) {
    return array[0] + ' and ' + array[1];
  } else {
    const last = array.pop();
    return array.join(', ') + ', and ' + last;
  }
}


export const isNumber = (value) => {
  return /^\d+$/.test(value);
}


export const joinUrls = (a, b) => {
  return a.replace(/\/+$/, '') + b.replace(/^\/*/, '/');
}

export const hex6ToHex8 = (hex6, alpha) =>
  hex6 +
  Math.round((alpha * 255) / 100)
    .toString(16)
    .padStart(2, '0');
