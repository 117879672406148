import React, { useState } from "react";
import {
  convertProjectRequestAPIResponse,
  getProjectRequest,
} from "../../../utils/gateway/ProjectRequestAPIGateway";
import { usePageState } from "../../reusable/page/usePageState";
import { loadProjectRequestState } from "../ProjectRequest/useEditProjectRequestPage";
import { useInboundOrderForm } from "./components/InboundOrderForm";

export const useEditInboundOrderPage = () => {
  const [orderIsLoaded, setOrderIsLoaded] = useState(false);
  const {
    state: pageState,
    setIsLoading,
    setIsSaving,
    setIsEditing,
  } = usePageState({ isLoading: true, isEditing: true });

  const formState = useInboundOrderForm();
  const {
    setInboundOrderState,
    packingListState,
    deliveryDetailsState,
    projectRequestTypeState,
    projectRequestFormState,
  } = formState;

  const { shippingFormatsState, packingSuppliesState, packingNotes } = packingListState;
  const { deliveryNotes } = deliveryDetailsState;
  const { setTrackingNumbers } =
    deliveryDetailsState.shippingDetails.carrierDetails.trackingNumbersState;

  // const onSaveInboundOrderChanges = () => {};

  const loadInboundOrder = (inboundOrder) => {
    // console.log("loadInboundOrder", inboundOrder);
    const {
      created_at,
      delivery_attachments,
      delivery_note,
      freight_company,
      freight_contact_email,
      freight_contact_first_name,
      freight_contact_last_name,
      freight_contact_phone,
      freight_container_bol,
      freight_container_number,
      freight_date,
      freight_facility_address,
      freight_facility_hours,
      freight_pickup_location,
      freight_pickup_number,
      freight_pickup_requirement_inside,
      freight_pickup_requirement_lift_gate,
      freight_pickup_requirement_limited_access,
      freight_pickup_requirement_residential,
      freight_pickup_requirement_sort_segregate,
      freight_reference_number,
      inbound_projects,
      is_airhouse_coordinating_freight,
      is_freight,
      items,
      name,
      num_boxes,
      num_containers,
      num_pallets,
      packing_note,
      packing_supplies,
      pallets,
      shipments,
      status,
      // store,
      store_detail,
      uuid,
      // warehouse,
      warehouse_detail,
    } = inboundOrder;

    setInboundOrderState({
      uuid: uuid,
      createdAt: created_at,
      name: name,
      isLoading: true,
      store: store_detail,
      status: status,
      projectRequests: (inbound_projects && inbound_projects) || [],
    });

    // Packing List
    shippingFormatsState.updateItem("Boxes", num_boxes, num_boxes > 0);
    shippingFormatsState.updateItem("Pallets", num_pallets, num_pallets > 0);
    shippingFormatsState.updateItem("Containers", num_containers, num_containers > 0);
    formState.packingListState.selectVariants.loadOrderItems(items);
    packing_supplies.forEach((ps) => {
      packingSuppliesState.updateItem(ps.name, ps.quantity, ps.quantity > 0);
    });
    packingNotes.setText(packing_note);

    // Delivery Details
    deliveryDetailsState.shippingDetails.loadShippingDetailsState({
      isFreight: is_freight,
      selectedWarehouse: warehouse_detail,

      // TODO: this should bet set by a call to setAssignedReceivingAddress but it's not clear if this is done
      // assignedReceivingAddress

      // Don't set these, they are set by a useEffect call which queries the store object.
      // warehouses
    });

    // shipments
    setTrackingNumbers(
      shipments.map((shipment) => ({
        trackingCarrier: shipment.carrier,
        trackingNumber: shipment.tracking_number,
        uuid: shipment.uuid,
      }))
    );

    deliveryDetailsState.shippingDetails.carrierDetails.freightCoordination.setIsAirhouseCoordinatingFreight(
      is_airhouse_coordinating_freight
    );
    deliveryDetailsState.shippingDetails.carrierDetails.freightDetails.setFreightDetailsState({
      freightCompany: freight_company,
      freightPickupLocation: freight_pickup_location,
      contactFirstName: freight_contact_first_name,
      contactLastName: freight_contact_last_name,
      contactEmail: freight_contact_email,
      contactPhone: freight_contact_phone,
      referenceNumber: freight_reference_number,
      containerNumber: freight_container_number,
      bolOrTrackingNumber: freight_container_bol,
    });
    deliveryDetailsState.shippingDetails.carrierDetails.freightDetails.deliveryDateState.setDate(
      freight_date
    );

    deliveryDetailsState.shippingDetails.carrierDetails.freightDetailsAirhouse.setFreightDetailsAirhouseState(
      {
        contactFirstName: freight_contact_first_name,
        contactLastName: freight_contact_last_name,
        contactEmail: freight_contact_email,
        contactPhone: freight_contact_phone,
        facilityAddress: freight_facility_address,
        facilityHours: freight_facility_hours,

        // Django requires us to use a BlankNullField to store empty values as None on backend,
        // and react requires us to not use null's on the frontend.
        pallets: pallets.map((pallet) => ({
          uuid: pallet.uuid,
          palletLength: pallet.palletLength || "",
          palletWidth: pallet.palletWidth || "",
          palletHeight: pallet.palletHeight || "",
          palletWeight: pallet.palletWeight || "",
        })),
        pickupNumber: freight_pickup_number,
        pickupRequirementLimitedAccess: freight_pickup_requirement_limited_access,
        pickupRequirementInside: freight_pickup_requirement_inside,
        pickupRequirementLiftGate: freight_pickup_requirement_lift_gate,
        pickupRequirementResidential: freight_pickup_requirement_residential,
        pickupRequirementSortSegregate: freight_pickup_requirement_sort_segregate,
        // freightCompany: freight_company,
        // freightPickUpLocation: freight_pickup_location,
        // freightReferenceNumber: freight_reference_number,
      }
    );
    deliveryDetailsState.shippingDetails.carrierDetails.freightDetailsAirhouse.pickupDateState.setDate(
      freight_date
    );
    deliveryNotes.setText(delivery_note);
    deliveryDetailsState.fileAttachments.loadItems(delivery_attachments);

    // TODO: Project Requests: we are not currently supporting inline project request edits
    // if (inbound_projects && inbound_projects.length) {
    //   getProjectRequest(inbound_projects[0].uuid).then((projectRequest) =>
    //     loadProjectRequestState(projectRequest, projectRequestFormState)
    //   );
    // }

    setOrderIsLoaded(true);
  };

  return {
    orderIsLoaded,
    pageState,
    formState,
    setIsLoading,
    setIsSaving,
    setIsEditing,
    loadInboundOrder,
  };
};
