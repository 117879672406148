import React, { useContext, useEffect, useState } from "react";
import { Modal, Columns } from "react-bulma-components";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../../contexts/UserStore";
import { PaymentModalContext } from "./PaymentStore";
import { maxDaysBeforePopupHavePassed } from "./PaymentModal";
// import

const PaymentSettingsModal = () => {
  const history = useHistory();
  const location = useLocation();
  const userContext = useContext(UserContext);
  const paymentModalContext = useContext(PaymentModalContext);
  const [isSuperuser, setIsSuperuser] = useState(false);

  useEffect(() => {
    const lastAccessedDate = localStorage.getItem("paymentSettingsModalPopupLastAccessed");
    const lastAccessedDays =
      (new Date() - new Date(lastAccessedDate)) / (1000 * 3600 * 24) > maxDaysBeforePopupHavePassed;
    const path = location.pathname.split("/");
    const ignorePaths = ![
      "billing",
      "login",
      "privacy",
      "register",
      "forgot-password",
      "reset-password",
      "settings",
    ].includes(path[1]);

    setIsSuperuser(userContext.data?.isAdmin);

    const showPopUp =
      !userContext.loading &&
      ignorePaths &&
      !userContext.data?.account?.company?.has_payment_information &&
      lastAccessedDays;

    paymentModalContext.setShowSettings(showPopUp);
  }, [userContext.store, location]);

  const onClose = () => {
    localStorage.setItem("paymentSettingsModalPopupLastAccessed", new Date());
    paymentModalContext.setShowSettings(false);
  };
  const onGoToPaymentSettings = () => {
    localStorage.setItem("paymentSettingsModalPopupLastAccessed", new Date());
    paymentModalContext.setShowSettings(false);
    history.push("/settings/payment");
  };

  if (isSuperuser) {
    return <></>;
  }

  return (
    <>
      <Modal show={paymentModalContext.showSettings} onClose={onClose}>
        <Modal.Card>
          <Modal.Card.Header onClose={onClose}>
            <Modal.Card.Title>Important: Missing Payment Information</Modal.Card.Title>
          </Modal.Card.Header>
          <Modal.Card.Body>
            <div>
              <p>
                In January, Airhouse modified how we invoice for fulfillment and collect payment.
              </p>
              <br />
              <p>
                This change will dramatically improve speed and visibility into your fulfillment
                costs. With this change, we require payment information on file in Airhouse. Please
                set up your payment settings to avoid disruptions to fulfillment.
              </p>
              <br />
              <p>
                If you have any questions, contact us at{" "}
                <a href="mailto:team@airhouse.io">team@airhouse.io</a>
              </p>
            </div>
          </Modal.Card.Body>
          <Modal.Card.Footer>
            <Columns className="" style={{ flex: "auto", float: "right", textAlign: "right" }}>
              <Columns.Column>
                <button className="button is-primary is-right mr-4" onClick={onGoToPaymentSettings}>
                  Set up payment settings
                </button>
              </Columns.Column>
            </Columns>
          </Modal.Card.Footer>
        </Modal.Card>
      </Modal>
    </>
  );
};

export default PaymentSettingsModal;
