import React from 'react';

export const EditButton = (props) => {
  const { isEnabled } = props;
  const editIcon = (
    <>
      <i className="icon-ic-edit" />
      <span className="ml-2">Edit</span>
    </>
  );
  const yesEdit = (
    <a className="dropdown-item" onClick={props.onClick}>
      {editIcon}
    </a>
  );
  const noEdit = <div className="dropdown-item">{editIcon}</div>;

  return isEnabled ? yesEdit : noEdit;
};
