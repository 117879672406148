import { API_BASE, get, post, put, del, postResponse } from "../requests";

import { getFileType } from "./ProjectRequestAPIGateway";
import { convertProjectRequestStateToPayload } from "./ProjectRequestAPIGateway";
import { parseOptions } from "../grid/helpers";
import _ from "lodash";
import { getToken, getSudoerToken, isSudoing } from "../authentication";

const INBOUND_ORDER_API_ENDPOINT = "api/inbound/";
const VALIDATE_VARIANTS_ENDPOINT = "api/validate-variants/";

export const convertInboundOrderAPIResponse = (apiResponseJson) => {
  return {
    ...apiResponseJson,
    delivery_attachments: apiResponseJson?.attachments.map((attachment) => {
      // List view for some reason attempting to serialize attachments made up of uuids
      if (typeof attachment === "string" || attachment instanceof String) {
        return;
      }
      const type = attachment.type.toLowerCase();
      let { url } = attachment;
      if (type === "file" && !url) {
        url = `${API_BASE}${INBOUND_ORDER_API_ENDPOINT}${apiResponseJson.uuid}/attachments/${attachment.uuid}`;
      }
      return {
        ...attachment,
        url,
      };
    }),
  };
};

const convertInboundOrderFormStateToPayload = (inboundOrderFormState, status) => {
  const { inboundOrderState, packingListState, deliveryDetailsState } = inboundOrderFormState;

  const { shippingFormatsState, packingSuppliesState, packingNotes, selectVariants } =
    packingListState;

  const { deliveryNotes, shippingDetails } = deliveryDetailsState;
  const { carrierDetails } = shippingDetails;

  const { name, store } = inboundOrderState;

  const { isAirhouseCoordinatingFreight } = carrierDetails.freightCoordination;
  const { isFreight } = shippingDetails.shippingDetailsState;
  const { selectedWarehouse } = shippingDetails.shippingDetailsState;
  const { assignedReceivingAddress } = shippingDetails.shippingDetailsState;
  const { trackingNumbers } = carrierDetails.trackingNumbersState;
  const { freightDetailsState, deliveryDateState } = carrierDetails.freightDetails;
  const { freightDetailsAirhouseState, pickupDateState } = carrierDetails.freightDetailsAirhouse;

  // TODO: if empty, passing nulls to backend will allow serializers to work. is that good practice?
  const selectedWarehouseUUID = selectedWarehouse?.uuid || null;
  const assignedReceivingAddressUUID = assignedReceivingAddress?.uuid || null;

  const {
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhone,
    freightCompany,
    freightPickupLocation,
    referenceNumber,
    containerNumber,
    bolOrTrackingNumber,
  } = freightDetailsState;

  const {
    contactFirstName: airhouseContactFirstName,
    contactLastName: airhouseContactLastName,
    contactEmail: airhouseContactEmail,
    contactPhone: airhouseContactPhone,
    freightCompany: airhouseFreightCompany,
    freightPickUpLocation: airhouseFreightPickupLocation,
    freightReferenceNumber: airhouseReferenceNumber,
    facilityAddress,
    facilityHours,
    pallets,
    pickupNumber,
    pickupRequirementLimitedAccess,
    pickupRequirementInside,
    pickupRequirementLiftGate,
    pickupRequirementResidential,
    pickupRequirementSortSegregate,
  } = freightDetailsAirhouseState;

  const freight_contact_first_name = isAirhouseCoordinatingFreight
    ? airhouseContactFirstName
    : contactFirstName;
  const freight_contact_last_name = isAirhouseCoordinatingFreight
    ? airhouseContactLastName
    : contactLastName;
  const freight_contact_email = isAirhouseCoordinatingFreight ? airhouseContactEmail : contactEmail;
  const freight_contact_phone = isAirhouseCoordinatingFreight ? airhouseContactPhone : contactPhone;
  const freight_company = isAirhouseCoordinatingFreight ? "" : freightCompany;
  const freight_pickup_location = isAirhouseCoordinatingFreight ? "" : freightPickupLocation;
  const freight_reference_number = isAirhouseCoordinatingFreight ? "" : referenceNumber;
  const freight_date = isAirhouseCoordinatingFreight
    ? pickupDateState.date
    : deliveryDateState.date || null;

  // project request
  const { projectRequestTypeState, projectRequestFormState } = inboundOrderFormState;
  const projectRequest = projectRequestTypeState.currentItem.uuid
    ? convertProjectRequestStateToPayload(
        { projectRequestTypeState, ...projectRequestFormState },
        store
      )
    : null;

  const payload = {
    name,
    status,
    store: store.uuid,

    // TODO: consider case wehre receiving address changes when order in draft state
    receiving_address: assignedReceivingAddressUUID,
    warehouse: selectedWarehouseUUID,
    total_price: selectVariants.getTotalPrice(),

    packing_note: packingNotes.getText(),
    delivery_note: deliveryNotes.getText(),

    num_boxes: shippingFormatsState.getQuantity("Boxes"),
    num_pallets: shippingFormatsState.getQuantity("Pallets"),
    num_containers: shippingFormatsState.getQuantity("Containers"),

    // shared freight fields
    is_freight: isFreight,
    freight_date,
    freight_contact_email,
    freight_contact_first_name,
    freight_contact_last_name,
    freight_contact_phone,
    freight_company,
    freight_pickup_location,
    freight_reference_number,

    // freight company fields
    freight_container_number: containerNumber,
    freight_container_bol: bolOrTrackingNumber,

    // airhouse coordination freight fields
    is_airhouse_coordinating_freight: isAirhouseCoordinatingFreight || false,
    freight_facility_address: facilityAddress,
    freight_facility_hours: facilityHours,
    freight_pickup_number: pickupNumber,
    freight_pickup_requirement_limited_access: pickupRequirementLimitedAccess || false,
    freight_pickup_requirement_inside: pickupRequirementInside || false,
    freight_pickup_requirement_lift_gate: pickupRequirementLiftGate || false,
    freight_pickup_requirement_residential: pickupRequirementResidential || false,
    freight_pickup_requirement_sort_segregate: pickupRequirementSortSegregate || false,

    // foreign key relationships
    pallets,
    shipments: trackingNumbers,

    // avoid sending total_count to the server, this value should always be set by the serializer
    items: selectVariants.getItems().map((item) => _.omit(item, ['total_count', 'track_lot_number'])),
    packing_supplies: packingSuppliesState.getPackingSuppliesState(),
    attachments: deliveryDetailsState.fileAttachments.items.map((item) => ({
      uuid: item.uuid,
      url: item.url,
      attachment_type: item.documentType?.uuid,
      file_name: item.file?.name,
      file_size: item.file?.size,
      file_content: item.fileContent,
      file_type: getFileType(item.file?.name),
      type: item.type.toLowerCase(),
    })),
    inbound_projects: !!projectRequest ? [projectRequest] : [],
  };
  return payload;
};

export const getInboundOrders = (options) => {
  const queryString = parseOptions(options);
  const url = INBOUND_ORDER_API_ENDPOINT + (!!queryString ? "?" + queryString : "");
  return get(url).then((response) => ({
    success: true,
    results: response.results.map(convertInboundOrderAPIResponse),
    count: response.count,
  }));
};

export const getInboundOrder = (id) => {
  return get(`${INBOUND_ORDER_API_ENDPOINT + id}/`).then((response) =>
    convertInboundOrderAPIResponse(response)
  );
};

export const createInboundOrder = (inboundOrderFormState, status) => {
  const payload = convertInboundOrderFormStateToPayload(inboundOrderFormState, status);
  return post(INBOUND_ORDER_API_ENDPOINT, payload);
};

export const deleteInboundOrder = (id) => {
  return del(`${INBOUND_ORDER_API_ENDPOINT + id}/`);
};

export const updateInboundOrder = (id, inboundOrderFormState, status) => {
  const payload = convertInboundOrderFormStateToPayload(inboundOrderFormState, status);
  return put(`${INBOUND_ORDER_API_ENDPOINT + id}/`, payload);
};

export const validateVariants = (store, skus) => {
  const payload = {
    store,
    skus,
  };
  return post(VALIDATE_VARIANTS_ENDPOINT, payload);
};

export const addTrackingNumberAfterOrderSubmission = (id, trackingCarrier, trackingNumber) => {
  const payload = {
    order: id,
    trackingCarrier,
    trackingNumber,
  };
  return post(`${INBOUND_ORDER_API_ENDPOINT + id}/add_tracking_number/`, payload);
};

export const exportInboundOrdersCSV = async () => {
  const queryString = isSudoing() ? `?sudoer=${getSudoerToken()}` : '';
  const url = `tasks/export-inbound-orders/${queryString}`;
  try {
    return await postResponse(url);
  } catch (err) {
    console.error(err);
  }
};
