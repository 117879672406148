import React from "react";
import { Columns } from "react-bulma-components";
import { InputInstructionsField } from "../fields/InputInstructionsField";

export const InputInstructionsSection = (props) => {
  const { description } = props;

  return <div className="section-container">
    <Columns>
      <Columns.Column size={3}>
        <p data-testid="target-due-date" className={'title is-4 mb-4'}>
          Input Products
        </p>
        {description && <p>{description}</p>}
      </Columns.Column>
      <Columns.Column>
        <InputInstructionsField {...props}/>
      </Columns.Column>
    </Columns>
  </div>
}

export const InputInstructionsInlineSection = (props) => (<div className="section-container">
  <h5 className="title is-5 mb-4">Input Products</h5>
  {props.description && <p>{props.description}</p>}
  <InputInstructionsField {...props}/>
</div>)