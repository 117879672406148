import React from "react";

const TrackingNumbersTable = (props) => {
  const { items, removeItem, isEditing } = props;

  const hasSelection = (items) => {
    return items.length;
  };

  const handleRemoveItem = (item) => {
    removeItem(item);
  };

  return (
    <>
      {hasSelection(items) ? (
        <>
          <table className="table modern">
            <thead>
              <tr>
                <th>Shipping Carrier</th>
                <th>Tracking Number</th>
                {isEditing && <th className="has-text-right">Remove</th>}
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={`${item.trackingCarrier}-${item.trackingNumber}`}>
                  <td>{item.trackingCarrier}</td>
                  <td>{item.trackingNumber}</td>
                  {isEditing && (
                    <td className="has-text-right">
                      <i
                        className="icon-ic-trash has-text-grey-light is-icon pl-2"
                        aria-hidden="true"
                        onClick={() => handleRemoveItem(item)}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default TrackingNumbersTable;
