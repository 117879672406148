import React, { useContext, useEffect } from "react";

import { Link, useHistory } from "react-router-dom";
import NavBar from "../../NavBar";
import { PageBody } from "../../reusable/page/PageBody";
import { PageHeader } from "../../reusable/page/PageHeader";
import { PageLoadingBar } from "../../reusable/page/loaders/PageLoadingBar";
import { UserContext } from "../../contexts/UserStore";
import useInboundOrderPage from "./useInboundOrderPage";
import { InboundOrderGrid } from "./components/InboundOrderGrid";
import { Button } from "../../reusable/page/buttons/Button";
import { usePromiseLoading } from "../../../utils/hooks/usePromiseLoading";
import { exportInboundOrdersCSV } from "../../../utils/gateway/InboundOrderAPIGateway";
import { exportGridCsv } from "../../../utils/grid/helpers";
import Loader from "../../reusable/Loader";

export const PAGE_TAB = "inbound";
export const PAGE_TITLE = "Restock";
export const PAGE_URL = "/inbound/";

export const InboundOrderInstructions = () => {
  return (
    <div className="is-flex-direction-column">
      <p className="mt-2 mb-2">
        New to sending an inventory shipment?{" "}
        <a
          target="_blank"
          href="https://help.airhouse.io/how-to-use-restock"
          className="has-text-right pt-1"
        >
          Refer to our docs for best practices
        </a>
      </p>
    </div>
  );
};

export const InboundOrderHomePage = (props) => {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const { isLoading, setIsLoading } = useInboundOrderPage();

  // ensure the userContext is loaded
  useEffect(() => {
    if (userContext.store) {
      setIsLoading(false);
    }
  }, [userContext.store]);

  const onCellClicked = (params) => {
    history.push(`/inbound/edit/${params.data.uuid}`);
  };

  return (
    <>
      <NavBar {...props} activeTab={PAGE_TAB} />
      <PageHeader
        title={PAGE_TITLE}
        menu={<InboundOrderPageMenu email={props.data.account.email} isAdmin={props.data.isAdmin} isDemo={props.data.isDemo} />}
        subtitle={<InboundOrderInstructions />}
      />
      <PageBody>
        {isLoading && <PageLoadingBar />}
        {!isLoading && <InboundOrderGrid onCellClicked={onCellClicked} />}
      </PageBody>
    </>
  );
};

export const InboundOrderPageMenu = ({ email, isAdmin, isDemo }) => {

  const { caller: exportFn, isLoading: isExporting } = usePromiseLoading(exportInboundOrdersCSV);
  const exportCsv = async () => {
    const response = await exportFn();
    exportGridCsv(response, email, () => null);
  }

  // button text should show a loader icon if it's in the process of exporting and/or emailing the csv
  const exportText = isExporting ? <Loader size='small' /> : 'Export';

  if (!isAdmin && !isDemo) {
    return <Button onClick={exportCsv} text={exportText} />
  }

  return (
    <>
      <Link to="/inbound/new">
        <Button isPrimary text='New Inventory Shipment' additionalClasses='mr-2' />
      </Link>
      <Button onClick={exportCsv} text={exportText}/>
    </>
  );
};

export default InboundOrderHomePage;
