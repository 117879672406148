import React, { useState } from "react";
import { Columns } from "react-bulma-components";
import { formatBytes } from "../../../utils/formatting/byteFormatter";
import apiEndpoints from "../../../utils/apiEndpoints";
import CustomDropdown from "../CustomDropdown";
import { Img } from "./Img";
import { FileLink } from "./FileLink";
import { useFileLink } from "./useFileLink";
import { FileAttachmentList } from "./FileAttachmentList";
import { ACCEPTED_UPLOAD_FILE_TYPES } from "../../../utils/consts";


export const EMPTY_TYPE = "";
export const FILE_TYPE = "file";
export const LINK_TYPE = "link";

const acceptedFileTypes = ACCEPTED_UPLOAD_FILE_TYPES.join(',');

export const FILE_ATTACHMENT_TYPES = [
  {
    uuid: EMPTY_TYPE,
    name: "Add document"
  },
  {
    uuid: FILE_TYPE,
    name: "File"
  },
  {
    uuid: LINK_TYPE,
    name: "Link"
  }
];


export const FileAttachmentUpload = ({
                                       selectedItem,
                                       items,
                                       isError,
                                       errorMessage,
                                       setSelectedItem,
                                       addItem,
                                       updateItem,
                                       deleteItem,
                                       readOnly,
                                       dropdownState = {},
                                       isFileLinkVisible
                                     } = {}) => {
  const fileUploadRef = React.useRef(null);

  const fileLinkState = useFileLink();

  const { toggleIsActive } = dropdownState;
  const currentItem = selectedItem || FILE_ATTACHMENT_TYPES[0];

  const onToggleItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    if (selectedItem.uuid === FILE_TYPE) {
      fileUploadRef.current.click();
    }


  };

  const onFileUpload = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target.result;
      addItem(FILE_TYPE, {file, fileContent});
    };
    reader.readAsDataURL(file);

    fileUploadRef.current.value = "";
  };

  const onFileLinkURLSave = (url) => {
    addItem(LINK_TYPE, { url });
  }

  return (
    <>
      <input
        ref={fileUploadRef}
        type="file"
        style={{ position: "fixed", top: "-100em" }}
        onChange={onFileUpload}
        accept={acceptedFileTypes}
      />

      <FileAttachmentList
        items={items}
        updateItem={updateItem}
        deleteItem={deleteItem}
        readOnly={readOnly}
      />
      {!readOnly && (
        <CustomDropdown
          items={FILE_ATTACHMENT_TYPES}
          currentItem={currentItem}
          toggleDropdownMenu={toggleIsActive}
          isDropdownActive={dropdownState.state.isActive}
          setCurrentItem={onToggleItem}
        />
      )}
      {isFileLinkVisible && <FileLink {...fileLinkState} onSave={onFileLinkURLSave}/>}
      <UploadDocumentsErrorMessage
        isError={isError}
        errorMessage={errorMessage}
      />
    </>
  );
};

const LoadingBar = (props) => {
  return "";
};







const UploadDocumentsErrorMessage = ({ isError, errorMessage }) => {
  return <>{isError && <p>Failed: Sorry, {errorMessage}</p>}</>;
};

const ErrorMessage = ({ message }) => {
  return (
    <Columns>
      <Columns.Column className="is-5">Failed: Sorry, {message}</Columns.Column>
      <Columns.Column className="is-align-items-center"></Columns.Column>
      <Columns.Column className="is-2">Try Again</Columns.Column>
    </Columns>
  );
};

export default FileAttachmentUpload;
