import { get } from "../requests";

const PROJECT_REQUEST_API_ENDPOINT = "api/orders/";

export const listRestocks = (filters = {}, store) => {

  const requestFilters = {
    type: "PURCHASE",
    return_request__isnull: true,
    fulfillments__warehouse_id: filters.warehouseId || "",
    search: filters.search,
  }



  return get(PROJECT_REQUEST_API_ENDPOINT, requestFilters).then((response) =>
    response["results"].map(convertRestockAPIResponse)
  );
};

export const getRestock = (id) => {
  return get(PROJECT_REQUEST_API_ENDPOINT + id + "/").then((response) =>
    convertRestockAPIResponse(response)
  );
};

export const convertRestockAPIResponse = (orderAPIResponse) => {
  return {
    uuid: orderAPIResponse.uuid,
    ...orderAPIResponse,
  };
};
