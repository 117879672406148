import React from "react";

const TableWithEditableQuantity = (props) => {
  const items = props.items || [];
  const updateItem = props.updateItem || (() => null);
  const heading = props.heading || "Products Included";
  const isEditing = props.isEditing || false;

  const handleUpdateItem = (name, quantity, checked) => {
    updateItem(name, Number(quantity), checked);
  };

  return (
    <div className="mt-2">
      <table className="table modern">
        <thead>
          <tr>
            <th>{heading}</th>
            <th className="has-text-right">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {items.map(
            (item) =>
              item.checked && (
                <tr key={item.name}>
                  <td>{item.name}</td>
                  <td className="has-text-right">
                    {!isEditing && `${item.quantity}`}
                    {isEditing && (
                      <input
                        style={{ width: "3.5rem" }}
                        className="input is-small"
                        type="number"
                        min={1}
                        max={item.name === "Containers" ? 1 : 1_000_000}
                        value={item.quantity}
                        onChange={(e) => handleUpdateItem(item.name, e.target.value, true)}
                      />
                    )}
                    {isEditing && (
                      <i
                        className="icon-ic-trash has-text-grey-light is-icon pl-2"
                        aria-hidden="true"
                        onClick={() => handleUpdateItem(item.name, 0, false)}
                      />
                    )}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableWithEditableQuantity;
