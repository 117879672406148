import React from "react";
import { Columns } from "react-bulma-components";
import { SelectInbounds } from "../fields/SelectInbounds";

export const SelectInboundSection = (props) => {
  return (
    <Columns className="section-container">
      <Columns.Column size={3}>
        <p
          data-testid="upload-document-subheading"
          className={"title is-4 mb-4"}
        >
          Inbounds
        </p>
        <p>Select the Inbound inventory shipment(s) associated with this project.
        </p>
      </Columns.Column>
      <Columns.Column>
        <SelectInbounds {...props} />
      </Columns.Column>
    </Columns>
  );
};

export const SelectInboundInlineSection = (props) => (<>
  <h5 className="title is-5 mb-4">Inbounds</h5>
  <p className={"mb-4"}>Select the Inbound inventory shipment(s) associated with this project.
  </p>
  <SelectInbounds {...props} />
</>);

export default SelectInboundSection;
